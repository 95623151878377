import { request } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";
import { getCurrency } from "../../plugins/utils";

const CURRENCY = getCurrency();

//lottery navbar drawer
export const apiGetLongDragonList = () =>
  request("get", `/${CURRENCY}/game/helper/`, {}, true);
//
