import Selector from "../../../components/Selector";

const LOTFT3SelectorEnum = {
  "LOTFT3HZ.HZ": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTFT3HZ.HZ_GROUP": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
    },
  },
  "LOTFT3ETHETD.ETD": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT3ETHETF.ETF": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectParams: "name",
    },
  },
  "LOTFT3EBTHBZXH.BZXH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT3EBTHDTXH.DTXH": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      twoSameKeys: [`lottery.bet.title.DM`, `lottery.bet.title.TM`],
      twoSameLimit: [1, 999],
    },
  },
  "LOTFT3SLH.SLH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT3STHSTD.STD": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT3STHSTT.STT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT3SBTH.SBTH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
};

export default LOTFT3SelectorEnum;
