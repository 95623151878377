import { combination } from "../../../functions/math";
import { SELECTED_COUNT_LIMIT } from "../../valudateFunctionEnum";

const FreePositionFunction = {
  //后三一码
  LOTEC5BDW3SBDWH31M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  //中三一码
  LOTEC5BDW3SBDWC31M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  // 前三一码
  LOTEC5BDW3SBDWQ31M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  //后三二码
  LOTEC5BDW3SBDWH32M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  //中三二码
  LOTEC5BDW3SBDWC32M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  //前三二码
  LOTEC5BDW3SBDWQ32M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },

  // 后四一码
  LOTEC5BDW4SBDWH41M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  // 前四一码
  LOTEC5BDW4SBDWQ41M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  //后四二码
  LOTEC5BDW4SBDWH42M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  // 前四二码
  LOTEC5BDW4SBDWQ42M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  //后四三码
  LOTEC5BDW4SBDWH43M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },
  // 前四三码
  LOTEC5BDW4SBDWQ43M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },

  // 五星一码
  LOTEC5BDW5SBDW5S1M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  // 五星二码
  LOTEC5BDW5SBDW5S2M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  // 五星三码
  LOTEC5BDW5SBDW5S3M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },
  // 五星四码
  LOTEC5BDW5SBDW5S4M: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 4);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(4)({ selected });
    },
  },
};

export { FreePositionFunction };
