import React from "react";
import EveryColor from "./components/EveryColor";
import Fast3 from "./components/Fast3";
import MarkSix from "./components/MarkSix";
import PK10 from "./components/PK10";
import VNEveryColor from "./components/VNEveryColor";

function GameResultRender({ data, gameType }) {
  const contentDict = {
    LOTMS: <MarkSix data={data} />,
    LOTFT: <Fast3 data={data} />,
    LOTEC: <EveryColor data={data} />,
    LOTPK10: <PK10 data={data} />,
    LOTVNNEC: <VNEveryColor data={data} />,
  };
  const contentRender = (type) => {
    if (data) {
      if (contentDict[type]) {
        return contentDict[type];
      } else {
        return data?.lottery_number || "-";
      }
    }
    return "-";
  };
  return <div>{contentRender(gameType)}</div>;
}

export default GameResultRender;
