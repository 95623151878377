import parseUrl from "parse-url";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../context/LangContext";
import gameSettingEnum from "./enumeration/gameSettingEnum";

import { useRef } from "react";
import { CustomPopup } from "../../components";
import { DEFAULT_BET_AMOUNT, PRODUCTION_NAME_DEV } from "../../config";
import { useFocus } from "../../context/FocusContext";
import { LotterySettingContextProvider } from "../../context/LotterySettingContext";
import { useWebsocket } from "../../context/WebsocketContext";
import localStorageKey from "../../enumerations/localStorageKey";
import { useGetLatestNumber, useGetRecentGameResult } from "../../hooks/game/game.hook";
import { useGetUserWallet } from "../../hooks/user/user.hook";
import {
  CategoryDropdown,
  ConfirmForm,
  GameResultViewer,
  Navbar,
  OddsAndRuleArea,
  RequestFooter,
} from "./components";
import LotteryResult from "./components/LotteryResult";
import VNGameResultViewer from "./components/VNGameResultViewer";
import selectorEnum from "./enumeration/selectorEnum";
import {
  useGetGameWay,
  useGetGameWayDetailMethod,
  useGetGameWayMethod,
  usePostGameBet,
} from "./hooks/lottery.hooks";
import initCategory from "./settings/initCategory.json";
import styles from "./style.module.scss";

const LotteryContext = createContext({
  gameWayData: undefined,
  currentGameWay: undefined,
  setCurrentGameWay: () => {},
  currentGameWayDetail: undefined,
  setCurrentGameWayDetail: () => {},
  gameWaySetting: {},
  haveSubCategory: false,
  subCategoryVisible: false,
  setSubCategoryVisible: () => {},
  currentLottery: {},
  selected: {},
  setSelected: () => {},
  resetValues: () => {},

  requestItems: [],
  setRequestItems: () => {},
  betConfirm: () => {},
  betRequest: () => {},
  currentBetNumber: undefined,
  setCurrentBetNumber: () => {},
  isLoadingPost: false,
  loading: false,
});
const defaultSelectedMap = (
  s,
  { currentBetNumber, currentGameWay, currentGameWayDetail, currentGameMethod },
) => {
  return {
    methodText: `gameWay.items.${currentGameWay}${
      currentGameWayDetail ? `.${currentGameWayDetail}` : ""
    }${currentGameMethod ? `.${currentGameMethod}` : ""}`,
    method: currentGameMethod,
    content: s,
    simpleMoney: currentBetNumber,
    money: currentBetNumber,
  };
};

export const defaultSelectedProcess = ({
  selected,
  currentBetNumber,
  currentGameWay,
  currentGameWayDetail,
}) => {
  let newSelected = [];
  console.log(selected);
  Object.keys(selected).forEach((key) => {
    newSelected = newSelected?.concat(
      selected[key].map((s) =>
        defaultSelectedMap(s, {
          selected,
          currentBetNumber,
          currentGameWay,
          currentGameWayDetail,
          currentGameMethod: key,
        }),
      ),
    );
  });

  return newSelected;
};

export const defaultRequestProcess = ({
  selected,
  currentBetNumber,
  currentGameWay,
  currentGameWayDetail,
  gameCategoryType,
  gameCategoryDetailType,
  lotteryType,
  period,
  requestItems,
}) => {
  const request = {
    game_category_type: gameCategoryType,
    game_category_detail_type: gameCategoryDetailType,
    game_type: lotteryType,
    game_way_type: currentGameWay,
    game_way_detail_type: currentGameWayDetail,
    period: period,
    choice_number: [],
  };

  request.choice_number = requestItems.map((i) => ({
    game_method_code: i?.method,
    bet_point: i?.simpleMoney,
    bet_content: i?.content,
  }));
  // console.log(request);

  return request;
};

const Lottery = () => {
  const navigate = useNavigate();
  const { lotteryType } = useParams();
  const {
    // openWebsocket,
    // closeWebsocket,
    // prevPeriod,
    currentPeriod,
    // isWebsocketConnected,
    // ping,
    // wsUrl,
    // setWebsocketToken,
    isWaitPeriod,
    counter,
  } = useWebsocket();
  const { t } = useTranslation();
  const language = useLanguage();
  const { focusState } = useFocus();

  const [gameCategoryType, setGameCategoryType] = useState();
  const [gameCategoryDetailType, setGameCategoryDetailType] = useState();
  const [currentGameWay, setCurrentGameWay] = useState();
  const [gameWaySetting, setGameWaySetting] = useState();
  const [isInit, setIsInit] = useState(false);

  const [currentGameWayDetail, setCurrentGameWayDetail] = useState();
  const [popupVisible, setPopupVisible] = useState(false);
  const [showPeriodEndModal, setShowPeriodEndModal] = useState(false);
  const [popupSetting, setPopupSetting] = useState({
    title: "",
    rightButtonText: "",
    leftButtonText: "",
    singleButton: false,
    onSecondaryButtonPress: () => {},
    onMainButtonPress: () => {},
    popupContent: "",
  });

  const [currentSelected, setCurrentSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const [currentBetNumber, setCurrentBetNumber] = useState(DEFAULT_BET_AMOUNT);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [subCategoryVisible, setSubCategoryVisible] = useState(false);
  const [lotteryResultVisible, setLotteryResultVisible] = useState(false);
  const [requestItems, setRequestItems] = useState();

  const [headerTitle, setHeaderTitle] = useState("");
  const [visibleHeaderTitle, setVisibleHeaderTitle] = useState("");

  const haveSubCategory = useRef(false);

  const getUserWallet = useGetUserWallet({
    enabled: !!parseUrl(window.location.href).query?.token === false,
  });

  const { data: gameWayData, isLoading: gameWayLoading } = useGetGameWay({
    params: { lottery: lotteryType },
    options: {
      enabled: !!parseUrl(window.location.href).query?.token === false,
    },
  });

  const {
    data: latestNumber,
    refetch: refetchLatestNumber,
    isLoading: latestNumberLoading,
    isRefetching: latestNumberRefetching,
  } = useGetLatestNumber({
    params: {
      game_way: lotteryType,
    },
    options: {
      enabled: !!lotteryType,
    },
  });

  const { refetch: refetchGameResult } = useGetRecentGameResult(
    {
      enabled: false,
    },
    { game_type: lotteryType },
    currentPeriod?.period || (latestNumber && latestNumber[0].period),
  );

  const refreshData = () => {
    console.debug("refreshing");
    if (lotteryType) refetchLatestNumber();
  };
  const refreshGameResultData = () => {
    if (lotteryType) refetchGameResult();
  };

  useEffect(() => {
    setGameWaySetting(gameWayData?.find((gameWay) => gameWay.game_way_type === currentGameWay));
  }, [currentGameWay]);

  useEffect(() => {
    const newHaveSubCategory = !!gameWaySetting?.game_way_list;
    haveSubCategory.current = newHaveSubCategory;
    if (newHaveSubCategory && gameWaySetting?.game_way_list?.length > 0 && isInit) {
      setCurrentGameWayDetail(gameWaySetting?.game_way_list[0].game_way_detail_type);
    }
  }, [gameWaySetting]);

  const { data: gameWayMethodData, isLoading: gameWayMethodLoading } = useGetGameWayMethod({
    options: {
      enabled: haveSubCategory.current
        ? false
        : currentGameWay !== undefined && !!parseUrl(window.location.href).query?.token === false,
    },
    params: {
      game_way_type: currentGameWay,
      game_way_detail_type: currentGameWayDetail,
    },
  });
  const {
    data: gameWayMethodDetailData,
    // isLoading: gameWayMethodDetailLoading,
  } = useGetGameWayDetailMethod({
    options: { enabled: !!currentGameWayDetail },
    params: { game_way_detail_type: currentGameWayDetail },
  });

  const { mutate: postGameBet, isLoading: isLoadingPost } = usePostGameBet();

  const gameSetting = gameSettingEnum[lotteryType];
  const currentCategoryGameSetting = gameSetting[currentGameWay];
  const currentGameItems = haveSubCategory.current ? gameWayMethodDetailData : gameWayMethodData;
  const groupSet = new Set();

  currentGameItems?.game_method.forEach((method) => {
    if (method.group) {
      groupSet.add(`${method.group}_GROUP`);
    } else {
      groupSet.add(method.code);
    }
  });

  const initLotteryInfo = async () => {
    const initGameWay = initCategory[lotteryType];
    const initGameWayData = gameWayData.find((data) => data.game_way_type === initGameWay);
    const firstGameWay = initGameWayData ? initGameWayData : gameWayData[0];
    setGameCategoryType(firstGameWay.game_category_type);
    setGameCategoryDetailType(firstGameWay.game_category_detail_type);
    if (firstGameWay?.game_way_list) {
      const initGameWayDetail = initCategory[lotteryType];
      const initGameWayDetailData = firstGameWay?.game_way_list.find(
        (data) => data.game_way_detail_type === initGameWayDetail,
      );

      const firstGameWayDetail = initGameWayDetailData
        ? initGameWayDetailData
        : firstGameWay?.game_way_list[0];

      setCurrentGameWay(firstGameWay?.game_way_type);
      setCurrentGameWayDetail(firstGameWayDetail?.game_way_detail_type);
      setHeaderTitle(
        `${t(`gameWay.${firstGameWay?.game_way_type}`)} - ${t(
          `gameWay.${firstGameWayDetail?.game_way_detail_type}`,
        )}`,
      );
      setVisibleHeaderTitle(
        `${t(`gameWay.${firstGameWay?.game_way_type}`)} - ${t(
          `gameWay.${firstGameWayDetail?.game_way_detail_type}`,
        )}`,
      );
    } else {
      setCurrentGameWay(firstGameWay?.game_way_type);
      setHeaderTitle(t(`gameWay.${firstGameWay?.game_way_type}`));
      setVisibleHeaderTitle(t(`gameWay.${firstGameWay?.game_way_type}`));
    }
    setTimeout(() => {
      setIsInit(true);
    }, 100);
  };

  useEffect(() => {
    // console.log(gameWayMethodData, gameWayMethodDetailData);
  }, [gameWayMethodData, gameWayMethodDetailData]);

  const resetValues = () => {
    setCurrentBetNumber(DEFAULT_BET_AMOUNT);
    setCurrentSelected({});
  };
  const resetTitle = () => {
    if (currentGameWayDetail) {
      setHeaderTitle(`${t(`gameWay.${currentGameWay}`)} - ${t(`gameWay.${currentGameWayDetail}`)}`);
      setVisibleHeaderTitle(
        `${t(`gameWay.${currentGameWay}`)} - ${t(`gameWay.${currentGameWayDetail}`)}`,
      );
    } else {
      setHeaderTitle(t(`gameWay.${currentGameWay}`));
      setVisibleHeaderTitle(t(`gameWay.${currentGameWay}`));
    }
  };

  const onHandleBackButtonPress = () => {
    if (confirmVisible) {
      setConfirmVisible(false);
      resetValues();
    } else {
      // closeWebsocket();
      navigate("/", {
        replace: true,
      });
    }
  };

  const updateRequestItems = () => {
    const selectedProcess = currentCategoryGameSetting?.selectedProcess;
    const selectParams = {
      selected: currentSelected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      gameCategoryType,
      gameCategoryDetailType,
      lotteryType,
      currentGameItems,
      // period: currentPeriod.period,
      period: latestNumber ? latestNumber[0].period : null,
    };
    const newSelected = selectedProcess
      ? selectedProcess(selectParams)
      : defaultSelectedProcess(selectParams);

    setRequestItems(newSelected);
  };

  const contentTextProccessor = (content) => {
    if (typeof content == "object") {
      const text = [];
      content?.map((i) => {
        text.push(t(i));
      });
      return text.join(",");
    } else {
      return t(content);
    }
  };

  const validateInput = (betValue) => {
    if (
      currentCategoryGameSetting?.confirmValidation &&
      currentCategoryGameSetting?.confirmValidation?.length > 0
    ) {
      const valiParams = {
        userMoney: getUserWallet.data?.money,
        selected: currentSelected,
        currentBetNumber: betValue,
        currentGameItems,
        currentGameWayDetail,
        currentGameWay,
        currentSelected,
        requestItems,
      };
      const _errorFunc = currentCategoryGameSetting?.confirmValidation?.find((validateFunction) => {
        if (
          typeof validateFunction === "object" &&
          validateFunction.game != (currentGameWayDetail ?? currentGameWay)
        ) {
          return false;
        }

        const _validateFunction =
          typeof validateFunction === "function" ? validateFunction : validateFunction.function;
        return (
          typeof _validateFunction(valiParams) === "string" ||
          typeof _validateFunction(valiParams) === "object"
        );
      });
      console.debug("validate", _errorFunc);
      const errorFunc = typeof _errorFunc === "function" ? _errorFunc : _errorFunc?.function;

      if (errorFunc) {
        if (typeof errorFunc(valiParams) === "string") {
          // Modal.alert({ content: t(errorFunc(valiParams)) });
          setPopupSetting({
            title: t("popup.periodEnd.title"),
            rightButtonText: t("popup.periodEnd.button"),
            leftButtonText: "",
            singleButton: true,
            onSecondaryButtonPress: () => {},
            onMainButtonPress: () => {},
            popupContent: t(errorFunc(valiParams)),
          });
          setPopupVisible(true);
        }
        if (typeof errorFunc(valiParams) === "object") {
          const errorText = errorFunc(valiParams);
          if (errorText.length <= 2) {
            // Modal.alert({ content: t(errorText[0], errorText[1]) });
            setPopupSetting({
              title: t("popup.periodEnd.title"),
              rightButtonText: t("popup.periodEnd.button"),
              leftButtonText: "",
              singleButton: true,
              onSecondaryButtonPress: () => {},
              onMainButtonPress: () => {},
              popupContent: t(errorText[0], errorText[1]),
            });
            setPopupVisible(true);
          } else {
            let params = errorText[1];
            errorText[2].isI18n.forEach((item) => {
              const translated = contentTextProccessor(params[item]);
              params = { ...params, [item]: translated };
            });
            // Modal.alert({ content: t(errorText[0], params) });
            setPopupSetting({
              title: t("popup.periodEnd.title"),
              rightButtonText: t("popup.periodEnd.button"),
              leftButtonText: "",
              singleButton: true,
              onSecondaryButtonPress: () => {},
              onMainButtonPress: () => {},
              popupContent: t(errorText[0], params),
            });
            setPopupVisible(true);
          }
        }

        return false;
      }
      return true;
    }
  };

  const onChangeCategory = () => {
    setLotteryResultVisible(false);
    setSubCategoryVisible((prevState) => {
      return !prevState;
    });
  };

  const betConfirm = async (betValue) => {
    console.debug(betValue);
    const isValid = validateInput(betValue);
    if (isValid === true) {
      setCurrentBetNumber(betValue);
      setConfirmVisible(true);
    }
  };

  const betRequest = async (betValue) => {
    setLoading(true);
    let isEmpty = false;
    requestItems.every((item) => {
      if (
        item.simpleMoney === "0" ||
        item.simpleMoney === 0 ||
        item.simpleMoney === undefined ||
        item.simpleMoney === null
      ) {
        isEmpty = true;
        return false;
      }
      return true;
    });
    const isValid = validateInput(isEmpty ? 0 : betValue);
    if (isValid === true) {
      const requestProcess = currentCategoryGameSetting?.requestProcess;
      const requestParams = {
        selected: currentSelected,
        currentBetNumber,
        currentGameWay,
        currentGameWayDetail,
        gameCategoryType,
        gameCategoryDetailType,
        lotteryType,
        requestItems,
        // period: currentPeriod.period,
        period: latestNumber ? latestNumber[0].period : null,
      };
      const newRequest = requestProcess
        ? requestProcess(requestParams)
        : defaultRequestProcess(requestParams);
      console.log("newRequest", newRequest);
      postGameBet(
        {
          params: newRequest,
        },
        {
          onSuccess: () => {
            setPopupSetting({
              title: t("popup.periodEnd.title"),
              rightButtonText: t("popup.betSuccess.btn.continue"),
              leftButtonText: t("popup.betSuccess.btn.betslip"),
              singleButton: false,
              onSecondaryButtonPress: () => {
                // closeWebsocket();
                resetValues();
                setConfirmVisible(false);
                navigate(`/betHistory?status=&game_type=${lotteryType}`);
              },
              onMainButtonPress: () => {
                resetValues();
                setConfirmVisible(false);
              },
              popupContent: t("popup.betSuccess.content"),
            });
            setPopupVisible(true);
            setLoading(false);
          },
          onError: (error) => {
            setPopupSetting({
              title: t("popup.periodEnd.title"),
              rightButtonText: t("popup.periodEnd.button"),
              leftButtonText: "",
              singleButton: true,
              onSecondaryButtonPress: () => {},
              onMainButtonPress: () => {},
              popupContent: error,
            });
            setPopupVisible(true);
            setLoading(false);
          },
        },
      );
    } else {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!haveSubCategory.current) {
  //     setCurrentGameWayDetail("");
  //     setSubCategoryVisible(false);
  //   } else {
  //     setSubCategoryVisible(true);
  //   }
  // }, [haveSubCategory?.current]);

  useEffect(() => {
    if (!gameWayLoading) {
      initLotteryInfo();
    }
  }, [gameWayData, gameWayLoading]);

  useEffect(() => {
    updateRequestItems();
  }, [currentBetNumber, currentSelected]);

  const handleSignIn = () => {
    //TODO: get token from login logic
    // console.log(window.location, parseUrl(window.location.href));
    const query = parseUrl(window.location.href).query;
    const _lang = query?.lang;
    const _token = query?.token;
    if (_lang) {
      window.localStorage.setItem(localStorageKey.DEFAULT_LANG, _lang);
      console.log(_lang);
      language.changeLanguage(_lang);
    }

    if (_token) {
      window.localStorage.setItem(localStorageKey.TOKEN, _token);
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, query.currency.toLowerCase());
      // setWebsocketToken(query.token);
      window.location.href = `${window.location.origin}/lottery/${lotteryType}`;
    } else {
      // setWebsocketToken(window.localStorage.getItem(localStorageKey.TOKEN));
    }
  };

  useEffect(() => {
    handleSignIn();
    // if (!isWebsocketConnected) {
    //   setWebsocketToken(window.localStorage.getItem(localStorageKey.TOKEN));
    // }
  }, []);
  // useEffect(() => {
  //   openWebsocket({ channel: lotteryType });
  // }, [wsUrl]);

  useEffect(() => {
    resetValues();
    resetTitle();
  }, [currentGameWay, currentGameWayDetail]);

  useEffect(() => {
    if (counter <= 1 && counter !== 0) {
      if (showPeriodEndModal === false && focusState === true) setShowPeriodEndModal(true);
    }
  }, [showPeriodEndModal, focusState, counter]);

  useEffect(() => {
    let modalTimeout;
    if (showPeriodEndModal === true) {
      modalTimeout = setTimeout(() => {
        showPeriodEndModal(false);
      }, [5000]);
    }
    return () => {
      clearTimeout(modalTimeout);
    };
  }, [showPeriodEndModal]);

  if (gameWayLoading) {
    return <div>Loading...</div>;
  }

  return (
    <LotterySettingContextProvider>
      <LotteryContext.Provider
        value={{
          gameWayData: gameWayData,
          currentGameWay: currentGameWay,
          setCurrentGameWay: setCurrentGameWay,
          currentGameWayDetail: currentGameWayDetail,
          setCurrentGameWayDetail: setCurrentGameWayDetail,
          gameWaySetting: gameWaySetting,
          haveSubCategory: haveSubCategory.current,
          subCategoryVisible: subCategoryVisible,
          setSubCategoryVisible: setSubCategoryVisible,
          selected: currentSelected,
          setSelected: setCurrentSelected,
          resetValues: resetValues,
          requestItems: requestItems,
          setRequestItems: setRequestItems,
          betConfirm: betConfirm,
          betRequest: betRequest,
          currentBetNumber: currentBetNumber,
          setCurrentBetNumber: setCurrentBetNumber,

          isLoadingPost: isLoadingPost,
          loading: loading,
        }}
      >
        <div className={styles.lotteryPage}>
          <Navbar
            onHandleBackButtonPress={onHandleBackButtonPress}
            gameType={gameCategoryDetailType}
            headerTitle={visibleHeaderTitle}
            // closeWebsocket={closeWebsocket}
            onChangeCategory={onChangeCategory}
            lotteryType={lotteryType}
          />
          {gameCategoryDetailType === "LOTVNNEC" ? (
            <VNGameResultViewer
              lotteryType={lotteryType}
              gameCategoryDetailType={gameCategoryDetailType}
              isOpenHistory={lotteryResultVisible}
              onOpenHistory={() => {
                setSubCategoryVisible(false);
                setLotteryResultVisible(!lotteryResultVisible);
              }}
              latestNumber={latestNumber}
              refreshData={refreshData}
              latestNumberRefetching={latestNumberRefetching}
            />
          ) : (
            <GameResultViewer
              haveAnimations={["LOTFT"]}
              lotteryType={lotteryType}
              gameCategoryDetailType={gameCategoryDetailType}
              isOpenHistory={lotteryResultVisible}
              onOpenHistory={() => {
                setSubCategoryVisible(false);
                setLotteryResultVisible(!lotteryResultVisible);
              }}
              latestNumber={latestNumber}
              refreshData={refreshData}
              refreshGameResultData={refreshGameResultData}
              lotteryResultVisible={lotteryResultVisible}
              latestNumberRefetching={latestNumberRefetching}
            />
          )}

          <CategoryDropdown
            gameWayData={gameWayData}
            setSubCategoryVisible={setSubCategoryVisible}
            haveSubCategory={haveSubCategory.current}
            gameWaySetting={gameWaySetting}
            haveTags={currentCategoryGameSetting?.haveTags}
            setHeaderTitle={setVisibleHeaderTitle}
          />
          <div
            className={`${styles.container} ${isWaitPeriod === true ? styles.noScroll : ""}`}
            style={{
              background: `no-repeat bottom / cover url(/images/layout/background/${PRODUCTION_NAME_DEV}/bet_bg.webp), no-repeat bottom / cover url(/images/layout/background/bet_bg.webp)`,
            }}
          >
            <OddsAndRuleArea
              currentGameItems={currentGameItems}
              gameWaySetting={haveSubCategory.current ? currentGameWayDetail : currentGameWay}
              currentCategoryGameSetting={currentCategoryGameSetting}
              currentSelected={currentSelected}
            />
            {[...groupSet]?.map((group) => {
              let currentGroupOptions = [];
              currentGameItems?.game_method?.forEach((gameItem) => {
                if (group === `${gameItem.group}_GROUP`) {
                  currentGroupOptions = currentGroupOptions.concat(
                    gameItem?.option?.map((o) => ({
                      ...o,
                      code: gameItem.code,
                    })),
                  );
                  return;
                }
                if (group === gameItem.code) {
                  currentGroupOptions = currentGroupOptions.concat(
                    gameItem?.option?.map((o) => ({
                      ...o,
                      code: gameItem.code,
                    })),
                  );
                  return;
                }
              });
              const selectorSetting =
                selectorEnum[
                  `${haveSubCategory.current ? currentGameWayDetail : currentGameWay}.${group}`
                ];
              const componentParams = selectorSetting?.params ?? {};
              const Selector = selectorSetting?.component;
              if (!Selector) return <div>Loading</div>;

              return (
                <Selector
                  items={currentGroupOptions}
                  title={group}
                  name={group}
                  group={group}
                  key={group}
                  odds={currentGameItems?.odds}
                  selectObjectKey={group}
                  {...componentParams}
                />
              );
            })}
            {isWaitPeriod === true ? (
              <div className={styles.waitPeriod}>{t("lottery.waitTime")}</div>
            ) : null}
          </div>
          <RequestFooter latestNumber={latestNumber} />
        </div>
        <ConfirmForm
          moneyHaveInput={currentCategoryGameSetting?.confirmMoneyHaveInput}
          visible={confirmVisible}
          gameWaySetting={haveSubCategory.current ? currentGameWayDetail : currentGameWay}
          setVisible={setConfirmVisible}
        />
        <LotteryResult
          visible={lotteryResultVisible}
          setVisible={setLotteryResultVisible}
          gameCategoryDetailType={gameCategoryDetailType}
          lotteryType={lotteryType}
          latestNumber={latestNumber}
        />
        <CustomPopup
          title={popupSetting.title}
          rightButtonText={popupSetting.rightButtonText}
          leftButtonText={popupSetting.leftButtonText}
          singleButton={popupSetting.singleButton}
          onSecondaryButtonPress={popupSetting.onSecondaryButtonPress}
          onMainButtonPress={popupSetting.onMainButtonPress}
          visible={popupVisible}
          // visible={true}
          onClose={() => {
            setPopupVisible(false);
          }}
        >
          <div style={{ textAlign: "center" }}>{popupSetting.popupContent}</div>
        </CustomPopup>
        <CustomPopup
          title={t("popup.periodEnd.title")}
          rightButtonText={t("popup.periodEnd.button")}
          visible={showPeriodEndModal}
          singleButton={true}
          onClose={() => {
            setShowPeriodEndModal(false);
          }}
          onSecondaryButtonPress={() => {}}
          onMainButtonPress={() => {
            setShowPeriodEndModal(false);
          }}
        >
          <div>{t("popup.periodEnd.content")}</div>
        </CustomPopup>
      </LotteryContext.Provider>
    </LotterySettingContextProvider>
  );
};

export default Lottery;

export const useLottery = () => {
  const LangValue = useContext(LotteryContext);
  return LangValue;
};
