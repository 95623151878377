import React from "react";
import { AuthContextProvider } from "./AuthContext";
import { FocusContextProvider } from "./FocusContext";
import { LangContextProvider } from "./LangContext";
import { LoadingContextProvider } from "./LoadingContext";
import { WebsocketContextProvider } from "./WebsocketContext";

const ContextList = [
  { name: "FocusContext", context: FocusContextProvider },
  { name: "AuthInfo", context: AuthContextProvider },
  { name: "Lang", context: LangContextProvider },
  { name: "WSProvider", context: WebsocketContextProvider },
  { name: "LoadingProvider", context: LoadingContextProvider },
];

function Context(props) {
  let output = props.children;
  ContextList.forEach((contextInfo) => {
    output = <contextInfo.context>{output}</contextInfo.context>;
  });
  return output;
}

export { AuthContextProvider, LangContextProvider, WebsocketContextProvider };

export default Context;
