import { useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetLongDragonList } from "../service";

export const useGetLongDragonList = ({ options }) => {
  return useQuery(
    [queryKey.LONG_DRAGON_LIST],
    () =>
      apiGetLongDragonList().then((res) => {
        const timezone = res.data.result.timezone;
        return res.data?.data.map((data) => {
          return { ...data, timezone: timezone };
        });
      }),
    {
      ...options,
      cacheTime: 0,
    },
  );
};
