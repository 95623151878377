const EveryColorKeyEnums = {
  //===============五星===============

  LOTEC35S5SZXFS: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
      limit: 1,
    },
    THOUSAND: {
      key: "T",
      name: "千位",
      limit: 1,
    },
    HUNDRED: {
      key: "H",
      name: "百位",
      limit: 1,
    },
    TENS: {
      key: "E",
      name: "十位",
      limit: 1,
    },
    UNIT: {
      key: "U",
      name: "个位",
      limit: 1,
    },
  },
  LOTEC35S5SZXZH: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
      limit: 1,
    },
    THOUSAND: {
      key: "T",
      name: "千位",
      limit: 1,
    },
    HUNDRED: {
      key: "H",
      name: "百位",
      limit: 1,
    },
    TENS: {
      key: "E",
      name: "十位",
      limit: 1,
    },
    UNIT: {
      key: "U",
      name: "个位",
      limit: 1,
    },
  },
  LOTEC35S5SDX120: {
    PICK_NUMBER: {
      key: "XH",
      name: "选号",
      limit: 5,
    },
  },
  LOTEC35S5SDX60: {
    DOUBLE: {
      key: "D",
      name: "二重号",
      limit: 1,
    },
    SINGLE: {
      key: "S",
      name: "单号",
      limit: 3,
    },
  },
  LOTEC35S5SDX30: {
    DOUBLE: {
      key: "D",
      name: "二重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },
  LOTEC35S5SDX20: {
    TRIPLE: {
      key: "TR",
      name: "三重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },
  LOTEC35S5SDX10: {
    TRIPLE: {
      key: "TR",
      name: "三重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },
  LOTEC35S5SDX5: {
    QUAD: {
      key: "Q",
      name: "四重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },

  //===============四星===============

  LOTEC34S4SH4ZXFS: {
    THOUSAND: {
      key: "T",
      name: "千位",
    },
    HUNDRED: {
      key: "H",
      name: "百位",
    },
    TENS: {
      key: "E",
      name: "十位",
    },
    UNIT: {
      key: "U",
      name: "个位",
    },
  },
  LOTEC34S4SH4ZXZH: {
    THOUSAND: {
      key: "T",
      name: "千位",
    },
    HUNDRED: {
      key: "H",
      name: "百位",
    },
    TENS: {
      key: "E",
      name: "十位",
    },
    UNIT: {
      key: "U",
      name: "个位",
    },
  },
  LOTEC34S4SH4DX24: {
    PICK_NUMBER: {
      key: "XH",
      name: "选号",
    },
  },
  LOTEC34S4SH4DX12: {
    DOUBLE: {
      key: "D",
      name: "二重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },
  LOTEC34S4SH4DX6: {
    DOUBLE: {
      key: "D",
      name: "二重号",
    },
  },
  LOTEC34S4SH4DX4: {
    TRIPLE: {
      key: "TR",
      name: "三重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },
  LOTEC34S4SQ4ZXFS: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
    },
    THOUSAND: {
      key: "T",
      name: "千位",
    },
    HUNDRED: {
      key: "H",
      name: "百位",
    },
    TENS: {
      key: "E",
      name: "十位",
    },
  },
  LOTEC34S4SQ4ZXZH: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
    },
    THOUSAND: {
      key: "T",
      name: "千位",
    },
    HUNDRED: {
      key: "H",
      name: "百位",
    },
    TENS: {
      key: "E",
      name: "十位",
    },
    UNIT: {
      key: "U",
      name: "个位",
    },
  },
  LOTEC34S4SQ4DX24: {
    PICK_NUMBER: {
      key: "XH",
      name: "选号",
    },
  },
  LOTEC34S4SQ4DX12: {
    DOUBLE: {
      key: "D",
      name: "二重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },
  LOTEC34S4SQ4DX6: {
    DOUBLE: {
      key: "D",
      name: "二重号",
    },
  },
  LOTEC34S4SQ4DX4: {
    TRIPLE: {
      key: "TR",
      name: "三重号",
    },
    SINGLE: {
      key: "S",
      name: "单号",
    },
  },

  //===============三星===============

  //三星后三直选复式
  LOTEC33S3SH3ZXFS: {
    HUNDRED: {
      key: "H",
      name: "百位",
    },
    TENS: {
      key: "E",
      name: "十位",
    },
    UNIT: {
      key: "U",
      name: "个位",
    },
  },
  LOTEC33S3SH3ZXHZ: {
    HEZHI: {
      key: "HZ",
      name: "和值",
    },
  },
  LOTEC33S3SH3ZXKD: {
    KUADU: {
      key: "KD",
      name: "号码",
    },
  },
  LOTEC33S3SH3ZXHZWS: {
    WEISHU: {
      key: "WS",
      name: "号码",
    },
  },
  LOTEC33S3SH3DX3: {
    HOUSAN_ZUSAN: {
      key: "HZS",
      name: "后三组三",
    },
  },
  LOTEC33S3SH3DX6: {
    HOUSAN_ZULIU: {
      key: "HZL",
      name: "后三组六",
    },
  },
  LOTEC33S3SC3ZXFS: {
    THOUSAND: {
      key: "T",
      name: "千位",
    },
    HUNDRED: {
      key: "H",
      name: "百位",
    },
    TENS: {
      key: "E",
      name: "十位",
    },
  },
  LOTEC33S3SC3ZXHZ: {
    HEZHI: {
      key: "HZ",
      name: "和值",
    },
  },
  LOTEC33S3SC3ZXKD: {
    KUADU: {
      key: "KD",
      name: "号码",
    },
  },
  LOTEC33S3SC3ZXHZWS: {
    WEISHU: {
      key: "WS",
      name: "号码",
    },
  },
  LOTEC33S3SC3DX3: {
    ZHONGSAN_ZUSAN: {
      key: "ZZS",
      name: "中三组三",
    },
  },
  LOTEC33S3SC3DX6: {
    ZHONGSAN_ZULIU: {
      key: "ZZL",
      name: "中三组六",
    },
  },
  LOTEC33S3SQ3ZXFS: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
    },
    THOUSAND: {
      key: "T",
      name: "千位",
    },
    HUNDRED: {
      key: "H",
      name: "百位",
    },
  },
  LOTEC33S3SQ3ZXHZ: {
    HEZHI: {
      key: "HZ",
      name: "和值",
    },
  },
  LOTEC33S3SQ3ZXKD: {
    KUADU: {
      key: "KD",
      name: "号码",
    },
  },
  LOTEC33S3SQ3ZXHZWS: {
    WEISHU: {
      key: "WS",
      name: "号码",
    },
  },
  LOTEC33S3SQ3DX3: {
    QIANSAN_ZUSAN: {
      key: "QZS",
      name: "前三组三",
    },
  },
  LOTEC33S3SQ3DX6: {
    QIANSAN_ZULIU: {
      key: "QZL",
      name: "前三组六",
    },
  },

  //=============二星=============

  //二星后二直选复式
  LOTEC32S2SH2ZXFS: {
    TENS: {
      key: "E",
      name: "十位",
      limit: 1,
    },
    UNIT: {
      key: "U",
      name: "个位",
      limit: 1,
    },
  },
  //二星后二直选和值
  LOTEC32S2SH2ZXHZ: {
    HEZHI: {
      key: "HZ",
      name: "和值",
    },
  },
  //二星后二直选跨度
  LOTEC32S2SH2ZXKD: {
    KUADU: {
      key: "KD",
      name: "号码",
    },
  },
  // 二星后二直选和值尾数
  LOTEC32S2SH2ZXHZWS: {
    WEISHU: {
      key: "WS",
      name: "号码",
    },
  },
  //二星后二组选复式
  LOTEC32S2SH2DXFS: {
    HOU_FUSHI: {
      key: "1",
      name: "后二组选复式",
    },
  },
  //二星前二直选复式
  LOTEC32S2SQ2ZXFS: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
      limit: 1,
    },
    THOUSAND: {
      key: "T",
      name: "千位",
      limit: 1,
    },
  },
  //二星前二直选和值
  LOTEC32S2SQ2ZXHZ: {
    HEZHI: {
      key: "HZ",
      name: "和值",
    },
  },
  //二星前二直选跨度
  LOTEC32S2SQ2ZXKD: {
    KUADU: {
      key: "KD",
      name: "号码",
    },
  },
  //二星前二直选和值尾数
  LOTEC32S2SQ2ZXHZWS: {
    WEISHU: {
      key: "WS",
      name: "号码",
    },
  },
  //二星前二组选复式
  LOTEC32S2SQ2DXFS: {
    QIAN_FUSHI: {
      key: "1",
      name: "前二组选复式",
    },
  },

  //==============不定位=============

  //后三一码
  LOTEC3BDW3SBDWH31M: {
    HOUSAN_YIMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  //中三一码
  LOTEC3BDW3SBDWC31M: {
    ZHONGSAN_YIMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  // 前三一码
  LOTEC3BDW3SBDWQ31M: {
    QIANSAN_YIMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  //后三二码
  LOTEC3BDW3SBDWH32M: {
    HOUSAN_ERMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  //中三二码
  LOTEC3BDW3SBDWC32M: {
    ZHONGSAN_ERMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  //前三二码
  LOTEC3BDW3SBDWQ32M: {
    QIANSAN_ERMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },

  // 后四一码
  LOTEC3BDW4SBDWH41M: {
    HOUSI_YIMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  // 前四一码
  LOTEC3BDW4SBDWQ41M: {
    QIANSI_YIMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  //后四二码
  LOTEC3BDW4SBDWH42M: {
    HOUSI_ERMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  // 前四二码
  LOTEC3BDW4SBDWQ42M: {
    QIANSI_ERMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  //后四三码
  LOTEC3BDW4SBDWH43M: {
    HOUSI_SANMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  // 前四三码
  LOTEC3BDW4SBDWQ43M: {
    QIANSI_SANMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },

  // 五星一码
  LOTEC3BDW5SBDW5S1M: {
    WUXIN_YIMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  // 五星二码
  LOTEC3BDW5SBDW5S2M: {
    WUXIN_ERMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  // 五星三码
  LOTEC3BDW5SBDW5S3M: {
    WUXIN_SANMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },
  // 五星四码
  LOTEC3BDW5SBDW5S4M: {
    WUXIN_SIMA: {
      key: "3SBDW",
      name: "不定位",
    },
  },

  //==============趣味==============

  //一帆風順
  LOTEC3QWTS1FFS: {
    YIFEN_FENGSHUN: {
      key: "TS1FFS",
      name: "一帆风顺",
    },
  },
  // 好事成双
  LOTEC3QWTSHSCS: {
    HAOSHI: {
      key: "TSHSCS",
      name: "好事成双",
    },
  },
  //三星报喜
  LOTEC3QWTS3SBC: {
    SANXING_BAOXI: {
      key: "TS3SBC",
      name: "三星报喜",
    },
  },
  //四季发财
  LOTEC3QWTS4GFC: {
    SIJI_FACAI: {
      key: "TS4GFC",
      name: "四季发财",
    },
  },
  // 五谷丰登
  LOTEC3QWTS5KDF: {
    WUGU_FENGDENG: {
      key: "TS5KDF",
      name: "五谷丰登",
    },
  },

  //==============大小雙單==============

  LOTEC3DXDS: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
      limit: 1,
    },
    THOUSAND: {
      key: "T",
      name: "千位",
      limit: 1,
    },
    HUNDRED: {
      key: "H",
      name: "百位",
      limit: 1,
    },
    TENS: {
      key: "E",
      name: "十位",
      limit: 1,
    },
    UNIT: {
      key: "U",
      name: "个位",
      limit: 1,
    },
    TOTAL: {
      key: "TOTAL",
      name: "總和",
    },
  },

  //复式
  LOTEC3RXR2FX: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
      limit: 1,
    },
    THOUSAND: {
      key: "T",
      name: "千位",
      limit: 1,
    },
    HUNDRED: {
      key: "H",
      name: "百位",
      limit: 1,
    },
    TENS: {
      key: "E",
      name: "十位",
      limit: 1,
    },
    UNIT: {
      key: "U",
      name: "个位",
      limit: 1,
    },
  },
  //组选
  LOTEC3RXR2DX: {
    POSITION: {
      key: "position",
      name: "位置",
    },
    NUMBER: {
      key: "number",
      name: "號碼",
    },
  },
  // 复式
  LOTEC3RXR3FX: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
      limit: 1,
    },
    THOUSAND: {
      key: "T",
      name: "千位",
      limit: 1,
    },
    HUNDRED: {
      key: "H",
      name: "百位",
      limit: 1,
    },
    TENS: {
      key: "E",
      name: "十位",
      limit: 1,
    },
    UNIT: {
      key: "U",
      name: "个位",
      limit: 1,
    },
  },
  // 组三
  LOTEC3RXR3Z3: {
    POSITION: {
      key: "position",
      name: "位置",
    },
    NUMBER: {
      key: "number",
      name: "號碼",
    },
  },
  // 组六
  LOTEC3RXR3Z6: {
    POSITION: {
      key: "position",
      name: "位置",
    },
    NUMBER: {
      key: "number",
      name: "號碼",
    },
  },
  //复式
  LOTEC3RXR4FX: {
    TEN_THOUSAND: {
      key: "W",
      name: "万位",
      limit: 1,
    },
    THOUSAND: {
      key: "T",
      name: "千位",
      limit: 1,
    },
    HUNDRED: {
      key: "H",
      name: "百位",
      limit: 1,
    },
    TENS: {
      key: "E",
      name: "十位",
      limit: 1,
    },
    UNIT: {
      key: "U",
      name: "个位",
      limit: 1,
    },
  },
};

export default EveryColorKeyEnums;
