import BetHistory from "../pages/BetHistory";
import GameResult from "../pages/GameResult";
import GameResultDetail from "../pages/GameResultDetail";
import Home from "../pages/Home";
import Lottery from "../pages/Lottery";
import Signin from "../pages/Signin";
// import BetHistoryDetail from "../pages/BetHistoryDetail";
import GameRules from "../pages/GameRules";
import LongDragon from "../pages/LongDragon";
import LongDragonDescription from "../pages/LongDragonDescription";

const RouterList = [
  {
    path: "/",
    name: "home",
    component: Home,
    navType: {
      hideHeader: true,
      hideFooter: false,
      background: "var(--background)",
      left: {
        type: "user",
      },
      center: {},
      right: { type: "user" },
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: Signin,
    navType: {
      hideHeader: true,
      hideFooter: true,
      background: "var(--background)",
    },
  },
  {
    path: "/lottery/:lotteryType",
    name: "lottery",
    component: Lottery,
    navType: {
      hideHeader: true,
      hideFooter: true,
      left: {
        type: "user",
      },
      center: {},
      right: { type: "user" },
    },
  },
  {
    path: "/longType",
    name: "longType",
    component: LongDragon,
    navType: {
      hideHeader: true,
      hideFooter: true,
      left: {
        type: "user",
      },
      center: {},
      right: { type: "user" },
    },
  },
  {
    path: "/longType/description",
    name: "longTypeDescription",
    component: LongDragonDescription,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: "/longType",
      },
      center: {
        title: "longdragon.desc.pageTitle",
        titleEllipsis: true,
      },
    },
  },
  {
    path: "/betHistory",
    name: "betHistory",
    component: BetHistory,
    navType: {
      hideHeader: true,
      hideFooter: false,
      background: "var(--adm-color-white)",
    },
  },
  // {
  //   path: "/betHistory/detail/:id",
  //   name: "betHistoryDetail",
  //   component: BetHistoryDetail,
  //   navType: {
  //     hideHeader: false,
  //     hideFooter: true,
  //     background: "var(--adm-color-white)",
  //     left: {
  //       backURL: "/betHistory",
  //     },
  //     center: {
  //       title: "home.betHistory.title",
  //     },
  //   },
  // },
  {
    path: "/gameResult",
    name: "gameResult",
    component: GameResult,
    navType: {
      hideHeader: false,
      hideFooter: false,
      background: "var(--adm-color-white)",
      renderBackButton: false,
      left: {
        backURL: "/",
      },
      center: {
        title: "page.gameResult",
      },
    },
  },
  {
    path: "/gameResult/detail/:game",
    name: "gameResultDetail",
    component: GameResultDetail,
    navType: {
      hideHeader: true,
      hideFooter: true,
      background: "var(--adm-color-white)",
      left: {
        backURL: -1,
      },
      center: {
        title: "page.gameResult",
      },
    },
  },
  {
    path: "/gameRules",
    name: "gameRules",
    component: GameRules,
    navType: {
      hideHeader: true,
      hideFooter: true,
      background: "var(--adm-color-white)",
      left: {
        backURL: "/",
      },
      center: {
        title: "page.gameRules",
      },
    },
  },
];
export default RouterList;
