import { Button, DotLoading, Image, InfiniteScroll, Popup, Selector } from "antd-mobile";
import { DownFill } from "antd-mobile-icons";
import Big from "big.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomNavbar, CustomTabs } from "../../components";
import { useLoading } from "../../context/LoadingContext";
import { moneyFormat } from "../../plugins/numberFormat";
import { getCurrency } from "../../plugins/utils";
import { GameFilter, HistoryItem } from "./components";
import DetailPopup from "./components/DetailPopup";
import {
  dateRange,
  dateSelectorEnum,
  selectorOptions,
  tabKeyEnum,
} from "./enumeration/dateSelectItem";
import statusEnum from "./enumeration/statusEnum";
import { useGetBetHistory } from "./hooks/betHistory.hooks";
import styles from "./style.module.scss";
import "./styles.scss";
const CURRENCY = getCurrency().toUpperCase();
//0 開始 1 成功 2 失敗 3 取消 4 處理中 5 審核過 6 審核敗

const BetHistory = ({
  isPopup = false,
  onClosePopup = undefined,
  popupGameType = undefined,
  popupGame = undefined,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const location = useLocation();
  // const _state = location?.state;
  const [params] = useSearchParams();
  const start_date = params.get("start_date");
  const end_date = params.get("end_date");
  const game_type = params.get("game_type");
  const status = params.get("status");

  const { setIsLoading } = useLoading();

  const [date, setDate] = useState("");
  const [gameSelectVisible, setGameSelectVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange[dateSelectorEnum.TODAY]);
  const [items, setItems] = useState(status || "");
  const [gameType, setGameType] = useState(isPopup ? popupGame : game_type || "");
  const [sumWinLose, setSumWinLose] = useState(0);
  const [sumTotalBetPoint, setSumTotalBetPoint] = useState(0);

  const [selectedItem, setSelectedItem] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const {
    data: getBetRecord,
    hasNextPage,
    fetchNextPage,
    isLoading: isBetRecordLoading,
    // isError: isBetRecordError,
    // isSuccess,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    refetch: refetchBetRecord,
  } = useGetBetHistory({
    params: {
      start_date: start_date ? start_date : selectedDateRange[0],
      end_date: end_date ? end_date : selectedDateRange[1],
      status: items,
      game_type: gameType,
    },
    options: {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < lastPage.last_page) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      cacheTime: 0,
    },
  });

  const renderRightIcon = (
    <Image
      src={"/images/header/reload@3x.webp"}
      height={34}
      fit={"contain"}
      onClick={() => {
        // window.location.reload();
        refetchBetRecord();
      }}
    />
  );

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <DotLoading />
          </>
        ) : (
          <span>{t("normal.infiniteScroll.bottom")}</span>
        )}
      </>
    );
  };

  const loadMore = async () => {
    // await sleep(2000);
    if (isFetchingNextPage === false) {
      await fetchNextPage();
    }
  };
  const onHandleSelectGame = (e) => {
    const type = e.toString();
    setGameType(type);
  };

  const onHandleSelector = (e) => {
    const type = e;
    setSelectedDateRange(dateRange[type]);
    setDate(type);
    setFilterVisible(false);
  };

  const tabItems = [
    {
      title: t("lottery.gameType.all"),
      key: "",
    },
    {
      title: t("history.pending"),
      key: 0,
    },
    {
      title: t("history.wonLottery"),
      key: 1,
    },
    {
      title: t("history.loseLottery"),
      key: 2,
    },
    {
      title: t("history.cancelled"),
      key: 4,
    },
    {
      title: t("history.returned"),
      key: 5,
    },
  ];

  const getSumData = () => {
    let newSumWinLose = new Big(0);
    let newSumTotalBetPoint = new Big(0);

    if (getBetRecord?.pages?.length > 0) {
      getBetRecord.pages.map((pageData) => {
        newSumWinLose = newSumWinLose.plus(pageData.sum_win_lose_point);
        newSumTotalBetPoint = newSumTotalBetPoint.plus(pageData.sum_total_bet_point);
      });
    }
    setSumWinLose(parseFloat(newSumWinLose));
    setSumTotalBetPoint(parseFloat(newSumTotalBetPoint));
  };

  const onShowDetail = (_selectedId) => {
    setSelectedItem(_selectedId);
    setShowDetail(true);
  };

  useEffect(() => {
    if (!!getBetRecord) {
      // console.log(getBetRecord);
      getSumData();
    }
  }, [getBetRecord]);

  useEffect(() => {
    // console.debug("isdddd", isFetchingNextPage, isFetching);
    if (isBetRecordLoading || (isFetching && !isFetchingNextPage)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isFetchingNextPage, isFetching, isBetRecordLoading]);

  const renderNavigationContent = () => {
    if (isPopup) {
      return t(`lottery.gameType.${popupGame}`);
    }

    return (
      <GameFilter
        visible={gameSelectVisible}
        setVisible={setGameSelectVisible}
        onChange={onHandleSelectGame}
        selectedGame={gameType}
      />
    );
  };

  return (
    <div id={"bet-history"} className={`${styles.container} bet-container`}>
      <CustomNavbar
        onBack={() => {
          if (isPopup) {
            onClosePopup();
            return;
          }
          navigate("/");
        }}
        right={renderRightIcon}
      >
        {renderNavigationContent()}
      </CustomNavbar>
      <CustomTabs
        tabItems={tabItems}
        activeKey={items}
        zIndex={gameSelectVisible ? undefined : 9998}
        defaultActiveKey={status || ""}
        tabOnChange={(e) => {
          console.log("e", e);
          setItems(e);
        }}
        rightSideWidth="auto"
        rightSideRender={() => {
          return (
            <>
              <Button
                block
                shape="rounded"
                size="mini"
                style={{
                  background: "linear-gradient(to bottom, #fff 2%, #ffc4c4 68%, #fff)",
                  padding: "4px",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  setFilterVisible(!filterVisible);
                }}
              >
                <span
                  style={{
                    color: "#830000",
                  }}
                >
                  {date ? t(`search.${date}`) : t(`search.today`)}
                </span>{" "}
                {filterVisible ? (
                  <DownFill
                    fontSize={10}
                    color="var(--color-main-active)"
                    style={{ transform: "rotate(180deg)" }}
                  />
                ) : (
                  <DownFill fontSize={10} />
                )}
              </Button>
            </>
          );
        }}
      />

      {getBetRecord?.pages[0]?.data.length >= 1 ? (
        <div className={`${styles.betContent} custom-card`}>
          {/* {isBetRecordError ? (
            ""
          ) : isBetRecordLoading ? (
            ""
          ) : (
            <>
              {isSuccess &&
                getBetRecord?.pages?.map((perPageData, pageIndex) => {
                  return perPageData?.data?.map((i, index) => {
                    return <HistoryItem key={"data" + i.id} item={i} />;
                  });
                })}
              <InfiniteScroll
                loadMore={loadMore}
                hasMore={hasNextPage}
              ></InfiniteScroll>
            </>
          )} */}

          {getBetRecord?.pages?.map((perPageData, pageIndex) => {
            return perPageData?.data?.map((i, index) => {
              return <HistoryItem key={"data" + i.id} item={i} onShowDetail={onShowDetail} />;
            });
          })}
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <InfiniteScrollContent hasMore={hasNextPage} />
          </InfiniteScroll>

          <div className={styles.bottomCount}>
            <div className={styles.group1}>
              <div />
              <div className={styles.subTotal}>{t("history.subtotal")}</div>
            </div>
            <div className={styles.group1}>
              <div className={styles.groupTitle}>
                {t(`history.subtotalBetAmount`)}
                {`(${CURRENCY})`}
              </div>
              <div className={styles.groupNum}>{moneyFormat(sumTotalBetPoint)}</div>
            </div>
            <div className={styles.group2}>
              <div className={styles.groupTitle}>
                {t("history.subtotalWinAmount")}
                {`(${CURRENCY})`}
              </div>
              <div className={styles.groupNum1}>{moneyFormat(sumWinLose)}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          {t("betHistory.noData")}
        </div>
      )}
      <Popup
        className="bet-history-game-filter"
        visible={filterVisible}
        mask={true}
        position="top"
        destroyOnClose="true"
        closeOnMaskClick={true}
        onClose={() => {
          setFilterVisible(false);
        }}
        getContainer={() => document.getElementById("bet-history")}
      >
        <div style={{ marginTop: 80 }} className={styles.filterPopup}>
          <div className={styles.selectorGroup}>
            <Selector
              columns={3}
              style={{
                "--border-radius": "4px",
                "--border": "solid var(--color-item-disable) 1px",
                "--checked-border": "solid var(--color-main-active) 1px",
                "--color": "none",
                "--text-color": "var(--color-tab-text)",
                "--checked-text-color": "var(--color-tab-text)",
                "--checked-color": "var(--color-background-active)",
                fontSize: "var(--fontSetting-middle)",
              }}
              showCheckMark={false}
              onChange={(e) => {
                onHandleSelector(e);
                console.log(e);
              }}
              options={selectorOptions[tabKeyEnum.DATE].map((opt) => {
                return { ...opt, label: t(`${opt.label}`) };
              })}
              className="betHistory-date-filter"
            />
          </div>
        </div>
      </Popup>
      <DetailPopup
        visible={showDetail}
        onClose={() => {
          setShowDetail(false);
        }}
        detailId={selectedItem}
        statusDict={statusEnum}
      />
    </div>
  );
};

export default BetHistory;
