import { request } from "../../apis";
import { getCurrency } from "../../plugins/utils";

const CURRENCY = getCurrency();

export const apiGetEachGameResult = (params) =>
  request("get", `/${CURRENCY}/game/record`, params, true);

export const apiGetGameList = (params) =>
  request("get", `/${CURRENCY}/game/category/LOT`, params, true);

export const getLatestNumber = ({ params }) =>
  request("get", `/${CURRENCY}/game/way/${params.game_way}/period`, params, true);
