import { Image, NavBar } from "antd-mobile";
import backIcon from "./images/back.webp";
import "./style.scss";

const CustomNavbar = ({
  children,
  back = "",
  backArrow = true,
  left,
  onBack = () => {},
  right,
  customStyle = "",
}) => {
  const renderBackArrow = <Image src={backIcon} height={34} fit={"contain"} />;

  return (
    <NavBar
      className={`custom-navbar ${customStyle}`}
      back={back}
      backArrow={backArrow === true ? renderBackArrow : backArrow}
      left={left}
      onBack={onBack}
      right={right}
    >
      {children}
    </NavBar>
  );
};

export default CustomNavbar;
