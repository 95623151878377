export default {
  LOTFT10HZ: {
    hint: "lottery.rules.modal.LOTFT3HZ.hint",
    description: "lottery.rules.modal.LOTFT3HZ.description",
    example: "lottery.rules.modal.LOTFT3HZ.example",
  },
  LOTFT10ETH: {
    hint: {
      LOTFT10ETHETD: "lottery.rules.modal.LOTFT3ETHETD.hint",
      LOTFT10ETHETF: "lottery.rules.modal.LOTFT3ETHETF.hint",
    },
    description: {
      LOTFT10ETHETD: "lottery.rules.modal.LOTFT3ETHETD.description",
      LOTFT10ETHETF: "lottery.rules.modal.LOTFT3ETHETF.description",
    },
    example: {
      LOTFT10ETHETD: "lottery.rules.modal.LOTFT3ETHETD.example",
      LOTFT10ETHETF: "lottery.rules.modal.LOTFT3ETHETF.example",
    },
  },
  LOTFT10EBTH: {
    hint: {
      LOTFT10EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.hint",
      LOTFT10EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.hint",
    },
    description: {
      LOTFT10EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.description",
      LOTFT10EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.description",
    },
    example: {
      LOTFT10EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.example",
      LOTFT10EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.example",
    },
  },
  LOTFT10SLH: {
    hint: "lottery.rules.modal.LOTFT3SLH.hint",
    description: "lottery.rules.modal.LOTFT3SLH.description",
    example: "lottery.rules.modal.LOTFT3SLH.example",
  },
  LOTFT10STH: {
    hint: {
      LOTFT10STHSTD: "lottery.rules.modal.LOTFT3STHSTD.hint",
      LOTFT10STHSTT: "lottery.rules.modal.LOTFT3STHSTT.hint",
    },
    description: {
      LOTFT10STHSTD: "lottery.rules.modal.LOTFT3STHSTD.description",
      LOTFT10STHSTT: "lottery.rules.modal.LOTFT3STHSTT.description",
    },
    example: {
      LOTFT10STHSTD: "lottery.rules.modal.LOTFT3STHSTD.example",
      LOTFT10STHSTT: "lottery.rules.modal.LOTFT3STHSTT.example",
    },
  },
  LOTFT10SBTH: {
    hint: "lottery.rules.modal.LOTFT3SBTH.hint",
    description: "lottery.rules.modal.LOTFT3SBTH.description",
    example: "lottery.rules.modal.LOTFT3SBTH.example",
  },
};
