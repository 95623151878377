export default {
  LOTPK1010GYJH: {
    hint: "lottery.rules.modal.LOTPK105GYJH.hint",
    description: "lottery.rules.modal.LOTPK105GYJH.description",
    example: "lottery.rules.modal.LOTPK105GYJH.example",
  },
  LOTPK1010QY: {
    hint: "lottery.rules.modal.LOTPK105QY.hint",
    description: "lottery.rules.modal.LOTPK105QY.description",
    example: "lottery.rules.modal.LOTPK105QY.example",
  },
  LOTPK1010QE: {
    hint: "lottery.rules.modal.LOTPK105QE.hint",
    description: "lottery.rules.modal.LOTPK105QE.description",
    example: "lottery.rules.modal.LOTPK105QE.example",
  },
  LOTPK1010QS: {
    hint: "lottery.rules.modal.LOTPK105QS.hint",
    description: "lottery.rules.modal.LOTPK105QS.description",
    example: "lottery.rules.modal.LOTPK105QS.example",
  },
  
  LOTPK1010DWD: {
    hint: {
      LOTPK1010DWD0110: "lottery.rules.modal.LOTPK105DWD0110.hint",
      LOTPK1010DWD0105: "lottery.rules.modal.LOTPK105DWD0105.hint",
      LOTPK1010DWD0610: "lottery.rules.modal.LOTPK105DWD0610.hint",
    },
    description: {
      LOTPK1010DWD0110: "lottery.rules.modal.LOTPK105DWD0110.description",
      LOTPK1010DWD0105: "lottery.rules.modal.LOTPK105DWD0105.description",
      LOTPK1010DWD0610: "lottery.rules.modal.LOTPK105DWD0610.description",
    },
    example: {
      LOTPK1010DWD0110: "lottery.rules.modal.LOTPK105DWD0110.example",
      LOTPK1010DWD0105: "lottery.rules.modal.LOTPK105DWD0105.example",
      LOTPK1010DWD0610: "lottery.rules.modal.LOTPK105DWD0610.example",
    },
  },
  LOTPK1010WX: {
    hint: {
      LOTPK1010WXGJ: "lottery.rules.modal.LOTPK105WXGJ.hint",
      LOTPK1010WXYJ: "lottery.rules.modal.LOTPK105WXYJ.hint",
      LOTPK1010WXJJ: "lottery.rules.modal.LOTPK105WXJJ.hint",
    },
    description: {
      LOTPK1010WXGJ: "lottery.rules.modal.LOTPK105WXGJ.description",
      LOTPK1010WXYJ: "lottery.rules.modal.LOTPK105WXYJ.description",
      LOTPK1010WXJJ: "lottery.rules.modal.LOTPK105WXJJ.description",
    },
    example: {
      LOTPK1010WXGJ: "lottery.rules.modal.LOTPK105WXGJ.example",
      LOTPK1010WXYJ: "lottery.rules.modal.LOTPK105WXYJ.example",
      LOTPK1010WXJJ: "lottery.rules.modal.LOTPK105WXJJ.example",
    },
  },
  LOTPK1010DXDS: {
    hint: {
      LOTPK1010DXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.hint",
      LOTPK1010DXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.hint",
    },
    description: {
      LOTPK1010DXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.description",
      LOTPK1010DXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.description",
    },
    example: {
      LOTPK1010DXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.example",
      LOTPK1010DXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.example",
    },
  },
  LOTPK1010LH: {
    hint: "lottery.rules.modal.LOTPK105LH.hint",
    description: "lottery.rules.modal.LOTPK105LH.description",
    example: "lottery.rules.modal.LOTPK105LH.example",
  },
  LOTPK1010NN: {
    hint: {
      LOTPK1010NNPB: "lottery.rules.modal.LOTPK105NNPB.hint",
      LOTPK1010NNFB: "lottery.rules.modal.LOTPK105NNPB.hint",
    },
    description: {
      LOTPK1010NNPB: "lottery.rules.modal.LOTPK105NNPB.description",
      LOTPK1010NNFB: "lottery.rules.modal.LOTPK105NNPB.description",
    },
    example: {
      LOTPK1010NNPB: "lottery.rules.modal.LOTPK105NNPB.example",
      LOTPK1010NNFB: "lottery.rules.modal.LOTPK105NNPB.example",
    },
  },
};
