import Selector from "../../../components/Selector";

const OFLOTMSMOSelectorEnum = {
  // 特碼
  "OFLOTMSMOTMTM.TM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSMOTMTM.TM_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 半波
  "OFLOTMSMOTMBP.BP_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 特碼生肖
  "OFLOTMSMOTMSX.TMSX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 一肖
  "OFLOTMSMOYX.YX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "OFLOTMSMOYX.TW_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正肖
  "OFLOTMSMOZX.ZOX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "OFLOTMSMOZX.QSB": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連肖
  //  二肖碰
  "OFLOTMSMOLXEXP.EXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  三肖碰
  "OFLOTMSMOLXSAXP.SAXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  四肖碰
  "OFLOTMSMOLXSIXP.SIXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  五肖碰
  "OFLOTMSMOLXWXP.WXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連尾
  //  二尾连
  "OFLOTMSMOLWELW.ELW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  三尾连
  "OFLOTMSMOLWSALW.SALW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  四尾连
  "OFLOTMSMOLWSILW.SILW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  五尾连
  "OFLOTMSMOLWWLW.WLW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 合肖
  //  中
  "OFLOTMSMOHXZ.Z": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  不中
  "OFLOTMSMOHXBZ.BZ": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 連碼
  //  四全中
  "OFLOTMSMOLMSIQZ.SIQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三全中
  "OFLOTMSMOLMSAQZ.SAQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三中二
  "OFLOTMSMOLMSZE.SZE": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二全中
  "OFLOTMSMOLMEQZ.EQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二中特
  "OFLOTMSMOLMEZT.EZT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  特串
  "OFLOTMSMOLMTC.TC": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選不中
  //  五不中
  "OFLOTMSMOTSBCWBZ.WBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六不中
  "OFLOTMSMOTSBCLBZ.LBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  七不中
  "OFLOTMSMOTSBCQBZ.QBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  八不中
  "OFLOTMSMOTSBCBBZ.BBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  九不中
  "OFLOTMSMOTSBCJBZ.JBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十不中
  "OFLOTMSMOTSBCSBZ.SBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十一不中
  "OFLOTMSMOTSBCSYBZ.SYBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十二不中
  "OFLOTMSMOTSBCSEBZ.SEBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選中一
  //  三中一
  "OFLOTMSMOCYSAZY.SAZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  四中一
  "OFLOTMSMOCYSIZY.SIZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  五中一
  "OFLOTMSMOCYWZY.WZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六中一
  "OFLOTMSMOCYLZY.LZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 正码
  "OFLOTMSMOCMCM.CM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  // 正码 1
  "OFLOTMSMOCMCM1.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSMOCMCM1.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 2
  "OFLOTMSMOCMCM2.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSMOCMCM2.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 3
  "OFLOTMSMOCMCM3.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSMOCMCM3.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 4
  "OFLOTMSMOCMCM4.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSMOCMCM4.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 5
  "OFLOTMSMOCMCM5.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSMOCMCM5.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 6
  "OFLOTMSMOCMCM6.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSMOCMCM6.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
};

export default OFLOTMSMOSelectorEnum;
