const queryKey = {
  HOME_GAME_LIST: "homeGameList",
  LOTTERY_POPUP_GAME_LIST: "lotteryPopupGameList",
  TRANSACTION_HISTORY: "transaction_history",
  USER_WALLET: "user_wallet",
  USER_WALLET_CACHE: "user_wallet_cache",
  BET_HISTORY: "bet_history",
  LOTTERY_GAME_WAY: "lottery_game_way",
  LOTTERY_GAME_WAY_METHOD: "lottery_game_way_method",
  LOTTERY_GAME_WAY_DETAIL_METHOD: "lottery_game_way_detail_method",
  POST_GAME_BET: "post_game_bet",
  BET_DETAIL: "bet_detail",
  ALL_LATEST_GAME_RESULT: "all_latest_game_result",
  USER_INFO: "user_info_meta",
  EACH_GAME_RESULT: "each_game_result",
  RECENT_GAME_RESULT: "recent_game_result",
  GAME_LIST_TYPE_DICT: "game_list_type_dict",
  BET_HISTORY_GAME_LIST: "bet_history_game_list",
  GAME_RECORD: "game_record",
  GAME_MAIN_CATEGORY: "game_main_category",
  LONG_DRAGON_LIST: "long_dragon_list",
  LATEST_NUMBER: "latest_number",
};

export default queryKey;
