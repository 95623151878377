import { Popover } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomNavbar } from "../../../../components";
import Question from "./icons/Question";
import Setting from "./icons/Setting";
import styles from "./style.module.scss";
import "./style.scss";

const Navbar = ({ onHandleBackButtonPress, headerTitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actions = [
    {
      key: "betHistory",
      text: t("home.betHistory.title"),
      path: `/betHistory`,
    },
    {
      key: "gameResult",
      text: t("page.gameResult"),
      path: `/gameResult`,
    },
    {
      key: "rules",
      text: t("page.rules"),
      path: `/gameRules`,
    },
  ];

  const onOpenDescription = () => {
    navigate("/longType/description");
  };

  const right = (
    <div className={styles.rightGroup}>
      <div className={styles.item}>
        <Popover.Menu
          mode="dark"
          actions={actions}
          placement="bottom-end"
          onAction={(node) => navigate(node?.path)}
          trigger="click"
          className="lottery-navbar-popover"
        >
          <Setting size={34} />
        </Popover.Menu>
      </div>
      <div className={styles.item} onClick={onOpenDescription}>
        <Question size={34} />
      </div>
    </div>
  );

  return (
    <div>
      <CustomNavbar onBack={onHandleBackButtonPress} right={right}>
        <span>{headerTitle}</span>
      </CustomNavbar>
    </div>
  );
};

export default Navbar;
