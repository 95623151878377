const LOTVNNEC120RuleEnum = {
  LOTVNNEC120BX: {
    hint: {
      LOTVNNEC120BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.hint",
      LOTVNNEC120BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.hint",
      LOTVNNEC120BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.hint",
      LOTVNNEC120BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.hint",
      LOTVNNEC120BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.hint",
      LOTVNNEC120BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.hint",
      LOTVNNEC120BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.hint",
      LOTVNNEC120BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.hint",
      LOTVNNEC120BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.hint",
      LOTVNNEC120BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.hint",
    },
    description: {
      LOTVNNEC120BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.description",
      LOTVNNEC120BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.description",
      LOTVNNEC120BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.description",
      LOTVNNEC120BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.description",
      LOTVNNEC120BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.description",
      LOTVNNEC120BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.description",
      LOTVNNEC120BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.description",
      LOTVNNEC120BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.description",
      LOTVNNEC120BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.description",
      LOTVNNEC120BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.description",
    },
    example: {
      LOTVNNEC120BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.example",
      LOTVNNEC120BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.example",
      LOTVNNEC120BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.example",
      LOTVNNEC120BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.example",
      LOTVNNEC120BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.example",
      LOTVNNEC120BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.example",
      LOTVNNEC120BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.example",
      LOTVNNEC120BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.example",
      LOTVNNEC120BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.example",
      LOTVNNEC120BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.example",
    },
  },
  LOTVNNEC120CG: {
    hint: {
      LOTVNNEC120CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.hint",
      LOTVNNEC120CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.hint",
      LOTVNNEC120CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.hint",
    },
    description: {
      LOTVNNEC120CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.description",
      LOTVNNEC120CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.description",
      LOTVNNEC120CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.description",
    },
    example: {
      LOTVNNEC120CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.example",
      LOTVNNEC120CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.example",
      LOTVNNEC120CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.example",
    },
  },
  LOTVNNEC1202S: {
    hint: {
      LOTVNNEC1202STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.hint",
      LOTVNNEC1202S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.hint",
      LOTVNNEC1202ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.hint",
      LOTVNNEC1202STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.hint",
      LOTVNNEC1202S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.hint",
      LOTVNNEC1202ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.hint",
      LOTVNNEC1202S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.hint",
    },
    description: {
      LOTVNNEC1202STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.description",
      LOTVNNEC1202S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.description",
      LOTVNNEC1202ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.description",
      LOTVNNEC1202STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.description",
      LOTVNNEC1202S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.description",
      LOTVNNEC1202ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.description",
      LOTVNNEC1202S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.description",
    },
    example: {
      LOTVNNEC1202STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.example",
      LOTVNNEC1202S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.example",
      LOTVNNEC1202ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.example",
      LOTVNNEC1202STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.example",
      LOTVNNEC1202S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.example",
      LOTVNNEC1202ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.example",
      LOTVNNEC1202S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.example",
    },
  },
  LOTVNNEC1203S: {
    hint: {
      LOTVNNEC1203STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.hint",
      LOTVNNEC1203S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.hint",
      LOTVNNEC1203ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.hint",
      LOTVNNEC1203STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.hint",
      LOTVNNEC1203STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.hint",
      LOTVNNEC1203STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.hint",
      LOTVNNEC1203S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.hint",
      LOTVNNEC1203ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.hint",
      LOTVNNEC1203STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.hint",
      LOTVNNEC1203STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.hint",
      LOTVNNEC1203S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.hint",
    },
    description: {
      LOTVNNEC1203STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.description",
      LOTVNNEC1203S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.description",
      LOTVNNEC1203ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.description",
      LOTVNNEC1203STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.description",
      LOTVNNEC1203STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.description",
      LOTVNNEC1203STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.description",
      LOTVNNEC1203S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.description",
      LOTVNNEC1203ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.description",
      LOTVNNEC1203STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.description",
      LOTVNNEC1203STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.description",
      LOTVNNEC1203S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.description",
    },
    example: {
      LOTVNNEC1203STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.example",
      LOTVNNEC1203S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.example",
      LOTVNNEC1203ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.example",
      LOTVNNEC1203STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.example",
      LOTVNNEC1203STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.example",
      LOTVNNEC1203STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.example",
      LOTVNNEC1203S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.example",
      LOTVNNEC1203ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.example",
      LOTVNNEC1203STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.example",
      LOTVNNEC1203STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.example",
      LOTVNNEC1203S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.example",
    },
  },
  LOTVNNEC1204S: {
    hint: {
      LOTVNNEC1204STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.hint",
      LOTVNNEC1204S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.hint",
      LOTVNNEC1204STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.hint",
      LOTVNNEC1204S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.hint",
    },
    description: {
      LOTVNNEC1204STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.description",
      LOTVNNEC1204S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.description",
      LOTVNNEC1204STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.description",
      LOTVNNEC1204S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.description",
    },
    example: {
      LOTVNNEC1204STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.example",
      LOTVNNEC1204S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.example",
      LOTVNNEC1204STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.example",
      LOTVNNEC1204S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.example",
    },
  },
  LOTVNNEC120ZXBZ: {
    hint: {
      LOTVNNEC120ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.hint",
      LOTVNNEC120ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.hint",
      LOTVNNEC120ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.hint",
      LOTVNNEC120ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.hint",
    },
    description: {
      LOTVNNEC120ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.description",
      LOTVNNEC120ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.description",
      LOTVNNEC120ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.description",
      LOTVNNEC120ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.description",
    },
    example: {
      LOTVNNEC120ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.example",
      LOTVNNEC120ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.example",
      LOTVNNEC120ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.example",
      LOTVNNEC120ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.example",
    },
  },
  LOTVNNEC120DXDS: {
    hint: "lottery.rules.modal.LOTVNNEC5DXDS.hint",
    description: "lottery.rules.modal.LOTVNNEC5DXDS.description",
    example: "lottery.rules.modal.LOTVNNEC5DXDS.example",
  },
};

export default LOTVNNEC120RuleEnum;
