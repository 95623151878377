const LOTVNNEC10RuleEnum = {
  LOTVNNEC10BX: {
    hint: {
      LOTVNNEC10BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.hint",
      LOTVNNEC10BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.hint",
      LOTVNNEC10BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.hint",
      LOTVNNEC10BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.hint",
      LOTVNNEC10BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.hint",
      LOTVNNEC10BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.hint",
      LOTVNNEC10BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.hint",
      LOTVNNEC10BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.hint",
      LOTVNNEC10BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.hint",
      LOTVNNEC10BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.hint",
    },
    description: {
      LOTVNNEC10BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.description",
      LOTVNNEC10BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.description",
      LOTVNNEC10BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.description",
      LOTVNNEC10BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.description",
      LOTVNNEC10BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.description",
      LOTVNNEC10BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.description",
      LOTVNNEC10BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.description",
      LOTVNNEC10BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.description",
      LOTVNNEC10BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.description",
      LOTVNNEC10BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.description",
    },
    example: {
      LOTVNNEC10BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.example",
      LOTVNNEC10BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.example",
      LOTVNNEC10BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.example",
      LOTVNNEC10BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.example",
      LOTVNNEC10BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.example",
      LOTVNNEC10BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.example",
      LOTVNNEC10BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.example",
      LOTVNNEC10BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.example",
      LOTVNNEC10BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.example",
      LOTVNNEC10BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.example",
    },
  },
  LOTVNNEC10CG: {
    hint: {
      LOTVNNEC10CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.hint",
      LOTVNNEC10CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.hint",
      LOTVNNEC10CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.hint",
    },
    description: {
      LOTVNNEC10CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.description",
      LOTVNNEC10CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.description",
      LOTVNNEC10CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.description",
    },
    example: {
      LOTVNNEC10CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.example",
      LOTVNNEC10CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.example",
      LOTVNNEC10CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.example",
    },
  },
  LOTVNNEC102S: {
    hint: {
      LOTVNNEC102STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.hint",
      LOTVNNEC102S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.hint",
      LOTVNNEC102ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.hint",
      LOTVNNEC102STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.hint",
      LOTVNNEC102S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.hint",
      LOTVNNEC102ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.hint",
      LOTVNNEC102S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.hint",
    },
    description: {
      LOTVNNEC102STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.description",
      LOTVNNEC102S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.description",
      LOTVNNEC102ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.description",
      LOTVNNEC102STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.description",
      LOTVNNEC102S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.description",
      LOTVNNEC102ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.description",
      LOTVNNEC102S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.description",
    },
    example: {
      LOTVNNEC102STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.example",
      LOTVNNEC102S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.example",
      LOTVNNEC102ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.example",
      LOTVNNEC102STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.example",
      LOTVNNEC102S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.example",
      LOTVNNEC102ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.example",
      LOTVNNEC102S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.example",
    },
  },
  LOTVNNEC103S: {
    hint: {
      LOTVNNEC103STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.hint",
      LOTVNNEC103S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.hint",
      LOTVNNEC103ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.hint",
      LOTVNNEC103STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.hint",
      LOTVNNEC103STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.hint",
      LOTVNNEC103STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.hint",
      LOTVNNEC103S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.hint",
      LOTVNNEC103ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.hint",
      LOTVNNEC103STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.hint",
      LOTVNNEC103STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.hint",
      LOTVNNEC103S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.hint",
    },
    description: {
      LOTVNNEC103STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.description",
      LOTVNNEC103S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.description",
      LOTVNNEC103ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.description",
      LOTVNNEC103STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.description",
      LOTVNNEC103STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.description",
      LOTVNNEC103STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.description",
      LOTVNNEC103S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.description",
      LOTVNNEC103ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.description",
      LOTVNNEC103STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.description",
      LOTVNNEC103STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.description",
      LOTVNNEC103S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.description",
    },
    example: {
      LOTVNNEC103STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.example",
      LOTVNNEC103S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.example",
      LOTVNNEC103ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.example",
      LOTVNNEC103STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.example",
      LOTVNNEC103STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.example",
      LOTVNNEC103STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.example",
      LOTVNNEC103S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.example",
      LOTVNNEC103ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.example",
      LOTVNNEC103STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.example",
      LOTVNNEC103STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.example",
      LOTVNNEC103S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.example",
    },
  },
  LOTVNNEC104S: {
    hint: {
      LOTVNNEC104STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.hint",
      LOTVNNEC104S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.hint",
      LOTVNNEC104STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.hint",
      LOTVNNEC104S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.hint",
    },
    description: {
      LOTVNNEC104STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.description",
      LOTVNNEC104S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.description",
      LOTVNNEC104STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.description",
      LOTVNNEC104S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.description",
    },
    example: {
      LOTVNNEC104STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.example",
      LOTVNNEC104S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.example",
      LOTVNNEC104STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.example",
      LOTVNNEC104S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.example",
    },
  },
  LOTVNNEC10ZXBZ: {
    hint: {
      LOTVNNEC10ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.hint",
      LOTVNNEC10ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.hint",
      LOTVNNEC10ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.hint",
      LOTVNNEC10ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.hint",
    },
    description: {
      LOTVNNEC10ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.description",
      LOTVNNEC10ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.description",
      LOTVNNEC10ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.description",
      LOTVNNEC10ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.description",
    },
    example: {
      LOTVNNEC10ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.example",
      LOTVNNEC10ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.example",
      LOTVNNEC10ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.example",
      LOTVNNEC10ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.example",
    },
  },
  LOTVNNEC10DXDS: {
    hint: "lottery.rules.modal.LOTVNNEC5DXDS.hint",
    description: "lottery.rules.modal.LOTVNNEC5DXDS.description",
    example: "lottery.rules.modal.LOTVNNEC5DXDS.example",
  },
};

export default LOTVNNEC10RuleEnum;
