import { Image } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { getNowLang } from "../../plugins/utils";
import styles from "./style.module.scss";
const LongDragonDescription = () => {
  const { t } = useTranslation();
  const nowLang = getNowLang()?.replace(/\"/gm, "");
  return (
    <div>
      <div className={styles.innerContainer}>
        <Image src={`/images/index/longDragon/long-dragon-desc.jpg`} />
        <div className={styles.desc}>{t("longdragon.desc.content")}</div>
        <div className={styles.title}>{t("longdragon.desc.title.zhanlong")}</div>
        <div className={styles.desc}>{t("longdragon.desc.content.zhanlong")}</div>
        <div className={styles.title}>{t("longdragon.desc.title.shunlong")}</div>
        <div className={styles.desc}>{t("longdragon.desc.content.shunlong")}</div>
      </div>
    </div>
  );
};

export default LongDragonDescription;
