import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import DefaultSelector from "../DefaultSelector";
import { useLottery } from "../../..";

export const RectangleItem = ({
  isActive,
  onClick,
  code,
  textNoI18n,
  item,
  hideOdds,
  group_key = "",
}) => {
  const { t } = useTranslation();
  const specialClasses = {
    red_wave: "red",
    green_wave: "green",
    blue_wave: "blue",
  };
  const contentNumsProcess = (name) => {
    const splitItems = name.split("/");
    if (splitItems && splitItems[1]) {
      const nums = splitItems[1].split(",");
      return nums;
    }
    return [];
  };
  const nums = contentNumsProcess(item.name);
  // console.log("nums", nums);
  return (
    <div
      className={`${styles.itemContainer}`}
      style={{ width: "calc(33.33% - 12px)" }}
    >
      <div
        key={code}
        className={`${styles.item} ${isActive ? styles.active : ""} ${
          specialClasses[code] ? styles[specialClasses[code]] : ""
        }`}
        onClick={() => onClick(group_key, item)}
      >
        <div>{textNoI18n ? code : t(`lottery.item.${code}`)}</div>
        <div className={`${styles.numBox}`}>
          {nums?.map((num, index) => {
            return <span key={"num" + index}>{num}</span>;
          })}
        </div>
      </div>
      <div className={`${styles.oddsText}`}>
        {hideOdds ? "" : t("normal.odds", { odds: item.odds })}
      </div>
    </div>
  );
};
const RectangleWithNums = (props) => {
  return (
    <DefaultSelector
      {...props}
      selectorRender={RectangleItem}
    ></DefaultSelector>
  );
};

export default RectangleWithNums;
