export const marksixRules = [
  "tm",
  "cm",
  "cm16",
  "cmt",
  "zhds",
  "zhdx",
  "lm",
  "sxsb",
  "yx",
  "hx",
  "bp",
  "bbp",
  "zox",
  "qsb",
  "tmts",
  "tmws",
  "cmws",
  "zhx",
  "zxds",
  "lx",
  "lw",
  "bz",
  "zy",
];

export const everycolorRules = [
  "5s",
  "4s",
  "3s",
  "2s",
  "dwd",
  "bdw",
  "cw",
  "lh",
  "lotec5dxds",
  "dn",
  "tsh",
  "rx",
];

export const fast3Rules = [
  "hz",
  "eth",
  "ebth",
  "slh",
  "sth",
  "sbth",
  "2l",
  "dd",
  "bz",
  "dz",
];

export const pk10Rules = [
  "gyjh",
  "qy",
  "qe",
  "qs",
  "dwd",
  "dxds",
  "lh",
  "wx",
  "nn",
];