import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
const Footer = ({ boxBg = "", isLogin }) => {
  const [currentMod, setCurrentMod] = useState("home");
  // const [showNeedLoginModal, setShowNeedLoginModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    updateCurrentMod(window.location.pathname);
  }, [window.location.pathname]);

  const updateCurrentMod = (path) => {
    const target = methods.find((m) => m.path === path);
    setCurrentMod(target?.id);
  };

  const methods = [
    {
      id: "home",
      name: t("footer.home"),
      code: "home",
      path: "/",
    },
    {
      id: "longdragon",
      name: t("footer.longdragon"),
      code: "longdragon",
      path: "/longType",
    },
    {
      id: "history",
      name: t("footer.history"),
      code: "history",
      path: "/betHistory",
    },
    {
      id: "result",
      name: t("footer.result"),
      code: "result",
      path: "/gameResult",
      active: false,
    },
  ];
  return (
    <div style={{ background: boxBg }}>
      <div className={styles.footer}>
        <ul className={styles.footerItems}>
          {methods.map((m, index) => (
            <li
              className={`${styles.item} ${currentMod === m.id ? styles.active : ""}`}
              key={"footer" + index}
              onClick={() => {
                // if (!isLogin) {
                //   setShowNeedLoginModal(true);
                //   return;
                // }
                navigate(m.path);
              }}
            >
              <div className={styles.content}>
                <span className={styles.icon}>
                  <img
                    src={`images/footer/${m.code}-${
                      currentMod === m.id ? "active" : "inactive"
                    }.webp`}
                    alt={m.code}
                    style={{ width: 40, height: 40 }}
                  />
                </span>
                <span className={styles.text}>{m.name}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
