import { SELECTED_COUNT_LIMIT } from "../../valudateFunctionEnum";

const OptionalFunction = {
  LOTVNNEC45ZXBZH24BZ: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = count / 4;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(4)({ selected });
    },
  },
  LOTVNNEC45ZXBZH26BZ: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = count / 6;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(6)({ selected });
    },
  },
  LOTVNNEC45ZXBZH28BZ: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = count / 8;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(8)({ selected });
    },
  },
  LOTVNNEC45ZXBZH210BZ: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = count / 10;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(10)({ selected });
    },
  },
};

export { OptionalFunction };
