import { Card, Image } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

import GameResultRender from "../../../../components/GameResultRender";
import VNGameResultRender from "../../../../components/VNGameResultRender";
import { CDN_URL, PRODUCTION_NAME_DEV } from "../../../../config.js";
import { nowS3LangFolders } from "../../../../enumerations/cdnFolders";
import { getNowLang } from "../../../../plugins/utils";

import styles from "./style.module.scss";
// import { gameTypesIcon } from "../../enumeration/gameTypes";

function CardItem(props) {
  const { data, gameType = "", gameCode = "", onClick = () => {} } = props;
  const { t } = useTranslation();
  const nowLang = getNowLang()?.replace(/\"/gm, "");
  console.debug(props);
  return (
    <Card
      onClick={onClick}
      style={{
        background: "var(--color-background-secondary)",
        marginBottom: "4px",
      }}
    >
      <div className="card-container">
        <div className="card-content">
          <div className="left-side">
            <div className="pic-box">
              <Image
                src={`${CDN_URL}/imgs/${PRODUCTION_NAME_DEV}/game/77lottery/${
                  nowS3LangFolders.includes(nowLang) ? nowLang : "en"
                }/${gameCode}.png`}
                width={"100%"}
                height={"100%"}
                fit="cover"
                style={{ borderRadius: "50%" }}
              />
            </div>
          </div>
          <div className="right-side">
            <div className="game-title">{t(`lottery.gameType.${gameCode}`)}</div>
            <div className="game-period">{t("history.periodNumber", { period: data.period })}</div>
            {gameType === "LOTVNNEC" ? null : <GameResultRender gameType={gameType} data={data} />}
          </div>
        </div>
        {gameType === "LOTVNNEC" ? (
          <VNGameResultRender
            containerStyle={styles.customVNContainer}
            lotteryType={data.game_type}
            hidePeriod={true}
            prevPeriod={{
              channel: data.game_type,
              lottery_number: data.lottery_number,
              period: data.period,
            }}
            handleViewAllHistory={onClick}
          />
        ) : null}
      </div>
    </Card>
  );
}

export default CardItem;
