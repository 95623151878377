import {
  FiveStarsFunctions,
  FourStarsFunction,
  FreePositionFunction,
  OptionalFunction,
  ThreeStarsFunction,
  TwoStarsFunction
} from "../../EveryColorEnums/LOTEC10Function";
import LOTEC10RuleEnum from "../../ruleDescription/LOTEC/LOTEC10RuleEnum";
import {
  MAX_MIN_BET,
  MAX_PAYOUT,
  MONEY_REQUIRED,
  NOT_ENOUGH_MONEY,
  SELECTED_COUNT_LIMIT,
  SELECTED_NON_NULL,
} from "../../valudateFunctionEnum";
import {
  FIVE_STAR,
  FOUR_STAR,
  THREE_STAR,
  TWO_STAR,
  FREE_POS,
  OPTION_SELECT
} from "../../EveryColorEnums/LOTEC10Validation";

const LOTEC10SettingEnum = {
  LOTEC105S: {
    name: "五星",
    code: "LOTEC105S",
    ruleDescription: LOTEC10RuleEnum.LOTEC105S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      FIVE_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (FiveStarsFunctions[currentGameWayDetail]) {
        newSelect = FiveStarsFunctions[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }

      return newSelect;
    },
  },
  LOTEC104S: {
    name: "四星",
    code: "LOTEC104S",
    ruleDescription: LOTEC10RuleEnum.LOTEC104S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      FOUR_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (FourStarsFunction[currentGameWayDetail]) {
        newSelect = FourStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTEC103S: {
    name: "三星",
    code: "LOTEC103S",
    ruleDescription: LOTEC10RuleEnum.LOTEC103S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      THREE_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (ThreeStarsFunction[currentGameWayDetail]) {
        newSelect = ThreeStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }

      return newSelect;
    },
  },
  LOTEC102S: {
    name: "二星",
    code: "LOTEC102S",
    ruleDescription: LOTEC10RuleEnum.LOTEC102S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      TWO_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: {
      LOTEC102S2SH2ZXHZWS: true,
      LOTEC102S2SQ2ZXHZWS: true,
    },
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (TwoStarsFunction[currentGameWayDetail]) {
        if (TwoStarsFunction[currentGameWayDetail].defaultProcess) {
          Object.keys(selected).forEach((key) => {
            newSelect = newSelect?.concat(
              selected[key].map((s) => {
                return {
                  methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                    }${key ? `.${key}` : ""}`,
                  method: currentGameItems?.game_method[0]?.code,
                  content: s,
                  simpleMoney: currentBetNumber,
                  money: currentBetNumber,
                };
              })
            );
          });
        } else {
          newSelect = TwoStarsFunction[currentGameWayDetail].selectProcess({
            selected,
            currentBetNumber,
            currentGameWayDetail,
            method: currentGameItems?.game_method[0]?.code,
          });
        }
      }
      return newSelect;
    },
  },
  LOTEC10DWD: {
    name: "定位胆",
    code: "LOTEC10DWD",
    ruleDescription: LOTEC10RuleEnum.LOTEC10DWD,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.LOTEC10DWD`,
        method: currentGameItems?.game_method[0]?.code,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  LOTEC10BDW: {
    name: "不定位",
    code: "LOTEC104S",
    haveTags: true,
    ruleDescription: LOTEC10RuleEnum.LOTEC10BDW,
    confirmValidation: [
      MONEY_REQUIRED,
      FREE_POS,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (FreePositionFunction[currentGameWayDetail]) {
        newSelect = FreePositionFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTEC10QW: {
    name: "趣味",
    code: "LOTEC10QW",
    haveTags: true,
    ruleDescription: LOTEC10RuleEnum.LOTEC10QW,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_COUNT_LIMIT(1),
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: currentGameItems?.game_method[0]?.code,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  LOTEC10LH: {
    name: "龙虎",
    code: "LOTEC10LH",
    ruleDescription: LOTEC10RuleEnum.LOTEC10LH,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    hideOdds: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${key ? `.${key}` : ""}`,
              method: currentGameItems?.game_method[0]?.code,
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC10DXDS: {
    name: "大小单双",
    code: "LOTEC10DXDS",
    ruleDescription: LOTEC10RuleEnum.LOTEC10DXDS,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${key ? `.${key}` : ""}`,
              method: "LOTECDXDS",
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC10DN: {
    name: "斗牛",
    code: "LOTEC10DN",
    ruleDescription: LOTEC10RuleEnum.LOTEC10DN,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameMethod,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${currentGameMethod ? `.${currentGameMethod}` : ""}.${key}`,
              method: key,
              content: s,
              option: s,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC10TSH: {
    name: "特殊号",
    code: "LOTEC10TSH",
    ruleDescription: LOTEC10RuleEnum.LOTEC10TSH,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameMethod,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${currentGameMethod ? `.${currentGameMethod}` : ""}`,
              method: key,
              content: s,
              option: s,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC10RX: {
    name: "任选",
    code: "LOTEC10RX",
    haveTags: true,
    ruleDescription: LOTEC10RuleEnum.LOTEC10RX,
    confirmValidation: [
      MONEY_REQUIRED,
      OPTION_SELECT,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (OptionalFunction[currentGameWayDetail]) {
        newSelect = OptionalFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
};

export default LOTEC10SettingEnum;
