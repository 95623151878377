import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetBetDetail, apiGetBetHistory, apiGetGameList, updateCancelBet } from "../service";

export const useGetBetHistory = ({ params, options }) => {
  return useInfiniteQuery(
    [queryKey.BET_HISTORY, params],
    ({ pageParam = 1 }) =>
      apiGetBetHistory({ ...params, page: pageParam }).then((res) => {
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameListType = (options) => {
  return useQuery(
    [queryKey.BET_HISTORY_GAME_LIST],
    () =>
      apiGetGameList().then((res) => {
        const _result = res.data.data;
        let typeDict = [];
        _result?.map((gameGroup) => {
          gameGroup?.game_list?.map((game) => {
            typeDict.push({ ...game, label: game.game_type, value: game.game_type });
          });
        });
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetBetDetail = ({ params, options }) => {
  return useQuery(
    [queryKey.BET_DETAIL, params],
    () =>
      apiGetBetDetail(params).then((res) => {
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useDeleteBet = () => {
  const cache = useQueryClient();
  return useMutation(
    ({ params, query }) => updateCancelBet(params, query).then((res) => res.data.data),
    {
      onSuccess: () => {
        cache.invalidateQueries([queryKey.BET_DETAIL]);
      },
    },
  );
};
