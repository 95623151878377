import Selector from "../../../components/Selector";

const LOTMS3SelectorEnum = {
  // 特碼
  "LOTMS3TMTM.TM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS3TMTM.TM_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 半波
  "LOTMS3TMBP.BP_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 特碼生肖
  "LOTMS3TMSX.TMSX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 一肖
  "LOTMS3YX.YX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "LOTMS3YX.TW_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正肖
  "LOTMS3ZX.ZOX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "LOTMS3ZX.QSB": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連肖
  //  二肖碰
  "LOTMS3LXEXP.EXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  三肖碰
  "LOTMS3LXSAXP.SAXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  四肖碰
  "LOTMS3LXSIXP.SIXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  五肖碰
  "LOTMS3LXWXP.WXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連尾
  //  二尾连
  "LOTMS3LWELW.ELW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  三尾连
  "LOTMS3LWSALW.SALW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  四尾连
  "LOTMS3LWSILW.SILW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  五尾连
  "LOTMS3LWWLW.WLW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 合肖
  //  中
  "LOTMS3HXZ.Z": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  不中
  "LOTMS3HXBZ.BZ": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 連碼
  //  四全中
  "LOTMS3LMSIQZ.SIQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三全中
  "LOTMS3LMSAQZ.SAQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三中二
  "LOTMS3LMSZE.SZE": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二全中
  "LOTMS3LMEQZ.EQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二中特
  "LOTMS3LMEZT.EZT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  特串
  "LOTMS3LMTC.TC": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選不中
  //  五不中
  "LOTMS3TSBCWBZ.WBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六不中
  "LOTMS3TSBCLBZ.LBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  七不中
  "LOTMS3TSBCQBZ.QBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  八不中
  "LOTMS3TSBCBBZ.BBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  九不中
  "LOTMS3TSBCJBZ.JBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十不中
  "LOTMS3TSBCSBZ.SBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十一不中
  "LOTMS3TSBCSYBZ.SYBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十二不中
  "LOTMS3TSBCSEBZ.SEBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選中一
  //  三中一
  "LOTMS3CYSAZY.SAZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  四中一
  "LOTMS3CYSIZY.SIZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  五中一
  "LOTMS3CYWZY.WZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六中一
  "LOTMS3CYLZY.LZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 正码
  "LOTMS3CMCM.CM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  // 正码 1
  "LOTMS3CMCM1.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS3CMCM1.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 2
  "LOTMS3CMCM2.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS3CMCM2.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 3
  "LOTMS3CMCM3.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS3CMCM3.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 4
  "LOTMS3CMCM4.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS3CMCM4.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 5
  "LOTMS3CMCM5.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS3CMCM5.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 6
  "LOTMS3CMCM6.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS3CMCM6.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
};

export default LOTMS3SelectorEnum;
