import Selector from "../../../components/Selector";

const LOTPK10SelectorEnum = {
  "LOTPK103GYJH.GYJH": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTPK103GYJH.GYJH_GROUP": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
    },
  },
  "LOTPK103QY.QY": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTPK103QE.QE": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      allowDuplicate: false,
    },
  },
  "LOTPK103QS.QS": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      allowDuplicate: false,
    },
  },
  // ========================定位胆========================

  "LOTPK103DWD0110.DWD0110": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTPK103DWD0105.DWD0105": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTPK103DWD0610.DWD0610": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // ========================五行========================
  "LOTPK103WXGJ.GJ": {
    component: Selector.rectangleType2,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTPK103WXYJ.YJ": {
    component: Selector.rectangleType2,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTPK103WXJJ.JJ": {
    component: Selector.rectangleType2,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  //=============================大小单双========================
  "LOTPK103DXDS0105.DXDS0105": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      // hideOdds: true,
    },
  },
  "LOTPK103DXDS0610.DXDS0610": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      // hideOdds: true,
    },
  },
  //=============================龙虎========================
  "LOTPK103LH.PK10LH": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
    },
  },
  //=============================牛牛========================
  "LOTPK103NNPB.NNPB": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTPK103NNFB.NNFB": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
};

export default LOTPK10SelectorEnum;
