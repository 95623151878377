import React from "react";
import { Grid } from "antd-mobile";
import styles from "./style.module.scss";
import LeftGroup from "./components/LeftGroup";
import CenterGroup from "./components/CenterGroup";
import RightGroup from "./components/RightGroup";
import { CustomNavbar } from "../../../components";
const Navigation = (params) => {
  // return (
  //   <>
  //     <div className={`${styles.navigation}`} style={{backgroundColor:`${params.background || "var(--adm-color-white)"}`}}>
  //       <Grid columns={24}>
  //         <LeftGroup {...(params?.left ?? {})} goBack={params.goBack} />
  //         <CenterGroup {...(params?.center ?? {})} />
  //         <RightGroup {...(params?.right ?? {})} />
  //       </Grid>
  //     </div>
  //   </>
  // );
  const onBackPress = () => {
    params.goBack(params?.left.backURL);
  };

  const renderRight = <RightGroup {...(params?.right ?? {})} />;

  return (
    <CustomNavbar
      onBack={onBackPress}
      right={renderRight}
      backArrow={params?.renderBackButton}
    >
      <CenterGroup {...(params?.center ?? {})} />
    </CustomNavbar>
  );
};

export default Navigation;
