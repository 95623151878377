import { InputNumber } from "antd";
import { Image, Popup } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLottery } from "../..";
import { CustomNavbar } from "../../../../components";
import CustomButton from "../../../../components/CustomButton";
import { QUICK_MONEY } from "../../../../config";
import { useWebsocket } from "../../../../context/WebsocketContext";
import { moneyFormat, thousandFormat } from "../../../../plugins/numberFormat";
import { getCurrency } from "../../../../plugins/utils";
import closeIcon from "./images/close.webp";
import styles from "./style.module.scss";
import "./style.scss";

const CURRENCY = getCurrency() ?? "";

const ConfirmForm = ({ visible, setVisible, moneyHaveInput, gameWaySetting }) => {
  const gameCode = useParams().lotteryType;
  const lottery = useLottery();
  const [quick, setQuick] = useState(lottery?.currentBetNumber);
  const { t } = useTranslation();
  const { disableBet } = useWebsocket();
  const _moneyHaveInput =
    typeof moneyHaveInput === "object" ? moneyHaveInput[gameWaySetting] : moneyHaveInput;
  const quickMoneys = QUICK_MONEY;
  const quickColors = [
    "var(--color-coin-green)",
    "var(--color-coin-brown)",
    "var(--color-coin-pink)",
    "var(--color-coin-grey)",
    "var(--color-coin-blue)",
  ];
  const contentProccessor = (content) => {
    if (content) {
      if (typeof content == "object") {
        const text = [];
        content?.map((i) => {
          text.push(t(i));
        });
        return text.join(",");
      } else {
        const groups = content.split("|");
        const sorted = groups
          .map((group) => {
            const item = group.split(",");
            return item.sort((a, b) => a - b).join(",");
          })
          .join("|");
        return t(sorted);
      }
    }
  };
  const contentTextProccessor = (contentText) => {
    if (contentText.indexOf("|") > -1) {
      const split = contentText.split("|");
      const position = split[0]
        .split(",")
        .map((pos) => t(pos))
        .join(",");
      const number = split[1]
        .split(",")
        .sort((a, b) => a - b)
        .join(",");
      return `${position}|${number}`;
    } else {
      return t(contentText);
    }
  };

  const handleBackPress = () => {
    setVisible(false);
    lottery.resetValues();
  };

  const renderBackArrow = <Image src={closeIcon} height={34} fit={"contain"} />;

  return (
    <Popup
      className="confirm-form"
      visible={visible}
      destroyOnClose={true}
      position={"right"}
      onMaskClick={() => {
        setVisible(false);
      }}
      mask={false}
    >
      <div className={styles.confirmForm}>
        <CustomNavbar onBack={handleBackPress} backArrow={renderBackArrow}>
          <span className={styles.title}>{`${t(`lottery.gameType.${gameCode}`)}-${t(
            "header.betSlip.title",
          )}`}</span>
        </CustomNavbar>
        <div className={styles.table}>
          <div className={styles.header}>
            <div className={styles.column}>{t("confirm.header.gameWay")}</div>
            <div className={styles.column}>{t("confirm.header.content")}</div>
            <div className={styles.column}>{t("confirm.header.single")}</div>
            <div className={styles.column}>{t("confirm.header.money")} </div>
          </div>
          <div className={styles.content}>
            {lottery?.requestItems?.length
              ? lottery?.requestItems
                  ?.sort((a, b) => {
                    return a.content - b.content;
                  })
                  ?.map((item, index) => {
                    return (
                      <div className={styles.row}>
                        <div className={styles.column}>
                          <span className={`${styles.methodText}`}>{t(item?.methodText)}</span>
                        </div>
                        <div className={`${styles.column}`}>
                          <span className={`${styles.betContent}`}>
                            {item?.contentText
                              ? contentTextProccessor(item?.contentText)
                              : contentProccessor(item?.content)}
                          </span>
                        </div>
                        <div className={styles.column}>
                          {_moneyHaveInput ? (
                            <InputNumber
                              className="confirmMoneyInput"
                              value={item?.simpleMoney}
                              onChange={(e) => {
                                const newRequestInput = [...lottery?.requestItems];
                                newRequestInput[index] = {
                                  ...item,
                                  simpleMoney: e,
                                };
                                lottery.setRequestItems(newRequestInput);
                              }}
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                            />
                          ) : (
                            item?.simpleMoney
                          )}
                        </div>
                        <div className={styles.column}>
                          {moneyFormat((item?.simpleMoney ?? 0) * (item?.count ?? 1))}
                        </div>
                      </div>
                    );
                  })
              : ""}
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <div className={styles.quickSet}>
          <div className={styles.inputGroup}>
            <div className={styles.title}>{t("lottery.quickMoney")}</div>
            <div className={styles.unit}>
              {t(`currency.symbol.${CURRENCY?.toLocaleUpperCase()}`)}
            </div>
            {visible ? (
              <InputNumber
                className="confirmMoneyInput"
                defaultValue={lottery?.currentBetNumber}
                value={quick}
                onChange={(e) => {
                  let newRequestInput = [...lottery?.requestItems];
                  newRequestInput = newRequestInput.map((i) => {
                    return { ...i, simpleMoney: e };
                  });
                  lottery.setRequestItems(newRequestInput);
                  setQuick(e);
                }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
              />
            ) : (
              <></>
            )}
          </div>
          <div className={styles.quickMoneys}>
            {quickMoneys.map((q, index) => {
              return (
                <div
                  style={{
                    padding: 9,
                    borderRadius: 4,
                    marginLeft: 8,
                    boxShadow: "0 4px 0 0 var(--color-coin-shadow)",
                    color: "var(--color-white)",
                    textShadow: "0 0 4px var(--color-black-50)",
                    backgroundColor: quickColors[index % quickColors.length],
                  }}
                  onClick={() => {
                    let newRequestInput = [...lottery?.requestItems];
                    newRequestInput = newRequestInput.map((i) => {
                      return { ...i, simpleMoney: q };
                    });
                    lottery.setRequestItems(newRequestInput);
                    setQuick(q);
                  }}
                >
                  {thousandFormat(q, 0)}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.submitGroup}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "var(--fontSetting-normal)",
            }}
            dangerouslySetInnerHTML={{
              __html: t("lottery.confirm.summary", {
                num: lottery?.requestItems?.reduce
                  ? lottery?.requestItems?.reduce((partialSum, i) => partialSum + (i.count ?? 1), 0)
                  : 0,
                total: moneyFormat(
                  lottery?.requestItems?.reduce
                    ? lottery?.requestItems?.reduce(
                        (partialSum, i) => partialSum + (i?.simpleMoney ?? 0) * (i?.count ?? 1),
                        0,
                      )
                    : 0,
                ),
                currency: t(`currency.symbol.${CURRENCY?.toLocaleUpperCase()}`),
              }),
            }}
          />

          <div
            style={{
              width: 125,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomButton
              disabled={disableBet}
              onClick={() => {
                lottery.betRequest(lottery.currentBetNumber);
              }}
              loading={lottery.loading}
            >
              {t("normal.confirmBet")}
            </CustomButton>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmForm;
