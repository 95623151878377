import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLoading } from "../../context/LoadingContext";
import { useGetGameListType } from "../../hooks/game/game.hook";
import CardItem from "./components/CardItem";
import { useGetAllGameResult } from "./hooks/gameResult.hooks";
import "./style.scss";

function GameResult() {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { data: allResult, isFetching } = useGetAllGameResult({});
  const getGameListType = useGetGameListType({});
  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);
  return (
    <div
      className="game-result-page"
      style={{
        backgroundImage: "url('/images/layout/background/other_bg.webp')",
      }}
    >
      <div className="content-container">
        {getGameListType.data &&
          allResult?.map((item, index) => {
            return (
              <CardItem
                gameType={getGameListType.data[item.game_type] ?? ""}
                gameCode={item.game_type}
                data={item}
                key={"gameResult" + index}
                onClick={() => {
                  navigate(
                    `/gameResult/detail/${item.game_type}?type=${
                      getGameListType.data[item.game_type]
                    }`,
                  );
                }}
              />
            );
          })}
      </div>
    </div>
  );
}

export default GameResult;
