export default {
  LOTPK103GYJH: {
    hint: "lottery.rules.modal.LOTPK105GYJH.hint",
    description: "lottery.rules.modal.LOTPK105GYJH.description",
    example: "lottery.rules.modal.LOTPK105GYJH.example",
  },
  LOTPK103QY: {
    hint: "lottery.rules.modal.LOTPK105QY.hint",
    description: "lottery.rules.modal.LOTPK105QY.description",
    example: "lottery.rules.modal.LOTPK105QY.example",
  },
  LOTPK103QE: {
    hint: "lottery.rules.modal.LOTPK105QE.hint",
    description: "lottery.rules.modal.LOTPK105QE.description",
    example: "lottery.rules.modal.LOTPK105QE.example",
  },
  LOTPK103QS: {
    hint: "lottery.rules.modal.LOTPK105QS.hint",
    description: "lottery.rules.modal.LOTPK105QS.description",
    example: "lottery.rules.modal.LOTPK105QS.example",
  },
  
  LOTPK103DWD: {
    hint: {
      LOTPK103DWD0110: "lottery.rules.modal.LOTPK105DWD0110.hint",
      LOTPK103DWD0105: "lottery.rules.modal.LOTPK105DWD0105.hint",
      LOTPK103DWD0610: "lottery.rules.modal.LOTPK105DWD0610.hint",
    },
    description: {
      LOTPK103DWD0110: "lottery.rules.modal.LOTPK105DWD0110.description",
      LOTPK103DWD0105: "lottery.rules.modal.LOTPK105DWD0105.description",
      LOTPK103DWD0610: "lottery.rules.modal.LOTPK105DWD0610.description",
    },
    example: {
      LOTPK103DWD0110: "lottery.rules.modal.LOTPK105DWD0110.example",
      LOTPK103DWD0105: "lottery.rules.modal.LOTPK105DWD0105.example",
      LOTPK103DWD0610: "lottery.rules.modal.LOTPK105DWD0610.example",
    },
  },
  LOTPK103WX: {
    hint: {
      LOTPK103WXGJ: "lottery.rules.modal.LOTPK105WXGJ.hint",
      LOTPK103WXYJ: "lottery.rules.modal.LOTPK105WXYJ.hint",
      LOTPK103WXJJ: "lottery.rules.modal.LOTPK105WXJJ.hint",
    },
    description: {
      LOTPK103WXGJ: "lottery.rules.modal.LOTPK105WXGJ.description",
      LOTPK103WXYJ: "lottery.rules.modal.LOTPK105WXYJ.description",
      LOTPK103WXJJ: "lottery.rules.modal.LOTPK105WXJJ.description",
    },
    example: {
      LOTPK103WXGJ: "lottery.rules.modal.LOTPK105WXGJ.example",
      LOTPK103WXYJ: "lottery.rules.modal.LOTPK105WXYJ.example",
      LOTPK103WXJJ: "lottery.rules.modal.LOTPK105WXJJ.example",
    },
  },
  LOTPK103DXDS: {
    hint: {
      LOTPK103DXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.hint",
      LOTPK103DXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.hint",
    },
    description: {
      LOTPK103DXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.description",
      LOTPK103DXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.description",
    },
    example: {
      LOTPK103DXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.example",
      LOTPK103DXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.example",
    },
  },
  LOTPK103LH: {
    hint: "lottery.rules.modal.LOTPK105LH.hint",
    description: "lottery.rules.modal.LOTPK105LH.description",
    example: "lottery.rules.modal.LOTPK105LH.example",
  },
  LOTPK103NN: {
    hint: {
      LOTPK103NNPB: "lottery.rules.modal.LOTPK105NNPB.hint",
      LOTPK103NNFB: "lottery.rules.modal.LOTPK105NNPB.hint",
    },
    description: {
      LOTPK103NNPB: "lottery.rules.modal.LOTPK105NNPB.description",
      LOTPK103NNFB: "lottery.rules.modal.LOTPK105NNPB.description",
    },
    example: {
      LOTPK103NNPB: "lottery.rules.modal.LOTPK105NNPB.example",
      LOTPK103NNFB: "lottery.rules.modal.LOTPK105NNPB.example",
    },
  },
};
