export default {
  LOTFT1HZ: {
    hint: "lottery.rules.modal.LOTFT3HZ.hint",
    description: "lottery.rules.modal.LOTFT3HZ.description",
    example: "lottery.rules.modal.LOTFT3HZ.example",
  },
  LOTFT1ETH: {
    hint: {
      LOTFT1ETHETD: "lottery.rules.modal.LOTFT3ETHETD.hint",
      LOTFT1ETHETF: "lottery.rules.modal.LOTFT3ETHETF.hint",
    },
    description: {
      LOTFT1ETHETD: "lottery.rules.modal.LOTFT3ETHETD.description",
      LOTFT1ETHETF: "lottery.rules.modal.LOTFT3ETHETF.description",
    },
    example: {
      LOTFT1ETHETD: "lottery.rules.modal.LOTFT3ETHETD.example",
      LOTFT1ETHETF: "lottery.rules.modal.LOTFT3ETHETF.example",
    },
  },
  LOTFT1EBTH: {
    hint: {
      LOTFT1EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.hint",
      LOTFT1EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.hint",
    },
    description: {
      LOTFT1EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.description",
      LOTFT1EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.description",
    },
    example: {
      LOTFT1EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.example",
      LOTFT1EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.example",
    },
  },
  LOTFT1SLH: {
    hint: "lottery.rules.modal.LOTFT3SLH.hint",
    description: "lottery.rules.modal.LOTFT3SLH.description",
    example: "lottery.rules.modal.LOTFT3SLH.example",
  },
  LOTFT1STH: {
    hint: {
      LOTFT1STHSTD: "lottery.rules.modal.LOTFT3STHSTD.hint",
      LOTFT1STHSTT: "lottery.rules.modal.LOTFT3STHSTT.hint",
    },
    description: {
      LOTFT1STHSTD: "lottery.rules.modal.LOTFT3STHSTD.description",
      LOTFT1STHSTT: "lottery.rules.modal.LOTFT3STHSTT.description",
    },
    example: {
      LOTFT1STHSTD: "lottery.rules.modal.LOTFT3STHSTD.example",
      LOTFT1STHSTT: "lottery.rules.modal.LOTFT3STHSTT.example",
    },
  },
  LOTFT1SBTH: {
    hint: "lottery.rules.modal.LOTFT3SBTH.hint",
    description: "lottery.rules.modal.LOTFT3SBTH.description",
    example: "lottery.rules.modal.LOTFT3SBTH.example",
  },
};
