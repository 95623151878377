import { Carousel } from "antd";
import { Grid } from "antd-mobile";
import parseUrl from "parse-url";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomTabs } from "../../components";
import CustomNavbar from "../../components/CustomNavbar";
import { CDN_URL, PRODUCTION_NAME_DEV } from "../../config";
import { useLanguage } from "../../context/LangContext";
import { nowS3LangFolders } from "../../enumerations/cdnFolders";
import { headerImgLangs } from "../../enumerations/imageLangEnum";
import localStorageKey from "../../enumerations/localStorageKey";
import { useGetUserInfo, useGetUserWallet } from "../../hooks/user/user.hook";
import { getCurrency, getNowLang } from "../../plugins/utils";
import { Balance, BannerItem, GameItem, Username } from "./components";
import { useGetGameList } from "./hooks/home.hook";
import styles from "./style.module.scss";
const CURRENCY = getCurrency() ?? "";
const Home = () => {
  const navigate = useNavigate();
  const language = useLanguage();
  const { t } = useTranslation();
  const nowLang = getNowLang()?.replace(/\"/gm, "");
  // const { setWebsocketToken } = useWebsocket();
  const [gameList, setGameList] = useState([]);
  const [activeCategory, setActiveCategory] = useState("ALL");
  const {
    data: categories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
  } = useGetGameList();

  const getUser = useGetUserInfo({});
  const getUserWallet = useGetUserWallet({});

  const renderUser = <Username username={getUser.data?.account} />;

  const renderBalance = (
    <Balance amount={getUserWallet.data?.money} currency={CURRENCY?.toLocaleUpperCase()} />
  );

  const handleSignIn = () => {
    //TODO: get token from login logic
    // console.log(window.location, parseUrl(window.location.href));
    const query = parseUrl(window.location.href).query;
    const _lang = query?.lang;
    const _token = query?.token;
    if (_lang) {
      window.localStorage.setItem(localStorageKey.DEFAULT_LANG, _lang);
      console.log(_lang);
      language.changeLanguage(_lang);
    }

    if (_token) {
      window.localStorage.setItem(localStorageKey.TOKEN, _token);
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, query.currency.toLowerCase());
      // setWebsocketToken(query.token);
      window.location.href = `${window.location.origin}`;
    } else {
      // setWebsocketToken(window.localStorage.getItem(localStorageKey.TOKEN));
    }
  };

  const onChangeCategory = (e) => {
    const games = categories.find((category) => category.game_category_detail_type === e);
    setActiveCategory(e);
    if (games) {
      setGameList(games.game_list);
    }
  };

  const onGameItemClick = (gameCode) => {
    navigate(`/lottery/${gameCode}`);
  };

  useEffect(() => {
    if (categories) {
      console.log(categories, "categories");
      const games = categories.find(
        (category) => category.game_category_detail_type === activeCategory,
      );
      console.debug("gms", games);
      if (games) {
        setGameList(games.game_list);
      } else {
        setActiveCategory("ALL");
        setGameList(categories[0].game_list);
      }
    }
  }, [categories, activeCategory]);

  useEffect(() => {
    handleSignIn();
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        background: `no-repeat bottom / cover url(/images/layout/background/${PRODUCTION_NAME_DEV}/home_bg.webp), no-repeat bottom / cover url("/images/layout/background/home_bg.webp")`,
      }}
    >
      <CustomNavbar left={renderUser} right={renderBalance} backArrow={false} back={null}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={`/images/logo/${PRODUCTION_NAME_DEV}/headerLogo/${
              headerImgLangs.includes(nowLang) ? nowLang : "en"
            }.png`}
            style={{ height: 24 }}
            alt={"logo"}
          />
        </div>
      </CustomNavbar>
      <Carousel>
        <BannerItem img={`/images/home/banner.jpg`} />
      </Carousel>
      {isErrorCategories ? (
        ""
      ) : isLoadingCategories ? (
        ""
      ) : (
        <CustomTabs
          tabItems={categories}
          tabOnChange={onChangeCategory}
          activeKey={activeCategory}
          isTitleNeedI18n={true}
        />
      )}
      <div className={styles.scroll}>
        <Grid columns={3} gap={9}>
          {gameList.map((game) => {
            return (
              <GameItem
                onGameItemClick={onGameItemClick}
                key={game.game_type}
                image={`${CDN_URL}/imgs/${PRODUCTION_NAME_DEV}/game/77lottery/${
                  nowS3LangFolders.includes(nowLang) ? nowLang : "en"
                }/${game.game_type}.png`}
                isNew={game.isNew}
                code={game.game_type}
                title={t(`gameTitle.${game.game_type}`)}
              />
            );
          })}
        </Grid>
      </div>
    </div>
  );
};
export default Home;
