const statusEnum = {
  0: {
    color: "var(--color-status-pending)",
    text: "history.pending",
  },
  1: {
    color: "var(--color-main-active)",
    text: "history.wonLottery",
  },
  2: {
    text: "history.loseLottery",
  },
  3: {
    text: "history.calculate",
  },
  4: {
    text: "history.cancelled",
  },
  5: {
    text: "history.returned",
  },
};

export default statusEnum;
