import React, { createContext, useContext, useEffect, useState } from "react";
import { useLoading } from "../../../context/LoadingContext";
import { useGetLongDragonList } from "../hooks/longdragon.hooks";

const LongDragonContext = createContext({
  isLoadingLongDragonList: false,
  data: [],
  isError: false,
  selectedOption: {},
  setSelectedOption: () => {},
  betAmount: 2,
  setCurrentBetNumber: () => {},
  confirmVisible: false,
  setConfirmVisible: () => {},
  requestItems: [],
  setRequestItems: () => {},
  betConfirm: () => {},
  betRequest: () => {},
  refreshQuery: () => {},
  drawing: false,
  setDrawing: () => {},
});

export const LongDragonContextProvider = ({ children }) => {
  const { data, isLoading: isLoadingLongDragonList, isError, refetch } = useGetLongDragonList({});
  const { setIsLoading, isLoading } = useLoading();
  const [selectedOption, setSelectedOption] = useState({});
  const [betAmount, setBetAmount] = useState(2);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [requestItems, setRequestItems] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);

  const refreshQuery = () => {
    setTimeout(async () => {
      window.location.reload();
    }, 3000);
  };

  const betConfirm = async (betValue) => {
    // const isValid = validateInput(betValue);
    // if (isValid === true) {
    betAmount(betValue);
    setConfirmVisible(true);
    // }
  };

  const betRequest = async (betValue) => {
    let isEmpty = false;
    requestItems.every((item) => {
      if (
        item.simpleMoney === "0" ||
        item.simpleMoney === 0 ||
        item.simpleMoney === undefined ||
        item.simpleMoney === null
      ) {
        isEmpty = true;
        return false;
      }
      return true;
    });
    // const isValid = validateInput(isEmpty ? 0 : betValue);
    // if (isValid === true) {
    //   const requestProcess = currentCategoryGameSetting?.requestProcess;
    //   const requestParams = {
    //     selected: currentSelected,
    //     currentBetNumber,
    //     currentGameWay,
    //     currentGameWayDetail,
    //     gameCategoryType,
    //     gameCategoryDetailType,
    //     lotteryType,
    //     requestItems,
    //     period: currentPeriod.period,
    //   };
    //   const newRequest = requestProcess
    //     ? requestProcess(requestParams)
    //     : defaultRequestProcess(requestParams);
    //   console.debug("newRequest", newRequest);
    //   postGameBet(
    //     {
    //       params: newRequest,
    //     },
    //     {
    //       onSuccess: () => {
    //         setPopupSetting({
    //           title: t("popup.periodEnd.title"),
    //           rightButtonText: t("popup.betSuccess.btn.continue"),
    //           leftButtonText: t("popup.betSuccess.btn.betslip"),
    //           singleButton: false,
    //           onSecondaryButtonPress: () => {
    //             resetValues();
    //             setConfirmVisible(false);
    //             navigate(`/betHistory`, {
    //               state: {
    //                 status: 0,
    //               },
    //             });
    //           },
    //           onMainButtonPress: () => {
    //             resetValues();
    //             setConfirmVisible(false);
    //           },
    //           popupContent: t("popup.betSuccess.content"),
    //         });
    //         setPopupVisible(true);
    //       },
    //       onError: (error) => {
    //         setPopupSetting({
    //           title: t("popup.periodEnd.title"),
    //           rightButtonText: t("popup.periodEnd.button"),
    //           leftButtonText: "",
    //           singleButton: true,
    //           onSecondaryButtonPress: () => {},
    //           onMainButtonPress: () => {},
    //           popupContent: error,
    //         });
    //         setPopupVisible(true);
    //       },
    //     }
    //   );
    // }
  };

  useEffect(() => {
    if (isDrawing === true) {
      refreshQuery();
    }
  }, [isDrawing]);

  useEffect(() => {
    setIsLoading(isLoadingLongDragonList);
  }, [isLoadingLongDragonList]);

  return (
    <LongDragonContext.Provider
      value={{
        isLoadingLongDragonList,
        data,
        isError,
        selectedOption,
        setSelectedOption,
        betAmount,
        setBetAmount,
        confirmVisible,
        setConfirmVisible,
        requestItems,
        setRequestItems,
        betConfirm,
        betRequest,
        refreshQuery,
        setIsDrawing,
        isDrawing,
      }}
    >
      {children}
    </LongDragonContext.Provider>
  );
};

export const useLongDragon = () => {
  const LongDragonValue = useContext(LongDragonContext);
  return LongDragonValue;
};
