import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VNEveryColor from "./components/VNEveryColor";
import styles from "./style.module.scss";

const VNGameResultRender = ({
  lotteryType,
  hidePeriod = false,
  hideMore = false,
  containerStyle = "",
  handleViewAllHistory = () => {},
  latestNumber,
  prevPeriod,
}) => {
  const { t } = useTranslation();
  // const { prevPeriod } = useWebsocket();
  const VN_NUMBERS_GROUP = [
    {
      position: "seventh",
      lot_count: 4,
    },
    {
      position: "sixth",
      lot_count: 3,
    },
    {
      position: "fifth",
      lot_count: 6,
    },
    {
      position: "fourth",
      lot_count: 4,
    },
    {
      position: "third",
      lot_count: 6,
    },
    {
      position: "second",
      lot_count: 2,
    },
    {
      position: "first",
      lot_count: 1,
    },
    {
      position: "special",
      lot_count: 1,
    },
  ];
  const [positions, setPositions] = useState();
  const [openAllResult, setOpenAllResult] = useState(false);

  const renderAllResult = () => {
    if (!positions) return;
    return (
      <table>
        {positions
          .filter((pos) => pos.position !== "special" && pos.position !== "first")
          .reverse()
          .map((pos) => {
            return (
              <tr key={pos.position}>
                <td width={"50px"} className={styles.column}>
                  {t(`vn.position.${pos.position}`)}
                </td>
                <td className={styles.column}>{pos.numbers}</td>
              </tr>
            );
          })}
      </table>
    );
  };

  const onOpenAllResult = (e) => {
    e.stopPropagation();
    setOpenAllResult((prevState) => !prevState);
  };

  useEffect(() => {
    return () => {
      setOpenAllResult(false);
    };
  }, []);

  const handleSetPosition = (_prevPeriod) => {
    const dataArray = _prevPeriod.lottery_number.split(",");
    let acc = 0;
    const allPositions = VN_NUMBERS_GROUP.map((group) => {
      const numbers = dataArray.slice(acc, group.lot_count + acc).join("，");
      acc += group.lot_count;
      return {
        ...group,
        numbers: numbers,
      };
    });
    setPositions(allPositions);
  };

  useEffect(() => {
    if (prevPeriod && prevPeriod.lottery_number) {
      handleSetPosition(prevPeriod);
    } else {
      if (latestNumber) {
        const apiPrevPeriod = latestNumber[1];
        handleSetPosition(apiPrevPeriod);
      }
    }
  }, [prevPeriod, latestNumber]);

  const renderGameResult = (_prevPeriod, _latestNumber) => {
    if (_prevPeriod && _prevPeriod.channel) {
      if (_prevPeriod.channel !== lotteryType) return null;
      return <VNEveryColor positions={positions} />;
    }
    if (_latestNumber) {
      return <VNEveryColor positions={positions} />;
    }
  };

  const renderPrevPeriod = (_prevPeriod, _latestNumber) => {
    if (_prevPeriod && _prevPeriod.period) {
      return _prevPeriod.period;
    }
    if (_latestNumber) {
      return _latestNumber[1].period;
    }
    return "-";
  };

  return (
    <div className={`${styles.bottomContainer} ${containerStyle}`} onClick={onOpenAllResult}>
      <div className={styles.bottomHeader}>
        <div className={styles.titleContainer}>
          <div className={styles.rightTitle}>
            {hidePeriod ? null : (
              <div className={styles.periodContainer}>
                <span className={styles.number}>{renderPrevPeriod(prevPeriod, latestNumber)}</span>
                <span className={styles.subtitle}>{t("game.periodNumber")}</span>
              </div>
            )}
          </div>
        </div>

        <div className={styles.headerResult}>
          {renderGameResult(prevPeriod, latestNumber)}
          <div className={styles.buttonText}>
            {openAllResult ? (
              <CaretUpFilled
                style={{
                  fontSize: "var(--fontSetting-small)",
                  color: "var(--color-main-active)",
                }}
              />
            ) : (
              <CaretDownFilled
                style={{
                  fontSize: "var(--fontSetting-small)",
                  color: "var(--color-arrow-light)",
                }}
              />
            )}
          </div>
        </div>
      </div>
      {openAllResult ? (
        <div>
          {renderAllResult()}
          {hideMore ? null : (
            <div onClick={handleViewAllHistory} className={styles.readMore}>
              {t("normal.readMore")}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VNGameResultRender;
