import { Button, Divider, Grid, Image } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { PRODUCTION_NAME_DEV } from "../../../../../../config";
import styles from "./style.module.scss";
import "./style.scss";

function GameCategoryItem({ gameCategoryType, gameList, changeGame }) {
  const { t } = useTranslation();
  const gameCode = useParams().lotteryType;
  return (
    <div className="popup-gameCategory-item ">
      <Divider contentPosition="left">
        <div className={styles.titleBox}>
          <Image
            src={`/images/logo/${PRODUCTION_NAME_DEV}/categoryIcons/${gameCategoryType}.webp`}
            width={20}
            height={20}
            fit="cover"
          />
          <span>{t(`gameWay.${gameCategoryType}`)}</span>
        </div>
      </Divider>
      <Grid columns={3} gap={[12, 8]} className={styles.btnGroup}>
        {gameList?.map((game, index) => {
          return (
            <Grid.Item key={"game" + index} className={styles.btnItem}>
              <Button
                block
                shape="rounded"
                className={`${styles.btn} ${gameCode == game.game_type ? styles.active : ""}`}
                onClick={() => {
                  changeGame(game.game_type);
                }}
              >
                {t(`lottery.gameType.${game.game_type}`)}
              </Button>
            </Grid.Item>
          );
        })}
      </Grid>
    </div>
  );
}

export default GameCategoryItem;
