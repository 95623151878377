import {
  FiveStarsFunctions,
  FourStarsFunction,
  FreePositionFunction,
  EveryColorKeyEnums,
  OptionalFunction,
  ThreeStarsFunction,
  TwoStarsFunction
} from "./LOTEC3Function";


export const FIVE_STAR = ({ selected, currentGameWayDetail }) => {
  return FiveStarsFunctions[currentGameWayDetail].validate({ selected });
};
export const FOUR_STAR = ({ selected, currentGameWayDetail }) => {
  return FourStarsFunction[currentGameWayDetail].validate({ selected });
};
export const THREE_STAR = ({ selected, currentGameWayDetail }) => {
  return ThreeStarsFunction[currentGameWayDetail].validate({ selected });
};

export const TWO_STAR = ({ selected, currentGameWayDetail }) => {
  return TwoStarsFunction[currentGameWayDetail].validate({ selected });
};

export const FREE_POS = ({ selected, currentGameWayDetail }) => {
  return FreePositionFunction[currentGameWayDetail].validate({ selected });
};

export const OPTION_SELECT = ({ selected, currentGameWayDetail }) => {
  return OptionalFunction[currentGameWayDetail].validate({ selected });
};