export default {
  OFLOTPK10LAGYJH: {
    hint: "lottery.rules.modal.LOTPK105GYJH.hint",
    description: "lottery.rules.modal.LOTPK105GYJH.description",
    example: "lottery.rules.modal.LOTPK105GYJH.example",
  },
  OFLOTPK10LAQY: {
    hint: "lottery.rules.modal.LOTPK105QY.hint",
    description: "lottery.rules.modal.LOTPK105QY.description",
    example: "lottery.rules.modal.LOTPK105QY.example",
  },
  OFLOTPK10LAQE: {
    hint: "lottery.rules.modal.LOTPK105QE.hint",
    description: "lottery.rules.modal.LOTPK105QE.description",
    example: "lottery.rules.modal.LOTPK105QE.example",
  },
  OFLOTPK10LAQS: {
    hint: "lottery.rules.modal.LOTPK105QS.hint",
    description: "lottery.rules.modal.LOTPK105QS.description",
    example: "lottery.rules.modal.LOTPK105QS.example",
  },

  OFLOTPK10LADWD: {
    hint: {
      OFLOTPK10LADWD0110: "lottery.rules.modal.LOTPK105DWD0110.hint",
      OFLOTPK10LADWD0105: "lottery.rules.modal.LOTPK105DWD0105.hint",
      OFLOTPK10LADWD0610: "lottery.rules.modal.LOTPK105DWD0610.hint",
    },
    description: {
      OFLOTPK10LADWD0110: "lottery.rules.modal.LOTPK105DWD0110.description",
      OFLOTPK10LADWD0105: "lottery.rules.modal.LOTPK105DWD0105.description",
      OFLOTPK10LADWD0610: "lottery.rules.modal.LOTPK105DWD0610.description",
    },
    example: {
      OFLOTPK10LADWD0110: "lottery.rules.modal.LOTPK105DWD0110.example",
      OFLOTPK10LADWD0105: "lottery.rules.modal.LOTPK105DWD0105.example",
      OFLOTPK10LADWD0610: "lottery.rules.modal.LOTPK105DWD0610.example",
    },
  },
  OFLOTPK10LAWX: {
    hint: {
      OFLOTPK10LAWXGJ: "lottery.rules.modal.LOTPK105WXGJ.hint",
      OFLOTPK10LAWXYJ: "lottery.rules.modal.LOTPK105WXYJ.hint",
      OFLOTPK10LAWXJJ: "lottery.rules.modal.LOTPK105WXJJ.hint",
    },
    description: {
      OFLOTPK10LAWXGJ: "lottery.rules.modal.LOTPK105WXGJ.description",
      OFLOTPK10LAWXYJ: "lottery.rules.modal.LOTPK105WXYJ.description",
      OFLOTPK10LAWXJJ: "lottery.rules.modal.LOTPK105WXJJ.description",
    },
    example: {
      OFLOTPK10LAWXGJ: "lottery.rules.modal.LOTPK105WXGJ.example",
      OFLOTPK10LAWXYJ: "lottery.rules.modal.LOTPK105WXYJ.example",
      OFLOTPK10LAWXJJ: "lottery.rules.modal.LOTPK105WXJJ.example",
    },
  },
  OFLOTPK10LADXDS: {
    hint: {
      OFLOTPK10LADXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.hint",
      OFLOTPK10LADXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.hint",
    },
    description: {
      OFLOTPK10LADXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.description",
      OFLOTPK10LADXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.description",
    },
    example: {
      OFLOTPK10LADXDS0105: "lottery.rules.modal.LOTPK105DXDS0105.example",
      OFLOTPK10LADXDS0610: "lottery.rules.modal.LOTPK105DXDS0610.example",
    },
  },
  OFLOTPK10LALH: {
    hint: "lottery.rules.modal.LOTPK105LH.hint",
    description: "lottery.rules.modal.LOTPK105LH.description",
    example: "lottery.rules.modal.LOTPK105LH.example",
  },
  OFLOTPK10LANN: {
    hint: {
      OFLOTPK10LANNPB: "lottery.rules.modal.LOTPK105NNPB.hint",
      OFLOTPK10LANNFB: "lottery.rules.modal.LOTPK105NNPB.hint",
    },
    description: {
      OFLOTPK10LANNPB: "lottery.rules.modal.LOTPK105NNPB.description",
      OFLOTPK10LANNFB: "lottery.rules.modal.LOTPK105NNPB.description",
    },
    example: {
      OFLOTPK10LANNPB: "lottery.rules.modal.LOTPK105NNPB.example",
      OFLOTPK10LANNFB: "lottery.rules.modal.LOTPK105NNPB.example",
    },
  },
};
