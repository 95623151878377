export const SELECTED_MIN_LIMIT = (min = 1, translation = "validate.option.minLimit") => {
  return ({ selected }) => (selected !== undefined &&
    Object.keys(selected).reduce(
      (partialSum, key) => partialSum + selected[key]?.length,
      0
    )) >= min || [translation, { min: min }];
};

export const SELECTED_MAX_LIMIT = (max, translation = "validate.option.maxLimit") => {
  return ({ selected }) => (selected !== undefined &&
    Object.keys(selected).reduce(
      (partialSum, key) => partialSum + selected[key]?.length,
      0
    )) <= max || [translation, { max: max }];
};

export const MS_TM = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case 'OFLOTMSMOTMTM':
      return SELECTED_MIN_LIMIT(1)({ selected });
    case 'OFLOTMSMOTMBP':
      return SELECTED_MIN_LIMIT(1)({ selected });
    default:
      break;
  }
};

export const MS_TMSX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MIN_LIMIT(1, "validate.zodiac.minLimit")({ selected });
};

export const MS_YX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MIN_LIMIT(1)({ selected });
};

export const MS_ZX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MIN_LIMIT(1)({ selected });
};

//連肖
export const MS_LX_MIN = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case 'OFLOTMSMOLXEXP':
      return SELECTED_MIN_LIMIT(2, "validate.zodiac.minLimit")({ selected });
    case 'OFLOTMSMOLXSAXP':
      return SELECTED_MIN_LIMIT(3, "validate.zodiac.minLimit")({ selected });
    case 'OFLOTMSMOLXSIXP':
      return SELECTED_MIN_LIMIT(4, "validate.zodiac.minLimit")({ selected });
    case 'OFLOTMSMOLXWXP':
      return SELECTED_MIN_LIMIT(5, "validate.zodiac.minLimit")({ selected });
    default:
      break;
  }
};

export const MS_LX_MAX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MAX_LIMIT(6, "validate.zodiac.maxLimit")({ selected });
};

//連尾
export const MS_LW_MIN = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case 'OFLOTMSMOLWELW':
      return SELECTED_MIN_LIMIT(2, "validate.ws.minLimit")({ selected });
    case 'OFLOTMSMOLWSALW':
      return SELECTED_MIN_LIMIT(3, "validate.ws.minLimit")({ selected });
    case 'OFLOTMSMOLWSILW':
      return SELECTED_MIN_LIMIT(4, "validate.ws.minLimit")({ selected });
    case 'OFLOTMSMOLWWLW':
      return SELECTED_MIN_LIMIT(5, "validate.ws.minLimit")({ selected });
    default:
      break;
  }
};

export const MS_LW_MAX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MAX_LIMIT(6, "validate.ws.maxLimit")({ selected });
};

//合肖
export const MS_HX_MIN = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MIN_LIMIT(1, "validate.zodiac.minLimit")({ selected });
};

export const MS_HX_MAX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MAX_LIMIT(11, "validate.zodiac.maxLimit")({ selected });
};

//連碼
export const MS_LM_MIN = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case 'OFLOTMSMOLMSIQZ':
      return SELECTED_MIN_LIMIT(4, "validate.num.minLimit")({ selected });
    case 'OFLOTMSMOLMSAQZ':
      return SELECTED_MIN_LIMIT(3, "validate.num.minLimit")({ selected });
    case 'OFLOTMSMOLMSZE':
      return SELECTED_MIN_LIMIT(3, "validate.num.minLimit")({ selected });
    case 'OFLOTMSMOLMEQZ':
      return SELECTED_MIN_LIMIT(2, "validate.num.minLimit")({ selected });
    case 'OFLOTMSMOLMEZT':
      return SELECTED_MIN_LIMIT(2, "validate.num.minLimit")({ selected });
    case 'OFLOTMSMOLMTC':
      return SELECTED_MIN_LIMIT(2, "validate.num.minLimit")({ selected });
    default:
      break;
  }
};

export const MS_LM_MAX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MAX_LIMIT(10, "validate.num.maxLimit")({ selected });
};

//自選不中
export const MS_TSBC_MIN = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case "OFLOTMSMOTSBCWBZ": //五不中
      return SELECTED_MIN_LIMIT(5, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOTSBCLBZ": //六不中
      return SELECTED_MIN_LIMIT(6, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOTSBCQBZ": //七不中
      return SELECTED_MIN_LIMIT(7, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOTSBCBBZ": //八不中
      return SELECTED_MIN_LIMIT(8, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOTSBCJBZ": //九不中
      return SELECTED_MIN_LIMIT(9, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOTSBCSBZ": //十不中
      return SELECTED_MIN_LIMIT(10, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOTSBCSYBZ": //十一不中
      return SELECTED_MIN_LIMIT(11, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOTSBCSEBZ": //十二不中
      return SELECTED_MIN_LIMIT(12, "validate.num.minLimit")({ selected });
    default:
      break;
  }
};

export const MS_TSBC_MAX = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case "OFLOTMSMOTSBCWBZ": //五不中
      return SELECTED_MAX_LIMIT(8, "validate.num.maxLimit")({ selected });
    case "OFLOTMSMOTSBCLBZ": //六不中
      return SELECTED_MAX_LIMIT(9, "validate.num.maxLimit")({ selected });
    case "OFLOTMSMOTSBCQBZ": //七不中
      return SELECTED_MAX_LIMIT(9, "validate.num.maxLimit")({ selected });
    case "OFLOTMSMOTSBCBBZ": //八不中
      return SELECTED_MAX_LIMIT(10, "validate.num.maxLimit")({ selected });
    case "OFLOTMSMOTSBCJBZ": //九不中
      return SELECTED_MAX_LIMIT(11, "validate.num.maxLimit")({ selected });
    case "OFLOTMSMOTSBCSBZ": //十不中
      return SELECTED_MAX_LIMIT(12, "validate.num.maxLimit")({ selected });
    case "OFLOTMSMOTSBCSYBZ": //十一不中
      return SELECTED_MAX_LIMIT(13, "validate.num.maxLimit")({ selected });
    case "OFLOTMSMOTSBCSEBZ": //十二不中
      return SELECTED_MAX_LIMIT(14, "validate.num.maxLimit")({ selected });
    default:
      break;
  }
};

//自選中一
export const MS_ZY_MIN = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case "OFLOTMSMOCYSAZY": //三中一
      return SELECTED_MIN_LIMIT(3, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOCYSIZY": //四中一
      return SELECTED_MIN_LIMIT(4, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOCYWZY": //五中一
      return SELECTED_MIN_LIMIT(5, "validate.num.minLimit")({ selected });
    case "OFLOTMSMOCYLZY": //六中一
      return SELECTED_MIN_LIMIT(6, "validate.num.minLimit")({ selected });
    default:
      break;
  }
};

export const MS_ZY_MAX = ({ selected, currentGameWayDetail }) => {
  return SELECTED_MAX_LIMIT(8, "validate.num.maxLimit")({ selected });
};
//正碼

export const MS_CM = ({ selected, currentGameWayDetail }) => {
  switch (currentGameWayDetail) {
    case 'OFLOTMSMOCMCM':
      return SELECTED_MIN_LIMIT(1, "validate.num.minLimit")({ selected });
    default:
      return SELECTED_MIN_LIMIT(1)({ selected });
  }
};