import Selector from "../../../components/Selector";

const LOTVNNEC5SelectorEnum = {
  //=============================包选========================
  "LOTVNNEC5BXB2BQ2ZX.B2BQ2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC5BXB2BH2ZX.B2BH2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  "LOTVNNEC5BXB2BQ2ZXBDW.B2BQ2ZXBDW": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC5BXB2BH2ZXBDW.B2BH2ZXBDW": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  "LOTVNNEC5BXB3BQ3ZX.B3BQ3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC5BXB3BH3ZX.B3BH3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC5BXB4BQ4ZX.B4BQ4ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC5BXB4BH4ZX.B4BH4ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  "LOTVNNEC5BXQBBQ2ZX.QBBQ2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  "LOTVNNEC5BXQBBH2ZX.QBBH2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  //=============================串关========================
  "LOTVNNEC5CG2CZX.2CZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
      limit: 2,
    },
  },
  "LOTVNNEC5CG3CZX.3CZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
      limit: 3,
    },
  },
  "LOTVNNEC5CG4CZX.4CZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
      limit: 4,
    },
  },
  //=============================二星========================
  "LOTVNNEC52STJQ2ZX.TJQ2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC52S1JQ2ZX.1JQ2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC52ST7Q2ZX.T7Q2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC52STJH2ZX.TJH2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC52S1JH2ZX.1JH2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC52ST7H2ZX.T7H2ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC52S7J2SZX.7J2SZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================三星========================

  "LOTVNNEC53STJQ3ZX.TJQ3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53S1JQ3ZX.1JQ3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53ST6Q3ZX.T6Q3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53STJQ3DX3.TJQ3DX3": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53STJQ3DX6.TJQ3DX6": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53STJH3ZX.TJH3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53S1JH3ZX.1JH3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53ST6H3ZX.T6H3ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53STJH3DX3.TJH3DX3": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53STJH3DX6.TJH3DX6": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC53S6J3SZX.6J3SZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  //=============================四星========================
  "LOTVNNEC54STJQ4ZX.TJQ4ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC54S1JQ4ZX.1JQ4ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC54STJH4ZX.TJH4ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTVNNEC54S1JH4ZX.1JH4ZX": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  //=============================自选不中========================
  "LOTVNNEC5ZXBZH24BZ.H24BZ": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
      limit: 4,
    },
  },
  "LOTVNNEC5ZXBZH26BZ.H26BZ": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
      limit: 6,
    },
  },
  "LOTVNNEC5ZXBZH28BZ.H28BZ": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
      limit: 8,
    },
  },
  "LOTVNNEC5ZXBZH210BZ.H210BZ": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
      limit: 10,
    },
  },
  //=============================大小单双========================
  "LOTVNNEC5DXDS.LOTVNNECDXDS": {
    component: Selector.numberLOTVNNEC5,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      hideOdds: true,
      haveBothNumberAndText: true,
    },
  },
  //=============================大小单双========================
};

export default LOTVNNEC5SelectorEnum;
