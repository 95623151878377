import { Button, Divider } from "antd-mobile";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
const QuickSelectBar = ({
  group = "",
  onClickAll = () => {},
  onClickBig = () => {},
  onClickSmall = () => {},
  onClickOdd = () => {},
  onClickEven = () => {},
  onClickClear = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Button
        onClick={() => onClickAll(group)}
        fill="none"
        className={styles.button}
        style={{ padding: "7px 8px" }}
      >
        {t("all")}
      </Button>
      <Divider direction="vertical" className={styles.divider} />
      <Button
        onClick={() => onClickBig(group)}
        fill="none"
        className={styles.button}
        style={{ padding: "7px 8px" }}
      >
        {t("big")}
      </Button>
      <Divider direction="vertical" className={styles.divider} />
      <Button
        onClick={() => onClickSmall(group)}
        fill="none"
        className={styles.button}
        style={{ padding: "7px 8px" }}
      >
        {t("small")}
      </Button>
      <Divider direction="vertical" className={styles.divider} />
      <Button
        onClick={() => onClickOdd(group)}
        fill="none"
        className={styles.button}
        style={{ padding: "7px 8px" }}
      >
        {t("odd")}
      </Button>
      <Divider direction="vertical" className={styles.divider} />
      <Button
        onClick={() => onClickEven(group)}
        fill="none"
        className={styles.button}
        style={{ padding: "7px 8px" }}
      >
        {t("even")}
      </Button>
      <Divider direction="vertical" className={styles.divider} />
      <Button
        onClick={() => onClickClear(group)}
        fill="none"
        className={styles.button}
        style={{ padding: "7px 8px" }}
      >
        {t("clear")}
      </Button>
    </div>
  );
};

export default QuickSelectBar;
