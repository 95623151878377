import { BALL_COUNT_EACH_GROUP } from "../../valudateFunctionEnum";

const PackageSelectionFunction = {
  //包前二直选
  LOTVNNEC10BXB2BQ2ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 27;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //包后二直选
  LOTVNNEC10BXB2BH2ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 27;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //包前二直选不定位
  LOTVNNEC10BXB2BQ2ZXBDW: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //包后二直选不定位
  LOTVNNEC10BXB2BH2ZXBDW: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //包前三直选
  LOTVNNEC10BXB3BQ3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 23;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //包后三直选
  LOTVNNEC10BXB3BH3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 23;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //包前四直选
  LOTVNNEC10BXB4BQ4ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 20;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //包后四直选
  LOTVNNEC10BXB4BH4ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 20;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //全包前二直选
  LOTVNNEC10BXQBBQ2ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //全包后二直选
  LOTVNNEC10BXQBBH2ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
};

export { PackageSelectionFunction };
