export default {
  LOTFT5HZ: {
    hint: "lottery.rules.modal.LOTFT3HZ.hint",
    description: "lottery.rules.modal.LOTFT3HZ.description",
    example: "lottery.rules.modal.LOTFT3HZ.example",
  },
  LOTFT5ETH: {
    hint: {
      LOTFT5ETHETD: "lottery.rules.modal.LOTFT3ETHETD.hint",
      LOTFT5ETHETF: "lottery.rules.modal.LOTFT3ETHETF.hint",
    },
    description: {
      LOTFT5ETHETD: "lottery.rules.modal.LOTFT3ETHETD.description",
      LOTFT5ETHETF: "lottery.rules.modal.LOTFT3ETHETF.description",
    },
    example: {
      LOTFT5ETHETD: "lottery.rules.modal.LOTFT3ETHETD.example",
      LOTFT5ETHETF: "lottery.rules.modal.LOTFT3ETHETF.example",
    },
  },
  LOTFT5EBTH: {
    hint: {
      LOTFT5EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.hint",
      LOTFT5EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.hint",
    },
    description: {
      LOTFT5EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.description",
      LOTFT5EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.description",
    },
    example: {
      LOTFT5EBTHBZXH: "lottery.rules.modal.LOTFT3EBTHBZXH.example",
      LOTFT5EBTHDTXH: "lottery.rules.modal.LOTFT3EBTHDTXH.example",
    },
  },
  LOTFT5SLH: {
    hint: "lottery.rules.modal.LOTFT3SLH.hint",
    description: "lottery.rules.modal.LOTFT3SLH.description",
    example: "lottery.rules.modal.LOTFT3SLH.example",
  },
  LOTFT5STH: {
    hint: {
      LOTFT5STHSTD: "lottery.rules.modal.LOTFT3STHSTD.hint",
      LOTFT5STHSTT: "lottery.rules.modal.LOTFT3STHSTT.hint",
    },
    description: {
      LOTFT5STHSTD: "lottery.rules.modal.LOTFT3STHSTD.description",
      LOTFT5STHSTT: "lottery.rules.modal.LOTFT3STHSTT.description",
    },
    example: {
      LOTFT5STHSTD: "lottery.rules.modal.LOTFT3STHSTD.example",
      LOTFT5STHSTT: "lottery.rules.modal.LOTFT3STHSTT.example",
    },
  },
  LOTFT5SBTH: {
    hint: "lottery.rules.modal.LOTFT3SBTH.hint",
    description: "lottery.rules.modal.LOTFT3SBTH.description",
    example: "lottery.rules.modal.LOTFT3SBTH.example",
  },
};
