import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { Divider } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Countdown } from "../../../../components";
import GameResultRender from "../../../../components/GameResultRender";
import { useWebsocket } from "../../../../context/WebsocketContext";
import AnimatedDrawNumber from "../AnimateDrawNumber";
import gameStateEnum from "./enumerations/gameStateEnum";
import styles from "./style.module.scss";
const GameResultViewer = ({
  haveAnimations = [],
  lotteryType = "",
  gameCategoryDetailType = "",
  isOpenHistory = false,
  onOpenHistory = () => {},
  latestNumber,
  refreshData = () => {},
  refreshGameResultData = () => {},
  lotteryResultVisible = false,
  latestNumberRefetching = false,
}) => {
  const { t } = useTranslation();
  const { prevPeriod, currentPeriod } = useWebsocket();
  const [currentGameState, setCurrentGameState] = useState(gameStateEnum.BET_PHASE);

  const getPeriodData = () => {
    if (prevPeriod && prevPeriod.lottery_number) {
      if (prevPeriod.channel !== lotteryType) return null;
      return {
        lottery_number: prevPeriod?.lottery_number,
        data: prevPeriod?.data,
      };
    }
    if (latestNumber) {
      const _apiPrevPeriod = latestNumber[1];
      return {
        lottery_number: _apiPrevPeriod?.lottery_number || "",
        data: _apiPrevPeriod?.data,
      };
    }
    return "";
  };

  const renderGameResult = () => {
    return <GameResultRender gameType={gameCategoryDetailType} data={getPeriodData()} />;
  };

  const renderCurrentPeriod = () => {
    if (currentPeriod.period) {
      return currentPeriod.channel === lotteryType ? currentPeriod?.period || " " : " ";
    }
    if (latestNumber) {
      return latestNumber[0].period || " ";
    }
    return " ";
  };

  const renderCurrentPeriodNumber = () => {
    return (
      <div className={styles.currentPeriodContainer}>
        <div className={`${styles.number} ${styles.current}`}>{renderCurrentPeriod()}</div>
        <div className={styles.subtitle}>{t("game.periodNumber")}</div>
      </div>
    );
  };

  const renderRightContent = () => {
    if (!haveAnimations.includes(gameCategoryDetailType)) {
      return (
        <>
          <div className={styles.rightTitle}>
            <div className={styles.buttonText} style={{ marginRight: 4 }}>
              {t(`gameTitle.${lotteryType}`)}
            </div>
            <div className={styles.periodContainer}>
              <span className={styles.number}>
                {prevPeriod?.period || (latestNumber && latestNumber[1].period) || "-"}
              </span>
              <span className={styles.subtitle}>{t("game.periodNumber")}</span>
              <div className={styles.buttonText} onClick={onOpenHistory}>
                {t("game.history.button")}
                {isOpenHistory ? (
                  <CaretUpFilled
                    onClick={onOpenHistory}
                    style={{
                      fontSize: "var(--fontSetting-small)",
                      color: "var(--color-main-active)",
                    }}
                  />
                ) : (
                  <CaretDownFilled
                    onClick={onOpenHistory}
                    style={{
                      fontSize: "var(--fontSetting-small)",
                      color: "var(--color-arrow-light)",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {renderGameResult()}
        </>
      );
    }
    if (currentGameState.key === gameStateEnum.BET_PHASE.key) {
      return (
        <>
          <div className={styles.rightTitle}>
            <div className={styles.buttonText} style={{ marginRight: 4 }}>
              {t(`gameTitle.${lotteryType}`)}
            </div>
            <div className={styles.periodContainer}>
              <span className={styles.number}>
                {prevPeriod?.period || (latestNumber && latestNumber[1].period) || "-"}
              </span>
              <span className={styles.subtitle}>{t("game.periodNumber")}</span>
              <div className={styles.buttonText} onClick={onOpenHistory}>
                {t("game.history.button")}
                {isOpenHistory ? (
                  <CaretUpFilled
                    onClick={onOpenHistory}
                    style={{
                      fontSize: "var(--fontSetting-small)",
                      color: "var(--color-main-active)",
                    }}
                  />
                ) : (
                  <CaretDownFilled
                    onClick={onOpenHistory}
                    style={{
                      fontSize: "var(--fontSetting-small)",
                      color: "var(--color-arrow-light)",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {renderGameResult()}
        </>
      );
    }
    return (
      <>
        <AnimatedDrawNumber
          currentGameState={currentGameState}
          gameType={gameCategoryDetailType}
          data={getPeriodData()}
        />
      </>
    );
  };

  return (
    <div className={styles.container} id="gameResultViewer">
      <div className={styles.leftContainer}>
        {renderCurrentPeriodNumber()}
        <div className={styles.subtitle}>{t("game.expiredTime")}</div>
        <Countdown
          setCurrentGameState={setCurrentGameState}
          currentGameState={currentGameState}
          lotteryType={lotteryType}
          punctuationStyle={{ color: "var(--color-text-timer)" }}
          latestNumber={latestNumber}
          refreshData={refreshData}
          refreshGameResultData={refreshGameResultData}
          lotteryResultVisible={lotteryResultVisible}
          latestNumberRefetching={latestNumberRefetching}
        />
      </div>
      <Divider
        direction={"vertical"}
        style={{
          height: "40px",
          backgroundColor: "var(--color-vertical-divider)",
          margin: "0",
        }}
      />
      <div className={styles.rightContainer}>{renderRightContent()}</div>
    </div>
  );
};

export default GameResultViewer;
