export const LOTMSNumberColor = {
  1: { color: "red" },
  2: { color: "red" },
  3: { color: "blue" },
  4: { color: "blue" },
  5: { color: "green" },
  6: { color: "green" },
  7: { color: "red" },
  8: { color: "red" },
  9: { color: "blue" },
  10: { color: "blue" },
  11: { color: "green" },
  12: { color: "red" },
  13: { color: "red" },
  14: { color: "blue" },
  15: { color: "blue" },
  16: { color: "green" },
  17: { color: "green" },
  18: { color: "red" },
  19: { color: "red" },
  20: { color: "blue" },
  21: { color: "green" },
  22: { color: "green" },
  23: { color: "red" },
  24: { color: "red" },
  25: { color: "blue" },
  26: { color: "blue" },
  27: { color: "green" },
  28: { color: "green" },
  29: { color: "red" },
  30: { color: "red" },
  31: { color: "blue" },
  32: { color: "green" },
  33: { color: "green" },
  34: { color: "red" },
  35: { color: "red" },
  36: { color: "blue" },
  37: { color: "blue" },
  38: { color: "green" },
  39: { color: "green" },
  40: { color: "red" },
  41: { color: "blue" },
  42: { color: "blue" },
  43: { color: "green" },
  44: { color: "green" },
  45: { color: "red" },
  46: { color: "red" },
  47: { color: "blue" },
  48: { color: "blue" },
  49: { color: "green" },
};