import { Card } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

import GameResultRender from "../../../../components/GameResultRender";
import VNGameResultRender from "../../../../components/VNGameResultRender";
import styles from "./style.module.scss";

function CardItem(props) {
  const { data, gameType = "", onClick = () => {} } = props;
  const { t } = useTranslation();
  return (
    <Card
      onClick={onClick}
      style={{
        background: "var(--color-background-secondary)",
        marginBottom: "4px",
      }}
    >
      <div className="card-content">
        <div className="left-side">
          <div>
            <div className="game-period">{t("history.periodNumber", { period: data.period })}</div>
            <GameResultRender gameType={gameType} data={data} />
          </div>
        </div>
        <div className="right-side">
          <div className="game-time">{data.draw_time}</div>
        </div>
        {gameType === "LOTVNNEC" ? (
          <VNGameResultRender
            containerStyle={styles.customVNContainer}
            lotteryType={data.game_type}
            hidePeriod={true}
            hideMore={true}
            prevPeriod={{
              channel: data.game_type,
              lottery_number: data.lottery_number,
              period: data.period,
            }}
          />
        ) : null}
      </div>
    </Card>
  );
}

export default CardItem;
