import { requestMetaMain, requestMetaUser } from "../../apis";
import { getCurrency } from "../../plugins/utils";

const CURRENCY = getCurrency();

export const apiGetUserInfo = (params) => requestMetaUser("get", `/user/info`, params, true);

export const apiGetUserWalletData = (params) =>
  requestMetaMain("get", `/${CURRENCY}/user/wallet`, params, true);

export const apiGetUserWalletCacheData = (params) =>
  requestMetaMain("get", `/${CURRENCY}/user/wallet/cache`, params, true);
