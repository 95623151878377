const renderChoiceNumber = (val, t) => {
  const itemSorting = (a, b) => {
    if (isNaN(parseInt(a)) && isNaN(parseInt(b))) {
      return 1;
    }
    return parseInt(a) - parseInt(b);
  };

  if (typeof val === "string") {
    const valSplit = val.split("|");
    return valSplit
      .map((_val) => {
        return (
          _val
            .split(",")
            .sort(itemSorting)
            //because parseInt(7X)=7
            .map((v) =>
              v.includes("X")
                ? t(`lottery.item.${v}`)
                : isNaN(parseInt(v)) && v !== "_" && v !== ""
                ? t(`lottery.item.${v}`)
                : v,
            )
            .join(",")
        );
      })
      .join("|");
  } else {
    return val;
  }
};

export default renderChoiceNumber;
