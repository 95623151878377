import { Button } from "antd-mobile";
import styles from "./style.module.scss";
const CustomButton = (props) => {
  return (
    <Button
      className={`${styles.customButton} ${
        props.disabled === true ? styles.disabled : ""
      }`}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default CustomButton;
