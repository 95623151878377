import { Image } from "antd-mobile";
import React from "react";
import gameStateEnum from "../GameResultViewer/enumerations/gameStateEnum";
import Dice1 from "./images/LOTFT/1.gif";
import Dice2 from "./images/LOTFT/2.gif";
import Dice3 from "./images/LOTFT/3.gif";
import styles from "./style.module.scss";

const AnimatedDrawNumber = ({ gameType, data, currentGameState }) => {
  const numbers = data?.lottery_number?.split(",") || [];
  const animateDiceRender = () => {
    return (
      <div className={styles.diceContainer}>
        <Image src={Dice1} className={styles.dice} />
        <Image src={Dice2} className={styles.dice} />
        <Image src={Dice3} className={styles.dice} />
      </div>
    );
  };

  const resultDiceRender = () => {
    return (
      <div className={`${styles.diceContainer}`}>
        {numbers.map((number, index) => {
          return (
            <div className={`${styles.diceResult}`} key={`${number}_${index}`}>
              <Image src={`/images/game/result/dice/dice${number}.png`} fit="cover" />
            </div>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    switch (gameType) {
      case "LOTFT":
        switch (currentGameState.key) {
          case gameStateEnum.ANIMATION_PHASE.key:
          case gameStateEnum.RESULT_PENDING_PHASE.key:
            return animateDiceRender();
          case gameStateEnum.RESULT_PHASE.key:
            return resultDiceRender();
          default:
            break;
        }
        break;
      case "LOTMS":
        break;
      case "LOTEC":
        break;
      default:
        break;
    }
  };

  return <div className={styles.container}>{renderContent()}</div>;
};

export default AnimatedDrawNumber;
