import { Divider } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Countdown } from "../../../../components";
import VNGameResultRender from "../../../../components/VNGameResultRender";
import { useWebsocket } from "../../../../context/WebsocketContext";
import gameStateEnum from "../GameResultViewer/enumerations/gameStateEnum";
import styles from "./style.module.scss";
const VNGameResultViewer = ({
  lotteryType = "",
  gameCategoryDetailType = "",
  latestNumber,
  refreshData = () => {},
  latestNumberRefetching = false,
}) => {
  const { t } = useTranslation();
  const { currentPeriod, prevPeriod } = useWebsocket();
  const [currentGameState, setCurrentGameState] = useState(gameStateEnum.BET_PHASE);
  const navigate = useNavigate();

  const handleViewAllHistory = (e) => {
    e.stopPropagation();
    navigate(`/gameResult/detail/${lotteryType}?type=${gameCategoryDetailType}`);
  };

  const getCurrentPeriod = (_currentPeriod, _latestNumber, _lotteryType) => {
    if (_currentPeriod && _currentPeriod.channel) {
      return _currentPeriod.channel === _lotteryType ? _currentPeriod?.period || " " : " ";
    }
    if (_latestNumber) {
      const apiCurrentPeriod = _latestNumber[0];
      return apiCurrentPeriod.period || " ";
    }
  };

  const renderCurrentPeriodNumber = () => {
    return (
      <div className={styles.currentPeriodContainer}>
        <div className={`${styles.number} ${styles.current}`}>
          {getCurrentPeriod(currentPeriod, latestNumber, lotteryType)}
        </div>
        <div className={styles.subtitle}>{t("game.periodNumber")}</div>
      </div>
    );
  };

  return (
    <div className={styles.container} id="VNGameResultViewer">
      <div className={styles.topContainer}>
        <div className={styles.left}>
          <div className={styles.buttonText} style={{ marginRight: 4 }}>
            {t(`gameTitle.${lotteryType}`)}
          </div>
          {renderCurrentPeriodNumber()}
        </div>
        <div className={styles.right}>
          <div className={styles.subtitle}>{t("game.expiredTime")}</div>
          <Countdown
            setCurrentGameState={setCurrentGameState}
            currentGameState={currentGameState}
            lotteryType={lotteryType}
            punctuationStyle={{ color: "var(--color-text-timer)" }}
            latestNumber={latestNumber}
            refreshData={refreshData}
            latestNumberRefetching={latestNumberRefetching}
          />
        </div>
      </div>
      <Divider style={{ color: "#e2e2e2", width: "100%", margin: "0" }} />
      <div
        style={{
          padding: "10px 10px 10px 10px",
          width: "100%",
        }}
      >
        <VNGameResultRender
          lotteryType={lotteryType}
          handleViewAllHistory={handleViewAllHistory}
          latestNumber={latestNumber}
          prevPeriod={prevPeriod}
        />
      </div>
    </div>
  );
};

export default VNGameResultViewer;
