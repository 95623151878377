import { useTranslation } from "react-i18next";
import { DEFAULT_CURRENCY } from "../../../../config";
import { moneyFormat } from "../../../../plugins/numberFormat";
import styles from "./style.module.scss";

const Balance = ({ amount = 0, currency = DEFAULT_CURRENCY }) => {
  const { t } = useTranslation();
  const split = moneyFormat(amount).split(".");

  const renderAmount = (split) => {
    if (split.length >= 2) {
      return (
        <>
          <span className={styles.amount}>{`${split[0]}.`}</span>
          <span className={styles.decimal}>{`${split[1]}`}</span>
        </>
      );
    }
    return <span className={styles.amount}>{`${split[0]}`}</span>;
  };

  return (
    <div className={styles.balanceContainer}>
      <span className={styles.currency}>{t(`currency.symbol.${currency}`)}</span>
      {renderAmount(split)}
    </div>
  );
};

export default Balance;
