import React from "react";
import { useTranslation } from "react-i18next";
import { LOTMSNumberColor } from "../../../enumeration/LOTMSNumberColorEnum";
import DefaultSelector from "../DefaultSelector";
import styles from "./style.module.scss";
// import { useLottery } from "../../..";

export const CircleItem = ({
  isActive,
  isDisabled = false,
  onClick,
  code,
  textNoI18n,
  item,
  isColorfulBalls,
  itemWidth,
  hideOdds,
  group_key = "",
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.itemContainer}`} style={{ width: itemWidth }}>
      <div
        key={code}
        className={`${styles.item} ${isActive ? styles.active : ""} ${
          isDisabled ? styles.disabled : ""
        } ${isColorfulBalls ? styles[LOTMSNumberColor[parseInt(code)].color] : ""}`}
        onClick={() => onClick(group_key, item)}
      >
        {textNoI18n ? code : t(`lottery.item.${code}`)}
      </div>
      <div className={`${styles.oddsText}`}>
        {hideOdds ? "" : t("normal.odds", { odds: item.odds })}
      </div>
    </div>
  );
};

const Circle = (props) => {
  return <DefaultSelector {...props} selectorRender={CircleItem} />;
};

export default Circle;
