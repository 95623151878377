import Selector from "../../../components/Selector";

const OFLOTMSHKSelectorEnum = {
  // 特碼
  "OFLOTMSHKTMTM.TM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSHKTMTM.TM_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 半波
  "OFLOTMSHKTMBP.BP_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 特碼生肖
  "OFLOTMSHKTMSX.TMSX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 一肖
  "OFLOTMSHKYX.YX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "OFLOTMSHKYX.TW_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正肖
  "OFLOTMSHKZX.ZOX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "OFLOTMSHKZX.QSB": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連肖
  //  二肖碰
  "OFLOTMSHKLXEXP.EXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  三肖碰
  "OFLOTMSHKLXSAXP.SAXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  四肖碰
  "OFLOTMSHKLXSIXP.SIXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  五肖碰
  "OFLOTMSHKLXWXP.WXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連尾
  //  二尾连
  "OFLOTMSHKLWELW.ELW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  三尾连
  "OFLOTMSHKLWSALW.SALW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  四尾连
  "OFLOTMSHKLWSILW.SILW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  五尾连
  "OFLOTMSHKLWWLW.WLW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 合肖
  //  中
  "OFLOTMSHKHXZ.Z": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  不中
  "OFLOTMSHKHXBZ.BZ": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 連碼
  //  四全中
  "OFLOTMSHKLMSIQZ.SIQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三全中
  "OFLOTMSHKLMSAQZ.SAQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三中二
  "OFLOTMSHKLMSZE.SZE": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二全中
  "OFLOTMSHKLMEQZ.EQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二中特
  "OFLOTMSHKLMEZT.EZT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  特串
  "OFLOTMSHKLMTC.TC": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選不中
  //  五不中
  "OFLOTMSHKTSBCWBZ.WBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六不中
  "OFLOTMSHKTSBCLBZ.LBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  七不中
  "OFLOTMSHKTSBCQBZ.QBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  八不中
  "OFLOTMSHKTSBCBBZ.BBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  九不中
  "OFLOTMSHKTSBCJBZ.JBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十不中
  "OFLOTMSHKTSBCSBZ.SBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十一不中
  "OFLOTMSHKTSBCSYBZ.SYBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十二不中
  "OFLOTMSHKTSBCSEBZ.SEBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選中一
  //  三中一
  "OFLOTMSHKCYSAZY.SAZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  四中一
  "OFLOTMSHKCYSIZY.SIZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  五中一
  "OFLOTMSHKCYWZY.WZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六中一
  "OFLOTMSHKCYLZY.LZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 正码
  "OFLOTMSHKCMCM.CM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  // 正码 1
  "OFLOTMSHKCMCM1.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSHKCMCM1.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 2
  "OFLOTMSHKCMCM2.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSHKCMCM2.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 3
  "OFLOTMSHKCMCM3.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSHKCMCM3.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 4
  "OFLOTMSHKCMCM4.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSHKCMCM4.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 5
  "OFLOTMSHKCMCM5.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSHKCMCM5.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 6
  "OFLOTMSHKCMCM6.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "OFLOTMSHKCMCM6.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
};

export default OFLOTMSHKSelectorEnum;
