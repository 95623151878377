import { combination, permutation, combinationResult } from "./math";
const threeSumDict = {
  0: 1,
  1: 3,
  2: 6,
  3: 10,
  4: 15,
  5: 21,
  6: 28,
  7: 36,
  8: 45,
  9: 55,
  10: 63,
  11: 69,
  12: 73,
  13: 75,
  14: 75,
  15: 73,
  16: 69,
  17: 63,
  18: 55,
  19: 45,
  20: 36,
  21: 28,
  22: 21,
  23: 15,
  24: 10,
  25: 6,
  26: 3,
  27: 1,
};
const threeDifferenceDict = {
  0: 10,
  1: 54,
  2: 96,
  3: 126,
  4: 144,
  5: 150,
  6: 144,
  7: 126,
  8: 96,
  9: 54,
};
const twoSumDict = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 7,
  7: 8,
  8: 9,
  9: 10,
  10: 9,
  11: 8,
  12: 7,
  13: 6,
  14: 5,
  15: 4,
  16: 3,
  17: 2,
  18: 1,
};
const twoDifferenceDict = {
  0: 10,
  1: 18,
  2: 16,
  3: 14,
  4: 12,
  5: 10,
  6: 8,
  7: 6,
  8: 4,
  9: 2,
};

export const threeStarSummaryCount = (arr) =>
  arr.reduce((partialSum, key) => partialSum + threeSumDict[key], 0);

export const threeStarDifferenceCount = (arr) =>
  arr.reduce((partialSum, key) => partialSum + threeDifferenceDict[key], 0);

export const twoStarSummaryCount = (arr) =>
  arr.reduce((partialSum, key) => partialSum + twoSumDict[key], 0);

export const twoStarDifferenceCount = (arr) =>
  arr.reduce((partialSum, key) => partialSum + twoDifferenceDict[key], 0);

export const anyTwoGroupOption = (unitArr, numArr) => {
  return combination(unitArr.length, 2) * combination(numArr.length, 2);
};

export const anyThreeGroupThree = (unitArr, numArr) => {
  return combination(unitArr.length, 3) * permutation(numArr.length, 2);
};

export const anyThreeGroupSix = (unitArr, numArr) => {
  return combination(unitArr.length, 3) * combination(numArr.length, 3);
};

export const anyComplexModel = (
  unit = { W: [], T: [], H: [], E: [], U: [] },
  num = 2
) => {
  const keys = Object.keys(unit).filter((key) => unit[key]?.length > 0);
  const keyCombination = combinationResult(keys, num);
  let summary = 0;
  keyCombination.forEach((comb) => {
    summary =
      summary +
      comb.reduce((partialSum, key) => partialSum * unit[key]?.length, 1);
  });
  return summary;
};
