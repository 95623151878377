import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_BET_AMOUNT } from "../../config";
import { LongDragonList, Navbar } from "./components";
import { LongDragonContextProvider } from "./context/LongDragonContext";
import styles from "./style.module.scss";
import "./style.scss";

const LongDragon = () => {
  const navigate = useNavigate();
  const [confirmVisible, setConfirmVisible] = useState(false);

  const [selected, setSelected] = useState({});
  const [currentBetNumber, setCurrentBetNumber] = useState(DEFAULT_BET_AMOUNT);

  const onHandleBackButtonPress = () => {
    if (confirmVisible) {
      setConfirmVisible(false);
      // resetValues();
    } else {
      // closeWebsocket();
      navigate("/", {
        replace: true,
      });
    }
  };

  const resetSelected = () => {
    setSelected({});
  };

  const handleSetBetNumber = (amount) => {
    setCurrentBetNumber(amount);
  };

  return (
    <LongDragonContextProvider>
      <div className={styles.longDragon}>
        <Navbar
          onHandleBackButtonPress={onHandleBackButtonPress}
          headerTitle={t("longdragon.pageTitle")}
        />
        <LongDragonList />
        {/* <RequestFooter
          resetSelected={resetSelected}
          currentBetNumber={currentBetNumber}
          handleSetBetNumber={handleSetBetNumber}
        /> */}
      </div>
    </LongDragonContextProvider>
  );
};

export default LongDragon;
