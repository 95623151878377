import { combination } from "../../../functions/math";
import {
  BALL_COUNT_EACH_GROUP,
  DUPLICATE_SELECT,
  NDOUBLE_MSINGLE,
  NQUAD_MSINGLE,
  NTRIPLE_MDOUBLE,
  NTRIPLE_MSINGLE,
  SELECTED_COUNT_LIMIT,
} from "../../valudateFunctionEnum";
import EveryColorKeyEnums from "./keyEnums";

const FiveStarsFunctions = {
  //五星直选复式
  LOTEC35S5SZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //五星直选组合
  LOTEC35S5SZXZH: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 5;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //五星组选120
  LOTEC35S5SDX120: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 5);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(5)({ selected });
    },
  },
  //五星组选60
  LOTEC35S5SDX60: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            if (key === EveryColorKeyEnums[currentGameWayDetail].DOUBLE.key) {
              multiplier += selected[key].length;
            } else if (
              key === EveryColorKeyEnums[currentGameWayDetail].SINGLE.key
            ) {
              count += selected[key].length;
            }
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 3) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected, currentGameWayDetail }) => {
      const isValid = NDOUBLE_MSINGLE({
        selected,
        limit_double: 1,
        limit_single: 3,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
  //五星组选30
  LOTEC35S5SDX30: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            if (key === EveryColorKeyEnums[currentGameWayDetail].SINGLE.key) {
              multiplier += selected[key].length;
            } else if (
              key === EveryColorKeyEnums[currentGameWayDetail].DOUBLE.key
            ) {
              count += selected[key].length;
            }
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected, currentGameWayDetail }) => {
      const isValid = NDOUBLE_MSINGLE({
        selected,
        limit_double: 2,
        limit_single: 1,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
  //五星组选20
  LOTEC35S5SDX20: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            if (key === EveryColorKeyEnums[currentGameWayDetail].TRIPLE.key) {
              multiplier += selected[key].length;
            } else if (
              key === EveryColorKeyEnums[currentGameWayDetail].SINGLE.key
            ) {
              count += selected[key].length;
            }
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected, currentGameWayDetail }) => {
      const isValid = NTRIPLE_MSINGLE({
        selected,
        limit_triple: 1,
        limit_single: 2,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
  //五星组选10
  LOTEC35S5SDX10: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected, currentGameWayDetail }) => {
      const isValid = NTRIPLE_MDOUBLE({
        selected,
        limit_triple: 1,
        limit_double: 1,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
  //五星组选5
  LOTEC35S5SDX5: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected, currentGameWayDetail }) => {
      const isValid = NQUAD_MSINGLE({
        selected,
        limit_quad: 1,
        limit_single: 1,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
};

export { FiveStarsFunctions };
