import Selector from "../../../components/Selector";

const LOTEC5SelectorEnum = {
  //=============================五星========================
  "LOTEC55S5SZXFS.5SZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC55S5SZXZH.5SZXZH": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  "LOTEC55S5SDX120.5SDX120": {
    component: Selector.number, //todo
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC55S5SDX60.5SDX60": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC55S5SDX30.5SDX30": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC55S5SDX20.5SDX20": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC55S5SDX10.5SDX10": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC55S5SDX5.5SDX5": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================五星========================
  //=============================四星========================
  "LOTEC54S4SH4ZXFS.4SH4ZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SH4ZXZH.4SH4ZXZH": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SH4DX24.4SH4DX24": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SH4DX12.4SH4DX12": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SH4DX6.4SH4DX6": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SH4DX4.4SH4DX4": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SQ4ZXFS.4SQ4ZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SQ4ZXZH.4SQ4ZXZH": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SQ4DX24.4SQ4DX24": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SQ4DX12.4SQ4DX12": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SQ4DX6.4SQ4DX6": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC54S4SQ4DX4.4SQ4DX4": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  //=============================四星========================
  //=============================三星========================
  "LOTEC53S3SH3ZXFS.3SH3ZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SH3ZXHZ.3SH3ZXHZ": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC53S3SH3ZXKD.3SH3ZXKD": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SH3ZXHZWS.3SH3ZXHZWS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SH3DX3.3SH3DX3": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SH3DX6.3SH3DX6": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SC3ZXFS.3SC3ZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SC3ZXHZ.3SC3ZXHZ": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC53S3SC3ZXKD.3SC3ZXKD": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SC3ZXHZWS.3SC3ZXHZWS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SC3DX3.3SC3DX3": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SC3DX6.3SC3DX6": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SQ3ZXFS.3SQ3ZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SQ3ZXHZ.3SQ3ZXHZ": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC53S3SQ3ZXKD.3SQ3ZXKD": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SQ3ZXHZWS.3SQ3ZXHZWS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SQ3DX3.3SQ3DX3": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC53S3SQ3DX6.3SQ3DX6": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================三星========================
  //=============================二星========================
  "LOTEC52S2SH2ZXFS.2SH2ZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC52S2SH2ZXHZ.2SH2ZXHZ": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC52S2SH2ZXKD.2SH2ZXKD": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC52S2SH2ZXHZWS.2SH2ZXHZWS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTEC52S2SH2DXFS.2SH2DXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.2SH2DXFS`,
    },
  },
  "LOTEC52S2SQ2ZXFS.2SQ2ZXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC52S2SQ2ZXHZ.2SQ2ZXHZ": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC52S2SQ2ZXKD.2SQ2ZXKD": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC52S2SQ2ZXHZWS.2SQ2ZXHZWS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTEC52S2SQ2DXFS.2SQ2DXFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.2SQ2DXFS`,
    },
  },
  //=============================二星========================
  //=============================定位胆========================
  "LOTEC5DWD.DWD": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================定位胆========================
  //=============================不定位========================
  "LOTEC5BDW3SBDWH31M.3SBDWH31M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWH31M`,
    },
  },
  "LOTEC5BDW3SBDWC31M.3SBDWC31M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWC31M`,
    },
  },
  "LOTEC5BDW3SBDWQ31M.3SBDWQ31M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWQ31M`,
    },
  },
  "LOTEC5BDW3SBDWH32M.3SBDWH32M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWH32M`,
    },
  },
  "LOTEC5BDW3SBDWC32M.3SBDWC32M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWC32M`,
    },
  },
  "LOTEC5BDW3SBDWQ32M.3SBDWQ32M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWQ32M`,
    },
  },
  "LOTEC5BDW4SBDWH41M.4SBDWH41M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWH41M`,
    },
  },
  "LOTEC5BDW4SBDWQ41M.4SBDWQ41M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWQ41M`,
    },
  },
  "LOTEC5BDW4SBDWH42M.4SBDWH42M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWH42M`,
    },
  },
  "LOTEC5BDW4SBDWQ42M.4SBDWQ42M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWQ42M`,
    },
  },
  "LOTEC5BDW4SBDWH43M.4SBDWH43M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWH43M`,
    },
  },
  "LOTEC5BDW4SBDWQ43M.4SBDWQ43M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWQ43M`,
    },
  },
  "LOTEC5BDW5SBDW5S1M.5SBDW5S1M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S1M`,
    },
  },
  "LOTEC5BDW5SBDW5S2M.5SBDW5S2M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S2M`,
    },
  },
  "LOTEC5BDW5SBDW5S3M.5SBDW5S3M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S3M`,
    },
  },
  "LOTEC5BDW5SBDW5S4M.5SBDW5S4M": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S4M`,
    },
  },
  //=============================不定位========================
  //=============================趣味========================
  "LOTEC5QWTS1FFS.TS1FFS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5QWTSHSCS.TSHSCS": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5QWTS3SBC.TS3SBC": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5QWTS4GFC.TS4GFC": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5QWTS5KDF.TS5KDF": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================趣味========================
  //=============================龙虎========================
  "LOTEC5LH.LOTECLH": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
    },
  },
  //=============================龙虎========================
  //=============================大小单双========================
  "LOTEC5DXDS.LOTECDXDS": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      hideOdds: true,
    },
  },
  //=============================大小单双========================
  //=============================斗牛========================
  "LOTEC5DN.DN": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC5DN.DN_GROUP": {
    component: Selector.rectangle,
    params: {
      hideQuickSelect: true,
      mutiple: true,
    },
  },
  //=============================斗牛========================
  //=============================特殊号========================
  "LOTEC5TSHTSHH3.TSHH3": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC5TSHTSHC3.TSHC3": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC5TSHTSHQ3.TSHQ3": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  //=============================特殊号========================
  //=============================任选========================
  "LOTEC5RXR2FX.RXR2FX": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5RXR2DX.RXR2DX": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5RXR3FX.RXR3FX": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5RXR3Z3.RXR3Z3": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5RXR3Z6.RXR3Z6": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC5RXR4FX.RXR4FX": {
    component: Selector.number,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================任选========================
};

export default LOTEC5SelectorEnum;
