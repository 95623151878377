import React from "react";

function Hamburger({ size = "68", color1 = "#F5F135", color2 = "#F47035" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 68 68"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="pi0g5ygnxa">
          <path d="M750 0v1624H0V0h750z" />
        </clipPath>
        <clipPath id="kw0sham97b">
          <path d="M34 0c18.778 0 34 15.222 34 34S52.778 68 34 68 0 52.778 0 34 15.222 0 34 0z" />
        </clipPath>
        <clipPath id="ohz5pwotxd">
          <path d="M40 0a4 4 0 1 1 0 8H4a4 4 0 1 1 0-8h36z" />
        </clipPath>
        <clipPath id="4hppdynfse">
          <path d="M27 0a4 4 0 1 1 0 8H4a4 4 0 1 1 0-8h23z" />
        </clipPath>
        <linearGradient
          id="pp37jpdh6c"
          x1="7.352"
          y1="8.469"
          x2="57.854"
          y2="59.007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color1} offset="0%" />
          <stop stop-color={color2} offset="100%" />
        </linearGradient>
      </defs>
      <g clip-path="url(#pi0g5ygnxa)" transform="translate(-658 -178)">
        <g clip-path="url(#kw0sham97b)" transform="translate(658 178)">
          <path fill="url(#pp37jpdh6c)" d="M0 0h68v68H0V0z" />
        </g>
        <g clip-path="url(#ohz5pwotxd)" transform="translate(671 196)">
          <path fill="#41444A" d="M0 0h44v8H0V0z" />
        </g>
        <g clip-path="url(#ohz5pwotxd)" transform="translate(671 209)">
          <path fill="#41444A" d="M0 0h44v8H0V0z" />
        </g>
        <g clip-path="url(#4hppdynfse)" transform="translate(684 222)">
          <path fill="#41444A" d="M0 0h31v8H0V0z" />
        </g>
      </g>
    </svg>
  );
}

export default Hamburger;
