import { combination, permutation } from "../../../functions/math";
import { BALL_COUNT_EACH_GROUP, SELECTED_COUNT_LIMIT } from "../../valudateFunctionEnum";

const ThreeStarsFunction = {
  // 特奖前三直选
  LOTVNNEC453STJQ3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 一奖前三直选
  LOTVNNEC453S1JQ3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 特六前三直选
  LOTVNNEC453ST6Q3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 4;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 特奖前三组选三
  LOTVNNEC453STJQ3DX3: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = permutation(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  // 特奖前三组选六
  LOTVNNEC453STJQ3DX6: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },
  // 特奖后三直选
  LOTVNNEC453STJH3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 一奖后三直选
  LOTVNNEC453S1JH3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 特六后三直选
  LOTVNNEC453ST6H3ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 4;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 特奖后三祖选三
  LOTVNNEC453STJH3DX3: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = permutation(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  // 特奖后三祖选六
  LOTVNNEC453STJH3DX6: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },
  // 六奖三星直选
  LOTVNNEC453S6J3SZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 3;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
};

export { ThreeStarsFunction };
