import Selector from "../../../components/Selector";

const LOTPK10SelectorEnum = {
  "LOTPK105GYJH.GYJH": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTPK105GYJH.GYJH_GROUP": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
    },
  },
  "LOTPK105QY.QY": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTPK105QE.QE": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      allowDuplicate: false,
    },
  },
  "LOTPK105QS.QS": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      allowDuplicate: false,
    },
  },
  // ========================定位胆========================

  "LOTPK105DWD0110.DWD0110": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTPK105DWD0105.DWD0105": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTPK105DWD0610.DWD0610": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // ========================五行========================
  "LOTPK105WXGJ.GJ": {
    component: Selector.rectangleType2,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTPK105WXYJ.YJ": {
    component: Selector.rectangleType2,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTPK105WXJJ.JJ": {
    component: Selector.rectangleType2,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  //=============================大小单双========================
  "LOTPK105DXDS0105.DXDS0105": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      // hideOdds: true,
    },
  },
  "LOTPK105DXDS0610.DXDS0610": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      // hideOdds: true,
    },
  },
  //=============================龙虎========================
  "LOTPK105LH.PK10LH": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
    },
  },
  //=============================牛牛========================
  "LOTPK105NNPB.NNPB": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTPK105NNFB.NNFB": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
};

export default LOTPK10SelectorEnum;
