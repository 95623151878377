import Selector from "../../../components/Selector";

const LOTFT5SelectorEnum = {
  "LOTFT5HZ.HZ": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTFT5HZ.HZ_GROUP": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
    },
  },
  "LOTFT5ETHETD.ETD": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT5ETHETF.ETF": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectParams: "name",
    },
  },
  "LOTFT5EBTHBZXH.BZXH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT5EBTHDTXH.DTXH": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      twoSameKeys: [`lottery.bet.title.DM`, `lottery.bet.title.TM`],
      twoSameLimit: [1, 999],
    },
  },
  "LOTFT5SLH.SLH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT5STHSTD.STD": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT5STHSTT.STT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT5SBTH.SBTH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
};

export default LOTFT5SelectorEnum;
