import { useQuery } from "@tanstack/react-query";
import { apiGetAllGameResult, apiGetGame } from "../service";
import queryKey from "../../../enumerations/queryKey";

export const useGetGameList = (options) => {
  return useQuery(
    [queryKey.LOTTERY_POPUP_GAME_LIST],
    () =>
      apiGetGame().then((res) => {
        const _result = res.data.data;
        let all_games = [];
        const categories = _result
          .map((item) => {
            all_games = [...all_games, ...item.game_list];
            return {
              ...item,
              title: item.name,
              key: item.game_category_detail_type,
            };
          })
          .flat(1);
        return [...categories];
      }),
    {
      ...options,
    }
  );
};

export const useGetAllGameResult = ({ params, options }) => {
  return useQuery(
    [queryKey.ALL_LATEST_GAME_RESULT],
    () =>
      apiGetAllGameResult(params).then((res) => {
        return res.data?.data;
      }),
    {
      ...options,
    }
  );
};
