import {
  threeStarDifferenceCount,
  threeStarSummaryCount,
} from "../../../functions/countCompute";
import { combination } from "../../../functions/math";
import {
  BALL_COUNT_EACH_GROUP,
  SELECTED_COUNT_LIMIT,
} from "../../valudateFunctionEnum";
import EveryColorKeyEnums from "./keyEnums";

const ThreeStarsFunction = {
  LOTEC13S3SH3ZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  LOTEC13S3SH3ZXHZ: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].HEZHI.key]) {
        count = threeStarSummaryCount(
          selected[EveryColorKeyEnums[currentGameWayDetail].HEZHI.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SH3ZXKD: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].KUADU.key]) {
        count = threeStarDifferenceCount(
          selected[EveryColorKeyEnums[currentGameWayDetail].KUADU.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SH3ZXHZWS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SH3DX3: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 2;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  LOTEC13S3SH3DX6: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },
  LOTEC13S3SC3ZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  LOTEC13S3SC3ZXHZ: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      // console.log("selected LOTEC13S3SC3ZXHZ", selected);
      if (selected["HZ"]) {
        count = threeStarSummaryCount(selected["HZ"]);
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SC3ZXKD: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      if (selected["KD"]) {
        count = threeStarDifferenceCount(selected["KD"]);
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SC3ZXHZWS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;

            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SC3DX3: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 2;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  LOTEC13S3SC3DX6: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },
  LOTEC13S3SQ3ZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  LOTEC13S3SQ3ZXHZ: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected["HZ"]) {
        count = threeStarSummaryCount(selected["HZ"]);
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SQ3ZXKD: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected["KD"]) {
        count = threeStarDifferenceCount(selected["KD"]);
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SQ3ZXHZWS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  LOTEC13S3SQ3DX3: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 2;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  LOTEC13S3SQ3DX6: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(3)({ selected });
    },
  },
};

export { ThreeStarsFunction };
