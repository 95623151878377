import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import { useLottery } from "../../..";
import { CircleItem } from "../Circle";
import { RectangleItem } from "../Rectangle";
const TwoSameSelector = ({
  items,
  mutiple,
  title,
  group,
  hideOdds,
  defaultValue = [],
  onChange,
  hideTitle,
  selectObjectKey,
  valueRepeat,
  max = 999,
  textNoI18n,
  twoSameKeys = [
    `lottery.bet.title.sameItems`,
    `lottery.bet.title.differentItems`,
  ],
  twoSameLimit = [999, 999],
}) => {
  const { t } = useTranslation();
  const { selected, setSelected } = useLottery();

  const codeSet = new Set();
  items?.forEach((i) => {
    codeSet.add(i?.code);
  });

  let currentLocalSelected = [];
  [...codeSet].forEach((code) => {
    if (selected[code]) {
      currentLocalSelected = currentLocalSelected.concat(selected[code]);
    }
  });

  const setNewSelected = (value, key = selectObjectKey) => {
    let newSelected = { ...selected };

    newSelected[key] = value;

    Object.keys(newSelected).forEach((key) => {
      if (newSelected[key].length === 0) {
        delete newSelected[key];
      }
    });

    setSelected(newSelected);
  };

  const activeClickHandler = (value, code) => {
    if (valueRepeat) {
      setNewSelected([...currentLocalSelected, value], code);
      return;
    }
    const currentCodeSelected = selected[code];
    console.log(currentCodeSelected, code, value, selected);
    const valueIndex = currentCodeSelected.indexOf(value);
    let newLocalSelected = [...currentCodeSelected];
    newLocalSelected.splice(valueIndex, 1);
    setNewSelected(newLocalSelected, code);
  };

  const sameItems = items[0]?.option[0]?.option;
  const sameKey = items[0]?.option[0]?.key;
  const differentItems = items[0]?.option[1]?.option;
  const differentKey = items[0]?.option[1]?.key;
  const code = items[0]?.code;

  return (
    <div className={styles.selector}>
      <div className={styles.gameMethodTitle}>{t(twoSameKeys[0])}</div>
      <div className={styles.container}>
        {sameItems ? (
          sameItems.map((i) => {
            const { id, key, name } = i;
            const processKey = `${sameKey},${key}`;
            const isActive = currentLocalSelected?.find(
              (s) => s === processKey
            );

            const clickHandler = () => {
              if (isActive) {
                activeClickHandler(processKey, code);
              } else {
                const currentCodeSelected = selected[code] ?? [];
                const differentItem = differentItems.find((i) => i.id === id);
                const differentItemIndex = currentCodeSelected.indexOf(
                  `${differentKey},${differentItem.key}`
                );
                if (differentItemIndex >= 0) {
                  currentCodeSelected.splice(differentItemIndex, 1);
                }
                setNewSelected([...currentCodeSelected, processKey], code);

                const sameSelected = currentLocalSelected.filter(
                  (s) => s.split(",")[0] === sameKey
                );

                if (sameSelected?.length >= twoSameLimit[0]) {
                  const firstSetCode = Object.keys(selected).find((key) => {
                    if (!codeSet.has(key)) {
                      return false;
                    }
                    if (selected[key]) {
                      return selected[key].length > 0;
                    }
                    return false;
                  });
                  const firstSelected = selected[firstSetCode];
                  const deleteId = firstSelected.findIndex(
                    (s) => s === sameSelected[0]
                  );
                  firstSelected.splice(deleteId, 1);

                  setNewSelected([...firstSelected, processKey], code);
                  return;
                }
              }
            };

            return (
              <CircleItem
                key={`${group}_${key}_${id}`}
                code={key}
                item={i}
                itemWidth={"25%"}
                isActive={isActive}
                textNoI18n={textNoI18n}
                onClick={clickHandler}
                hideTitle={hideTitle}
                hideOdds={hideOdds}
              ></CircleItem>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <div className={styles.gameMethodTitle}>{t(twoSameKeys[1])}</div>
      <div className={styles.container}>
        {differentItems ? (
          differentItems.map((i) => {
            const { id, key, name } = i;
            const processKey = `${differentKey},${key}`;
            const isActive = currentLocalSelected?.find(
              (s) => s === processKey
            );

            const clickHandler = () => {
              if (isActive) {
                activeClickHandler(processKey, code);
              } else {
                const currentCodeSelected = selected[code] ?? [];
                const sameItem = sameItems.find((i) => i.id === id);
                const sameItemIndex = currentCodeSelected.indexOf(
                  `${sameKey},${sameItem.key}`
                );
                if (sameItemIndex >= 0) {
                  currentCodeSelected.splice(sameItemIndex, 1);
                }
                setNewSelected([...currentCodeSelected, processKey], code);

                const differentSelected = currentLocalSelected.filter(
                  (s) => s.split(",")[0] === differentKey
                );

                if (differentSelected?.length >= twoSameLimit[1]) {
                  const firstSetCode = Object.keys(selected).find((key) => {
                    if (!codeSet.has(key)) {
                      return false;
                    }
                    if (selected[key]) {
                      return selected[key].length > 0;
                    }
                    return false;
                  });
                  const firstSelected = selected[firstSetCode];
                  const deleteId = firstSelected.findIndex(
                    (s) => s === differentItems[0]
                  );
                  firstSelected.splice(deleteId, 1);
                  setNewSelected([...firstSelected, processKey], code);
                  return;
                }
              }
            };

            return (
              <CircleItem
                key={`${group}_${key}_${id}`}
                code={key}
                item={i}
                itemWidth={"25%"}
                isActive={isActive}
                textNoI18n={textNoI18n}
                onClick={clickHandler}
                hideTitle={hideTitle}
                hideOdds={hideOdds}
              ></CircleItem>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TwoSameSelector;
