import { Image } from "antd-mobile";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import newIcon from "../../images/icons/new.png";
import styles from "./style.module.scss";

const GameItem = ({
  code = "",
  image = "",
  isNew = false,
  title = "",
  // endingTime,
  onGameItemClick = () => {},
}) => {
  const [now, setNow] = useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  const { t } = useTranslation();

  // const ending = dayjs(endingTime).format("YYYY-MM-DD HH:mm:ss");
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(dayjs().format("YYYY-MM-DD HH:mm:ss"));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div onClick={() => onGameItemClick(code)} className={styles.gameItemContainer}>
      <Image src={image} height={80} fit={"contain"} />
      <div className={styles.gameTitle}>{title}</div>
      {/* <Countdown
        current_date_time={now}
        end={ending}
        disableTimeZoneTrans={true}
        disableShowModal={true}
      /> */}
      {isNew ? <Image className={styles.newLabel} src={newIcon} width={35} /> : null}
    </div>
  );
};

export default GameItem;
