import React from "react";
import { useTranslation } from "react-i18next";
import DefaultSelector from "../DefaultSelector";
import styles from "./style.module.scss";

export const RectangleItem = ({
  isActive,
  onClick,
  code,
  textNoI18n,
  item,
  itemWidth,
  group_key = "",
  hideOdds = false,
}) => {
  const { t } = useTranslation();
  const specialClasses = {
    red_wave: "red",
    green_wave: "green",
    blue_wave: "blue",
  };
  return (
    <div className={`${styles.itemContainer}`} style={{ width: itemWidth }}>
      <div
        key={code}
        className={`${styles.item} ${isActive ? styles.active : ""} ${
          specialClasses[code] ? styles[specialClasses[code]] : ""
        }`}
        onClick={() => onClick(group_key, item)}
      >
        <div style={{ wordWrap: "break-word", width: "100%" }}>
          {/* <div> */}
          {textNoI18n ? code : t(`lottery.item.${code}`)}
        </div>
      </div>
      <div className={`${styles.oddsText}`}>
        {hideOdds ? "" : t("normal.odds", { odds: item.odds })}
      </div>
    </div>
  );
};
const Rectangle = (props) => {
  return <DefaultSelector {...props} selectorRender={RectangleItem} />;
};

export default Rectangle;
