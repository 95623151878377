const LOTVNNEC3RuleEnum = {
  LOTVNNEC3BX: {
    hint: {
      LOTVNNEC3BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.hint",
      LOTVNNEC3BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.hint",
      LOTVNNEC3BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.hint",
      LOTVNNEC3BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.hint",
      LOTVNNEC3BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.hint",
      LOTVNNEC3BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.hint",
      LOTVNNEC3BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.hint",
      LOTVNNEC3BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.hint",
      LOTVNNEC3BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.hint",
      LOTVNNEC3BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.hint",
    },
    description: {
      LOTVNNEC3BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.description",
      LOTVNNEC3BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.description",
      LOTVNNEC3BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.description",
      LOTVNNEC3BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.description",
      LOTVNNEC3BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.description",
      LOTVNNEC3BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.description",
      LOTVNNEC3BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.description",
      LOTVNNEC3BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.description",
      LOTVNNEC3BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.description",
      LOTVNNEC3BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.description",
    },
    example: {
      LOTVNNEC3BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.example",
      LOTVNNEC3BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.example",
      LOTVNNEC3BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.example",
      LOTVNNEC3BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.example",
      LOTVNNEC3BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.example",
      LOTVNNEC3BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.example",
      LOTVNNEC3BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.example",
      LOTVNNEC3BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.example",
      LOTVNNEC3BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.example",
      LOTVNNEC3BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.example",
    },
  },
  LOTVNNEC3CG: {
    hint: {
      LOTVNNEC3CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.hint",
      LOTVNNEC3CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.hint",
      LOTVNNEC3CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.hint",
    },
    description: {
      LOTVNNEC3CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.description",
      LOTVNNEC3CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.description",
      LOTVNNEC3CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.description",
    },
    example: {
      LOTVNNEC3CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.example",
      LOTVNNEC3CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.example",
      LOTVNNEC3CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.example",
    },
  },
  LOTVNNEC32S: {
    hint: {
      LOTVNNEC32STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.hint",
      LOTVNNEC32S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.hint",
      LOTVNNEC32ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.hint",
      LOTVNNEC32STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.hint",
      LOTVNNEC32S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.hint",
      LOTVNNEC32ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.hint",
      LOTVNNEC32S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.hint",
    },
    description: {
      LOTVNNEC32STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.description",
      LOTVNNEC32S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.description",
      LOTVNNEC32ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.description",
      LOTVNNEC32STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.description",
      LOTVNNEC32S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.description",
      LOTVNNEC32ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.description",
      LOTVNNEC32S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.description",
    },
    example: {
      LOTVNNEC32STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.example",
      LOTVNNEC32S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.example",
      LOTVNNEC32ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.example",
      LOTVNNEC32STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.example",
      LOTVNNEC32S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.example",
      LOTVNNEC32ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.example",
      LOTVNNEC32S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.example",
    },
  },
  LOTVNNEC33S: {
    hint: {
      LOTVNNEC33STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.hint",
      LOTVNNEC33S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.hint",
      LOTVNNEC33ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.hint",
      LOTVNNEC33STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.hint",
      LOTVNNEC33STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.hint",
      LOTVNNEC33STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.hint",
      LOTVNNEC33S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.hint",
      LOTVNNEC33ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.hint",
      LOTVNNEC33STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.hint",
      LOTVNNEC33STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.hint",
      LOTVNNEC33S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.hint",
    },
    description: {
      LOTVNNEC33STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.description",
      LOTVNNEC33S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.description",
      LOTVNNEC33ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.description",
      LOTVNNEC33STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.description",
      LOTVNNEC33STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.description",
      LOTVNNEC33STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.description",
      LOTVNNEC33S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.description",
      LOTVNNEC33ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.description",
      LOTVNNEC33STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.description",
      LOTVNNEC33STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.description",
      LOTVNNEC33S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.description",
    },
    example: {
      LOTVNNEC33STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.example",
      LOTVNNEC33S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.example",
      LOTVNNEC33ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.example",
      LOTVNNEC33STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.example",
      LOTVNNEC33STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.example",
      LOTVNNEC33STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.example",
      LOTVNNEC33S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.example",
      LOTVNNEC33ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.example",
      LOTVNNEC33STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.example",
      LOTVNNEC33STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.example",
      LOTVNNEC33S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.example",
    },
  },
  LOTVNNEC34S: {
    hint: {
      LOTVNNEC34STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.hint",
      LOTVNNEC34S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.hint",
      LOTVNNEC34STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.hint",
      LOTVNNEC34S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.hint",
    },
    description: {
      LOTVNNEC34STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.description",
      LOTVNNEC34S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.description",
      LOTVNNEC34STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.description",
      LOTVNNEC34S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.description",
    },
    example: {
      LOTVNNEC34STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.example",
      LOTVNNEC34S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.example",
      LOTVNNEC34STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.example",
      LOTVNNEC34S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.example",
    },
  },
  LOTVNNEC3ZXBZ: {
    hint: {
      LOTVNNEC3ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.hint",
      LOTVNNEC3ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.hint",
      LOTVNNEC3ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.hint",
      LOTVNNEC3ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.hint",
    },
    description: {
      LOTVNNEC3ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.description",
      LOTVNNEC3ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.description",
      LOTVNNEC3ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.description",
      LOTVNNEC3ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.description",
    },
    example: {
      LOTVNNEC3ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.example",
      LOTVNNEC3ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.example",
      LOTVNNEC3ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.example",
      LOTVNNEC3ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.example",
    },
  },
  LOTVNNEC3DXDS: {
    hint: "lottery.rules.modal.LOTVNNEC5DXDS.hint",
    description: "lottery.rules.modal.LOTVNNEC5DXDS.description",
    example: "lottery.rules.modal.LOTVNNEC5DXDS.example",
  },
};

export default LOTVNNEC3RuleEnum;
