import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DISABLE_BET_BUTTON_TIMER } from "../../config";
import { useWebsocket } from "../../context/WebsocketContext";
import webSocketActionEnum from "../../enumerations/webSocketActionEnum";
import gameStateEnum from "../../pages/Lottery/components/GameResultViewer/enumerations/gameStateEnum";
import { closeTime } from "../../pages/Lottery/enumeration/closeTimeEnum";
import styles from "./style.module.scss";

const HOUR_SECOND = 60 * 60;
const MINUTE_SECOND = 60;

dayjs.extend(utc);
dayjs.extend(timezone);

const Countdown = ({
  lotteryType,
  punctuationStyle = {},
  disableTimeZoneTrans,
  disableShowModal,
  setCurrentGameState = () => {},
  currentGameState,
  latestNumber,
  refreshData = () => {},
  refreshGameResultData = () => {},
  lotteryResultVisible = false,
  latestNumberRefetching = false,
}) => {
  const {
    isWaitPeriod,
    setIsWaitPeriod,
    setDisableBet,
    disableBet,
    prevPeriod,
    currentPeriod,
    setCounter,
    counter,
  } = useWebsocket();

  const apiTimerRef = useRef();
  const [localCurrentTime, setLocalCurrentTime] = useState();
  const [localCounter, setLocalCounter] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const gameCloseTime = useRef(closeTime[lotteryType] || DISABLE_BET_BUTTON_TIMER);

  const countdown = useCallback(() => {
    if (currentPeriod.end && currentPeriod.current_date_time) {
      const timeStringProcess = (timeString) => {
        const regex = /\-/g;
        return timeString.replace(regex, "/");
      };
      const difference = dayjs(
        `${timeStringProcess(currentPeriod.end)}${disableTimeZoneTrans ? "" : " +00:00"}`,
      ).diff(dayjs(timeStringProcess(currentPeriod.current_date_time)), "s");
      if (difference <= 1) {
        // if (!disableShowModal && focusState === true && difference !== 0) {
        //   setShowModal(true);
        // }
        if (prevPeriod.action === webSocketActionEnum.PENDING) {
          if (currentGameState.key !== gameStateEnum.RESULT_PENDING_PHASE.key)
            setCurrentGameState(gameStateEnum.RESULT_PENDING_PHASE);
        } else if (prevPeriod.action === webSocketActionEnum.END) {
          if (currentGameState.key !== gameStateEnum.ANIMATION_PHASE.key)
            setCurrentGameState(gameStateEnum.ANIMATION_PHASE);
        }
      }
      if (DISABLE_BET_BUTTON_TIMER > 0) {
        if (difference <= gameCloseTime.current) {
          if (disableBet === false) setDisableBet(true);
        } else {
          if (disableBet === true) setDisableBet(false);
        }
      }
      setCounter(difference);
      return difference;
    }
    return 0;
  }, [
    currentPeriod.end,
    currentPeriod.current_date_time,
    prevPeriod,
    disableBet,
    // focusState,
    currentGameState,
  ]);

  const handleChangeGameState = (diff, latestNumber) => {
    if (latestNumber[0].period === null) {
      setIsWaitPeriod(true);
    } else {
      setIsWaitPeriod(false);
    }

    if (latestNumber[1].action === webSocketActionEnum.PENDING) {
      if (currentGameState !== gameStateEnum.RESULT_PENDING_PHASE) {
        setCurrentGameState(gameStateEnum.RESULT_PENDING_PHASE);
      }
    }

    if (DISABLE_BET_BUTTON_TIMER > 0) {
      if (diff <= gameCloseTime.current) {
        if (disableBet === false) setDisableBet(true);
      } else {
        if (disableBet === true) setDisableBet(false);
      }
    }
  };

  const apiCountDown = useCallback(() => {
    if (latestNumber && localCurrentTime) {
      const activePeriod = latestNumber[0];
      const difference = dayjs(`${activePeriod.end} +00:00`.replace(/-/g, "/")).diff(
        dayjs(`${localCurrentTime} +00:00`.replace(/-/g, "/")),
        // dayjs().utc(),
        "seconds",
      );
      setCounter(difference);
      handleChangeGameState(difference, latestNumber);
      return difference;
    }
    return 0;
  }, [latestNumber, localCurrentTime]);

  const handleSetLocalTimer = (diff) => {
    const hours = String(parseInt(diff / HOUR_SECOND + 100)).slice(-2);
    const minutes = String(parseInt(((diff / MINUTE_SECOND) % MINUTE_SECOND) + 100)).slice(-2);
    const seconds = String(parseInt((diff % MINUTE_SECOND) + 100)).slice(-2);
    setLocalCounter({ hours, minutes, seconds });
  };

  // useEffect(() => {
  //   let modalTimeout;
  //   if (showModal === true) {
  //     modalTimeout = setTimeout(() => {
  //       setShowModal(false);
  //     }, [5000]);
  //   }
  //   return () => {
  //     clearTimeout(modalTimeout);
  //   };
  // }, [showModal]);

  useEffect(() => {
    return () => {
      setIsWaitPeriod(false);
    };
  }, []);

  useEffect(() => {
    switch (currentGameState.key) {
      case gameStateEnum.ANIMATION_PHASE.key:
        setTimeout(() => {
          setCurrentGameState(gameStateEnum.RESULT_PHASE);
        }, [3000]);
        break;
      case gameStateEnum.RESULT_PHASE.key:
        setTimeout(() => {
          setCurrentGameState(gameStateEnum.BET_PHASE);
        }, [2000]);

        break;

      default:
        break;
    }
  }, [currentGameState]);

  useEffect(() => {
    if (latestNumber) {
      setLocalCurrentTime(latestNumber[0].current_time);
    }
  }, [latestNumber]);

  useEffect(() => {
    let _timer;
    if (currentPeriod && currentPeriod.end) {
      clearInterval(apiTimerRef.current);
      _timer = countdown();
      handleSetLocalTimer(_timer);
    } else {
      let _diff = apiCountDown();
      let waitTime = 1;
      apiTimerRef.current = setInterval(() => {
        _diff = Math.max(_diff - 1, 0);
        console.debug("_diff", _diff);
        handleSetLocalTimer(_diff);
        if (DISABLE_BET_BUTTON_TIMER > 0) {
          if (_diff <= gameCloseTime.current) {
            if (disableBet === false) setDisableBet(true);
          } else {
            if (disableBet === true) setDisableBet(false);
          }
        }
        setCounter(_diff);
        if (latestNumberRefetching === false) {
          if (_diff <= 0) {
            // if (currentGameState.key !== gameStateEnum.RESULT_PENDING_PHASE.key) {
            //   setCurrentGameState(gameStateEnum.RESULT_PENDING_PHASE);
            // }
            if (waitTime <= 0) {
              refreshData();
              waitTime = 2;
            }
            waitTime--;
          } else {
            if (latestNumber[1].lottery_number === null) {
              if (waitTime <= 0) {
                refreshData();
                waitTime = 2;
              }
              waitTime--;
            }
            // else {
            //   if (currentGameState.key === gameStateEnum.RESULT_PENDING_PHASE.key) {
            //     setCurrentGameState(gameStateEnum.ANIMATION_PHASE);
            //     if (lotteryResultVisible) {
            //       refreshGameResultData();
            //     }
            //   }
            // }
          }
        }
      }, [1000]);
    }

    return () => {
      clearInterval(apiTimerRef.current);
    };
  }, [currentPeriod, latestNumber, latestNumberRefetching, localCurrentTime]);

  useEffect(() => {
    return () => {
      clearTimeout(apiTimerRef.current);
    };
  }, []);

  useEffect(() => {
    if (latestNumber) {
      if (counter > 0 && latestNumber[1].lottery_number !== null) {
        if (currentGameState === gameStateEnum.RESULT_PENDING_PHASE) {
          setCurrentGameState(gameStateEnum.ANIMATION_PHASE);
        }
      }
    }
  }, [counter, latestNumber, currentGameState]);

  const render = useMemo(() => {
    return (
      <div className={styles.countdown}>
        <div className={styles.countdownNumbers}>
          <div className={styles.countdownBox}>
            {isWaitPeriod === true ? " " : localCounter.hours}
          </div>
          <div style={punctuationStyle} className={styles.punctuation}>
            :
          </div>
          <div className={styles.countdownBox}>
            {isWaitPeriod === true ? " " : localCounter.minutes}
          </div>
          <div style={punctuationStyle} className={styles.punctuation}>
            :
          </div>
          <div className={styles.countdownBox}>
            {isWaitPeriod === true ? " " : localCounter.seconds}
          </div>
        </div>

        {/* <CustomPopup
          title={t("popup.periodEnd.title")}
          rightButtonText={t("popup.periodEnd.button")}
          visible={showModal}
          singleButton={true}
          onClose={() => {
            setShowModal(false);
          }}
          onSecondaryButtonPress={() => {}}
          onMainButtonPress={() => {
            setShowModal(false);
          }}
        >
          <div>{t("popup.periodEnd.content")}</div>
        </CustomPopup> */}
      </div>
    );
  }, [localCounter]);
  return render;
};

export default Countdown;
