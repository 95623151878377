import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import { useLottery } from "../../..";
const DefaultSelector = ({
  items,
  mutiple,
  title,
  customTitle,
  group,
  defaultValue = [],
  onChange,
  hideTitle,
  selectObjectKey,
  valueRepeat,
  max = 999,
  textNoI18n,
  selectorRender,
  hideOdds,
  selectParams,
  selectAll,
  isColorfulBalls = false,
  itemWidth = "25%",
  hideMethodTitle = false,
}) => {
  const { t } = useTranslation();
  const { selected, setSelected } = useLottery();
  const localMaxLimit = mutiple ? max : 1;
  const codeSet = new Set();
  items?.forEach((i) => {
    codeSet.add(i?.code);
  });

  let currentLocalSelected = [];
  [...codeSet].forEach((code) => {
    if (selected[code]) {
      currentLocalSelected = currentLocalSelected.concat(selected[code]);
    }
  });

  const setNewSelected = (value, key = selectObjectKey) => {
    let newSelected = { ...selected };

    newSelected[key] = value;

    Object.keys(newSelected).forEach((key) => {
      if (newSelected[key].length === 0) {
        delete newSelected[key];
      }
    });

    setSelected(newSelected);
  };

  const activeClickHandler = (value, code) => {
    if (valueRepeat) {
      setNewSelected([...currentLocalSelected, value], code);
      return;
    }
    const currentCodeSelected = selected[code];
    console.log(currentCodeSelected, code, value, selected);
    const valueIndex = currentCodeSelected.indexOf(value);
    let newLocalSelected = [...currentCodeSelected];
    newLocalSelected.splice(valueIndex, 1);
    setNewSelected(newLocalSelected, code);
  };

  return (
    <div className={styles.selector}>
      {hideMethodTitle ? (
        ""
      ) : (
        <div>
          <div className={styles.gameMethodTitle}>
            {customTitle ? t(customTitle) : t(`lottery.bet.title.${title}`)}
          </div>
        </div>
      )}
      <div className={styles.container}>
        {items.map((i) => {
          const { id, key, name, code } = i;
          const isActive = currentLocalSelected?.find((s) => s === key);

          const Render = selectorRender;
          const clickHandler = () => {
            if (isActive) {
              if (selectAll) {
                setNewSelected([], code);
                return;
              }

              activeClickHandler(key, code);
            } else {
              if (selectAll) {
                setNewSelected(
                  items.map((_i) => _i.key),
                  code
                );
                return;
              }

              if (currentLocalSelected?.length >= localMaxLimit) {
                const firstSetCode = Object.keys(selected).find((key) => {
                  if (!codeSet.has(key)) {
                    return false;
                  }
                  if (selected[key]) {
                    return selected[key].length > 0;
                  }
                  return false;
                });
                const firstSelected = selected[firstSetCode];
                firstSelected.shift();
                setNewSelected([...firstSelected, key], code);
                return;
              }
              const currentCodeSelected = selected[code] ?? [];
              setNewSelected([...currentCodeSelected, key], code);
            }
          };

          if (Render) {
            return (
              <Render
                key={`${group}_${key}_${id}`}
                code={selectParams ? i[selectParams] : key}
                item={i}
                hideOdds={hideOdds}
                isActive={isActive}
                textNoI18n={textNoI18n}
                onClick={clickHandler}
                hideTitle={hideTitle}
                isColorfulBalls={isColorfulBalls}
                itemWidth={itemWidth}
              ></Render>
            );
          }

          return (
            <div
              key={key}
              className={`${styles.item} ${isActive ? styles.active : ""}`}
              onClick={clickHandler}
            >
              {textNoI18n ? name : t(`lottery.item.${key}`)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DefaultSelector;
