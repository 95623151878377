import { Image } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";

function Fast3({ data }) {
  const { t } = useTranslation();
  const numbers = data.lottery_number.split(",");
  const getTextColor = (option) => {
    const colorDict = {
      big: "var(--color-result-red)",
      small: "var(--color-result-blue)",
      odd: "var(--color-result-red)",
      even: "var(--color-result-blue)",
    };
    return { color: colorDict[option] ?? "" };
  };
  return (
    <div className={`${styles.fast3Result}`}>
      <div className={`${styles.row}`}>
        {numbers.map((number, index) => {
          if (!parseInt(number)) return null;
          return (
            <div className={`${styles.rowItem}`} key={"dice" + index}>
              <Image
                src={`/images/game/result/dice/dice${number}.png`}
                width={20}
                height={20}
                fit="cover"
              />
            </div>
          );
        })}
        {numbers.map((number, index) => {
          return (
            <div key={"number" + index} className={`${styles.rowItem}`}>
              {number}
            </div>
          );
        })}
      </div>
      <div className={`${styles.row}`}>
        <div className={`${styles.rowItem}`}>{data?.data?.sum_number || ""}</div>
        <div
          className={`${styles.rowItem} ${styles.bigSmall}`}
          style={getTextColor(data?.data?.big_small)}
        >
          {data?.data?.big_small ? t(`gameResult.${data?.data?.big_small}`) : ""}
        </div>
        <div
          className={`${styles.rowItem} ${styles.oddEven}`}
          style={getTextColor(data?.data?.odd_even)}
        >
          {data?.data?.odd_even ? t(`gameResult.${data?.data?.odd_even}`) : ""}
        </div>
      </div>
    </div>
  );
}

export default Fast3;
