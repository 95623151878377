//LOTMS
import LOTMS10SelectorEnum from "./selectorEnums/LOTMS/LOTMS10SelectorEnum";
import LOTMS1SelectorEnum from "./selectorEnums/LOTMS/LOTMS1SelectorEnum";
import LOTMS3SelectorEnum from "./selectorEnums/LOTMS/LOTMS3SelectorEnum";
import LOTMS5SelectorEnum from "./selectorEnums/LOTMS/LOTMS5SelectorEnum";
//LOTFT
import LOTFT10SelectorEnum from "./selectorEnums/LOTFT/LOTFT10SelectorEnum";
import LOTFT1SelectorEnum from "./selectorEnums/LOTFT/LOTFT1SelectorEnum";
import LOTFT3SelectorEnum from "./selectorEnums/LOTFT/LOTFT3SelectorEnum";
import LOTFT5SelectorEnum from "./selectorEnums/LOTFT/LOTFT5SelectorEnum";
//LOTEC
import LOTEC10SelectorEnum from "./selectorEnums/LOTEC/LOTEC10SelectorEnum";
import LOTEC1SelectorEnum from "./selectorEnums/LOTEC/LOTEC1SelectorEnum";
import LOTEC3SelectorEnum from "./selectorEnums/LOTEC/LOTEC3SelectorEnum";
import LOTEC5SelectorEnum from "./selectorEnums/LOTEC/LOTEC5SelectorEnum";
//LOTPK10
import LOTPK1010SelectorEnum from "./selectorEnums/LOTPK10/LOTPK1010SelectorEnum";
import LOTPK101SelectorEnum from "./selectorEnums/LOTPK10/LOTPK101SelectorEnum";
import LOTPK103SelectorEnum from "./selectorEnums/LOTPK10/LOTPK103SelectorEnum";
import LOTPK105SelectorEnum from "./selectorEnums/LOTPK10/LOTPK105SelectorEnum";
//OFLOTMS
import OFLOTMSHKSelectorEnum from "./selectorEnums/OFLOTMS/OFLOTMSHKSelectorEnum";
import OFLOTMSMOSelectorEnum from "./selectorEnums/OFLOTMS/OFLOTMSMOSelectorEnum";
//OFLOTPK10
import OFLOTPK10LASelectorEnum from "./selectorEnums/OFLOTPK10/OFLOTPK10LASelectorEnum";

//LOTVNNEC
import LOTVNNEC10SelectorEnum from "./selectorEnums/LOTVNNEC/LOTVNNEC10SelectorEnum";
import LOTVNNEC120SelectorEnum from "./selectorEnums/LOTVNNEC/LOTVNNEC120SelectorEnum";
import LOTVNNEC1SelectorEnum from "./selectorEnums/LOTVNNEC/LOTVNNEC1SelectorEnum";
import LOTVNNEC3SelectorEnum from "./selectorEnums/LOTVNNEC/LOTVNNEC3SelectorEnum";
import LOTVNNEC45SelectorEnum from "./selectorEnums/LOTVNNEC/LOTVNNEC45SelectorEnum";
import LOTVNNEC5SelectorEnum from "./selectorEnums/LOTVNNEC/LOTVNNEC5SelectorEnum";
import LOTVNNEC75SelectorEnum from "./selectorEnums/LOTVNNEC/LOTVNNEC75SelectorEnum";
const selectorEnum = {
  ...LOTMS1SelectorEnum,
  ...LOTMS3SelectorEnum,
  ...LOTMS5SelectorEnum,
  ...LOTMS10SelectorEnum,
  ...LOTFT1SelectorEnum,
  ...LOTFT3SelectorEnum,
  ...LOTFT5SelectorEnum,
  ...LOTFT10SelectorEnum,
  ...LOTEC1SelectorEnum,
  ...LOTEC3SelectorEnum,
  ...LOTEC5SelectorEnum,
  ...LOTEC10SelectorEnum,
  ...LOTPK101SelectorEnum,
  ...LOTPK103SelectorEnum,
  ...LOTPK1010SelectorEnum,
  ...LOTPK105SelectorEnum,
  ...OFLOTMSHKSelectorEnum,
  ...OFLOTMSMOSelectorEnum,
  ...OFLOTPK10LASelectorEnum,
  ...LOTVNNEC10SelectorEnum,
  ...LOTVNNEC5SelectorEnum,
  ...LOTVNNEC3SelectorEnum,
  ...LOTVNNEC1SelectorEnum,
  ...LOTVNNEC120SelectorEnum,
  ...LOTVNNEC45SelectorEnum,
  ...LOTVNNEC75SelectorEnum,
};

export default selectorEnum;
