import Selector from "../../../components/Selector";

const LOTFT1SelectorEnum = {
  "LOTFT1HZ.HZ": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTFT1HZ.HZ_GROUP": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
    },
  },
  "LOTFT1ETHETD.ETD": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT1ETHETF.ETF": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectParams: "name",
    },
  },
  "LOTFT1EBTHBZXH.BZXH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT1EBTHDTXH.DTXH": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      twoSameKeys: [`lottery.bet.title.DM`, `lottery.bet.title.TM`],
      twoSameLimit: [1, 999],
    },
  },
  "LOTFT1SLH.SLH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT1STHSTD.STD": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT1STHSTT.STT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT1SBTH.SBTH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
};

export default LOTFT1SelectorEnum;
