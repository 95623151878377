const LOTEC5RuleEnum = {
  LOTEC55S: {
    hint: {
      LOTEC55S5SZXFS: "lottery.rules.modal.LOTEC55S5SZXFS.hint",
      LOTEC55S5SZXZH: "lottery.rules.modal.LOTEC55S5SZXZH.hint",
      LOTEC55S5SDX120: "lottery.rules.modal.LOTEC55S5SDX120.hint",
      LOTEC55S5SDX60: "lottery.rules.modal.LOTEC55S5SDX60.hint",
      LOTEC55S5SDX30: "lottery.rules.modal.LOTEC55S5SDX30.hint",
      LOTEC55S5SDX20: "lottery.rules.modal.LOTEC55S5SDX20.hint",
      LOTEC55S5SDX10: "lottery.rules.modal.LOTEC55S5SDX10.hint",
      LOTEC55S5SDX5: "lottery.rules.modal.LOTEC55S5SDX5.hint",
    },
    description: {
      LOTEC55S5SZXFS: "lottery.rules.modal.LOTEC55S5SZXFS.description",
      LOTEC55S5SZXZH: "lottery.rules.modal.LOTEC55S5SZXZH.description",
      LOTEC55S5SDX120: "lottery.rules.modal.LOTEC55S5SDX120.description",
      LOTEC55S5SDX60: "lottery.rules.modal.LOTEC55S5SDX60.description",
      LOTEC55S5SDX30: "lottery.rules.modal.LOTEC55S5SDX30.description",
      LOTEC55S5SDX20: "lottery.rules.modal.LOTEC55S5SDX20.description",
      LOTEC55S5SDX10: "lottery.rules.modal.LOTEC55S5SDX10.description",
      LOTEC55S5SDX5: "lottery.rules.modal.LOTEC55S5SDX5.description",
    },
    example: {
      LOTEC55S5SZXFS: "lottery.rules.modal.LOTEC55S5SZXFS.example",
      LOTEC55S5SZXZH: "lottery.rules.modal.LOTEC55S5SZXZH.example",
      LOTEC55S5SDX120: "lottery.rules.modal.LOTEC55S5SDX120.example",
      LOTEC55S5SDX60: "lottery.rules.modal.LOTEC55S5SDX60.example",
      LOTEC55S5SDX30: "lottery.rules.modal.LOTEC55S5SDX30.example",
      LOTEC55S5SDX20: "lottery.rules.modal.LOTEC55S5SDX20.example",
      LOTEC55S5SDX10: "lottery.rules.modal.LOTEC55S5SDX10.example",
      LOTEC55S5SDX5: "lottery.rules.modal.LOTEC55S5SDX5.example",
    },
  },
  LOTEC54S: {
    hint: {
      LOTEC54S4SH4ZXFS: "lottery.rules.modal.LOTEC54S4SH4ZXFS.hint",
      LOTEC54S4SH4ZXZH: "lottery.rules.modal.LOTEC54S4SH4ZXZH.hint",
      LOTEC54S4SH4DX24: "lottery.rules.modal.LOTEC54S4SH4DX24.hint",
      LOTEC54S4SH4DX12: "lottery.rules.modal.LOTEC54S4SH4DX12.hint",
      LOTEC54S4SH4DX6: "lottery.rules.modal.LOTEC54S4SH4DX6.hint",
      LOTEC54S4SH4DX4: "lottery.rules.modal.LOTEC54S4SH4DX4.hint",
      LOTEC54S4SQ4ZXFS: "lottery.rules.modal.LOTEC54S4SQ4ZXFS.hint",
      LOTEC54S4SQ4ZXZH: "lottery.rules.modal.LOTEC54S4SQ4ZXZH.hint",
      LOTEC54S4SQ4DX24: "lottery.rules.modal.LOTEC54S4SQ4DX24.hint",
      LOTEC54S4SQ4DX12: "lottery.rules.modal.LOTEC54S4SQ4DX12.hint",
      LOTEC54S4SQ4DX6: "lottery.rules.modal.LOTEC54S4SQ4DX6.hint",
      LOTEC54S4SQ4DX4: "lottery.rules.modal.LOTEC54S4SQ4DX4.hint",
    },
    description: {
      LOTEC54S4SH4ZXFS: "lottery.rules.modal.LOTEC54S4SH4ZXFS.description",
      LOTEC54S4SH4ZXZH: "lottery.rules.modal.LOTEC54S4SH4ZXZH.description",
      LOTEC54S4SH4DX24: "lottery.rules.modal.LOTEC54S4SH4DX24.description",
      LOTEC54S4SH4DX12: "lottery.rules.modal.LOTEC54S4SH4DX12.description",
      LOTEC54S4SH4DX6: "lottery.rules.modal.LOTEC54S4SH4DX6.description",
      LOTEC54S4SH4DX4: "lottery.rules.modal.LOTEC54S4SH4DX4.description",
      LOTEC54S4SQ4ZXFS: "lottery.rules.modal.LOTEC54S4SQ4ZXFS.description",
      LOTEC54S4SQ4ZXZH: "lottery.rules.modal.LOTEC54S4SQ4ZXZH.description",
      LOTEC54S4SQ4DX24: "lottery.rules.modal.LOTEC54S4SQ4DX24.description",
      LOTEC54S4SQ4DX12: "lottery.rules.modal.LOTEC54S4SQ4DX12.description",
      LOTEC54S4SQ4DX6: "lottery.rules.modal.LOTEC54S4SQ4DX6.description",
      LOTEC54S4SQ4DX4: "lottery.rules.modal.LOTEC54S4SQ4DX4.description",
    },
    example: {
      LOTEC54S4SH4ZXFS: "lottery.rules.modal.LOTEC54S4SH4ZXFS.example",
      LOTEC54S4SH4ZXZH: "lottery.rules.modal.LOTEC54S4SH4ZXZH.example",
      LOTEC54S4SH4DX24: "lottery.rules.modal.LOTEC54S4SH4DX24.example",
      LOTEC54S4SH4DX12: "lottery.rules.modal.LOTEC54S4SH4DX12.example",
      LOTEC54S4SH4DX6: "lottery.rules.modal.LOTEC54S4SH4DX6.example",
      LOTEC54S4SH4DX4: "lottery.rules.modal.LOTEC54S4SH4DX4.example",
      LOTEC54S4SQ4ZXFS: "lottery.rules.modal.LOTEC54S4SQ4ZXFS.example",
      LOTEC54S4SQ4ZXZH: "lottery.rules.modal.LOTEC54S4SQ4ZXZH.example",
      LOTEC54S4SQ4DX24: "lottery.rules.modal.LOTEC54S4SQ4DX24.example",
      LOTEC54S4SQ4DX12: "lottery.rules.modal.LOTEC54S4SQ4DX12.example",
      LOTEC54S4SQ4DX6: "lottery.rules.modal.LOTEC54S4SQ4DX6.example",
      LOTEC54S4SQ4DX4: "lottery.rules.modal.LOTEC54S4SQ4DX4.example",
    },
  },
  LOTEC53S: {
    hint: {
      LOTEC53S3SH3ZXFS: "lottery.rules.modal.LOTEC53S3SH3ZXFS.hint",
      LOTEC53S3SH3ZXHZ: "lottery.rules.modal.LOTEC53S3SH3ZXHZ.hint",
      LOTEC53S3SH3ZXKD: "lottery.rules.modal.LOTEC53S3SH3ZXKD.hint",
      LOTEC53S3SH3ZXHZWS: "lottery.rules.modal.LOTEC53S3SH3ZXHZWS.hint",
      LOTEC53S3SH3DX3: "lottery.rules.modal.LOTEC53S3SH3DX3.hint",
      LOTEC53S3SH3DX6: "lottery.rules.modal.LOTEC53S3SH3DX6.hint",
      LOTEC53S3SC3ZXFS: "lottery.rules.modal.LOTEC53S3SC3ZXFS.hint",
      LOTEC53S3SC3ZXHZ: "lottery.rules.modal.LOTEC53S3SC3ZXHZ.hint",
      LOTEC53S3SC3ZXKD: "lottery.rules.modal.LOTEC53S3SC3ZXKD.hint",
      LOTEC53S3SC3ZXHZWS: "lottery.rules.modal.LOTEC53S3SC3ZXHZWS.hint",
      LOTEC53S3SC3DX3: "lottery.rules.modal.LOTEC53S3SC3DX3.hint",
      LOTEC53S3SC3DX6: "lottery.rules.modal.LOTEC53S3SC3DX6.hint",
      LOTEC53S3SQ3ZXFS: "lottery.rules.modal.LOTEC53S3SQ3ZXFS.hint",
      LOTEC53S3SQ3ZXHZ: "lottery.rules.modal.LOTEC53S3SQ3ZXHZ.hint",
      LOTEC53S3SQ3ZXKD: "lottery.rules.modal.LOTEC53S3SQ3ZXKD.hint",
      LOTEC53S3SQ3ZXHZWS: "lottery.rules.modal.LOTEC53S3SQ3ZXHZWS.hint",
      LOTEC53S3SQ3DX3: "lottery.rules.modal.LOTEC53S3SQ3DX3.hint",
      LOTEC53S3SQ3DX6: "lottery.rules.modal.LOTEC53S3SQ3DX6.hint",
    },
    description: {
      LOTEC53S3SH3ZXFS: "lottery.rules.modal.LOTEC53S3SH3ZXFS.description",
      LOTEC53S3SH3ZXHZ: "lottery.rules.modal.LOTEC53S3SH3ZXHZ.description",
      LOTEC53S3SH3ZXKD: "lottery.rules.modal.LOTEC53S3SH3ZXKD.description",
      LOTEC53S3SH3ZXHZWS: "lottery.rules.modal.LOTEC53S3SH3ZXHZWS.description",
      LOTEC53S3SH3DX3: "lottery.rules.modal.LOTEC53S3SH3DX3.description",
      LOTEC53S3SH3DX6: "lottery.rules.modal.LOTEC53S3SH3DX6.description",
      LOTEC53S3SC3ZXFS: "lottery.rules.modal.LOTEC53S3SC3ZXFS.description",
      LOTEC53S3SC3ZXHZ: "lottery.rules.modal.LOTEC53S3SC3ZXHZ.description",
      LOTEC53S3SC3ZXKD: "lottery.rules.modal.LOTEC53S3SC3ZXKD.description",
      LOTEC53S3SC3ZXHZWS: "lottery.rules.modal.LOTEC53S3SC3ZXHZWS.description",
      LOTEC53S3SC3DX3: "lottery.rules.modal.LOTEC53S3SC3DX3.description",
      LOTEC53S3SC3DX6: "lottery.rules.modal.LOTEC53S3SC3DX6.description",
      LOTEC53S3SQ3ZXFS: "lottery.rules.modal.LOTEC53S3SQ3ZXFS.description",
      LOTEC53S3SQ3ZXHZ: "lottery.rules.modal.LOTEC53S3SQ3ZXHZ.description",
      LOTEC53S3SQ3ZXKD: "lottery.rules.modal.LOTEC53S3SQ3ZXKD.description",
      LOTEC53S3SQ3ZXHZWS: "lottery.rules.modal.LOTEC53S3SQ3ZXHZWS.description",
      LOTEC53S3SQ3DX3: "lottery.rules.modal.LOTEC53S3SQ3DX3.description",
      LOTEC53S3SQ3DX6: "lottery.rules.modal.LOTEC53S3SQ3DX6.description",
    },
    example: {
      LOTEC53S3SH3ZXFS: "lottery.rules.modal.LOTEC53S3SH3ZXFS.example",
      LOTEC53S3SH3ZXHZ: "lottery.rules.modal.LOTEC53S3SH3ZXHZ.example",
      LOTEC53S3SH3ZXKD: "lottery.rules.modal.LOTEC53S3SH3ZXKD.example",
      LOTEC53S3SH3ZXHZWS: "lottery.rules.modal.LOTEC53S3SH3ZXHZWS.example",
      LOTEC53S3SH3DX3: "lottery.rules.modal.LOTEC53S3SH3DX3.example",
      LOTEC53S3SH3DX6: "lottery.rules.modal.LOTEC53S3SH3DX6.example",
      LOTEC53S3SC3ZXFS: "lottery.rules.modal.LOTEC53S3SC3ZXFS.example",
      LOTEC53S3SC3ZXHZ: "lottery.rules.modal.LOTEC53S3SC3ZXHZ.example",
      LOTEC53S3SC3ZXKD: "lottery.rules.modal.LOTEC53S3SC3ZXKD.example",
      LOTEC53S3SC3ZXHZWS: "lottery.rules.modal.LOTEC53S3SC3ZXHZWS.example",
      LOTEC53S3SC3DX3: "lottery.rules.modal.LOTEC53S3SC3DX3.example",
      LOTEC53S3SC3DX6: "lottery.rules.modal.LOTEC53S3SC3DX6.example",
      LOTEC53S3SQ3ZXFS: "lottery.rules.modal.LOTEC53S3SQ3ZXFS.example",
      LOTEC53S3SQ3ZXHZ: "lottery.rules.modal.LOTEC53S3SQ3ZXHZ.example",
      LOTEC53S3SQ3ZXKD: "lottery.rules.modal.LOTEC53S3SQ3ZXKD.example",
      LOTEC53S3SQ3ZXHZWS: "lottery.rules.modal.LOTEC53S3SQ3ZXHZWS.example",
      LOTEC53S3SQ3DX3: "lottery.rules.modal.LOTEC53S3SQ3DX3.example",
      LOTEC53S3SQ3DX6: "lottery.rules.modal.LOTEC53S3SQ3DX6.example",
    },
  },
  LOTEC52S: {
    hint: {
      LOTEC52S2SH2ZXFS: "lottery.rules.modal.LOTEC52S2SH2ZXFS.hint",
      LOTEC52S2SH2ZXHZ: "lottery.rules.modal.LOTEC52S2SH2ZXHZ.hint",
      LOTEC52S2SH2ZXKD: "lottery.rules.modal.LOTEC52S2SH2ZXKD.hint",
      LOTEC52S2SH2ZXHZWS: "lottery.rules.modal.LOTEC52S2SH2ZXHZWS.hint",
      LOTEC52S2SH2DXFS: "lottery.rules.modal.LOTEC52S2SH2DXFS.hint",
      LOTEC52S2SQ2ZXFS: "lottery.rules.modal.LOTEC52S2SQ2ZXFS.hint",
      LOTEC52S2SQ2ZXHZ: "lottery.rules.modal.LOTEC52S2SQ2ZXHZ.hint",
      LOTEC52S2SQ2ZXKD: "lottery.rules.modal.LOTEC52S2SQ2ZXKD.hint",
      LOTEC52S2SQ2ZXHZWS: "lottery.rules.modal.LOTEC52S2SQ2ZXHZWS.hint",
      LOTEC52S2SQ2DXFS: "lottery.rules.modal.LOTEC52S2SQ2DXFS.hint",
    },
    description: {
      LOTEC52S2SH2ZXFS: "lottery.rules.modal.LOTEC52S2SH2ZXFS.description",
      LOTEC52S2SH2ZXHZ: "lottery.rules.modal.LOTEC52S2SH2ZXHZ.description",
      LOTEC52S2SH2ZXKD: "lottery.rules.modal.LOTEC52S2SH2ZXKD.description",
      LOTEC52S2SH2ZXHZWS: "lottery.rules.modal.LOTEC52S2SH2ZXHZWS.description",
      LOTEC52S2SH2DXFS: "lottery.rules.modal.LOTEC52S2SH2DXFS.description",
      LOTEC52S2SQ2ZXFS: "lottery.rules.modal.LOTEC52S2SQ2ZXFS.description",
      LOTEC52S2SQ2ZXHZ: "lottery.rules.modal.LOTEC52S2SQ2ZXHZ.description",
      LOTEC52S2SQ2ZXKD: "lottery.rules.modal.LOTEC52S2SQ2ZXKD.description",
      LOTEC52S2SQ2ZXHZWS: "lottery.rules.modal.LOTEC52S2SQ2ZXHZWS.description",
      LOTEC52S2SQ2DXFS: "lottery.rules.modal.LOTEC52S2SQ2DXFS.description",
    },
    example: {
      LOTEC52S2SH2ZXFS: "lottery.rules.modal.LOTEC52S2SH2ZXFS.example",
      LOTEC52S2SH2ZXHZ: "lottery.rules.modal.LOTEC52S2SH2ZXHZ.example",
      LOTEC52S2SH2ZXKD: "lottery.rules.modal.LOTEC52S2SH2ZXKD.example",
      LOTEC52S2SH2ZXHZWS: "lottery.rules.modal.LOTEC52S2SH2ZXHZWS.example",
      LOTEC52S2SH2DXFS: "lottery.rules.modal.LOTEC52S2SH2DXFS.example",
      LOTEC52S2SQ2ZXFS: "lottery.rules.modal.LOTEC52S2SQ2ZXFS.example",
      LOTEC52S2SQ2ZXHZ: "lottery.rules.modal.LOTEC52S2SQ2ZXHZ.example",
      LOTEC52S2SQ2ZXKD: "lottery.rules.modal.LOTEC52S2SQ2ZXKD.example",
      LOTEC52S2SQ2ZXHZWS: "lottery.rules.modal.LOTEC52S2SQ2ZXHZWS.example",
      LOTEC52S2SQ2DXFS: "lottery.rules.modal.LOTEC52S2SQ2DXFS.example",
    },
  },
  LOTEC5DWD: {
    hint: "lottery.rules.modal.LOTEC5DWD.hint",
    description: "lottery.rules.modal.LOTEC5DWD.description",
    example: "lottery.rules.modal.LOTEC5DWD.example",
  },
  LOTEC5BDW: {
    hint: {
      LOTEC5BDW3SBDWH31M: "lottery.rules.modal.LOTEC5BDW3SBDWH31M.hint",
      LOTEC5BDW3SBDWC31M: "lottery.rules.modal.LOTEC5BDW3SBDWC31M.hint",
      LOTEC5BDW3SBDWQ31M: "lottery.rules.modal.LOTEC5BDW3SBDWQ31M.hint",
      LOTEC5BDW3SBDWH32M: "lottery.rules.modal.LOTEC5BDW3SBDWH32M.hint",
      LOTEC5BDW3SBDWC32M: "lottery.rules.modal.LOTEC5BDW3SBDWC32M.hint",
      LOTEC5BDW3SBDWQ32M: "lottery.rules.modal.LOTEC5BDW3SBDWQ32M.hint",
      LOTEC5BDW4SBDWH41M: "lottery.rules.modal.LOTEC5BDW4SBDWH41M.hint",
      LOTEC5BDW4SBDWQ41M: "lottery.rules.modal.LOTEC5BDW4SBDWQ41M.hint",
      LOTEC5BDW4SBDWH42M: "lottery.rules.modal.LOTEC5BDW4SBDWH42M.hint",
      LOTEC5BDW4SBDWQ42M: "lottery.rules.modal.LOTEC5BDW4SBDWQ42M.hint",
      LOTEC5BDW4SBDWH43M: "lottery.rules.modal.LOTEC5BDW4SBDWH43M.hint",
      LOTEC5BDW4SBDWQ43M: "lottery.rules.modal.LOTEC5BDW4SBDWQ43M.hint",
      LOTEC5BDW5SBDW5S1M: "lottery.rules.modal.LOTEC5BDW5SBDW5S1M.hint",
      LOTEC5BDW5SBDW5S2M: "lottery.rules.modal.LOTEC5BDW5SBDW5S2M.hint",
      LOTEC5BDW5SBDW5S3M: "lottery.rules.modal.LOTEC5BDW5SBDW5S3M.hint",
      LOTEC5BDW5SBDW5S4M: "lottery.rules.modal.LOTEC5BDW5SBDW5S4M.hint",
    },
    description: {
      LOTEC5BDW3SBDWH31M: "lottery.rules.modal.LOTEC5BDW3SBDWH31M.description",
      LOTEC5BDW3SBDWC31M: "lottery.rules.modal.LOTEC5BDW3SBDWC31M.description",
      LOTEC5BDW3SBDWQ31M: "lottery.rules.modal.LOTEC5BDW3SBDWQ31M.description",
      LOTEC5BDW3SBDWH32M: "lottery.rules.modal.LOTEC5BDW3SBDWH32M.description",
      LOTEC5BDW3SBDWC32M: "lottery.rules.modal.LOTEC5BDW3SBDWC32M.description",
      LOTEC5BDW3SBDWQ32M: "lottery.rules.modal.LOTEC5BDW3SBDWQ32M.description",
      LOTEC5BDW4SBDWH41M: "lottery.rules.modal.LOTEC5BDW4SBDWH41M.description",
      LOTEC5BDW4SBDWQ41M: "lottery.rules.modal.LOTEC5BDW4SBDWQ41M.description",
      LOTEC5BDW4SBDWH42M: "lottery.rules.modal.LOTEC5BDW4SBDWH42M.description",
      LOTEC5BDW4SBDWQ42M: "lottery.rules.modal.LOTEC5BDW4SBDWQ42M.description",
      LOTEC5BDW4SBDWH43M: "lottery.rules.modal.LOTEC5BDW4SBDWH43M.description",
      LOTEC5BDW4SBDWQ43M: "lottery.rules.modal.LOTEC5BDW4SBDWQ43M.description",
      LOTEC5BDW5SBDW5S1M: "lottery.rules.modal.LOTEC5BDW5SBDW5S1M.description",
      LOTEC5BDW5SBDW5S2M: "lottery.rules.modal.LOTEC5BDW5SBDW5S2M.description",
      LOTEC5BDW5SBDW5S3M: "lottery.rules.modal.LOTEC5BDW5SBDW5S3M.description",
      LOTEC5BDW5SBDW5S4M: "lottery.rules.modal.LOTEC5BDW5SBDW5S4M.description",
    },
    example: {
      LOTEC5BDW3SBDWH31M: "lottery.rules.modal.LOTEC5BDW3SBDWH31M.example",
      LOTEC5BDW3SBDWC31M: "lottery.rules.modal.LOTEC5BDW3SBDWC31M.example",
      LOTEC5BDW3SBDWQ31M: "lottery.rules.modal.LOTEC5BDW3SBDWQ31M.example",
      LOTEC5BDW3SBDWH32M: "lottery.rules.modal.LOTEC5BDW3SBDWH32M.example",
      LOTEC5BDW3SBDWC32M: "lottery.rules.modal.LOTEC5BDW3SBDWC32M.example",
      LOTEC5BDW3SBDWQ32M: "lottery.rules.modal.LOTEC5BDW3SBDWQ32M.example",
      LOTEC5BDW4SBDWH41M: "lottery.rules.modal.LOTEC5BDW4SBDWH41M.example",
      LOTEC5BDW4SBDWQ41M: "lottery.rules.modal.LOTEC5BDW4SBDWQ41M.example",
      LOTEC5BDW4SBDWH42M: "lottery.rules.modal.LOTEC5BDW4SBDWH42M.example",
      LOTEC5BDW4SBDWQ42M: "lottery.rules.modal.LOTEC5BDW4SBDWQ42M.example",
      LOTEC5BDW4SBDWH43M: "lottery.rules.modal.LOTEC5BDW4SBDWH43M.example",
      LOTEC5BDW4SBDWQ43M: "lottery.rules.modal.LOTEC5BDW4SBDWQ43M.example",
      LOTEC5BDW5SBDW5S1M: "lottery.rules.modal.LOTEC5BDW5SBDW5S1M.example",
      LOTEC5BDW5SBDW5S2M: "lottery.rules.modal.LOTEC5BDW5SBDW5S2M.example",
      LOTEC5BDW5SBDW5S3M: "lottery.rules.modal.LOTEC5BDW5SBDW5S3M.example",
      LOTEC5BDW5SBDW5S4M: "lottery.rules.modal.LOTEC5BDW5SBDW5S4M.example",
    },
  },
  LOTEC5QW: {
    hint: {
      LOTEC5QWTS1FFS: "lottery.rules.modal.LOTEC5QWTS1FFS.hint",
      LOTEC5QWTSHSCS: "lottery.rules.modal.LOTEC5QWTSHSCS.hint",
      LOTEC5QWTS3SBC: "lottery.rules.modal.LOTEC5QWTS3SBC.hint",
      LOTEC5QWTS4GFC: "lottery.rules.modal.LOTEC5QWTS4GFC.hint",
      LOTEC5QWTS5KDF: "lottery.rules.modal.LOTEC5QWTS5KDF.hint",
    },
    description: {
      LOTEC5QWTS1FFS: "lottery.rules.modal.LOTEC5QWTS1FFS.description",
      LOTEC5QWTSHSCS: "lottery.rules.modal.LOTEC5QWTSHSCS.description",
      LOTEC5QWTS3SBC: "lottery.rules.modal.LOTEC5QWTS3SBC.description",
      LOTEC5QWTS4GFC: "lottery.rules.modal.LOTEC5QWTS4GFC.description",
      LOTEC5QWTS5KDF: "lottery.rules.modal.LOTEC5QWTS5KDF.description",
    },
    example: {
      LOTEC5QWTS1FFS: "lottery.rules.modal.LOTEC5QWTS1FFS.example",
      LOTEC5QWTSHSCS: "lottery.rules.modal.LOTEC5QWTSHSCS.example",
      LOTEC5QWTS3SBC: "lottery.rules.modal.LOTEC5QWTS3SBC.example",
      LOTEC5QWTS4GFC: "lottery.rules.modal.LOTEC5QWTS4GFC.example",
      LOTEC5QWTS5KDF: "lottery.rules.modal.LOTEC5QWTS5KDF.example",
    },
  },
  LOTEC5LH: {
    hint: "lottery.rules.modal.LOTEC5LH.hint",
    description: "lottery.rules.modal.LOTEC5LH.description",
    example: "lottery.rules.modal.LOTEC5LH.example",
  },
  LOTEC5DXDS: {
    hint: "lottery.rules.modal.LOTEC5DXDS.hint",
    description: "lottery.rules.modal.LOTEC5DXDS.description",
    example: "lottery.rules.modal.LOTEC5DXDS.example",
  },
  LOTEC5DN: {
    hint: "lottery.rules.modal.LOTEC5DN.hint",
    description: "lottery.rules.modal.LOTEC5DN.description",
    example: "lottery.rules.modal.LOTEC5DN.example",
  },
  LOTEC5TSH: {
    hint: {
      LOTEC5TSHTSHH3: "lottery.rules.modal.LOTEC5TSHTSHH3.hint",
      LOTEC5TSHTSHC3: "lottery.rules.modal.LOTEC5TSHTSHC3.hint",
      LOTEC5TSHTSHQ3: "lottery.rules.modal.LOTEC5TSHTSHQ3.hint",
    },
    description: {
      LOTEC5TSHTSHH3: "lottery.rules.modal.LOTEC5TSHTSHH3.description",
      LOTEC5TSHTSHC3: "lottery.rules.modal.LOTEC5TSHTSHC3.description",
      LOTEC5TSHTSHQ3: "lottery.rules.modal.LOTEC5TSHTSHQ3.description",
    },
    example: {
      LOTEC5TSHTSHH3: "lottery.rules.modal.LOTEC5TSHTSHH3.example",
      LOTEC5TSHTSHC3: "lottery.rules.modal.LOTEC5TSHTSHC3.example",
      LOTEC5TSHTSHQ3: "lottery.rules.modal.LOTEC5TSHTSHQ3.example",
    },
  },
  LOTEC5RX: {
    hint: {
      LOTEC5RXR2FX: "lottery.rules.modal.LOTEC5RXR2FX.hint",
      LOTEC5RXR2DX: "lottery.rules.modal.LOTEC5RXR2DX.hint",
      LOTEC5RXR3FX: "lottery.rules.modal.LOTEC5RXR3FX.hint",
      LOTEC5RXR3Z3: "lottery.rules.modal.LOTEC5RXR3Z3.hint",
      LOTEC5RXR3Z6: "lottery.rules.modal.LOTEC5RXR3Z6.hint",
      LOTEC5RXR4FX: "lottery.rules.modal.LOTEC5RXR4FX.hint",
    },
    description: {
      LOTEC5RXR2FX: "lottery.rules.modal.LOTEC5RXR2FX.description",
      LOTEC5RXR2DX: "lottery.rules.modal.LOTEC5RXR2DX.description",
      LOTEC5RXR3FX: "lottery.rules.modal.LOTEC5RXR3FX.description",
      LOTEC5RXR3Z3: "lottery.rules.modal.LOTEC5RXR3Z3.description",
      LOTEC5RXR3Z6: "lottery.rules.modal.LOTEC5RXR3Z6.description",
      LOTEC5RXR4FX: "lottery.rules.modal.LOTEC5RXR4FX.description",
    },
    example: {
      LOTEC5RXR2FX: "lottery.rules.modal.LOTEC5RXR2FX.example",
      LOTEC5RXR2DX: "lottery.rules.modal.LOTEC5RXR2DX.example",
      LOTEC5RXR3FX: "lottery.rules.modal.LOTEC5RXR3FX.example",
      LOTEC5RXR3Z3: "lottery.rules.modal.LOTEC5RXR3Z3.example",
      LOTEC5RXR3Z6: "lottery.rules.modal.LOTEC5RXR3Z6.example",
      LOTEC5RXR4FX: "lottery.rules.modal.LOTEC5RXR4FX.example",
    },
  },
};

export default LOTEC5RuleEnum;
