import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import localStorageKey from "../enumerations/localStorageKey";
import useLocalStorage from "./useLocalStorage";
import { DEFAULT_LANG } from "../config";

const langList = [
  { id: 1, lang: "zh", name: "简中" },
  { id: 2, lang: "en", name: "English" },
  { id: 3, lang: "idr", name: "印尼 (Indonesian)" },
];

const LangContext = createContext({
  changeLanguage: () => { },
  getLanguage: () => { },
  langList: [],
});

export const LangContextProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useLocalStorage(localStorageKey.DEFAULT_LANG, DEFAULT_LANG);

  const changeLanguage = (lang) => {
    // setLang(lang.id);
    setLang(lang);
    console.log("changeLanguage", lang);
    i18n.changeLanguage(lang);
  };

  const getLanguage = () => {
    return lang;
  };

  return (
    <LangContext.Provider
      value={{
        changeLanguage,
        getLanguage,
        langList,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
export const useLanguage = () => {
  const LangValue = useContext(LangContext);
  return LangValue;
};
