import {
  twoStarDifferenceCount,
  twoStarSummaryCount,
} from "../../../functions/countCompute";
import { combination } from "../../../functions/math";
import {
  BALL_COUNT_EACH_GROUP,
  SELECTED_COUNT_LIMIT,
} from "../../valudateFunctionEnum";
import EveryColorKeyEnums from "./keyEnums";

const TwoStarsFunction = {
  //二星后二直选复式
  LOTEC102S2SH2ZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //二星后二直选和值
  LOTEC102S2SH2ZXHZ: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].HEZHI.key]) {
        count = twoStarSummaryCount(
          selected[EveryColorKeyEnums[currentGameWayDetail].HEZHI.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  //二星后二直选跨度
  LOTEC102S2SH2ZXKD: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].KUADU.key]) {
        count = twoStarDifferenceCount(
          selected[EveryColorKeyEnums[currentGameWayDetail].KUADU.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  // 二星后二直选和值尾数
  LOTEC102S2SH2ZXHZWS: {
    defaultProcess: true,
    // selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
    //   const newSelect = [];
    //   let count = 0;
    //   const flatten = Object.keys(selected)
    //     .map((key) => {
    //       if (selected[key].length > 0) {
    //         count += selected[key].length;
    //         return selected[key].join(",");
    //       } else {
    //         return "_";
    //       }
    //     })
    //     .join("|");
    //   newSelect.push({
    //     methodText: `gameWay.items.${currentGameWayDetail}`,
    //     method: method,
    //     content: flatten,
    //     simpleMoney: currentBetNumber,
    //     count: count,
    //   });
    //   return newSelect;
    // },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //二星后二组选复式
  LOTEC102S2SH2DXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  //二星前二直选复式
  LOTEC102S2SQ2ZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //二星前二直选和值
  LOTEC102S2SQ2ZXHZ: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].HEZHI.key]) {
        count = twoStarSummaryCount(
          selected[EveryColorKeyEnums[currentGameWayDetail].HEZHI.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  //二星前二直选跨度
  LOTEC102S2SQ2ZXKD: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].KUADU.key]) {
        count = twoStarDifferenceCount(
          selected[EveryColorKeyEnums[currentGameWayDetail].KUADU.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(1)({ selected });
    },
  },
  //二星前二直选和值尾数
  LOTEC102S2SQ2ZXHZWS: {
    defaultProcess: true,
    // selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
    //   const newSelect = [];
    //   let count = 0;
    //   const flatten = Object.keys(selected)
    //     .map((key) => {
    //       if (selected[key].length > 0) {
    //         count += selected[key].length;
    //         return selected[key].join(",");
    //       } else {
    //         return "_";
    //       }
    //     })
    //     .join("|");
    //   newSelect.push({
    //     methodText: `gameWay.items.${currentGameWayDetail}`,
    //     method: method,
    //     content: flatten,
    //     simpleMoney: currentBetNumber,
    //     count: count,
    //   });
    //   return newSelect;
    // },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  //二星前二组选复式
  LOTEC102S2SQ2DXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
};

export { TwoStarsFunction };
