const gameStateEnum = {
  BET_PHASE: {
    key: "BET_PHASE",
    length: undefined,
  },
  ANIMATION_PHASE: {
    key: "ANIMATION_PHASE",
    length: 2,
  },
  RESULT_PENDING_PHASE: {
    key: "RESULT_PENDING_PHASE",
    length: 2,
  },
  RESULT_PHASE: {
    key: "RESULT_PHASE",
    length: 1,
  },
};

export default gameStateEnum;
