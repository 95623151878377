import Selector from "../../../components/Selector";

const LOTFT10SelectorEnum = {
  "LOTFT10HZ.HZ": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTFT10HZ.HZ_GROUP": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
    },
  },
  "LOTFT10ETHETD.ETD": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT10ETHETF.ETF": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectParams: "name",
    },
  },
  "LOTFT10EBTHBZXH.BZXH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT10EBTHDTXH.DTXH": {
    component: Selector.twoSame,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      twoSameKeys: [`lottery.bet.title.DM`, `lottery.bet.title.TM`],
      twoSameLimit: [1, 999],
    },
  },
  "LOTFT10SLH.SLH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT10STHSTD.STD": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTFT10STHSTT.STT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      selectAll: true,
    },
  },
  "LOTFT10SBTH.SBTH": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
};

export default LOTFT10SelectorEnum;
