const LOTVNNEC5RuleEnum = {
  LOTVNNEC5BX: {
    hint: {
      LOTVNNEC5BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.hint",
      LOTVNNEC5BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.hint",
      LOTVNNEC5BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.hint",
      LOTVNNEC5BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.hint",
      LOTVNNEC5BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.hint",
      LOTVNNEC5BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.hint",
      LOTVNNEC5BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.hint",
      LOTVNNEC5BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.hint",
      LOTVNNEC5BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.hint",
      LOTVNNEC5BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.hint",
    },
    description: {
      LOTVNNEC5BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.description",
      LOTVNNEC5BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.description",
      LOTVNNEC5BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.description",
      LOTVNNEC5BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.description",
      LOTVNNEC5BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.description",
      LOTVNNEC5BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.description",
      LOTVNNEC5BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.description",
      LOTVNNEC5BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.description",
      LOTVNNEC5BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.description",
      LOTVNNEC5BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.description",
    },
    example: {
      LOTVNNEC5BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.example",
      LOTVNNEC5BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.example",
      LOTVNNEC5BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.example",
      LOTVNNEC5BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.example",
      LOTVNNEC5BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.example",
      LOTVNNEC5BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.example",
      LOTVNNEC5BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.example",
      LOTVNNEC5BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.example",
      LOTVNNEC5BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.example",
      LOTVNNEC5BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.example",
    },
  },
  LOTVNNEC5CG: {
    hint: {
      LOTVNNEC5CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.hint",
      LOTVNNEC5CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.hint",
      LOTVNNEC5CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.hint",
    },
    description: {
      LOTVNNEC5CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.description",
      LOTVNNEC5CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.description",
      LOTVNNEC5CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.description",
    },
    example: {
      LOTVNNEC5CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.example",
      LOTVNNEC5CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.example",
      LOTVNNEC5CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.example",
    },
  },
  LOTVNNEC52S: {
    hint: {
      LOTVNNEC52STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.hint",
      LOTVNNEC52S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.hint",
      LOTVNNEC52ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.hint",
      LOTVNNEC52STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.hint",
      LOTVNNEC52S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.hint",
      LOTVNNEC52ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.hint",
      LOTVNNEC52S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.hint",
    },
    description: {
      LOTVNNEC52STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.description",
      LOTVNNEC52S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.description",
      LOTVNNEC52ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.description",
      LOTVNNEC52STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.description",
      LOTVNNEC52S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.description",
      LOTVNNEC52ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.description",
      LOTVNNEC52S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.description",
    },
    example: {
      LOTVNNEC52STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.example",
      LOTVNNEC52S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.example",
      LOTVNNEC52ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.example",
      LOTVNNEC52STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.example",
      LOTVNNEC52S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.example",
      LOTVNNEC52ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.example",
      LOTVNNEC52S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.example",
    },
  },
  LOTVNNEC53S: {
    hint: {
      LOTVNNEC53STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.hint",
      LOTVNNEC53S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.hint",
      LOTVNNEC53ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.hint",
      LOTVNNEC53STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.hint",
      LOTVNNEC53STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.hint",
      LOTVNNEC53STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.hint",
      LOTVNNEC53S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.hint",
      LOTVNNEC53ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.hint",
      LOTVNNEC53STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.hint",
      LOTVNNEC53STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.hint",
      LOTVNNEC53S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.hint",
    },
    description: {
      LOTVNNEC53STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.description",
      LOTVNNEC53S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.description",
      LOTVNNEC53ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.description",
      LOTVNNEC53STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.description",
      LOTVNNEC53STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.description",
      LOTVNNEC53STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.description",
      LOTVNNEC53S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.description",
      LOTVNNEC53ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.description",
      LOTVNNEC53STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.description",
      LOTVNNEC53STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.description",
      LOTVNNEC53S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.description",
    },
    example: {
      LOTVNNEC53STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.example",
      LOTVNNEC53S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.example",
      LOTVNNEC53ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.example",
      LOTVNNEC53STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.example",
      LOTVNNEC53STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.example",
      LOTVNNEC53STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.example",
      LOTVNNEC53S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.example",
      LOTVNNEC53ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.example",
      LOTVNNEC53STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.example",
      LOTVNNEC53STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.example",
      LOTVNNEC53S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.example",
    },
  },
  LOTVNNEC54S: {
    hint: {
      LOTVNNEC54STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.hint",
      LOTVNNEC54S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.hint",
      LOTVNNEC54STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.hint",
      LOTVNNEC54S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.hint",
    },
    description: {
      LOTVNNEC54STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.description",
      LOTVNNEC54S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.description",
      LOTVNNEC54STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.description",
      LOTVNNEC54S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.description",
    },
    example: {
      LOTVNNEC54STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.example",
      LOTVNNEC54S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.example",
      LOTVNNEC54STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.example",
      LOTVNNEC54S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.example",
    },
  },
  LOTVNNEC5ZXBZ: {
    hint: {
      LOTVNNEC5ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.hint",
      LOTVNNEC5ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.hint",
      LOTVNNEC5ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.hint",
      LOTVNNEC5ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.hint",
    },
    description: {
      LOTVNNEC5ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.description",
      LOTVNNEC5ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.description",
      LOTVNNEC5ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.description",
      LOTVNNEC5ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.description",
    },
    example: {
      LOTVNNEC5ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.example",
      LOTVNNEC5ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.example",
      LOTVNNEC5ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.example",
      LOTVNNEC5ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.example",
    },
  },
  LOTVNNEC5DXDS: {
    hint: "lottery.rules.modal.LOTVNNEC5DXDS.hint",
    description: "lottery.rules.modal.LOTVNNEC5DXDS.description",
    example: "lottery.rules.modal.LOTVNNEC5DXDS.example",
  },
};

export default LOTVNNEC5RuleEnum;
