import React from "react";

function Setting({ size = "68", color1 = "#F5F135", color2 = "#F47035" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 68 68"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="9sxny4df2a">
          <path d="M750 0v1624H0V0h750z" />
        </clipPath>
        <clipPath id="rpzkedshlb">
          <path d="M34 0c18.778 0 34 15.222 34 34S52.778 68 34 68 0 52.778 0 34 15.222 0 34 0z" />
        </clipPath>
        <clipPath id="13evf3sdvd">
          <path d="M22.115.044c1.88.207 3.517 1.704 3.911 3.55.27 1.038-.088 2.164.368 3.165.537 1.293 2.037 2.094 3.41 1.796.43-.079.81-.274 1.184-.488l.446-.26c.15-.085.3-.167.454-.24a4.602 4.602 0 0 1 3.69-.11 4.587 4.587 0 0 1 2.264 1.957c.52.89 1.034 1.783 1.546 2.678a4.6 4.6 0 0 1-1.456 6.132c-.646.433-1.416.723-1.903 1.358-.89 1.075-.89 2.764.002 3.838.47.615 1.209.905 1.84 1.315a4.601 4.601 0 0 1 1.52 6.172c-.539.933-1.07 1.872-1.622 2.799-.82 1.348-2.378 2.195-3.953 2.17-.933.005-1.836-.323-2.616-.819-.598-.375-1.268-.69-1.99-.672-1.272-.01-2.5.857-2.898 2.07-.292.81-.094 1.685-.211 2.522-.17 1.416-1.053 2.718-2.294 3.418a4.664 4.664 0 0 1-2.367.603c-1.09-.008-2.179.017-3.267-.014-1.958-.096-3.739-1.589-4.178-3.499-.3-1.06.076-2.213-.384-3.237-.524-1.273-1.98-2.07-3.335-1.817-.78.117-1.402.634-2.093.969a4.61 4.61 0 0 1-6.02-1.812 388.77 388.77 0 0 1-1.55-2.68 4.61 4.61 0 0 1-.495-3.317 4.594 4.594 0 0 1 2.009-2.852c.617-.4 1.336-.682 1.806-1.271.927-1.071.944-2.795.042-3.885-.413-.541-1.037-.839-1.61-1.174a4.689 4.689 0 0 1-1.559-1.422 4.61 4.61 0 0 1-.62-3.891c.214-.761.678-1.412 1.055-2.097.424-.699.783-1.44 1.267-2.1 1.146-1.513 3.28-2.164 5.075-1.546.93.275 1.653 1.02 2.615 1.203 1.068.223 2.23-.194 2.93-1.026.468-.539.722-1.248.729-1.96.01-.742-.042-1.5.181-2.219.454-1.655 1.91-2.977 3.603-3.27.62-.115 1.256-.068 1.884-.077.863.017 1.73-.04 2.59.04zM18.992 13.89c-2.467.306-4.714 1.911-5.831 4.127a7.688 7.688 0 0 0-.566 5.505 7.683 7.683 0 0 0 2.645 3.993 7.653 7.653 0 0 0 8.852.478 7.675 7.675 0 0 0 3.1-9.178 7.713 7.713 0 0 0-2.925-3.694 7.663 7.663 0 0 0-5.275-1.23z" />
        </clipPath>
        <linearGradient
          id="s6tan06lbc"
          x1="7.352"
          y1="8.469"
          x2="57.854"
          y2="59.007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color1} offset="0%" />
          <stop stop-color={color2} offset="100%" />
        </linearGradient>
      </defs>
      <g clip-path="url(#9sxny4df2a)" transform="translate(-574 -178)">
        <g clip-path="url(#rpzkedshlb)" transform="translate(574 178)">
          <path fill="url(#s6tan06lbc)" d="M0 0h68v68H0V0z" />
        </g>
        <g clip-path="url(#13evf3sdvd)" transform="translate(588 190)">
          <path fill="#41444A" d="M0 0h40v43H0V0z" />
        </g>
      </g>
    </svg>
  );
}

export default Setting;
