import {
  FourStarsFunction,
  OptionalFunction,
  PackageSelectionFunction,
  ParlayFunction,
  ThreeStarsFunction,
  TwoStarsFunction,
} from "../../VNNorthEveryColorEnums/VNNEC3Function";
import {
  FOUR_STAR,
  OPTIONAL,
  PACKAGE,
  PARLAY,
  THREE_STAR,
  TWO_STAR,
} from "../../VNNorthEveryColorEnums/VNNEC3Validation";
import LOTVNNEC3RuleEnum from "../../ruleDescription/LOTVNNEC/LOTVNNEC3RuleEnum";
import {
  MAX_MIN_BET,
  MAX_PAYOUT,
  MONEY_REQUIRED,
  NOT_ENOUGH_MONEY,
  SELECTED_NON_NULL,
} from "../../valudateFunctionEnum";

const LOTVNNEC3SettingEnum = {
  LOTVNNEC3BX: {
    name: "包选",
    code: "LOTVNNEC3BX",
    ruleDescription: LOTVNNEC3RuleEnum.LOTVNNEC3BX,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, PACKAGE, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (PackageSelectionFunction[currentGameWayDetail]) {
        newSelect = PackageSelectionFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTVNNEC3CG: {
    name: "串关",
    code: "LOTVNNEC3CG",
    ruleDescription: LOTVNNEC3RuleEnum.LOTVNNEC3CG,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, PARLAY, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (ParlayFunction[currentGameWayDetail]) {
        newSelect = ParlayFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTVNNEC32S: {
    name: "二星",
    code: "LOTVNNEC32S",
    ruleDescription: LOTVNNEC3RuleEnum.LOTVNNEC32S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, TWO_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (TwoStarsFunction[currentGameWayDetail]) {
        newSelect = TwoStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC33S: {
    name: "三星",
    code: "LOTVNNEC33S",
    ruleDescription: LOTVNNEC3RuleEnum.LOTVNNEC33S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, THREE_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (ThreeStarsFunction[currentGameWayDetail]) {
        newSelect = ThreeStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC34S: {
    name: "四星",
    code: "LOTVNNEC34S",
    ruleDescription: LOTVNNEC3RuleEnum.LOTVNNEC34S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, FOUR_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (FourStarsFunction[currentGameWayDetail]) {
        newSelect = FourStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC3DXDS: {
    name: "大小单双",
    code: "LOTVNNEC3DXDS",
    ruleDescription: LOTVNNEC3RuleEnum.LOTVNNEC3DXDS,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWay, currentGameWayDetail }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${
                currentGameWayDetail ? `.${currentGameWayDetail}` : ""
              }${key ? `.${key}` : ""}`,
              method: "LOTVNNECDXDS",
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          }),
        );
      });

      return newSelected;
    },
  },
  LOTVNNEC3ZXBZ: {
    name: "自选不中",
    code: "LOTVNNEC3ZXBZ",
    ruleDescription: LOTVNNEC3RuleEnum.LOTVNNEC3ZXBZ,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, OPTIONAL, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (OptionalFunction[currentGameWayDetail]) {
        newSelect = OptionalFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
};

export default LOTVNNEC3SettingEnum;
