import { request } from "../apis";
import localStorageKey from "../enumerations/localStorageKey";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetUserInfo = (params) =>
  request("get", `/${CURRENCY}/user/info`, params, true);

export const apiGetUserWalletData = (params) =>
  request("get", `/${CURRENCY}/user/wallet`, params, true);
