import React from "react";
import DefaultSelector from "../DefaultSelector";
import { RectangleItem } from "../RectangleWithNums";

const RectangleWithNumsType2 = (props) => {
  let newItems = [];
  props?.items?.forEach((i) => {
    newItems = newItems.concat(
      i?.option.map((o) => {
        return { ...o, code: i.code };
      })
    );
  });
  return (
    <DefaultSelector
      {...props}
      items={newItems}
      selectorRender={RectangleItem}
    ></DefaultSelector>
  );
};

export default RectangleWithNumsType2;
