import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import {
  apiGetGame,
  apiGetGameWay,
  apiGetGameWayDetailMethod,
  apiGetGameWayMethod,
  apiPostGameBet,
} from "../service";

export const useGetGameWay = ({ params, options }) => {
  return useQuery(
    [queryKey.LOTTERY_GAME_WAY, params?.lottery],
    () =>
      apiGetGameWay(params).then((res) => {
        return res.data?.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameWayMethod = ({ params, options }) => {
  return useQuery(
    [queryKey.LOTTERY_GAME_WAY_METHOD, params?.game_way_type, params?.game_way_detail_type],
    () => {
      if (!params?.game_way_type || params?.game_way_detail_type) return undefined;

      return apiGetGameWayMethod(params).then((res) => {
        return res.data?.data;
      });
    },
    {
      ...options,
    },
  );
};

export const useGetGameWayDetailMethod = ({ params, options }) => {
  // console.log(params, "params");
  return useQuery(
    [queryKey.LOTTERY_GAME_WAY_DETAIL_METHOD, params?.game_way_detail_type],
    () => {
      if (!params?.game_way_detail_type) return undefined;

      return apiGetGameWayDetailMethod(params).then((res) => {
        return res.data?.data;
      });
    },
    {
      ...options,
    },
  );
};

export const usePostGameBet = () => {
  const cache = useQueryClient();
  return useMutation(({ params }) => apiPostGameBet(params), {
    onSuccess: () => {
      cache.invalidateQueries([queryKey.USER_WALLET]);
    },
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const useGetGameList = (options) => {
  return useQuery(
    [queryKey.LOTTERY_POPUP_GAME_LIST],
    () =>
      apiGetGame().then((res) => {
        const _result = res.data.data;
        let all_games = [];
        const categories = _result
          .map((item) => {
            all_games = [...all_games, ...item.game_list];
            return {
              ...item,
              title: item.name,
              key: item.game_category_detail_type,
            };
          })
          .flat(1);
        return [...categories];
      }),
    {
      ...options,
    },
  );
};
