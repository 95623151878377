//LOTMS
import LOTMS10SettingEnum from "./gameSettingEnums/LOTMS/LOTMS10SettingEnum";
import LOTMS1SettingEnum from "./gameSettingEnums/LOTMS/LOTMS1SettingEnum";
import LOTMS3SettingEnum from "./gameSettingEnums/LOTMS/LOTMS3SettingEnum";
import LOTMS5SettingEnum from "./gameSettingEnums/LOTMS/LOTMS5SettingEnum";
//LOTFT
import LOTFT10SettingEnum from "./gameSettingEnums/LOTFT/LOTFT10SettingEnum";
import LOTFT1SettingEnum from "./gameSettingEnums/LOTFT/LOTFT1SettingEnum";
import LOTFT3SettingEnum from "./gameSettingEnums/LOTFT/LOTFT3SettingEnum";
import LOTFT5SettingEnum from "./gameSettingEnums/LOTFT/LOTFT5SettingEnum";
//LOTEC
import LOTEC10SettingEnum from "./gameSettingEnums/LOTEC/LOTEC10SettingEnum";
import LOTEC1SettingEnum from "./gameSettingEnums/LOTEC/LOTEC1SettingEnum";
import LOTEC3SettingEnum from "./gameSettingEnums/LOTEC/LOTEC3SettingEnum";
import LOTEC5SettingEnum from "./gameSettingEnums/LOTEC/LOTEC5SettingEnum";
//LOTPK10
import LOTPK1010SettingEnum from "./gameSettingEnums/LOTPK10/LOTPK1010SettingEnum";
import LOTPK101SettingEnum from "./gameSettingEnums/LOTPK10/LOTPK101SettingEnum";
import LOTPK103SettingEnum from "./gameSettingEnums/LOTPK10/LOTPK103SettingEnum";
import LOTPK105SettingEnum from "./gameSettingEnums/LOTPK10/LOTPK105SettingEnum";
//OFLOTMS
import OFLOTMSHKSettingEnum from "./gameSettingEnums/OFLOTMS/OFLOTMSHKSettingEnum";
import OFLOTMSMOSettingEnum from "./gameSettingEnums/OFLOTMS/OFLOTMSMOSettingEnum";
//OFLOTPK10
import OFLOTPK10LASettingEnum from "./gameSettingEnums/OFLOTPK10/OFLOTPK10LASettingEnum";

//LOTVNNEC
import LOTVNNEC10SettingEnum from "./gameSettingEnums/LOTVNNEC/LOTVNNEC10SettingEnum";
import LOTVNNEC120SettingEnum from "./gameSettingEnums/LOTVNNEC/LOTVNNEC120SettingEnum";
import LOTVNNEC1SettingEnum from "./gameSettingEnums/LOTVNNEC/LOTVNNEC1SettingEnum";
import LOTVNNEC3SettingEnum from "./gameSettingEnums/LOTVNNEC/LOTVNNEC3SettingEnum";
import LOTVNNEC45SettingEnum from "./gameSettingEnums/LOTVNNEC/LOTVNNEC45SettingEnum";
import LOTVNNEC5SettingEnum from "./gameSettingEnums/LOTVNNEC/LOTVNNEC5SettingEnum";
import LOTVNNEC75SettingEnum from "./gameSettingEnums/LOTVNNEC/LOTVNNEC75SettingEnum";

const gameSettingEnum = {
  LOTMS1: LOTMS1SettingEnum,
  LOTMS3: LOTMS3SettingEnum,
  LOTMS5: LOTMS5SettingEnum,
  LOTMS10: LOTMS10SettingEnum,
  LOTFT1: LOTFT1SettingEnum,
  LOTFT3: LOTFT3SettingEnum,
  LOTFT5: LOTFT5SettingEnum,
  LOTFT10: LOTFT10SettingEnum,
  LOTEC1: LOTEC1SettingEnum,
  LOTEC3: LOTEC3SettingEnum,
  LOTEC5: LOTEC5SettingEnum,
  LOTEC10: LOTEC10SettingEnum,
  LOTPK101: LOTPK101SettingEnum,
  LOTPK103: LOTPK103SettingEnum,
  LOTPK105: LOTPK105SettingEnum,
  LOTPK1010: LOTPK1010SettingEnum,
  OFLOTMSHK: OFLOTMSHKSettingEnum,
  OFLOTMSMO: OFLOTMSMOSettingEnum,
  OFLOTPK10LA: OFLOTPK10LASettingEnum,

  //越南彩
  LOTVNNEC10: LOTVNNEC10SettingEnum,
  LOTVNNEC5: LOTVNNEC5SettingEnum,
  LOTVNNEC3: LOTVNNEC3SettingEnum,
  LOTVNNEC1: LOTVNNEC1SettingEnum,
  LOTVNNEC45: LOTVNNEC45SettingEnum,
  LOTVNNEC75: LOTVNNEC75SettingEnum,
  LOTVNNEC120: LOTVNNEC120SettingEnum,
};

export default gameSettingEnum;
