import dayjs from "dayjs";
import dayjstimezone from "dayjs/plugin/timezone";
import dayjsutc from "dayjs/plugin/utc";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useLongDragon } from "../../context/LongDragonContext";
import styles from "./style.module.scss";

dayjs.extend(dayjsutc);
dayjs.extend(dayjstimezone);

const LongDragonTimer = ({
  draw_time = dayjs(),
  timezone,
  title,
  localIsDrawing = false,
  setLocalIsDrawing = () => {},
}) => {
  const interval = useRef();
  const { isDrawing, setIsDrawing } = useLongDragon();
  // const [localIsDrawing, setLocalIsDrawing] = useState(false);
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const getTime = () => {
    const now = dayjs().tz(timezone);
    const draw = dayjs.tz(draw_time, timezone);
    const time = draw.diff(now);
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      getTime();
      if (seconds <= 0 && minutes <= 0) {
        setIsDrawing(true);
        setLocalIsDrawing(true);
      }
    }, 1000);
    return () => clearInterval(interval.current);
  }, [seconds, minutes]);

  // useEffect(() => {
  //   if (isDrawing === true) {
  //     clearInterval(interval.current);
  //   }
  // }, [isDrawing]);

  const renderTime = () => {
    if (isNaN(minutes) || isNaN(seconds)) return "";
    if (isDrawing === true && localIsDrawing === true) {
      return t("longdragon.drawingPrize");
    } else {
      return `${(minutes + 100).toString().substring(1, 3)}:${(seconds + 100)
        .toString()
        .substring(1, 3)}`;
    }
  };

  return <div className={styles.timer}>{renderTime()}</div>;
};

export default LongDragonTimer;
