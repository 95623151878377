import Selector from "../../../components/Selector";

const LOTMS5SelectorEnum = {
  // 特碼
  "LOTMS5TMTM.TM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS5TMTM.TM_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 半波
  "LOTMS5TMBP.BP_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 特碼生肖
  "LOTMS5TMSX.TMSX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 一肖
  "LOTMS5YX.YX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "LOTMS5YX.TW_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正肖
  "LOTMS5ZX.ZOX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "LOTMS5ZX.QSB": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連肖
  //  二肖碰
  "LOTMS5LXEXP.EXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  三肖碰
  "LOTMS5LXSAXP.SAXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  四肖碰
  "LOTMS5LXSIXP.SIXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  五肖碰
  "LOTMS5LXWXP.WXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連尾
  //  二尾连
  "LOTMS5LWELW.ELW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  三尾连
  "LOTMS5LWSALW.SALW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  四尾连
  "LOTMS5LWSILW.SILW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  五尾连
  "LOTMS5LWWLW.WLW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 合肖
  //  中
  "LOTMS5HXZ.Z": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  不中
  "LOTMS5HXBZ.BZ": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 連碼
  //  四全中
  "LOTMS5LMSIQZ.SIQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三全中
  "LOTMS5LMSAQZ.SAQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三中二
  "LOTMS5LMSZE.SZE": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二全中
  "LOTMS5LMEQZ.EQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二中特
  "LOTMS5LMEZT.EZT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  特串
  "LOTMS5LMTC.TC": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選不中
  //  五不中
  "LOTMS5TSBCWBZ.WBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六不中
  "LOTMS5TSBCLBZ.LBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  七不中
  "LOTMS5TSBCQBZ.QBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  八不中
  "LOTMS5TSBCBBZ.BBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  九不中
  "LOTMS5TSBCJBZ.JBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十不中
  "LOTMS5TSBCSBZ.SBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十一不中
  "LOTMS5TSBCSYBZ.SYBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十二不中
  "LOTMS5TSBCSEBZ.SEBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選中一
  //  三中一
  "LOTMS5CYSAZY.SAZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  四中一
  "LOTMS5CYSIZY.SIZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  五中一
  "LOTMS5CYWZY.WZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六中一
  "LOTMS5CYLZY.LZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 正码
  "LOTMS5CMCM.CM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  // 正码 1
  "LOTMS5CMCM1.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS5CMCM1.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 2
  "LOTMS5CMCM2.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS5CMCM2.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 3
  "LOTMS5CMCM3.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS5CMCM3.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 4
  "LOTMS5CMCM4.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS5CMCM4.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 5
  "LOTMS5CMCM5.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS5CMCM5.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 6
  "LOTMS5CMCM6.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS5CMCM6.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
};

export default LOTMS5SelectorEnum;
