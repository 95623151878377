// import { toast } from "../components";
import parseUrl from "parse-url";
import { DEFAULT_CURRENCY, DEFAULT_LANG } from "../config";
import localStorageKey from "../enumerations/localStorageKey";

export const copyText = (text, successMessageContent) => {
  if (!text) return;
  // navigator.clipboard.writeText(text);
  const selectGroup = document.getElementById("copy-group");
  let tempArea = document.createElement("textarea");
  selectGroup?.appendChild(tempArea);
  tempArea.innerText = text;
  tempArea.select();
  document.execCommand("Copy");
  selectGroup?.removeChild(tempArea);
  // toast({ content: successMessageContent, type: "success" });
};

export const getCurrency = () => {
  const storageCurrency = localStorage.getItem(
    localStorageKey.DEFAULT_CURRENCY
  );
  const queryStringObject = parseUrl(window.location.href).query;
  const queryCurrency = queryStringObject.currency;
  return storageCurrency || queryCurrency || DEFAULT_CURRENCY;
};

export const getNowLang = () => {
  const storageLang = localStorage.getItem(
    localStorageKey.DEFAULT_LANG
  );
  const queryStringObject = parseUrl(window.location.href).query;
  const queryLang = queryStringObject.lang;
  return storageLang || queryLang || DEFAULT_LANG;
};

