import { Image } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CDN_URL, PRODUCTION_NAME_DEV } from "../../../../config";
import { nowS3LangFolders } from "../../../../enumerations/cdnFolders";
import oddsTypeEnum from "../../../../enumerations/oddsTypeEnum";
import { getNowLang } from "../../../../plugins/utils";
import { useLongDragon } from "../../context/LongDragonContext";
import GameResult from "../GameResult";
import styles from "./style.module.scss";
const LongDragonItem = ({ item = {} }) => {
  const {
    type,
    position,
    game_type,
    game_code,
    content,
    count,
    period,
    game_method,
    current_period,
    options,
    game_category_type,
    game_category_detail_type,
    game_way_type,
    game_way_detail_type,
    timezone,
  } = item;
  const { t } = useTranslation();
  const nowLang = getNowLang()?.replace(/\"/gm, "");

  const { setRequestItems, requestItems, isDrawing, betAmount } = useLongDragon();
  const [localIsDrawing, setLocalIsDrawing] = useState(false);

  const renderGameType = () => {
    if (type === 1) return t(`longdragon.gameWay.${game_code}`);
    if (type === 2) {
      const opt = options.map((option) => t(option)).join("");
      const split = content.split("|");
      return `${t(`longdragon.position.${split[0]}`)}${opt}`;
    }
  };

  const renderGameStreakOpt = () => {
    if (type === 1) return t(`${content}`);
    if (type === 2) {
      const split = content.split("|");
      return t(`${split[1]}`);
    }
  };

  const getRules = (option) => {
    switch (game_method.odds_type) {
      case oddsTypeEnum.EACH_OPT_DIFF_ODDS:
        const currentOdds = game_method.method.find((odd) => odd.key === option);
        return {
          odds: currentOdds.odds,
          highest_single_bet: currentOdds.highest_single_bet,
          lowest_single_bet: currentOdds.lowest_single_bet,
          max_payout: currentOdds.max_payout,
        };
      case oddsTypeEnum.ALL_OPT_SAME_ODDS:
        return {
          odds: game_method.odds,
          highest_single_bet: game_method.method[0].highest_single_bet,
          lowest_single_bet: game_method.method[0].lowest_single_bet,
          max_payout: game_method.method[0].max_payout,
        };
      case oddsTypeEnum.DYNAMIC_ODDS: {
        const currentOdds = game_method.method[0].odds.find((odd) => odd.key === option);
        return {
          odds: currentOdds.odds,
          highest_single_bet: currentOdds.highest_single_bet,
          lowest_single_bet: currentOdds.lowest_single_bet,
          max_payout: currentOdds.max_payout,
        };
      }

      default:
        return 0;
    }
  };

  const onHandleItemClick = (
    period,
    option,
    game_type,
    game_code,
    position,
    game_category_type,
    game_category_detail_type,
    game_way_type,
    game_way_detail_type,
  ) => {
    if (isDrawing === true && localIsDrawing === true) return;
    const rules = getRules(option);
    let content = "";
    switch (type) {
      case 1:
        content = option;
        break;
      case 2:
        content = `${position}|${option}`;
        break;
      default:
        break;
    }
    setRequestItems([
      {
        game_category_type: game_category_type,
        game_category_detail_type: game_category_detail_type,
        game_type: game_type,
        game_way_type: game_way_type,
        game_way_detail_type: game_way_detail_type,
        period: period,

        content: content,
        contentText: option,
        methodText: t(`gameWay.items.${game_way_type}.${position ? position : game_code}`),
        method: game_code,
        simpleMoney: betAmount,

        money: betAmount,
        position: position,

        odds: rules.odds,
        max_payout: rules.max_payout,
        highest_single_bet: rules.highest_single_bet,
        lowest_single_bet: rules.lowest_single_bet,
        option: option,
      },
    ]);
  };

  const renderOption = (
    option,
    game_code,
    game_type,
    position,
    game_way_type,
    game_way_detail_type,
  ) => {
    const rules = getRules(option);
    const selected = requestItems.find((item) => {
      return (
        item.method === game_code &&
        item.position === position &&
        item.option === option &&
        item.game_type === game_type &&
        item.game_way_type === game_way_type &&
        item.game_way_detail_type === game_way_detail_type
      );
    });

    return (
      <div
        onClick={() =>
          onHandleItemClick(
            current_period.period,
            option,
            game_type,
            game_code,
            position,
            game_category_type,
            game_category_detail_type,
            game_way_type,
            game_way_detail_type,
          )
        }
        className={`${styles.option} ${isDrawing && localIsDrawing ? styles.drawing : ""} ${
          selected && localIsDrawing === false ? styles.selected : ""
        } `}
      >
        <div className={styles.optTitle}>{t(option)}</div>
        <div className={styles.odds}>{rules.odds}</div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.descContainer}>
        <Image
          src={`${CDN_URL}/imgs/${PRODUCTION_NAME_DEV}/game/77lottery/${
            nowS3LangFolders.includes(nowLang) ? nowLang : "en"
          }/${game_type}.png`}
          height={42}
          width={42}
          fit={"contain"}
        />
        <div className={styles.contentContainer}>
          <div className={styles.title}>{t(`gameTitle.${game_type}`)}</div>
          <GameResult
            current_period={current_period}
            timezone={timezone}
            title={t(`gameTitle.${game_type}`)}
            localIsDrawing={localIsDrawing}
            setLocalIsDrawing={setLocalIsDrawing}
          />
          <div className={styles.resultContainer}>
            <div className={styles.resultGameType}>{renderGameType()}</div>
            <div className={styles.resultStreakOpt}>{renderGameStreakOpt()}</div>
            <div className={styles.streakCount}>{`${count}${t("game.periodNumber")}`}</div>
          </div>
          <div className={styles.period}>{t("longdragon.periodFrom", { period: period })}</div>
        </div>
      </div>
      <div className={styles.oddsContainer}>
        {/* {options.map((option) => {
          return renderOption(
            option,
            game_code,
            game_type,
            position,
            game_way_type,
            game_way_detail_type
          );
        })} */}
      </div>
    </div>
  );
};

export default LongDragonItem;
