import { useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetUserInfo, apiGetUserWalletCacheData, apiGetUserWalletData } from "./service";
export const useGetUserInfo = (options) => {
  return useQuery(
    [queryKey.USER_INFO],
    () =>
      apiGetUserInfo().then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};

export const useGetUserWallet = (options) => {
  return useQuery(
    [queryKey.USER_WALLET],
    () =>
      apiGetUserWalletData().then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};

export const useGetUserWalletCache = (options) => {
  return useQuery(
    [queryKey.USER_WALLET_CACHE],
    () => apiGetUserWalletCacheData().then((res) => res.data.data),
    {
      ...options,
    },
  );
};
