import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetEachGameResult, apiGetGameList, getLatestNumber } from "./service";

export const useGetEachGameResult = (options, params, period) => {
  return useInfiniteQuery(
    [queryKey.EACH_GAME_RESULT, params?.game_type, period],
    ({ pageParam = 1 }) =>
      apiGetEachGameResult({ ...params, page: pageParam }).then((res) => {
        return res.data?.data;
      }),
    {
      ...options,
      // keepPreviousData: false,
      cacheTime: 0,
      // refetchPage: (_, index) => index === 0
    },
  );
};

export const useGetRecentGameResult = (options, params, period) => {
  return useQuery(
    [queryKey.RECENT_GAME_RESULT, params?.game_type, period],
    () =>
      apiGetEachGameResult(params).then((res) => {
        return res.data?.data?.data?.slice(0, 10);
      }),
    {
      ...options,
    },
  );
};

export const useGetLatestNumber = ({ params, options }) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });
  const _arrParams = Object.keys(params).map((key) => params[key]);
  return useQuery(
    [queryKey.LATEST_NUMBER, ..._arrParams],
    () =>
      getLatestNumber({ params }).then((res) => {
        const current_time = res?.data?.result?.now;
        const _result = res.data.data.map((_data) => {
          return {
            ..._data,
            current_time: current_time,
          };
        });
        return _result;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameListType = (options) => {
  return useQuery(
    [queryKey.GAME_LIST_TYPE_DICT],
    () =>
      apiGetGameList().then((res) => {
        const _result = res.data.data;
        let typeDict = {};
        _result?.map((gameGroup) => {
          gameGroup?.game_list?.map((game) => {
            typeDict[game.game_type] = gameGroup.game_category_detail_type;
          });
        });
        return typeDict;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameMainCategory = (options) => {
  return useQuery(
    [queryKey.GAME_MAIN_CATEGORY],
    () =>
      apiGetGameList().then((res) => {
        const _result = res.data.data;
        let mainCategory = [];
        _result?.map((gameGroup) => {
          mainCategory.push(gameGroup?.game_category_detail_type);
        });
        return mainCategory;
      }),
    {
      ...options,
    },
  );
};
