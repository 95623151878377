import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

const VNEveryColor = ({ positions }) => {
  const { t } = useTranslation();
  const COLOR = {
    7: {
      color1: "#C3B9FF",
      color2: "#7303CB",
    },
    6: {
      color1: "#FFB9F0",
      color2: "#CB0357",
    },
  };

  const renderNumbers = (position) => {
    if (!positions) return null;
    return (
      <div className={styles.numberContainer}>
        {t(`vn.position.${positions[position].position}`)}
        {positions[position].numbers.split("").map((num, index) => {
          return (
            <div
              key={`${num}_${index}`}
              className={styles.num}
              style={{
                background: `linear-gradient(180deg, ${COLOR[position].color1} 0%, ${COLOR[position].color2} 100%)`,
              }}
            >
              {num}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.posItem}>{renderNumbers(7)}</div>
      <div className={styles.posItem}>{renderNumbers(6)}</div>
    </div>
  );
};

export default VNEveryColor;
