import { useTranslation } from "react-i18next";
import styles from "../style.module.scss";
import { Grid } from "antd-mobile";

const RightGroup = ({ title, type, closeURL, goBack }) => {
  const { t } = useTranslation();

  if (closeURL) {
    return (
      <Grid.Item className={styles.rightGroup}>
        <button>close</button>
      </Grid.Item>
    );
  }

  return (
    <Grid.Item span={4}>
      <span>{title ? t(title) : ""}</span>
    </Grid.Item>
  );
};

export default RightGroup;
