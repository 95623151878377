import Circle from "./Circle";
import CircleType2 from "./CircleType2";
import NumberSelector from "./NumberSelector";
import NumberSelector1 from "./NumberSelectorLOTEC1";
import NumberSelector10 from "./NumberSelectorLOTEC10";
import NumberSelector3 from "./NumberSelectorLOTEC3";
import NumberSelectorLOTVNNEC1 from "./NumberSelectorLOTVNNEC1";
import NumberSelectorLOTVNNEC10 from "./NumberSelectorLOTVNNEC10";
import NumberSelectorLOTVNNEC120 from "./NumberSelectorLOTVNNEC120";
import NumberSelectorLOTVNNEC3 from "./NumberSelectorLOTVNNEC3";
import NumberSelectorLOTVNNEC45 from "./NumberSelectorLOTVNNEC45";
import NumberSelectorLOTVNNEC5 from "./NumberSelectorLOTVNNEC5";
import NumberSelectorLOTVNNEC75 from "./NumberSelectorLOTVNNEC75";
import NumberSelectorPK10 from "./NumberSelectorPK10";
import Rectangle from "./Rectangle";
import RectangleType2 from "./RectangleType2";
import RectangleWithNums from "./RectangleWithNums";
import RectangleWithNumsType2 from "./RectangleWithNumsType2";
import TwoSameSelector from "./TwoSameSelector";
const selector = {
  circle: Circle,
  circleType2: CircleType2,
  rectangle: Rectangle,
  rectangleType2: RectangleType2,
  rectangleWithNums: RectangleWithNums,
  rectangleWithNumsType2: RectangleWithNumsType2,
  twoSame: TwoSameSelector,
  number: NumberSelector,
  numberLOTEC1: NumberSelector1,
  numberLOTEC3: NumberSelector3,
  numberLOTEC10: NumberSelector10,
  numberPK10: NumberSelectorPK10,
  numberLOTVNNEC10: NumberSelectorLOTVNNEC10,
  numberLOTVNNEC5: NumberSelectorLOTVNNEC5,
  numberLOTVNNEC3: NumberSelectorLOTVNNEC3,
  numberLOTVNNEC1: NumberSelectorLOTVNNEC1,
  numberLOTVNNEC45: NumberSelectorLOTVNNEC45,
  numberLOTVNNEC75: NumberSelectorLOTVNNEC75,
  numberLOTVNNEC120: NumberSelectorLOTVNNEC120,
};
export default selector;
