import { Image } from "antd-mobile";
import styles from "./style.module.scss";

const Username = ({ username = "" }) => {
  return (
    <div className={styles.usernameContainer}>
      <Image src={"/images/home/profile_placeholder.svg"} width={20} />
      <span className={styles.username}>{username}</span>
    </div>
  );
};

export default Username;
