import React from "react";

function DeleteIcon({ size = 27, color = "red" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path id="tlp5u70l9a" d="M0 0h54v54H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="u5vd2ve4lb" fill="#fff">
          <use xlinkHref="#tlp5u70l9a" />
        </mask>
        <g mask="url(#u5vd2ve4lb)" fill={color} fillRule="nonzero">
          <path d="M18.407 2.405c.522-.372 1.186-.403 1.806-.405 4.351.02 8.703-.007 13.054.014 1.207-.042 2.276.95 2.353 2.148.072 1.54.005 3.08.026 4.621 2.933.002 5.868-.005 8.8 0 1.17-.06 2.414.791 2.407 2.048l.147-.693v1.792l-.002-.689c-.121.945-.76 1.827-1.685 2.111-.673.117-1.362.063-2.043.073-11.652-.01-23.304.004-34.954-.007-1.151.065-2.062-.936-2.316-1.984v-.433c.1-1.208 1.162-2.223 2.378-2.211 2.992-.021 5.985 0 8.976-.007.005-1.383-.01-2.765-.002-4.148-.014-.845.3-1.774 1.055-2.23m3.588 4.192v2.184c3.002.002 6.008.002 9.01 0V6.597c-3.002-.002-6.008-.002-9.01 0zM8.264 18.164c-.098-1.306 1.028-2.656 2.395-2.505 1.204-.086 2.306 1.06 2.248 2.246.012 8.469-.01 16.937.007 25.405.051 2.198 2.057 4.123 4.254 4.095 6.22.004 12.44.004 18.66 0 2.054.028 3.878-1.625 4.237-3.615.058-8.236.012-16.471.023-24.707.035-.84-.17-1.806.436-2.502.582-.915 1.808-1.117 2.781-.787.907.394 1.477 1.38 1.43 2.36-.002 7.528 0 15.059-.002 22.589-.014 1.387.089 2.79-.21 4.16-.526 2.37-2.003 4.552-4.109 5.795A9.28 9.28 0 0 1 35.583 52c-6.131-.005-12.26.002-18.392-.005a9.135 9.135 0 0 1-5.57-1.967c-2.082-1.669-3.329-4.304-3.354-6.967-.003-8.298 0-16.599-.003-24.897z" />
          <path d="M21.298 20.266c1.368-.468 2.942.65 2.95 2.095.03 5.272.006 10.547.011 15.821.077 1.213-.909 2.458-2.164 2.458-1.34.159-2.565-1.136-2.428-2.455.003-5.21 0-10.42 0-15.629-.07-1.024.643-2.02 1.631-2.29zM30.64 20.196c1.283-.254 2.658.777 2.683 2.102.021 5.293 0 10.586.01 15.88.14 1.317-1.081 2.618-2.423 2.462-1.258.005-2.248-1.245-2.169-2.46-.002-5.196-.005-10.391 0-15.589-.058-1.129.771-2.214 1.899-2.395z" />
        </g>
      </g>
    </svg>
  );
}

export default DeleteIcon;
