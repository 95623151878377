import {
  MONEY_REQUIRED,
  SELECTED_NON_NULL,
  SELECTED_COUNT_LIMIT,
  MAX_MIN_BET,
  NOT_ENOUGH_MONEY,
  MAX_PAYOUT,
  TWO_SAME_SELECT,
} from "../../valudateFunctionEnum";
import rule from "../../ruleDescription/LOTFT/LOTFT10RuleEnum";
function fraction(n) {
  let result = 1;
  while (n > 0) {
    result *= n;
    n -= 1;
  }
  return result;
}

function selectedToSingleContent(selected) {
  const newRequest = [];
  Object.keys(selected)?.forEach((key) => {
    newRequest.push(selected[key]);
  });
  return newRequest?.join(",") ?? "";
}
function selectedCount(selected) {
  let newRequest = [];
  Object.keys(selected)?.forEach((key) => {
    newRequest = newRequest.concat(selected[key]);
  });
  return newRequest.length ?? 1;
}
const LOTFT10SettingEnum = {
  LOTFT10HZ: {
    name: "和值",
    code: "LOTFT10HZ",
    ruleDescription: rule.LOTFT10HZ,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
  },
  LOTFT10ETH: {
    name: "二同号",
    code: "LOTMS5TMSX",
    ruleDescription: rule.LOTFT10ETH,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
      { game: "LOTFT10ETHETD", function: TWO_SAME_SELECT },
    ],
    haveTags: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      const twoSameKeys = [
        "ETH,11",
        "ETH,22",
        "ETH,33",
        "ETH,44",
        "ETH,55",
        "ETH,66",
      ];
      const twoDifferentKeys = [
        "BTH,1",
        "BTH,2",
        "BTH,3",
        "BTH,4",
        "BTH,5",
        "BTH,6",
      ];
      const newSelect = [];
      switch (currentGameWayDetail) {
        case "LOTFT10ETHETD":
          const twoSameSelected =
            selected?.ETD?.filter((e) => twoSameKeys.indexOf(e) >= 0) ?? [];
          const twoDifferentSelected =
            selected?.ETD?.filter((e) => twoDifferentKeys.indexOf(e) >= 0) ??
            [];
          const contentHead = twoSameSelected
            .map((s) => s.split(",")[1][0])
            .join(",");
          const contentFoot = twoDifferentSelected
            .map((s) => s.split(",")[1])
            .join(",");
          const content = `${contentHead}|${contentFoot}`;

          newSelect.push({
            methodText: `gameWay.items.${currentGameWayDetail}`,
            method: "ETD",
            content: content,
            simpleMoney: currentBetNumber,
            count: twoSameSelected.length * twoDifferentSelected.length,
          });
          break;
        case "LOTFT10ETHETF":
          newSelect.push({
            methodText: `gameWay.items.${currentGameWayDetail}`,
            method: "ETF",
            content: selected?.ETF?.map((e) => e[0]).join(","),
            simpleMoney: currentBetNumber,
            count: selected?.ETF?.length ?? 0,
          });
          break;
        default:
          break;
      }

      return newSelect;
    },
  },
  LOTFT10EBTH: {
    name: "二不同号",
    code: "LOTFT10EBTH",
    ruleDescription: rule.LOTFT10EBTH,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      SELECTED_COUNT_LIMIT(2),
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    haveTags: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      const twoSameKeys = ["DM,1", "DM,2", "DM,3", "DM,4", "DM,5", "DM,6"];
      const twoDifferentKeys = ["TM,1", "TM,2", "TM,3", "TM,4", "TM,5", "TM,6"];
      const newSelect = [];
      switch (currentGameWayDetail) {
        case "LOTFT10EBTHBZXH":
          newSelect.push({
            methodText: `gameWay.items.${currentGameWayDetail}`,
            method: "BZXH",
            content: selected?.BZXH?.map((e) => e[0]).join(","),
            simpleMoney: currentBetNumber,
            count:
              fraction(selected?.BZXH?.length) /
              (fraction(selected?.BZXH?.length - 2) * 2) ?? 0,
          });
          break;

        case "LOTFT10EBTHDTXH":
          const twoSameSelected =
            selected?.DTXH?.filter((e) => twoSameKeys.indexOf(e) >= 0) ?? [];
          const twoDifferentSelected =
            selected?.DTXH?.filter((e) => twoDifferentKeys.indexOf(e) >= 0) ??
            [];
          const contentHead = twoSameSelected
            .map((s) => s.split(",")[1])
            .join(",");
          const contentFoot = twoDifferentSelected
            .map((s) => s.split(",")[1])
            .join(",");
          const content = `${contentHead}|${contentFoot}`;

          newSelect.push({
            methodText: `gameWay.items.${currentGameWayDetail}`,
            method: "DTXH",
            content: content,
            simpleMoney: currentBetNumber,
            count: twoSameSelected.length * twoDifferentSelected.length,
          });
          break;

        default:
          break;
      }

      return newSelect;
    },
  },
  LOTFT10SLH: {
    name: "三连号",
    code: "LOTFT10SLH",
    ruleDescription: rule.LOTFT10SLH,
    selectedProcess: ({ selected, currentBetNumber, currentGameWay }) => [
      {
        methodText: `gameWay.items.${currentGameWay}`,
        method: "SLH",
        content: selectedToSingleContent(selected),
        simpleMoney: currentBetNumber,
        count: 1,
      },
    ],
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
      SELECTED_COUNT_LIMIT(1),
    ],
  },
  LOTFT10STH: {
    name: "三同号",
    code: "LOTFT10STH",
    ruleDescription: rule.LOTFT10STH,
    haveTags: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      let _selected = JSON.parse(JSON.stringify(selected));
      switch (currentGameWayDetail) {
        case "LOTFT10STHSTD":
          if (_selected?.STD) {
            _selected.STD = _selected?.STD.map((slh) => slh.substring(0, 1));
          }
          return [
            {
              methodText: `gameWay.items.${currentGameWayDetail}`,
              method: "STD",
              content: selectedToSingleContent(_selected),
              simpleMoney: currentBetNumber,
              count: selectedCount(selected),
            },
          ];

        case "LOTFT10STHSTT":
          if (_selected?.STT) {
            _selected.STT = _selected?.STT.map((stt) => stt.substring(0, 1));
          }
          return [
            {
              methodText: `gameWay.items.${currentGameWayDetail}`,
              method: "STT",
              content: selectedToSingleContent(_selected),
              simpleMoney: currentBetNumber,
              count: 1,
            },
          ];

        default:
          break;
      }
    },
    confirmValidation: [
      MONEY_REQUIRED,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
      SELECTED_COUNT_LIMIT(1),
    ],
  },
  LOTFT10SBTH: {
    name: "三不同号",
    code: "LOTFT10SBTH",
    ruleDescription: rule.LOTFT10SBTH,
    selectedProcess: ({ selected, currentBetNumber, currentGameWay }) => {
      const selectCountNum = selectedCount(selected);

      return [
        {
          methodText: `gameWay.items.${currentGameWay}`,
          method: "SBTH",
          content: selectedToSingleContent(selected),
          simpleMoney: currentBetNumber,
          count:
            fraction(selectCountNum) /
            (fraction(selectCountNum - 3) * fraction(3)),
        },
      ];
    },

    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_COUNT_LIMIT(3),
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
  },
};

export default LOTFT10SettingEnum;
