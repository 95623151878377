import React, { useState } from "react";
import { Input } from "antd-mobile";
import { DeleteOutline } from "antd-mobile-icons";
import { useLottery } from "../..";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../components/CustomButton";
import styles from "./style.module.scss";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { getCurrency } from "../../../../plugins/utils";
import { useGetUserWallet } from "../../../../hooks/user/user.hook";
import "./style.scss";
const CURRENCY = getCurrency();
const RequestFooter = ({
  resetSelected = () => {},
  handleSetBetNumber = () => {},
  currentBetNumber = 2,
}) => {
  const { t } = useTranslation();
  // const lottery = useLottery();
  const getUserWallet = useGetUserWallet({});
  // const [betValue, setBetValue] = useState(lottery?.currentBetNumber);

  return (
    <div className={styles.requestFooter}>
      <div className={styles.clearGroup}>
        <DeleteOutline
          fontSize={27}
          color="var(--color-main-active)"
          onClick={() => {
            resetSelected();
          }}
        ></DeleteOutline>
      </div>

      <div className={styles.walletGroup}>
        <div className={styles.walletContent}>
          <div> {t("normal.balance")}</div>
          <div style={{ color: "var(--color-bet-panel-red" }}>
            {moneyFormat(getUserWallet.data?.money)} {CURRENCY.toUpperCase()}{" "}
          </div>
        </div>
      </div>
      <div className={styles.inputGroup}>
        <div style={{ width: 50, marginRight: 10 }}>
          {t("normal.singleBet")}
        </div>
        <Input
          placeholder="请输入"
          value={currentBetNumber}
          className="requestMoneyInput"
          style={{
            borderRadius: 4,
            border: "solid 2px var(--color-item-disable)",
            height: 35,
          }}
          onChange={(e) => {
            handleSetBetNumber(e);
          }}
          type="number"
        ></Input>
        <div style={{ width: 25, marginLeft: 10 }}>{t("normal.yuan")}</div>
      </div>
      <div className={styles.betGroup}>
        <CustomButton
          onClick={() => {
            // lottery.betConfirm(lottery.currentBetNumber);
          }}
        >
          {t("normal.bet")}
        </CustomButton>
      </div>
    </div>
  );
};

export default RequestFooter;
