import { Button, Card, Image, List, Modal } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CustomDrawer, CustomNavbar, CustomPopup } from "../../../../components";
import { useLoading } from "../../../../context/LoadingContext";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { getCurrency } from "../../../../plugins/utils";
import { useDeleteBet, useGetBetDetail } from "../../hooks/betHistory.hooks";

import renderChoiceNumber from "../../utils/renderChoiceNumber";
import styles from "./style.module.scss";
import "./style.scss";

const CURRENCY = getCurrency().toUpperCase();

function DetailPopup({ visible, onClose, detailId, statusDict }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { setIsLoading } = useLoading();
  const getBetDetail = useGetBetDetail({
    params: { game_bet_id: detailId },
    options: {
      enabled: visible === true && detailId !== undefined,
    },
  });
  const { mutate: deleteBet } = useDeleteBet();

  const renderWinAmount = (data) => {
    switch (data.status) {
      //待开奖
      case 0:
        return "-";
      case 1:
        return (
          <span style={{ color: "var(--color-main-active)" }}>
            {moneyFormat(getBetDetail.data.total_win_lose)} {CURRENCY}
          </span>
        );
      //退單
      case 5:
        return `${moneyFormat(0)} ${CURRENCY}`;
      default:
        return `${moneyFormat(getBetDetail.data.total_win_lose)} ${CURRENCY}`;
    }
  };

  const renderWinLoss = (data) => {
    //退單
    if (data.status === 5) return `${moneyFormat(0)} ${CURRENCY}`;
    if (!data.earn_point) return "-";

    return (
      <span
        style={{
          color: getBetDetail.data.earn_point < 0 ? "var(--color-black)" : "",
        }}
      >
        {getBetDetail.data.earn_point > 0 ? "+" : ""}
        {moneyFormat(getBetDetail.data.earn_point)} {CURRENCY}
      </span>
    );
  };

  const renderGameResult = (data) => {
    if (!data.lottery_number) return "-";
    if (data.game_category_detail_type === "LOTMS") {
      const result = `${data.lottery_number.period_result_number.join(",")},${
        data.lottery_number.special_result_number
      }`;
      return result;
    }
    return `${data.lottery_number.period_result_number.join(",")}`;
  };

  const renderRightIcon = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Image
        src={"/images/header/reload@3x.webp"}
        height={34}
        fit={"contain"}
        onClick={() => {
          getBetDetail.refetch();
        }}
      />
    </div>
  );

  useEffect(() => {
    setIsLoading(getBetDetail.isFetching || getBetDetail.isLoading);
  }, [getBetDetail.isFetching, getBetDetail.isLoading]);

  return (
    <CustomDrawer visible={visible} onClose={onClose}>
      <CustomNavbar onBack={onClose} right={renderRightIcon}>
        <span style={{ fontSize: "var(--fontSetting-middle)" }}>
          {t("betHistory.detail.title")}
        </span>
      </CustomNavbar>
      <div className={`${styles.detailPopup} detail-popup`}>
        {!!getBetDetail.data ? (
          <>
            <Card
              title={<div>{t(`lottery.gameType.${getBetDetail.data.game_type}`)}</div>}
              extra={
                <div
                  style={{
                    fontWeight: "normal",
                    color: "#5f2300",
                    fontSize: "14px",
                  }}
                >
                  {t("history.periodNumber", {
                    period: getBetDetail.data.period,
                  })}
                </div>
              }
              style={{
                borderRadius: "8px",
                marginBottom: "8px",
              }}
            >
              <List.Item
                extra={`【${t(`gameWay.${getBetDetail.data.game_way_type}`)} ${
                  getBetDetail.data.game_way_detail_type
                    ? `-${t(`gameWay.${getBetDetail.data.game_way_detail_type}`)}`
                    : ""
                }】${renderChoiceNumber(getBetDetail.data.choice_number, t)}`}
              >
                {t("history.betOption")}
              </List.Item>
              <List.Item extra={moneyFormat(getBetDetail.data.total_bet_point) + " " + CURRENCY}>
                {t("history.betAmount")}
              </List.Item>
              <List.Item extra={getBetDetail.data.trans_id}>{t("history.transId")}</List.Item>
              <List.Item extra={getBetDetail.data.trans_time}>{t("history.betTime")}</List.Item>
              <List.Item extra={getBetDetail.data.odds}>{t("history.odds")}</List.Item>
              <List.Item extra={renderGameResult(getBetDetail.data)}>
                {t("page.gameResult")}
              </List.Item>
              {/* 狀態 0 待开奖 1已中奖 2 未中奖 3結算中 4已撤单 */}
              <List.Item
                extra={
                  <span
                    style={{
                      color: statusDict[getBetDetail.data.status]?.color,
                    }}
                  >
                    {statusDict[getBetDetail.data.status]?.text
                      ? t(statusDict[getBetDetail.data.status]?.text)
                      : "-"}
                  </span>
                }
              >
                {t("normal.status")}
              </List.Item>
              <List.Item extra={renderWinAmount(getBetDetail.data)}>
                {t("history.winAmount")}
              </List.Item>
              <List.Item
                extra={
                  <span
                    style={
                      getBetDetail.data.status !== 0
                        ? { color: statusDict[getBetDetail.data.status]?.color }
                        : {}
                    }
                  >
                    {renderWinLoss(getBetDetail.data)}
                  </span>
                }
              >
                {t("history.profitLoss")}
              </List.Item>
            </Card>
            <div className={styles.btnArea}>
              {/* {getBetDetail.data.status === 0 ? (
                <Button
                  fill="solid"
                  size="large"
                  style={{
                    color: "var(--color-white)",
                    width: "180px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    backgroundImage:
                      "linear-gradient(to bottom, var(--color-button-secondary-1), var(--color-button-secondary-2) 51%, var(--color-button-secondary-3))",
                  }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("history.detail.cancelBet")}
                </Button>
              ) : (
                ""
              )} */}
              <Button
                fill="solid"
                size="large"
                style={{
                  color: "var(--color-white)",
                  width: "180px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  backgroundImage:
                    "linear-gradient(to bottom, var(--color-button-main-1), var(--color-button-main-2) 51%, var(--color-button-main-3))",
                }}
                onClick={() => {
                  navigate(`/lottery/${getBetDetail.data.game_type}`);
                }}
              >
                {t("history.detail.moreBet")}
              </Button>
            </div>
          </>
        ) : (
          ""
        )}
        <CustomPopup
          title={t("popup.periodEnd.title")}
          leftButtonText={t("normal.cancel")}
          rightButtonText={t("normal.confirm")}
          visible={showModal}
          // singleButton={true}
          onClose={() => {
            setShowModal(false);
          }}
          // onSecondaryButtonPress={() => {}}
          onMainButtonPress={() => {
            deleteBet({
              params: { trans_id: getBetDetail.data.trans_id },
              onSuccess: () => {},
              onError: (error) => Modal.alert(error),
            });
          }}
        >
          <div>{t("modal.confirm.cancelBet")}</div>
        </CustomPopup>
      </div>
    </CustomDrawer>
  );
}

export default DetailPopup;
