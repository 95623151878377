import {
  FourStarsFunction,
  OptionalFunction,
  PackageSelectionFunction,
  ParlayFunction,
  ThreeStarsFunction,
  TwoStarsFunction,
} from "./VNNEC75Function";

export const PACKAGE = ({ selected, currentGameWayDetail }) => {
  return PackageSelectionFunction[currentGameWayDetail].validate({ selected });
};

export const PARLAY = ({ selected, currentGameWayDetail }) => {
  return ParlayFunction[currentGameWayDetail].validate({ selected });
};

export const TWO_STAR = ({ selected, currentGameWayDetail }) => {
  return TwoStarsFunction[currentGameWayDetail].validate({ selected });
};

export const THREE_STAR = ({ selected, currentGameWayDetail }) => {
  return ThreeStarsFunction[currentGameWayDetail].validate({ selected });
};

export const FOUR_STAR = ({ selected, currentGameWayDetail }) => {
  return FourStarsFunction[currentGameWayDetail].validate({ selected });
};

export const OPTIONAL = ({ selected, currentGameWayDetail }) => {
  return OptionalFunction[currentGameWayDetail].validate({ selected });
};
