import { SyncOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLottery } from "../..";
import CustomButton from "../../../../components/CustomButton";
import { useWebsocket } from "../../../../context/WebsocketContext";
import { useGetUserWallet, useGetUserWalletCache } from "../../../../hooks/user/user.hook";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { getCurrency } from "../../../../plugins/utils";
import DeleteIcon from "../../icon/DeleteIcon";
import styles from "./style.module.scss";
import "./style.scss";
const CURRENCY = getCurrency();
const RequestFooter = ({ latestNumber }) => {
  const { t } = useTranslation();
  const [localWallet, setLocalWallet] = useState(0);
  const { isWaitPeriod, disableBet, currentPeriod } = useWebsocket();
  const lottery = useLottery();
  const { data: wallet, isFetching } = useGetUserWallet({});
  const {
    data: cachewallet,
    isFetching: isFetchingCache,
    refetch: refetchCache,
  } = useGetUserWalletCache({
    enabled: false,
  });
  // const [betValue, setBetValue] = useState(lottery?.currentBetNumber);
  const moneyStyle = {
    color: "var(--color-bet-panel-red)",
    fontWeight: 600,
    lineHeight: 1,
  };

  const handleReloadBalance = () => {
    refetchCache();
  };

  useEffect(() => {
    if (wallet) {
      setLocalWallet(wallet.money);
    }
  }, [wallet]);

  useEffect(() => {
    if (cachewallet) {
      setLocalWallet(cachewallet.money);
    }
  }, [cachewallet]);

  return (
    <div className={styles.requestFooter}>
      <div className={styles.leftGroup}>
        <div
          onClick={() => {
            // lottery.setCurrentBetNumber("");
            lottery.setSelected({});
          }}
          className={styles.clearGroup}
        >
          <DeleteIcon color="var(--color-main-active)" />
        </div>
      </div>
      <div className={styles.rightGroup}>
        <div className={styles.walletGroup}>
          <div style={{ lineHeight: "normal" }}> {t("normal.balance")}</div>
          <div className={styles.moneyContainer}>
            <div
              className={styles.money}
              style={{ ...moneyStyle, fontSize: "var(--fontSetting-middle)" }}
            >
              {t(`currency.symbol.${CURRENCY.toUpperCase()}`)} {moneyFormat(localWallet)}
            </div>
            <div onClick={handleReloadBalance}>
              <SyncOutlined className={styles.icon} spin={isFetching || isFetchingCache} />
            </div>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.singleBetText}>{t("normal.singleBet")}</div>
          <InputNumber
            placeholder={t("normal.input.placeholder")}
            value={lottery.currentBetNumber}
            // value={betValue}
            className="requestMoneyInput"
            style={{
              borderRadius: "2px",
              border: "solid 1px var(--color-item-disable)",
              height: "auto",
              flex: 1,
            }}
            onChange={(e) => {
              lottery.setCurrentBetNumber(e);
              // setBetValue(e);
            }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
          />
          {/* <div style={{ marginLeft: "2px" }}>{t("normal.yuan")}</div> */}
        </div>
        <div className={styles.betGroup}>
          <CustomButton
            loading={!!currentPeriod?.period && !!latestNumber && !!latestNumber[0].period}
            disabled={isWaitPeriod || disableBet}
            onClick={() => {
              lottery.betConfirm(lottery.currentBetNumber);
            }}
            style={{
              fontSize: "var(--fontSetting-middle)",
              height: "35px",
              fontWeight: 500,
            }}
          >
            {t("normal.bet")}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default RequestFooter;
