import { useTranslation } from "react-i18next";
import LongDragonTimer from "../LongDragonTimer";
import styles from "./style.module.scss";
const GameResult = ({
  current_period = {},
  title,
  localIsDrawing,
  setLocalIsDrawing,
  timezone,
}) => {
  const { t } = useTranslation();

  const renderCurrentPeriod = () => {
    if (current_period) {
      return (
        <>
          <div className={styles.period}>{`${current_period?.period || ""}${t(
            "game.periodNumber",
          )}`}</div>
          <LongDragonTimer
            draw_time={current_period?.draw_time || null}
            timezone={timezone}
            title={title}
            localIsDrawing={localIsDrawing}
            setLocalIsDrawing={setLocalIsDrawing}
          />
        </>
      );
    } else {
      return <div className={styles.period}>{t("lottery.waitTime")}</div>;
    }
  };
  return <div className={styles.container}>{renderCurrentPeriod()}</div>;
};

export default GameResult;
