import { Grid, Popup } from "antd-mobile";
import { CheckOutline } from "antd-mobile-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLottery } from "../..";
import styles from "./style.module.scss";
import "./styles.scss";
const CategoryDropdown = ({
  gameWayData,
  gameWaySetting,
  haveSubCategory,
  haveTags,
  setSubCategoryVisible,
  setHeaderTitle,
}) => {
  const lottery = useLottery();
  const { t } = useTranslation();
  const [init, setInit] = useState(false);
  const [tags, setTags] = useState([]);

  // useEffect(() => {
  //   if (!init && !!gameWayData[0].game_way_list) {
  //     setInit(true);
  //     console.log("setInit", init, gameWayData, !!gameWayData[0].game_way_list);
  //     setSubCategoryVisible(false);
  //     return;
  //   }

  //   if (haveSubCategory) {
  //     setSubCategoryVisible(true);
  //   } else {
  //     setSubCategoryVisible(false);
  //   }
  //   setInit(true);
  // }, [haveSubCategory]);

  useEffect(() => {
    if (haveSubCategory === true && gameWaySetting?.game_way_list) {
      const _tags = new Set();
      gameWaySetting?.game_way_list.forEach((gameWay) => {
        const { tag } = gameWay;
        _tags.add(tag);
      });
      setTags([..._tags]);
    }
    if (haveSubCategory === false) {
      setTags([]);
    }
  }, [haveSubCategory, gameWaySetting]);

  const onChangeCategory = (game_way_type) => {
    if (game_way_type === lottery.currentGameWay) {
      lottery.setSubCategoryVisible(false);
      return;
    }

    lottery.setCurrentGameWay(game_way_type);
    lottery.setCurrentGameWayDetail("");

    const newGameWayData = gameWayData.find((item) => {
      return item.game_way_type === game_way_type;
    });

    if (!!newGameWayData?.game_way_list === false) {
      // setHeaderTitle(t(`gameWay.${game_way_type}`));
      lottery.setSubCategoryVisible(false);
    }
  };

  const onChangeSubCategory = (game_way_detail_type) => {
    lottery.setCurrentGameWayDetail(game_way_detail_type);
    // setHeaderTitle(
    //   `${t(`gameWay.${lottery.currentGameWay}`)} - ${t(
    //     `gameWay.${game_way_detail_type}`
    //   )}`
    // );
    lottery.setSubCategoryVisible(false);
  };

  return (
    <Popup
      className="subcategory-pop"
      position="top"
      visible={lottery.subCategoryVisible}
      onMaskClick={() => {
        lottery.setSubCategoryVisible(false);
      }}
      bodyStyle={{
        top: "42px",
        height: "calc( var(--vh, 1vh) * 100 - 42px )",
        overflowY: "scroll",
      }}
    >
      <div>
        <Grid className={styles.category} columns={3} gap={12}>
          {gameWayData.map((gameWay) => {
            // console.log("gameWay", gameWay);
            return (
              <Grid.Item
                className={`${styles.categoryItem} ${
                  lottery.currentGameWay === gameWay.game_way_type ? styles.selected : ""
                }`}
                onClick={() => onChangeCategory(gameWay.game_way_type)}
              >
                <div>{t(`gameWay.${gameWay.game_way_type}`)}</div>
              </Grid.Item>
            );
          })}
        </Grid>
        {[...tags].map((tag) => {
          return (
            <>
              {haveTags ? (
                <div className={styles.subCategoryTitleContainer}>
                  <div className={styles.subCategoryTitle}>{t(`lottery.tag.${tag}`)}</div>
                  <div className={styles.divider} />
                </div>
              ) : (
                <></>
              )}
              <Grid className={styles.subCategory} columns={3}>
                {gameWaySetting?.game_way_list
                  ?.filter((g) => g.tag === tag)
                  ?.map((gameWay) => {
                    const { name, game_way_detail_type } = gameWay;
                    return (
                      <Grid.Item
                        className={`${styles.categoryItem} ${
                          lottery.currentGameWayDetail === game_way_detail_type ? styles.active : ""
                        }`}
                        onClick={() => onChangeSubCategory(game_way_detail_type)}
                      >
                        <div className={styles.categoryText}>
                          {t(`gameWay.${game_way_detail_type}`)}
                        </div>

                        {lottery.currentGameWayDetail === game_way_detail_type ? (
                          <div className={styles.categoryTriangle}>
                            <CheckOutline
                              color="white"
                              style={{
                                position: "absolute",
                                top: 7,
                                left: -12,
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid.Item>
                    );
                  })}
              </Grid>
            </>
          );
        })}
      </div>
    </Popup>
  );
};

export default CategoryDropdown;
