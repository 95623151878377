import { Button } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import parseUrl from "parse-url";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { CustomNavbar } from "../../components";
import { useLoading } from "../../context/LoadingContext";
import { useGetEachGameResult } from "../../hooks/game/game.hook";
import CardItem from "./components/CardItem";
import "./style.scss";

function GameResultDetail({
  isPopup = false,
  onClosePopup = undefined,
  popupGameType = undefined,
  popupGame = undefined,
}) {
  const { t } = useTranslation();
  const { setIsLoading } = useLoading();
  const gameCode = useParams().game;
  const params = parseUrl(window.location.href).query;
  const navigate = useNavigate();
  const getResult = useGetEachGameResult(
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < lastPage.last_page) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    },
    { game_type: isPopup ? popupGame : gameCode },
    "",
  );

  useEffect(() => {
    setIsLoading(getResult.isLoading);
  }, [getResult.isLoading]);

  return (
    <div
      className="game-result-detail-page"
      style={{
        backgroundImage: "url('/images/layout/background/other_bg.webp')",
      }}
    >
      <CustomNavbar
        onBack={() => {
          if (isPopup) {
            onClosePopup();
            return;
          }
          navigate(-1);
        }}
      >
        {t("page.gameResult.detail", {
          gameName: isPopup
            ? t(`lottery.gameType.${popupGame}`)
            : t(`lottery.gameType.${gameCode}`),
        })}
      </CustomNavbar>
      <div className={`content-container ${isPopup ? "popup" : ""}`}>
        {getResult?.data?.pages?.map((group, index) => {
          return group.data.map((item, index) => {
            return (
              <CardItem
                gameType={isPopup ? popupGameType : params?.type}
                data={item}
                key={"gameResult" + index}
              />
            );
          });
        })}
        <Button
          loading="auto"
          fill="none"
          block
          size="small"
          onClick={async () => {
            await sleep(1000);
            await getResult.fetchNextPage();
          }}
          style={{
            fontSize: "var(--fontSetting-small)",
            marginTop: "16px",
            color: "var(--color-black)",
            opacity: 0.75,
          }}
          disabled={!getResult.hasNextPage}
        >
          {getResult.hasNextPage ? t("gameResult.detail.loadMore") : t("normal.noMore")}
        </Button>
      </div>
      {!isPopup ? (
        <div className="bottom-area">
          <Button
            block
            size="large"
            style={{
              backgroundImage:
                "linear-gradient(to bottom, var(--color-button-main-1), var(--color-button-main-2) 51%, var(--color-button-main-3))",
              color: "var(--color-white)",
              fontSize: "var(--fontSetting-middle)",
              fontWeight: 500,
              borderRadius: 0,
              height: "52px",
              border: "none",
            }}
            onClick={() => {
              navigate(`/lottery/${gameCode}`);
            }}
          >
            {t("gameResult.detail.toBet")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default GameResultDetail;
