import { Button, Popup, Selector } from "antd-mobile";
import { DownFill } from "antd-mobile-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetGameListType } from "../../hooks/betHistory.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const GameFilter = ({ onChange, selectedGame, visible, setVisible }) => {
  const { t } = useTranslation();
  // const [visible, setVisible] = useState(false);
  const [game, setGame] = useState("");
  const [gameTypes, setGameTypes] = useState([]);
  const getGameListType = useGetGameListType({});
  const gameItems = [
    {
      title: t("lottery.gameType.all"),
      key: "",
    },
    {
      title: t("lottery.gameType.LOTFT3"),
      key: "LOTFT3",
    },
    {
      title: t("lottery.gameType.LOTMS5"),
      key: "LOTMS5",
    },
    {
      title: t("lottery.gameType.LOTEC5"),
      key: "LOTEC5",
    },
  ];
  const slectItemStyle = {
    "--border-radius": "4px",
    "--border": "solid var(--color-item-disable) 1px",
    "--checked-border": "solid var(--color-main-active) 1px",
    "--color": "none",
    "--text-color": "var(--color-tab-text)",
    "--checked-text-color": "var(--color-tab-text)",
    "--checked-color": "var(--color-background-active)",
    fontSize: "var(--fontSetting-middle)",
    // margin: "10px",
    padding: "12px",
    // width: "25%",
  };
  useEffect(() => {
    if (!!getGameListType.data) {
      let typeDict = [];
      getGameListType.data?.map((gameGroup) => {
        gameGroup?.game_list?.map((game) => {
          typeDict.push({
            ...game,
            label: t(`gameTitle.${game.game_type}`),
            value: game.game_type,
          });
        });
      });
      setGameTypes([
        {
          label: t("lottery.gameType.all"),
          value: "",
        },
        ...typeDict,
      ]);
    } else {
      setGameTypes([
        {
          label: t("lottery.gameType.all"),
          value: "",
        },
      ]);
    }
  }, [getGameListType.data]);

  return (
    <div className={styles.gameFilterContainer}>
      <Button
        className={styles.buttonContainer}
        fill="outline"
        size="small"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <div className={styles.innerContainer}>
          <div className={styles.title}>
            {selectedGame ? t(`lottery.gameType.${selectedGame}`) : t(`lottery.gameType.all`)}
          </div>
          {visible ? (
            <DownFill fontSize={12} style={{ transform: "rotate(180deg)" }} />
          ) : (
            <DownFill fontSize={12} />
          )}
        </div>
      </Button>
      <Popup
        className="bet-history-game-filter"
        visible={visible}
        onClick={() => {
          setVisible(false);
        }}
        position="top"
        destroyOnClose={true}
        onClose={() => {
          setVisible(false);
        }}
        closeOnMaskClick={true}
      >
        <div
          style={{
            // display: "flex",
            // flexDirection: "row",
            // justifyContent: "flex-start",
            marginTop: "42px",
            // alignContent: "flex-start",
            // flexWrap: "wrap",
          }}
          className={styles.filterGamePopup}
        >
          <Selector
            columns={3}
            style={slectItemStyle}
            showCheckMark={false}
            onChange={onChange}
            options={gameTypes}
            className="gameFilter-selector"
          />
        </div>
      </Popup>
    </div>
  );
};

export default GameFilter;
