import oddsTypeEnum from "../../../enumerations/oddsTypeEnum";
// import { FiveStarsFunctions } from "./EveryColorEnums/FiveStarsFunction";
// import { FourStarsFunction } from "./EveryColorEnums/FourStarsFunction";
// import { FreePositionFunction } from "./EveryColorEnums/FreePositionFunction";
// import EveryColorKeyEnums from "./EveryColorEnums/keyEnums";
// import { OptionalFunction } from "./EveryColorEnums/OptionalFunction";
// import { ThreeStarsFunction } from "./EveryColorEnums/ThreeStarsFunction";
// import { TwoStarsFunction } from "./EveryColorEnums/TwoStarsFunction";

export const NOT_ENOUGH_MONEY = ({ userMoney, requestItems }) => {
  const totalBetAmount = requestItems.reduce((accumulator, value) => {
    return accumulator + (value?.count || 1) * value.simpleMoney;
  }, 0);
  return userMoney >= totalBetAmount || "validate.notEnoughMoney";
};

export const MONEY_REQUIRED = ({ currentBetNumber }) =>
  !!currentBetNumber || "validate.moneyRequired";

export const REQUEST_ITEMS_NON_NULL = ({ requestItems }) =>
  (requestItems !== undefined && requestItems?.length > 0) ||
  "validate.requestItemsNonull";

export const SELECTED_NON_NULL = ({ selected }) =>
  (selected !== undefined &&
    !!Object.keys(selected).find((key) => selected[key]?.length > 0)) ||
  "validate.selectedNonnull";
export const SELECTED_COUNT_LIMIT = (count = 2) => {
  return ({ selected }) =>
    (selected !== undefined &&
      Object.keys(selected).reduce(
        (partialSum, key) => partialSum + selected[key]?.length,
        0
      )) >= count || ["validate.selectedCount", { count }];
};

export const BALL_COUNT_EACH_GROUP = ({ selected }) => {
  const limit = 1;
  let isValid = true;
  Object.keys(selected).forEach((key) => {
    if (selected[key].length < limit) isValid = false;
  });
  return isValid || ["validate.notEnoughBall"];
};

export const NPOS_MNUM = ({ selected, limit_pos, limit_num }) => {
  let isValid = true;
  if (
    selected["position"].length < limit_pos ||
    selected["number"].length < limit_num
  ) {
    isValid = false;
  }

  return isValid || [`validate.nPosmNum`, { limit_pos, limit_num }];
};

export const BALL_COUNT_N_GROUP = ({ selected, n }) => {
  let numberGroupHaveBall = 0;
  Object.keys(selected).forEach((key) => {
    if (selected[key].length > 0) numberGroupHaveBall++;
  });
  numberGroupHaveBall -= n;
  return numberGroupHaveBall >= 0 || [`validate.notEnoughBallOnGroup.${n}`];
};

export const NDOUBLE_MSINGLE = ({ selected, limit_double, limit_single }) => {
  let isValid = true;
  if (
    selected["D"].length < limit_double ||
    selected["S"].length < limit_single
  ) {
    isValid = false;
  }

  return isValid || [`validate.nDoublemSingle`, { limit_double, limit_single }];
};

export const NTRIPLE_MSINGLE = ({ selected, limit_triple, limit_single }) => {
  let isValid = true;
  if (
    selected["TR"].length < limit_triple ||
    selected["S"].length < limit_single
  ) {
    isValid = false;
  }

  return isValid || [`validate.nTriplemSingle`, { limit_triple, limit_single }];
};

export const NTRIPLE_MDOUBLE = ({ selected, limit_triple, limit_double }) => {
  let isValid = true;
  if (
    selected["TR"].length < limit_triple ||
    selected["D"].length < limit_double
  ) {
    isValid = false;
  }

  return isValid || [`validate.nTriplemDouble`, { limit_triple, limit_double }];
};

export const NQUAD_MSINGLE = ({ selected, limit_quad, limit_single }) => {
  let isValid = true;
  if (
    selected["Q"].length < limit_quad ||
    selected["S"].length < limit_single
  ) {
    isValid = false;
  }

  return isValid || [`validate.nQuadmSingle`, { limit_quad, limit_single }];
};

export const DUPLICATE_SELECT = ({ selected }) => {
  let isValid = true;

  const array = Object.keys(selected).map((keys) => {
    return selected[keys];
  });

  for (let i = 0; i < array.length; i++) {
    for (let j = i + 1; j < array.length; j++) {
      const filtered = array[i].filter((item) => {
        return array[j].indexOf(item) !== -1;
      });
      if (filtered.length > 0) {
        isValid = false;
        break;
      }
    }
    if (!isValid) {
      break;
    }
  }
  return isValid || ["validate.duplicateSelection"];
};

export const MAX_PAYOUT = ({
  currentGameItems,
  selected,
  currentSelected,
  requestItems,
}) => {
  const odds_type = currentGameItems.game_method[0].odds_type;
  let isValid = true;
  let errorSelectedOpt = {};
  switch (odds_type) {
    case oddsTypeEnum.EACH_OPT_DIFF_ODDS: {
      requestItems.every((item) => {
        const currentMethodIndex = currentGameItems.game_method.findIndex(
          (method) => {
            return method.code === item.method;
          }
        );
        const split = item.option
          ? item.option.split(",")
          : item.content.split(",");
        const selectedOption = currentGameItems.game_method[
          currentMethodIndex
        ].option.find((opt) => {
          return split.includes(opt.key); //opt.key === item.option;
        });
        const currentMaxPayout = item.simpleMoney * selectedOption.odds;
        if (selectedOption.max_payout <= currentMaxPayout) {
          errorSelectedOpt = {
            content: item.content,
            max: selectedOption.max_payout,
          };
          isValid = false;
          return false;
        }
        return true;
      });
      return (
        isValid || [
          "validate.maxPayoutContent",
          { ...errorSelectedOpt },
          { isI18n: ["content"] },
        ]
      );
    }
    case oddsTypeEnum.ALL_OPT_SAME_ODDS: {
      const selectedOption = currentGameItems.game_method[0].option[0];
      const odds = currentGameItems.game_method[0].odds;
      const item = requestItems[0];
      const currentMaxPayout = item.simpleMoney * odds;
      if (selectedOption.max_payout <= currentMaxPayout) {
        isValid = false;
      }
      return (
        isValid || [
          "validate.maxPayout",
          {
            max: selectedOption.max_payout,
          },
        ]
      );
    }
    case oddsTypeEnum.ALL_OPT_TWO_ODDS: {
      const selectedOption = currentGameItems.game_method[0].option[0];
      const odds = Math.min(
        ...currentGameItems?.game_method[0]?.odds.split(",")
      );

      requestItems.every((item) => {
        const currentMaxPayout = item.simpleMoney * odds;
        if (selectedOption.max_payout <= parseInt(currentMaxPayout)) {
          errorSelectedOpt = {
            content: item.content,
            max: selectedOption.max_payout,
          };
          isValid = false;
          return false;
        }
        return true;
      });
      return (
        isValid || [
          "validate.maxPayoutContent",
          { ...errorSelectedOpt },
          { isI18n: ["content"] },
        ]
      );
    }
    case oddsTypeEnum.DYNAMIC_ODDS: {
      console.log('requestItems', requestItems)
      if (requestItems[0]?.option) {
        const countOptions = requestItems[0].option.split(",").length;
        const selectedOption =
          currentGameItems.game_method[0].option[0].odds.find(
            (odd) => odd.count === countOptions
          );
        const currentMaxPayout =
          requestItems[0].simpleMoney * selectedOption.odds;
        if (selectedOption.max_payout <= currentMaxPayout) {
          isValid = false;
        }
        return (
          isValid || [
            "validate.maxPayout",
            {
              max: selectedOption.max_payout,
            },
          ]
        );
      } else {
        const limit = currentGameItems?.game_method[0]?.option[0]?.odds.reduce(
          (accumulator, value) => {
            return { ...accumulator, [value.key]: value };
          },
          {}
        );
        // requestItems.every((item) => {
        //   const _content = item.content.split("|")[1];
        //   if (
        //     limit[_content].highest_single_bet <= parseInt(item.simpleMoney) ||
        //     limit[_content].lowest_single_bet >= parseInt(item.simpleMoney)
        //   ) {
        //     isValid = false;
        //     errorSelectedOpt = {
        //       content: _content,
        //       max: limit[_content].max_payout,
        //     };
        //     return false;
        //   }
        //   return true;
        // });
        requestItems.every((item) => {
          const _content = item.content.split("|")[1];
          const currentMaxPayout = item.simpleMoney * limit[_content].odds;
          if (limit[_content].max_payout <= parseInt(currentMaxPayout)) {
            isValid = false;
            errorSelectedOpt = {
              content: _content,
              max: limit[_content].max_payout,
            };
            return false;
          }
          return true;
        });
        return (
          isValid || [
            "validate.maxPayoutContent",
            { ...errorSelectedOpt },
            { isI18n: ["content"] },
          ]
        );
      }
    }
    case oddsTypeEnum.ALL_OPT_FOUR_ODDS: {
      const selectedOption = currentGameItems.game_method[0].option[0];
      const odds = Math.min(
        ...currentGameItems?.game_method[0]?.odds.split(",")
      );
      const item = requestItems[0];
      const currentMaxPayout = item.simpleMoney * odds;
      if (selectedOption.max_payout <= currentMaxPayout) {
        isValid = false;
      }
      return (
        isValid || [
          "validate.maxPayout",
          {
            max: selectedOption.max_payout,
          },
        ]
      );
    }
    default:
      break;
  }
};

export const MAX_MIN_BET = ({
  currentGameItems,
  selected,
  currentSelected,
  requestItems,
}) => {
  const odds_type = currentGameItems.game_method[0].odds_type;
  let isValid = true;
  let errorSelectedOpt = {};

  const requestItemsNoInput = requestItems.find((i) => !i.simpleMoney);
  if (requestItemsNoInput) {
    return "validate.simpleMoneyNull";
  }

  switch (odds_type) {
    case oddsTypeEnum.EACH_OPT_DIFF_ODDS: {
      requestItems.every((item) => {
        const currentMethodIndex = currentGameItems.game_method.findIndex(
          (method) => {
            return method.code === item.method;
          }
        );
        const split = item.option
          ? item.option.split(",")
          : item.content.split(",");
        const selectedOption = currentGameItems.game_method[
          currentMethodIndex
        ].option.find((opt) => {
          return split.includes(opt.key); //opt.key === item.option;
        });

        if (
          selectedOption.highest_single_bet < parseInt(item.simpleMoney) ||
          selectedOption.lowest_single_bet > parseInt(item.simpleMoney)
        ) {
          errorSelectedOpt = {
            content: item.content,
            max: selectedOption.highest_single_bet,
            min: selectedOption.lowest_single_bet,
          };
          isValid = false;
          return false;
        }
        return true;
      });
      return (
        isValid || [
          "validate.maxMinBetContent",
          { ...errorSelectedOpt },
          { isI18n: ["content"] },
        ]
      );
    }
    case oddsTypeEnum.ALL_OPT_SAME_ODDS: {
      const selectedOption = currentGameItems.game_method[0].option[0];
      const item = requestItems[0];
      if (
        selectedOption.highest_single_bet < parseInt(item.simpleMoney) ||
        selectedOption.lowest_single_bet > parseInt(item.simpleMoney)
      ) {
        isValid = false;
      }
      return (
        isValid || [
          "validate.maxMinBet",
          {
            min: selectedOption.lowest_single_bet,
            max: selectedOption.highest_single_bet,
          },
        ]
      );
    }
    case oddsTypeEnum.ALL_OPT_TWO_ODDS: {
      const selectedOption = currentGameItems.game_method[0].option[0];
      requestItems.every((item) => {
        if (
          selectedOption.highest_single_bet < parseInt(item.simpleMoney) ||
          selectedOption.lowest_single_bet > parseInt(item.simpleMoney)
        ) {
          errorSelectedOpt = {
            content: item.content,
            max: selectedOption.highest_single_bet,
            min: selectedOption.lowest_single_bet,
          };
          isValid = false;
          return false;
        }
        return true;
      });
      return (
        isValid || [
          "validate.maxMinBetContent",
          { ...errorSelectedOpt },
          { isI18n: ["content"] },
        ]
      );
    }
    case oddsTypeEnum.DYNAMIC_ODDS: {
      if (requestItems[0]?.option) {
        const countOptions = requestItems[0].option.split(",").length;
        const selectedOption =
          currentGameItems.game_method[0].option[0].odds.find(
            (odd) => odd.count === countOptions
          );
        if (
          selectedOption.highest_single_bet <
          parseInt(requestItems[0].simpleMoney) ||
          selectedOption.lowest_single_bet >
          parseInt(requestItems[0].simpleMoney)
        ) {
          isValid = false;
        }
        return (
          isValid || [
            "validate.maxMinBet",
            {
              min: selectedOption.lowest_single_bet,
              max: selectedOption.highest_single_bet,
            },
          ]
        );
      } else {
        const limit = currentGameItems?.game_method[0]?.option[0]?.odds.reduce(
          (accumulator, value) => {
            return { ...accumulator, [value.key]: value };
          },
          {}
        );
        requestItems.every((item) => {
          const _content = item.content.split("|")[1];
          if (
            limit[_content].highest_single_bet < parseInt(item.simpleMoney) ||
            limit[_content].lowest_single_bet > parseInt(item.simpleMoney)
          ) {
            isValid = false;
            errorSelectedOpt = {
              content: _content,
              max: limit[_content].highest_single_bet,
              min: limit[_content].lowest_single_bet,
            };
            return false;
          }
          return true;
        });
        return (
          isValid || [
            "validate.maxMinBetContent",
            { ...errorSelectedOpt },
            { isI18n: ["content"] },
          ]
        );
      }
    }
    case oddsTypeEnum.ALL_OPT_FOUR_ODDS: {
      const selectedOption = currentGameItems.game_method[0].option[0];
      const item = requestItems[0];
      if (
        selectedOption.highest_single_bet < parseInt(item.simpleMoney) ||
        selectedOption.lowest_single_bet > parseInt(item.simpleMoney)
      ) {
        isValid = false;
      }
      return (
        isValid || [
          "validate.maxMinBet",
          {
            min: selectedOption.lowest_single_bet,
            max: selectedOption.highest_single_bet,
          },
        ]
      );
    }
    default:
      break;
  }
};

// export const FIVE_STAR = ({ selected, currentGameWayDetail }) => {
//   return FiveStarsFunctions[currentGameWayDetail].validate({ selected });
// };
// export const FOUR_STAR = ({ selected, currentGameWayDetail }) => {
//   return FourStarsFunction[currentGameWayDetail].validate({ selected });
// };
// export const THREE_STAR = ({ selected, currentGameWayDetail }) => {
//   return ThreeStarsFunction[currentGameWayDetail].validate({ selected });
// };

// export const TWO_STAR = ({ selected, currentGameWayDetail }) => {
//   return TwoStarsFunction[currentGameWayDetail].validate({ selected });
// };

// export const FREE_POS = ({ selected, currentGameWayDetail }) => {
//   return FreePositionFunction[currentGameWayDetail].validate({ selected });
// };

// export const OPTION_SELECT = ({ selected, currentGameWayDetail }) => {
//   return OptionalFunction[currentGameWayDetail].validate({ selected });
// };
export const TWO_SAME_SELECT = ({ selected }) => {
  let isValid = true;
  const selectedGroup = new Set();

  Object.keys(selected).map((keys) => {
    if (typeof selected[keys] === "object" && selected[keys]?.length > 0) {
      selected[keys].forEach((item) => {
        const itemSplit = item?.split(",");
        selectedGroup.add(itemSplit[0]);
      });
    }
  });

  isValid = selectedGroup.size > 1;
  return isValid || ["validate.twoSameSelection"];
};
