import { createContext, useContext, useState } from "react";
import localStorageKey from "../enumerations/localStorageKey";
import useLocalStorage from "./useLocalStorage";
import { apiGetUserWalletData, apiGetUserInfo } from "./service";

const AuthContext = createContext({
  token: "",
  setToken: () => {},
  userInfo: {},
  setUserInfo: () => {},
  updateUserInfo: () => {},
  userWallet: null,
  updateUserWallet: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage(localStorageKey.TOKEN);
  const [userWallet, setUserWallet] = useState();
  const [userInfo, setUserInfo] = useLocalStorage(localStorageKey.USER_INFO);

  const updateUserWallet = async () => {
    const success = await apiGetUserWalletData();
    if (success) {
      setUserWallet(success.data);
    }
  };

  const updateUserInfo = async () => {
    const success = await apiGetUserInfo();
    if (success) {
      console.log(success.data.data);
      setUserInfo(success.data.data);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        setToken,
        token,
        setUserInfo,
        userInfo,
        userWallet,
        updateUserInfo,
        updateUserWallet,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const AuthValue = useContext(AuthContext);
  return AuthValue;
};
