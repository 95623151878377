import {
  FourStarsFunction,
  OptionalFunction,
  PackageSelectionFunction,
  ParlayFunction,
  ThreeStarsFunction,
  TwoStarsFunction,
} from "../../VNNorthEveryColorEnums/VNNEC5Function";
import {
  FOUR_STAR,
  OPTIONAL,
  PACKAGE,
  PARLAY,
  THREE_STAR,
  TWO_STAR,
} from "../../VNNorthEveryColorEnums/VNNEC5Validation";
import LOTVNNEC5RuleEnum from "../../ruleDescription/LOTVNNEC/LOTVNNEC5RuleEnum";
import {
  MAX_MIN_BET,
  MAX_PAYOUT,
  MONEY_REQUIRED,
  NOT_ENOUGH_MONEY,
  SELECTED_NON_NULL,
} from "../../valudateFunctionEnum";

const LOTVNNEC5SettingEnum = {
  LOTVNNEC5BX: {
    name: "包选",
    code: "LOTVNNEC5BX",
    ruleDescription: LOTVNNEC5RuleEnum.LOTVNNEC5BX,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, PACKAGE, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (PackageSelectionFunction[currentGameWayDetail]) {
        newSelect = PackageSelectionFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTVNNEC5CG: {
    name: "串关",
    code: "LOTVNNEC5CG",
    ruleDescription: LOTVNNEC5RuleEnum.LOTVNNEC5CG,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, PARLAY, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (ParlayFunction[currentGameWayDetail]) {
        newSelect = ParlayFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTVNNEC52S: {
    name: "二星",
    code: "LOTVNNEC52S",
    ruleDescription: LOTVNNEC5RuleEnum.LOTVNNEC52S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, TWO_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (TwoStarsFunction[currentGameWayDetail]) {
        newSelect = TwoStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC53S: {
    name: "三星",
    code: "LOTVNNEC53S",
    ruleDescription: LOTVNNEC5RuleEnum.LOTVNNEC53S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, THREE_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (ThreeStarsFunction[currentGameWayDetail]) {
        newSelect = ThreeStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC54S: {
    name: "四星",
    code: "LOTVNNEC54S",
    ruleDescription: LOTVNNEC5RuleEnum.LOTVNNEC54S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, FOUR_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (FourStarsFunction[currentGameWayDetail]) {
        newSelect = FourStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC5DXDS: {
    name: "大小单双",
    code: "LOTVNNEC5DXDS",
    ruleDescription: LOTVNNEC5RuleEnum.LOTVNNEC5DXDS,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWay, currentGameWayDetail }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${
                currentGameWayDetail ? `.${currentGameWayDetail}` : ""
              }${key ? `.${key}` : ""}`,
              method: "LOTVNNECDXDS",
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          }),
        );
      });

      return newSelected;
    },
  },
  LOTVNNEC5ZXBZ: {
    name: "自选不中",
    code: "LOTVNNEC5ZXBZ",
    ruleDescription: LOTVNNEC5RuleEnum.LOTVNNEC5ZXBZ,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, OPTIONAL, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (OptionalFunction[currentGameWayDetail]) {
        newSelect = OptionalFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
};

export default LOTVNNEC5SettingEnum;
