import { DotLoading } from "antd-mobile";
import React from "react";

import styles from "./style.module.scss";

const LoadingView = () => {
  return (
    <div className={styles.container}>
      <DotLoading color="#ff0000" />
    </div>
  );
};

export default LoadingView;
