import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Popover, Popup } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useLottery } from "../..";
import { CustomNavbar } from "../../../../components";
import { useLotterySetting } from "../../../../context/LotterySettingContext";
import CategoryPopupContent from "./components/CategoryPopupContent";
import Hamburger from "./icons/Hamburger";
import Setting from "./icons/Setting";
import styles from "./style.module.scss";
import "./style.scss";

const Navbar = ({
  onHandleBackButtonPress,
  onChangeCategory = () => {},
  headerTitle,
  gameType,
  // closeWebsocket,
  lotteryType,
}) => {
  const [gameCategoryVisible, setGameCategoryVisible] = useState(false);
  const {
    setBetRecordVisible,
    setCurrentGameType,
    setCurrentGame,
    setGameDescriptionVisible,
    setGameResultVisible,
  } = useLotterySetting();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lottery = useLottery();
  const gameCode = useParams().lotteryType;

  const actions = [
    {
      key: "betHistory",
      text: t("home.betHistory.title"),
      path: `/betHistory?status=&game_type=${lotteryType}`,
      action: () => {
        setCurrentGameType(gameType);
        setCurrentGame(gameCode);
        setBetRecordVisible(true);
      },
    },
    {
      key: "gameResult",
      text: t("page.gameResult"),
      path: `/gameResult/detail/${gameCode}?type=${gameType}`,
      action: () => {
        setCurrentGameType(gameType);
        setCurrentGame(gameCode);
        setGameResultVisible(true);
      },
    },
    {
      key: "rules",
      text: t("page.rules"),
      path: `/gameRules?game=${gameType}`,
      action: () => {
        setCurrentGameType(gameType);
        setGameDescriptionVisible(true);
      },
    },
  ];

  const right = (
    <div className={styles.rightGroup}>
      <div className={styles.item}>
        <Popover.Menu
          mode="dark"
          actions={
            gameType === "LOTVNNEC" ? actions.filter((action) => action.key !== "rules") : actions
          }
          placement="bottom-end"
          onAction={(node) => {
            if (node.action) {
              node.action();
              return;
            }
            // closeWebsocket();
            navigate(node?.path);
          }}
          trigger="click"
          className="lottery-navbar-popover"
        >
          <Setting size={34} />
        </Popover.Menu>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          setGameCategoryVisible(true);
        }}
      >
        <Hamburger size={34} />
      </div>
    </div>
  );

  const changeGame = (gameType) => {
    // console.log("gameType", gameType);
    // closeWebsocket();
    window.location.href = `/lottery/${gameType}`;
    // navigate(`/lottery/${gameType}`);
  };
  const onHandleChangeCategory = () => {
    onChangeCategory();
  };

  // useEffect(() => {
  //   if (gameCategoryVisible === true) {
  //     refetchCategory();
  //   }
  // }, [gameCategoryVisible]);

  return (
    <div>
      <CustomNavbar onBack={onHandleBackButtonPress} right={right}>
        <Button onClick={onHandleChangeCategory} className={styles.buttonTitle} fill="outline">
          <div className={styles.innerContainer}>
            <div className={styles.gameMethodName}>{headerTitle}</div>
            <div className={styles.arrowIcon}>
              {lottery.subCategoryVisible === true ? (
                <CaretUpOutlined style={{ color: "var(--color-white)", marginLeft: "4px" }} />
              ) : (
                <CaretDownOutlined style={{ color: "var(--color-white)", marginLeft: "4px" }} />
              )}
            </div>
          </div>
        </Button>
      </CustomNavbar>
      <Popup
        className="navbar-game-list-pop"
        visible={gameCategoryVisible}
        onMaskClick={() => {
          setGameCategoryVisible(false);
        }}
        // bodyStyle={{ height: "40vh" }}
      >
        <CategoryPopupContent changeGame={changeGame} />
      </Popup>
    </div>
  );
};

export default Navbar;
