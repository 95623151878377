import { combination } from "../../../functions/math";
import {
  BALL_COUNT_EACH_GROUP,
  DUPLICATE_SELECT,
  NDOUBLE_MSINGLE,
  NTRIPLE_MSINGLE,
  SELECTED_COUNT_LIMIT,
} from "../../valudateFunctionEnum";
import EveryColorKeyEnums from "./keyEnums";

const FourStarsFunction = {
  LOTEC14S4SH4ZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  LOTEC14S4SH4ZXZH: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 4;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  LOTEC14S4SH4DX24: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 4);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(4)({ selected });
    },
  },
  LOTEC14S4SH4DX12: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            if (key === EveryColorKeyEnums[currentGameWayDetail].DOUBLE.key) {
              multiplier += selected[key].length;
            } else if (
              key === EveryColorKeyEnums[currentGameWayDetail].SINGLE.key
            ) {
              count += selected[key].length;
            }
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      const isValid = NDOUBLE_MSINGLE({
        selected,
        limit_double: 1,
        limit_single: 2,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
  LOTEC14S4SH4DX6: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  LOTEC14S4SH4DX4: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      const isValid = NTRIPLE_MSINGLE({
        selected,
        limit_triple: 1,
        limit_single: 1,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
  LOTEC14S4SQ4ZXFS: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  LOTEC14S4SQ4ZXZH: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count *= 4;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  LOTEC14S4SQ4DX24: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 4);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });

      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(4)({ selected });
    },
  },
  LOTEC14S4SQ4DX12: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      let multiplier = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            if (key === EveryColorKeyEnums[currentGameWayDetail].DOUBLE.key) {
              multiplier += selected[key].length;
            } else if (
              key === EveryColorKeyEnums[currentGameWayDetail].SINGLE.key
            ) {
              count += selected[key].length;
            }
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2) * multiplier;
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      const isValid = NDOUBLE_MSINGLE({
        selected,
        limit_double: 1,
        limit_single: 2,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
  LOTEC14S4SQ4DX6: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count += selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");

      count = combination(count, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return SELECTED_COUNT_LIMIT(2)({ selected });
    },
  },
  LOTEC14S4SQ4DX4: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count *= selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      const isValid = NTRIPLE_MSINGLE({
        selected,
        limit_triple: 1,
        limit_single: 1,
      });
      if (isValid === true) {
        return DUPLICATE_SELECT({ selected });
      } else {
        return isValid;
      }
    },
  },
};

export { FourStarsFunction };
