import {
  MONEY_REQUIRED,
  SELECTED_NON_NULL,
  SELECTED_COUNT_LIMIT,
  MAX_MIN_BET,
  NOT_ENOUGH_MONEY,
  MAX_PAYOUT,
} from "../../valudateFunctionEnum";
import {
  MS_TM,
  MS_TMSX,
  MS_YX,
  MS_ZX,
  MS_LX_MIN,
  MS_LX_MAX,
  MS_LW_MIN,
  MS_LW_MAX,
  MS_HX_MIN,
  MS_HX_MAX,
  MS_LM_MIN,
  MS_LM_MAX,
  MS_TSBC_MIN,
  MS_TSBC_MAX,
  MS_ZY_MIN,
  MS_ZY_MAX,
  MS_CM,
} from "../../MrakSixEnums/LOTMS1Validation";
import { combinationResult } from "../../../functions/math";

const generalSelectProcess = ({
  selected,
  currentBetNumber,
  currentGameWayDetail,
}) => {
  console.log("currentGameWayDetail", currentGameWayDetail);
  const newSelect = [];
  Object.keys(selected).map((key, index) => {
    selected[key]?.map((option, index) => {
      if (key == "TM") {
        newSelect.push({
          methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
          method: key,
          content: option,
          option: option,
          simpleMoney: currentBetNumber,
          count: 1,
        });
      } else {
        newSelect.push({
          methodText: `gameWay.items${currentGameWayDetail ? "." + currentGameWayDetail : ""
            }.${key}`,
          method: key,
          content: `lottery.item.${option}`,
          option: option,
          simpleMoney: currentBetNumber,
          count: 1,
        });
      }
    });
  });
  return newSelect;
};

const generalRequestProcess = ({
  selected,
  currentBetNumber,
  currentGameWay,
  currentGameWayDetail,
  gameCategoryType,
  gameCategoryDetailType,
  lotteryType,
  period,
  requestItems,
}) => {
  const request = {
    game_category_type: gameCategoryType,
    game_category_detail_type: gameCategoryDetailType,
    game_type: lotteryType,
    game_way_type: currentGameWay,
    game_way_detail_type: currentGameWayDetail,
    period: period,
    choice_number: [],
  };
  request.choice_number = requestItems.map((i) => ({
    game_method_code: i?.method,
    bet_point: i?.simpleMoney,
    bet_content: i?.option,
  }));
  console.log(request);
  return request;
};

const LOTMS1SettingEnum = {
  LOTMS1TM: {
    name: "特码",
    code: "LOTMS1TM",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_TM,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: generalSelectProcess,
    requestProcess: generalRequestProcess,
  },
  LOTMS1TMSX: {
    name: "特码生肖",
    code: "LOTMS1TMSX",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_TMSX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: generalSelectProcess,
    requestProcess: generalRequestProcess,
  },
  LOTMS1YX: {
    name: "一肖",
    code: "LOTMS1YX",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_YX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: generalSelectProcess,
    requestProcess: generalRequestProcess,
  },
  LOTMS1ZX: {
    name: "正肖",
    code: "LOTMS1ZX",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_ZX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: generalSelectProcess,
    requestProcess: generalRequestProcess,
  },
  LOTMS1LX: {
    name: "連肖",
    code: "LOTMS1LX",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_LX_MIN,
      MS_LX_MAX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      console.log("currentGameWayDetail", currentGameWayDetail);
      const keys = Object.keys(selected);
      const newSelect = [];
      const resultProccessor = (num) => {
        keys?.map((key, index) => {
          const nowSelected = selected[key];
          if (nowSelected.length > 6) return;
          const allResults = combinationResult(nowSelected, num);
          // console.log('allResults', allResults);
          allResults?.map((option) => {
            // console.log('option', option);
            let translationKeynames = [];
            option.forEach((item) => {
              translationKeynames.push(`lottery.item.${item}`);
            });
            newSelect.push({
              methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
              method: key,
              content: translationKeynames,
              option: option.join(","),
              simpleMoney: currentBetNumber,
              count: 1,
            });
          });
        });
      };
      switch (currentGameWayDetail) {
        case "LOTMS1LXEXP": //二肖碰
          resultProccessor(2);
          break;
        case "LOTMS1LXSAXP": //三肖碰
          resultProccessor(3);
          break;
        case "LOTMS1LXSIXP": //四肖碰
          resultProccessor(4);
          break;
        case "LOTMS1LXWXP": //五肖碰
          resultProccessor(5);
          break;
        default:
          break;
      }
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
  LOTMS1LW: {
    name: "連尾",
    code: "LOTMS1LW",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_LW_MIN,
      MS_LW_MAX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      console.log("currentGameWayDetail", currentGameWayDetail);
      const keys = Object.keys(selected);
      const newSelect = [];
      const resultProccessor = (num) => {
        keys?.map((key, index) => {
          const nowSelected = selected[key];
          if (nowSelected.length > 6) return;
          const allResults = combinationResult(nowSelected, num);
          // console.log('allResults', allResults);
          allResults?.map((option) => {
            // console.log('option', option);
            let translationKeynames = [];
            option.forEach((item) => {
              translationKeynames.push(`lottery.item.${item}`);
            });
            newSelect.push({
              methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
              method: key,
              content: translationKeynames,
              option: option.join(","),
              simpleMoney: currentBetNumber,
              count: 1,
            });
          });
        });
      };
      switch (currentGameWayDetail) {
        case "LOTMS1LWELW": //二尾连
          resultProccessor(2);
          break;
        case "LOTMS1LWSALW": //三尾连
          resultProccessor(3);
          break;
        case "LOTMS1LWSILW": //四尾连
          resultProccessor(4);
          break;
        case "LOTMS1LWWLW": //五尾连
          resultProccessor(5);
          break;
        default:
          break;
      }
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
  LOTMS1HX: {
    name: "合肖",
    code: "LOTMS1HX",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_HX_MIN,
      MS_HX_MAX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      const keys = Object.keys(selected);
      const newSelect = [];
      const resultProccessor = () => {
        keys?.map((key, index) => {
          const allResults = selected[key];
          let translationKeynames = [];
          allResults?.map((option) => {
            translationKeynames.push(`lottery.item.${option}`);
          });
          newSelect.push({
            methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
            method: key,
            content: translationKeynames,
            option: allResults.join(","),
            simpleMoney: currentBetNumber,
            count: 1,
          });
        });
      };
      switch (currentGameWayDetail) {
        case "LOTMS1HXZ": //中
          resultProccessor();
          break;
        case "LOTMS1HXBZ": //不中
          resultProccessor();
          break;
        default:
          break;
      }
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
  LOTMS1LM: {
    name: "連碼",
    code: "LOTMS1LM",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_LM_MIN,
      MS_LM_MAX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      console.log("currentGameWayDetail", currentGameWayDetail);
      const keys = Object.keys(selected);
      const newSelect = [];
      const resultProccessor = (num) => {
        keys?.map((key, index) => {
          const nowSelected = selected[key];
          if (nowSelected.length > 10) return;
          const allResults = combinationResult(nowSelected, num);
          // console.log('allResults', allResults);
          allResults?.map((option) => {
            // console.log('option', option);
            let translationKeynames = [];
            option.forEach((item) => {
              translationKeynames.push(item);
            });
            newSelect.push({
              methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
              method: key,
              content: translationKeynames,
              option: option.join(","),
              simpleMoney: currentBetNumber,
              count: 1,
            });
          });
        });
      };
      switch (currentGameWayDetail) {
        case "LOTMS1LMSIQZ": //四全中
          resultProccessor(4);
          break;
        case "LOTMS1LMSAQZ": //三全中
          resultProccessor(3);
          break;
        case "LOTMS1LMSZE": //三中二
          resultProccessor(3);
          break;
        case "LOTMS1LMEQZ": //二全中
          resultProccessor(2);
          break;
        case "LOTMS1LMEZT": //二中特
          resultProccessor(2);
          break;
        case "LOTMS1LMTC": //特串
          resultProccessor(2);
          break;
        default:
          break;
      }
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
  LOTMS1TSBC: {
    name: "自選不中",
    code: "LOTMS1TSBC",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_TSBC_MIN,
      MS_TSBC_MAX,
      MAX_MIN_BET,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      console.log("currentGameWayDetail", currentGameWayDetail);
      const keys = Object.keys(selected);
      const newSelect = [];
      const resultProccessor = (num, limit) => {
        keys?.map((key, index) => {
          const nowSelected = selected[key];
          if (nowSelected.length > limit) return;
          const allResults = combinationResult(nowSelected, num);
          // console.log('allResults', allResults);
          allResults?.map((option) => {
            // console.log('option', option);
            let translationKeynames = [];
            option.forEach((item) => {
              translationKeynames.push(item);
            });
            newSelect.push({
              methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
              method: key,
              content: translationKeynames,
              option: option.join(","),
              simpleMoney: currentBetNumber,
              count: 1,
            });
          });
        });
      };
      switch (currentGameWayDetail) {
        case "LOTMS1TSBCWBZ": //五不中
          resultProccessor(5, 8);
          break;
        case "LOTMS1TSBCLBZ": //六不中
          resultProccessor(6, 9);
          break;
        case "LOTMS1TSBCQBZ": //七不中
          resultProccessor(7, 9);
          break;
        case "LOTMS1TSBCBBZ": //八不中
          resultProccessor(8, 10);
          break;
        case "LOTMS1TSBCJBZ": //九不中
          resultProccessor(9, 11);
          break;
        case "LOTMS1TSBCSBZ": //十不中
          resultProccessor(10, 12);
          break;
        case "LOTMS1TSBCSYBZ": //十一不中
          resultProccessor(11, 13);
          break;
        case "LOTMS1TSBCSEBZ": //十二不中
          resultProccessor(12, 14);
          break;
        default:
          break;
      }
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
  LOTMS1ZY: {
    name: "自選中一",
    code: "LOTMS1ZY",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_ZY_MIN,
      MS_ZY_MAX,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      console.log("currentGameWayDetail", currentGameWayDetail);
      const keys = Object.keys(selected);
      const newSelect = [];
      const resultProccessor = (num) => {
        keys?.map((key, index) => {
          const nowSelected = selected[key];
          if (nowSelected.length > 8) return;
          const allResults = combinationResult(nowSelected, num);
          // console.log('allResults', allResults);
          allResults?.map((option) => {
            // console.log('option', option);
            let translationKeynames = [];
            option.forEach((item) => {
              translationKeynames.push(item);
            });
            newSelect.push({
              methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
              method: key,
              content: translationKeynames,
              option: option.join(","),
              simpleMoney: currentBetNumber,
              count: 1,
            });
          });
        });
      };
      switch (currentGameWayDetail) {
        case "LOTMS1CYSAZY": //三中一
          resultProccessor(3);
          break;
        case "LOTMS1CYSIZY": //四中一
          resultProccessor(4);
          break;
        case "LOTMS1CYWZY": //五中一
          resultProccessor(5);
          break;
        case "LOTMS1CYLZY": //六中一
          resultProccessor(6);
          break;
        default:
          break;
      }
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
  LOTMS1CM: {
    name: "正碼",
    code: "LOTMS1CM",
    hideRuleButton: true,
    confirmValidation: [
      MONEY_REQUIRED,
      MS_CM,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      console.log("currentGameWayDetail", currentGameWayDetail);
      const newSelect = [];
      Object.keys(selected).map((key, index) => {
        selected[key]?.map((option, index) => {
          if (key == "CM16" || key == "CM") {
            newSelect.push({
              methodText: `gameWay.items.${currentGameWayDetail}.${key}`,
              method: key,
              content: option,
              option: option,
              simpleMoney: currentBetNumber,
              count: 1,
            });
          } else {
            newSelect.push({
              methodText: `gameWay.items${currentGameWayDetail ? "." + currentGameWayDetail : ""
                }.${key}`,
              method: key,
              content: `lottery.item.${option}`,
              option: option,
              simpleMoney: currentBetNumber,
              count: 1,
            });
          }
        });
      });
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
};

export default LOTMS1SettingEnum;
