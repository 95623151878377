import {
  anyComplexModel,
  anyThreeGroupSix,
  anyThreeGroupThree,
  anyTwoGroupOption,
} from "../../../functions/countCompute";
import {
  BALL_COUNT_N_GROUP,
  NPOS_MNUM,
  TWOPOS_TWONUM,
} from "../../valudateFunctionEnum";
import EveryColorKeyEnums from "./keyEnums";

const OptionalFunction = {
  LOTEC1RXR2FX: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = anyComplexModel(selected, 2);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_N_GROUP({ selected, n: 2 });
    },
  },
  LOTEC1RXR2DX: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].POSITION.key]) {
        count = anyTwoGroupOption(
          selected[EveryColorKeyEnums[currentGameWayDetail].POSITION.key],
          selected[EveryColorKeyEnums[currentGameWayDetail].NUMBER.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        contentText: flatten,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return NPOS_MNUM({ selected, limit_pos: 2, limit_num: 2 });
    },
  },
  LOTEC1RXR3FX: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = anyComplexModel(selected, 3);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_N_GROUP({ selected, n: 3 });
    },
  },
  LOTEC1RXR3Z3: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].POSITION.key]) {
        count = anyThreeGroupThree(
          selected[EveryColorKeyEnums[currentGameWayDetail].POSITION.key],
          selected[EveryColorKeyEnums[currentGameWayDetail].NUMBER.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        contentText: flatten,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return NPOS_MNUM({ selected, limit_pos: 3, limit_num: 2 });
    },
  },
  LOTEC1RXR3Z6: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      if (selected[EveryColorKeyEnums[currentGameWayDetail].POSITION.key]) {
        count = anyThreeGroupSix(
          selected[EveryColorKeyEnums[currentGameWayDetail].POSITION.key],
          selected[EveryColorKeyEnums[currentGameWayDetail].NUMBER.key]
        );
      }
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        contentText: flatten,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return NPOS_MNUM({ selected, limit_pos: 3, limit_num: 3 });
    },
  },
  LOTEC1RXR4FX: {
    selectProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      method,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      count = anyComplexModel(selected, 4);
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_N_GROUP({ selected, n: 4 });
    },
  },
};

export { OptionalFunction };
