import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";

function EveryColor({ data }) {
  const { t } = useTranslation();
  const numbers = data.lottery_number.split(",");
  // console.log("numbers", numbers);
  const getTextColor = (option) => {
    const colorDict = {
      big: "var(--color-result-red)",
      small: "var(--color-result-blue)",
      odd: "var(--color-result-red)",
      even: "var(--color-result-blue)",
      dragon: "var(--color-result-red)",
      tiger: "var(--color-result-blue)",
      tie: "var(--color-main-active)",
    };
    return { color: colorDict[option] ?? "" };
  };
  return (
    <div className={`${styles.everycolorResult}`}>
      <div className={`${styles.row}`}>
        {numbers.map((number, index) => {
          return (
            <div key={"number" + index} className={`${styles.rowItem}`}>
              <div className={`${styles.ball}`}>{number}</div>
            </div>
          );
        })}
      </div>
      <div className={`${styles.row}`}>
        <div
          className={`${styles.rowItem}`}
          style={getTextColor(data.data.dragon_tiger)}
        >
          {t(`gameResult.${data.data.dragon_tiger}`)}
        </div>
        <div
          className={`${styles.rowItem}`}
          style={getTextColor(data.data.big_small)}
        >
          {t(`gameResult.${data.data.big_small}`)}
        </div>
        <div
          className={`${styles.rowItem}`}
          style={getTextColor(data.data.odd_even)}
        >
          {t(`gameResult.${data.data.odd_even}`)}
        </div>
      </div>
    </div>
  );
}

export default EveryColor;
