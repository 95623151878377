import { Grid } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLottery } from "../../..";
// import EveryColorKeyEnums from "../../../enumeration/EveryColorEnums/keyEnums";
import EveryColorKeyEnums from "../../../enumeration/EveryColorEnums/LOTEC5Function/keyEnums";
import QuickSelectBar from "../../QuickSelectBar";
import { CircleItem } from "../Circle";
import Rectangle, { RectangleItem } from "../Rectangle";
import styles from "./style.module.scss";

const NumberSelectorPK105 = ({
  allowDuplicate = true,
  items,
  mutiple,
  title,
  customTitle,
  group,
  defaultValue = [],
  onChange,
  hideTitle,
  selectObjectKey,
  valueRepeat,
  max = 999,
  textNoI18n,
  hideQuickSelect = false,
  hideOdds = false,
  shape = "circle",
  twoSameKeys = [
    `lottery.bet.title.sameItems`,
    `lottery.bet.title.differentItems`,
  ],
  twoSameLimit = [999, 999],
}) => {
  const { t } = useTranslation();
  const { setSelected, selected } = useLottery();
  const [groups, setGroups] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState({});
  const [allSelection, setAllSelection] = useState({});
  const codeSet = new Set();
  items?.forEach((i) => {
    codeSet.add(i?.code);
  });

  let currentLocalSelected = [];
  [...codeSet].forEach((code) => {
    if (selected[code]) {
      currentLocalSelected = currentLocalSelected.concat(selected[code]);
    }
  });

  const initOptions = () => {
    if (items.length <= 1) {
      setGroups(items[0].option);
    } else {
      if (items.option) {
        let _group = {};
        items.forEach((item, index) => {
          if (index === 0) {
            _group = { ..._group, ...item };
          } else {
            const _option = item.option[0].option;
            _group.option[0].option = [..._group.option[0].option, ..._option];
          }
        });
        setGroups(_group.option);
      } else {
        setGroups(items);
      }
    }
  };

  const initSelectNumber = () => {
    let objects = {};
    const _options = items[0]?.option ? items[0].option : items;
    _options.forEach((opt) => {
      let object = { [opt.key]: [] };
      setSelectedNumber((prevState) => {
        return {
          ...prevState,
          ...object,
        };
      });
      const keys = opt.option.map((_opt) => {
        return _opt.key;
      });
      setAllSelection((prevState) => {
        return {
          ...prevState,
          [opt.key]: keys,
        };
      });
    });
  };

  const initData = () => {
    initOptions();
    initSelectNumber();
  };

  useEffect(() => {
    initData();
  }, []);

  const handleOnItemClick = (group_key, item) => {
    const isSelected = selectedNumber[group_key].includes(item.key);
    if (isSelected) {
      const filter = selectedNumber[group_key].filter(
        (_item) => item.key !== _item
      );
      setSelectedNumber((prevState) => {
        return {
          ...prevState,
          [group_key]: filter,
        };
      });
    } else {
      if (allowDuplicate === false) {
        let _newSelected = {};
        Object.keys(selectedNumber).forEach((key) => {
          if (key === group_key) {
            _newSelected[key] = [...selectedNumber[key], item.key];
          } else {
            const filter = selectedNumber[key].filter((_item) => {
              return _item !== item.key;
            });
            _newSelected[key] = [...filter];
          }
        });

        setSelectedNumber(_newSelected);
      } else {
        setSelectedNumber((prevState) => {
          return {
            ...prevState,
            [group_key]: [...prevState[group_key], item.key],
          };
        });
      }
    }
  };

  const onClickAll = (group_key) => {
    setSelectedNumber((prevState) => {
      return {
        ...prevState,
        [group_key]: allSelection[group_key],
      };
    });
  };

  const onClickClear = (group_key) => {
    setSelectedNumber((prevState) => {
      return {
        ...prevState,
        [group_key]: [],
      };
    });
  };

  const onClickOdd = (group_key) => {
    const select = allSelection[group_key].filter((opt) => opt % 2 === 1);
    setSelectedNumber((prevState) => {
      return {
        ...prevState,
        [group_key]: select,
      };
    });
  };

  const onClickEven = (group_key) => {
    const select = allSelection[group_key].filter((opt) => opt % 2 === 0);
    setSelectedNumber((prevState) => {
      return {
        ...prevState,
        [group_key]: select,
      };
    });
  };

  const onClickBig = (group_key) => {
    const select = allSelection[group_key].filter((opt) => opt > 5);
    setSelectedNumber((prevState) => {
      return {
        ...prevState,
        [group_key]: select,
      };
    });
  };

  const onClickSmall = (group_key) => {
    const select = allSelection[group_key].filter((opt) => opt < 6);
    setSelectedNumber((prevState) => {
      return {
        ...prevState,
        [group_key]: select,
      };
    });
  };

  useEffect(() => {
    setSelected({ ...selected, ...selectedNumber });
  }, [selectedNumber]);

  useEffect(() => {
    if (Object.keys(selected).length === 0) {
      initSelectNumber();
    }
  }, [selected]);

  return (
    <div className={styles.selector}>
      {groups.map((group) => {
        return (
          <div key={group.key}>
            <div className={styles.titleContainer}>
              <div className={styles.gameMethodTitle}>
                {customTitle
                  ? t(customTitle)
                  : t(`lottery.bet.title.ssc.${group.key}`)}
                {/* {group.name} */}
              </div>
              {hideQuickSelect === true ||
              group.key ===
                EveryColorKeyEnums.LOTEC5RXR2DX.POSITION.key ? null : (
                <QuickSelectBar
                  group={group.key}
                  onClickAll={onClickAll}
                  onClickBig={onClickBig}
                  onClickSmall={onClickSmall}
                  onClickEven={onClickEven}
                  onClickOdd={onClickOdd}
                  onClickClear={onClickClear}
                />
              )}
            </div>
            {shape === "circle" &&
            group.key !== EveryColorKeyEnums.LOTEC5RXR2DX.POSITION.key ? (
              <Grid className={styles.container} columns={5}>
                {group.option.map((opt) => {
                  return (
                    <Grid.Item key={`${opt.id}`}>
                      <CircleItem
                        group_key={group.key}
                        code={opt.key}
                        item={opt}
                        isActive={selectedNumber[group.key].includes(opt.key)}
                        textNoI18n={textNoI18n}
                        onClick={handleOnItemClick}
                        hideTitle={hideTitle}
                        hideOdds={hideOdds}
                      />
                    </Grid.Item>
                  );
                })}
              </Grid>
            ) : (
              <div className={styles.rectContainer}>
                {group.option.map((opt) => {
                  return (
                    <RectangleItem
                      itemWidth={"25%"}
                      key={`${opt.id}`}
                      group_key={group.key}
                      code={opt.key}
                      item={opt}
                      isActive={selectedNumber[group.key].includes(opt.key)}
                      textNoI18n={
                        textNoI18n &&
                        group.key !==
                          EveryColorKeyEnums.LOTEC5RXR2DX.POSITION.key
                      }
                      onClick={handleOnItemClick}
                      hideTitle={hideTitle}
                      hideOdds={hideOdds}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default NumberSelectorPK105;
