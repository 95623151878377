import { CapsuleTabs } from "antd-mobile";
import { t } from "i18next";
import React, { useEffect } from "react";
import "./style.scss";

const CustomTabs = (props) => {
  const {
    tabItems,
    defaultActiveKey = "",
    tabOnChange = () => {},
    activeKey,
    tabBarWidth = "80vw",
    rightSideWidth = "auto",
    rightSideRender,
    onClick,
    zIndex,
    isTitleNeedI18n = false,
  } = props;

  useEffect(() => {
    const tabElList = [];

    tabItems.forEach((tab) => {
      const tabEl = document.querySelector(`.custom-capsule-tabs .tab-${tab.key}`);

      const clickHandler = () => {
        if (onClick) {
          onClick(tab);
        }
      };
      tabEl?.addEventListener("click", clickHandler);
      if (tabEl) {
        tabElList.push({
          el: tabEl,
          clickFunction: clickHandler,
        });
      }
    });
    return () => {
      tabElList.forEach(({ el, clickFunction }) => {
        el?.removeEventListener("click", clickFunction);
      });
    };
  }, [tabItems]);

  return (
    <div
      className="custom-capsule-tabs"
      style={{
        display: "flex",
        flex: "auto",
        zIndex: zIndex,
        position: "relative",
      }}
    >
      <CapsuleTabs
        defaultActiveKey={defaultActiveKey}
        onChange={tabOnChange}
        activeKey={activeKey}
        style={rightSideRender ? { width: tabBarWidth } : { width: "100vw" }}
      >
        {tabItems?.map((item, index) => {
          return (
            <CapsuleTabs.Tab
              title={isTitleNeedI18n ? t(item.title) : item.title}
              className={`tab-${item.key}`}
              key={item.key}
              disabled={item.disabled}
            />
          );
        })}
      </CapsuleTabs>
      {rightSideRender ? (
        <div className="right-side-render" style={{ width: rightSideWidth }}>
          {rightSideRender()}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomTabs;
