import { useNavigate } from "react-router-dom";
import Background from "./components/Background";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import styles from "./style.module.scss";

const Layout = (props) => {
  let navigate = useNavigate();
  const { navType } = props;
  // console.log(navType);
  const renderHeader = () => {
    if (navType.hideHeader) {
      return null;
    }
    return (
      <Navigation
        {...navType}
        goBack={(backURL) => {
          navigate(backURL, {
            replace: true,
          });
        }}
      />
    );
  };

  const renderFooter = () => {
    if (navType.hideFooter) {
      return null;
    }
    return <Footer />;
  };

  return (
    <Background>
      <div
        className={styles.main}
        style={{
          overflow: "hidden",
          maxWidth: "450px",
          height: "calc(var(--vh, 1vh) * 100)", //vh * 100,
          margin: "0 auto",
        }}
      >
        {renderHeader()}
        <div className={styles.container}>{props.children}</div>
        {renderFooter()}
      </div>
    </Background>
  );
};
export default Layout;
