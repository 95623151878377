import parseUrl from "parse-url";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { CustomNavbar, CustomTabs } from "../../components";
import { useGetGameMainCategory } from "../../hooks/game/game.hook";
import { everycolorRules, fast3Rules, marksixRules, pk10Rules } from "./enumerations/rulesCode";
import styles from "./style.module.scss";

function GameRules({ isPopup = false, onClosePopup = undefined, popupGameType = undefined }) {
  const navigate = useNavigate();
  const [nowTab, setNowTab] = useState("");
  const [tabs, setTabs] = useState();
  const { t } = useTranslation();
  const params = parseUrl(window.location.href).query;
  const { data: categoryData } = useGetGameMainCategory({
    enabled: isPopup === false,
    onSuccess: (data) => {
      if (data?.length === 0) return;
      const tabItems = [];
      console.debug("data", data);
      data
        ?.filter((_data) => _data !== "LOTVNNEC")
        .map((item) => {
          tabItems.push({
            title: t(`gameWay.${item}`),
            key: item,
          });
        });
      setTabs(tabItems);
    },
  });
  const rulesDict = {
    LOTMS: {
      rules: marksixRules,
      extraRender: () => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("gameRules.LOTMS.extraRender"),
            }}
            style={{
              color: "var(--color-main-active)",
              fontSize: "var(--fontSetting-middle)",
            }}
          />
        );
      },
    },
    LOTEC: { rules: everycolorRules },
    LOTFT: { rules: fast3Rules },
    LOTPK10: {
      rules: pk10Rules,
      extraRender: () => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("gameRules.LOTPK10.extraRender"),
            }}
            style={{
              color: "var(--color-main-active)",
              fontSize: "var(--fontSetting-middle)",
            }}
          />
        );
      },
    },
  };
  const getNowTab = () => {
    if (!params.game || !Object.keys(rulesDict).includes(params.game)) {
      setNowTab(categoryData[0]);
      return;
    }
    setNowTab(params.game);
  };
  useEffect(() => {
    if (!!tabs) {
      getNowTab();
    }
  }, [tabs]);

  return (
    <div
      className={`${styles.gameRules}`}
      style={{
        backgroundImage: "url('/images/layout/background/other_bg.webp')",
      }}
    >
      <CustomNavbar
        onBack={() => {
          if (isPopup) {
            onClosePopup();
            return;
          }
          navigate(-1);
        }}
      >
        {isPopup
          ? `【${t(`gameWay.${popupGameType}`)}】${t("page.gameRules")}`
          : t("page.gameRules")}
      </CustomNavbar>
      <CustomTabs
        tabItems={tabs ?? []}
        tabOnChange={(e) => {
          console.log("e", e);
          setNowTab(e);
        }}
        activeKey={nowTab}
      />
      <div className={`${styles.ruleContainer}`}>
        <div className={`${styles.ruleBox}`}>
          {rulesDict[isPopup ? popupGameType : nowTab]?.extraRender
            ? rulesDict[isPopup ? popupGameType : nowTab]?.extraRender()
            : ""}
          {rulesDict[isPopup ? popupGameType : nowTab]?.rules?.map((item, index) => {
            return (
              <>
                <div className={`${styles.ruleTitle}`}>
                  {index + 1}. {t(`gameRules.${isPopup ? popupGameType : nowTab}.${item}.title`)}
                </div>
                <div
                  className={`${styles.ruleContent}`}
                  dangerouslySetInnerHTML={{
                    __html: t(`gameRules.${isPopup ? popupGameType : nowTab}.${item}.content`),
                  }}
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default GameRules;
