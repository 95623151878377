import dayjs from "dayjs";
import zhTw from "dayjs/locale/zh-tw";
const weekday = require('dayjs/plugin/weekday')
const DATE_FORMAT = "YYYY-MM-DD";
dayjs.extend(weekday)
//had checked weekday1 is set by changing timzone of laptop
//but still not sure whether it works or not globaly
dayjs.locale({
  ...zhTw,
  weekStart: 1,
});

export const tabKeyEnum = {
  DATE: "date",
  // CATEGORY: "category",
};

export const dateSelectorEnum = {
  TODAY: "today",
  YESTERDAY: "yesterday",
  SEVEN_DAYS: "seven.days",
  THIRTY_DAYS: "thirty.days",
};

export const selectorOptions = {
  [tabKeyEnum.DATE]: [
    { label: "search.today", value: dateSelectorEnum.TODAY },
    { label: "search.yesterday", value: dateSelectorEnum.YESTERDAY },
    { label: "search.seven.days", value: dateSelectorEnum.SEVEN_DAYS },
    { label: "search.thirty.days", value: dateSelectorEnum.THIRTY_DAYS },
  ],
};

export const dateRange = {
  [dateSelectorEnum.TODAY]: [
    dayjs().format(DATE_FORMAT),
    dayjs().format(DATE_FORMAT),
  ],
  [dateSelectorEnum.YESTERDAY]: [
    dayjs().add(-1, "d").format(DATE_FORMAT),
    dayjs().add(-1, "d").format(DATE_FORMAT),
  ],

  [dateSelectorEnum.SEVEN_DAYS]: [
    dayjs().weekday(0).format(DATE_FORMAT),
    dayjs().weekday(6).format(DATE_FORMAT),
  ],
  [dateSelectorEnum.THIRTY_DAYS]: [
    dayjs().startOf("month").format(DATE_FORMAT),
    dayjs().endOf("month").format(DATE_FORMAT),
  ],
};

export const dropdownItem = [
  {
    key: tabKeyEnum.DATE,
    selectorColumnCount: 3,
    selector: {
      options: selectorOptions[tabKeyEnum.DATE],
      defaultValue: [selectorOptions[tabKeyEnum.DATE][0].value],
    },
  },
];
