import React from "react";

import { Popup } from "antd-mobile";

import "./styles.scss";

function CustomDrawer({ destroyOnClose = true, ...props }) {
  return (
    <Popup
      className="custom-drawer-popup"
      position="right"
      bodyStyle={{ width: "100vw", height: "calc(var(--vh, 1vh) * 100)" }}
      destroyOnClose={destroyOnClose}
      style={{ "--z-index": 9999 }}
      {...props}
    >
      {props.children}
    </Popup>
  );
}

export default CustomDrawer;
