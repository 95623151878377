import { List } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useLongDragon } from "../../context/LongDragonContext";
import LongDragonItem from "../LongDragonItem";
import styles from "./style.module.scss";

const LongDragonList = () => {
  const { t } = useTranslation();
  const { data, isLoadingLongDragonList, isError } = useLongDragon();

  return (
    <div className={styles.container}>
      {data?.length > 0 ? (
        <List className={"long-dragon-list"}>
          {isLoadingLongDragonList
            ? ""
            : isError
            ? ""
            : data.map((item) => {
                return (
                  <List.Item key={`${item.game_type}${item.game_code}${item.content}`}>
                    <LongDragonItem item={item} />
                  </List.Item>
                );
              })}
        </List>
      ) : (
        <div className={styles.noDataContainer}>{t("betHistory.noData")}</div>
      )}
    </div>
  );
};

export default LongDragonList;
