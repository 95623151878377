import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANG } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import cn from "../locals/cn.json";
import en from "../locals/en.json";
import id from "../locals/id.json";
import jp from "../locals/jp.json";
import pt from "../locals/pt.json";
import vn from "../locals/vn.json";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: en },
  zh: { translation: cn },
  pt: { translation: pt },
  vi: { translation: vn },
  jp: { translation: jp },
  id: { translation: id },
};
const nowLangProcess = () => {
  const lang = window?.localStorage?.getItem(localStorageKey.DEFAULT_LANG) ?? DEFAULT_LANG;
  return lang.replace(/\"/gm, "").toLowerCase();
};
const nowLang = nowLangProcess();
console.log("nowLang", nowLang);
const hasTranslation = Boolean(Object.keys(resources).includes(nowLang));
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: hasTranslation ? nowLang : DEFAULT_LANG,
    fallbackLng: nowLang,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
