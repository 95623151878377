import { createContext, useContext, useState } from "react";
import { CustomDrawer } from "../components";
import BetHistory from "../pages/BetHistory";
import GameResultDetail from "../pages/GameResultDetail";
import GameRules from "../pages/GameRules";

const LotterySettingContext = createContext({
  setBetRecordVisible: () => {},
  setGameResultVisible: () => {},
  setGameDescriptionVisible: () => {},
  setCurrentGameType: () => {},
  setCurrentGame: () => {},
});

export const LotterySettingContextProvider = ({ children }) => {
  const [betRecordVisible, setBetRecordVisible] = useState(false);
  const [gameResultVisible, setGameResultVisible] = useState(false);
  const [gameDescriptionVisible, setGameDescriptionVisible] = useState(false);

  const [currentGameType, setCurrentGameType] = useState();
  const [currentGame, setCurrentGame] = useState();

  const handleOnCloseBetRecord = () => {
    setBetRecordVisible(false);
  };

  const handleOnCloseGameDescription = () => {
    setGameDescriptionVisible(false);
  };

  const handleOnCloseGameResult = () => {
    setGameResultVisible(false);
  };

  return (
    <LotterySettingContext.Provider
      value={{
        setBetRecordVisible,
        setGameResultVisible,
        setCurrentGameType,
        setCurrentGame,
        setGameDescriptionVisible,
      }}
    >
      {children}
      <CustomDrawer visible={betRecordVisible} onClose={handleOnCloseBetRecord}>
        <BetHistory
          isPopup={true}
          onClosePopup={handleOnCloseBetRecord}
          popupGameType={currentGameType}
          popupGame={currentGame}
        />
      </CustomDrawer>
      <CustomDrawer
        visible={gameResultVisible}
        onClose={handleOnCloseGameResult}
        style={{ zIndex: "9999" }}
      >
        <GameResultDetail
          isPopup={true}
          onClosePopup={handleOnCloseGameResult}
          popupGameType={currentGameType}
          popupGame={currentGame}
        />
      </CustomDrawer>
      <CustomDrawer
        visible={gameDescriptionVisible}
        onClose={handleOnCloseGameDescription}
        style={{ zIndex: "9999" }}
        destroyOnClose={false}
      >
        <GameRules
          isPopup={true}
          onClosePopup={handleOnCloseGameDescription}
          popupGameType={currentGameType}
        />
      </CustomDrawer>
    </LotterySettingContext.Provider>
  );
};
export const useLotterySetting = () => {
  const LotterySettingValue = useContext(LotterySettingContext);
  return LotterySettingValue;
};
