import { useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetGame } from "../service";

export const useGetGameList = (options) => {
  return useQuery(
    [queryKey.HOME_GAME_LIST],
    () =>
      apiGetGame().then((res) => {
        const _result = res.data.data;
        let all_games = [];
        const categories = _result
          .map((item) => {
            all_games = [...all_games, ...item.game_list];
            return {
              ...item,
              title: `gameWay.${item.game_category_detail_type}`,
              key: item.game_category_detail_type,
            };
          })
          .flat(1);
        const all_category = {
          game_category_name: "Lottery Game",
          game_category_type: "LOT",
          game_category_detail_type: "ALL",
          currency: "MYR",
          name: "全部",
          game_list: all_games,
          title: "normal.all",
          key: "ALL",
          sorting: 0,
          status: 1,
        };
        return [all_category, ...categories];
      }),
    {
      ...options,
    },
  );
};
