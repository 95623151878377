import Selector from "../../../components/Selector";

const OFLOTPK10LASelectorEnum = {
  "OFLOTPK10LAGYJH.GYJH": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "OFLOTPK10LAGYJH.GYJH_GROUP": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
    },
  },
  "OFLOTPK10LAQY.QY": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "OFLOTPK10LAQE.QE": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      allowDuplicate: false,
    },
  },
  "OFLOTPK10LAQS.QS": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      allowDuplicate: false,
    },
  },
  // ========================定位胆========================

  "OFLOTPK10LADWD0110.DWD0110": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "OFLOTPK10LADWD0105.DWD0105": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "OFLOTPK10LADWD0610.DWD0610": {
    component: Selector.numberPK10,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // ========================五行========================
  "OFLOTPK10LAWXGJ.GJ": {
    component: Selector.number,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "OFLOTPK10LAWXYJ.YJ": {
    component: Selector.number,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "OFLOTPK10LAWXJJ.JJ": {
    component: Selector.number,
    params: {
      // textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  //=============================大小单双========================
  "OFLOTPK10LADXDS0105.DXDS0105": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      // hideOdds: true,
    },
  },
  "OFLOTPK10LADXDS0610.DXDS0610": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      // hideOdds: true,
    },
  },
  //=============================龙虎========================
  "OFLOTPK10LALH.PK10LH": {
    component: Selector.number,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
    },
  },
  //=============================牛牛========================
  "OFLOTPK10LANNPB.NNPB": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "OFLOTPK10LANNFB.NNFB": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
};

export default OFLOTPK10LASelectorEnum;
