import { request } from "../../apis";
import { getCurrency } from "../../plugins/utils";

const CURRENCY = getCurrency();

export const apiGetGame = (params) =>
  request("get", `/${CURRENCY}/game/category/LOT`, params, true);

export const apiGetAllGameResult = (params) =>
  request("get", `/${CURRENCY}/game/record/latest`, params, true);
