import { Button, CenterPopup } from "antd-mobile";
import "./style.scss";

const CustomPopup = ({
  title = "",
  leftButtonText = "",
  rightButtonText = "",
  visible = false,
  singleButton = false,
  children,
  closeOnMaskClick = false,
  showCloseButton = false,
  onClose = () => {},
  onSecondaryButtonPress = () => {},
  onMainButtonPress = () => {},
}) => {
  const onHandleSecondaryButtonPress = () => {
    onSecondaryButtonPress();
    onClose();
  };

  const onHandleMainButtonPress = () => {
    onMainButtonPress();
    onClose();
  };

  return (
    <CenterPopup
      closeOnMaskClick={closeOnMaskClick}
      destroyOnClose={true}
      className="custom-popup"
      showCloseButton={showCloseButton}
      visible={visible}
      onClose={onClose}
    >
      <div className="title">{title}</div>
      <div className="content">{children}</div>
      <div className="button-container">
        {singleButton === false ? (
          <Button onClick={onHandleSecondaryButtonPress} fill="none" className="button">
            {leftButtonText}
          </Button>
        ) : null}

        <Button onClick={onHandleMainButtonPress} fill="none" className="button">
          {rightButtonText}
        </Button>
      </div>
    </CenterPopup>
  );
};

export default CustomPopup;
