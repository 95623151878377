//跳轉、錯誤處理、提示等方法放在這裡
// import {Message} from 'element-ui';
// import router from '../router/index';
import {Toast} from "antd-mobile"

const ALLOWED_PATH = ["/signin", "/signup", "/forgotPassword", "/"];

export const isAllowedWithoutCurrency = (path) => {
  return ALLOWED_PATH.includes(path);
};
export const tip = (msg) => {
  Toast.show({icon: 'fail', content: msg});
};

export const toLogin = (nuxt) => {
  window.location.href = "/signin";
  // this.$router.push('/');
  // $router.replace({
  //     name: 'Login',
  //     query: {
  //         redirect: router.currentRoute.fullPath
  //     }
  // });
  // debugger;
  // nuxt.$router.push('/login');
  // window.location.pathname = "/error";
  // window.history.pushState("123","title","/Login");
};

export const to403Page = (nuxt) => {
  // nuxt.$router.push('/403');
  window.location.pathname = "/403";
  // this.$router.push('/');
  // $router.replace({
  //    name: '403'
  // });
};
