import React from "react";
import DefaultSelector from "../DefaultSelector";
import { RectangleItem } from "../Rectangle";
// const RectangleItem = ({ isActive, onClick, code, textNoI18n }) => {
//   const { t } = useTranslation();
//   return (
//     <div
//       key={code}
//       className={`${styles.item} ${isActive ? styles.active : ""}`}
//       onClick={onClick}
//     >
//       {textNoI18n ? code : t(`lottery.item.${code}`)}
//     </div>
//   );
// };
const Rectangle = (props) => {
  let newItems = [];
  props?.items?.forEach((i) => {
    newItems = newItems.concat(
      i?.option[0]?.option.map((o) => {
        return { ...o, code: i.code };
      }),
    );
  });

  // console.log("newItems", newItems);
  // console.log("props?.items", props?.items);
  return (
    <DefaultSelector
      {...props}
      title={props?.items[0]?.option[0]?.key}
      items={newItems}
      selectorRender={RectangleItem}
    />
  );
};

export default Rectangle;
