import {
  FiveStarsFunctions,
  FourStarsFunction,
  FreePositionFunction,
  OptionalFunction,
  ThreeStarsFunction,
  TwoStarsFunction
} from "../../EveryColorEnums/LOTEC5Function";
import LOTEC5RuleEnum from "../../ruleDescription/LOTEC/LOTEC5RuleEnum";
import {
  MAX_MIN_BET,
  MAX_PAYOUT,
  MONEY_REQUIRED,
  NOT_ENOUGH_MONEY,
  SELECTED_COUNT_LIMIT,
  SELECTED_NON_NULL,
} from "../../valudateFunctionEnum";
import {
  FIVE_STAR,
  FOUR_STAR,
  THREE_STAR,
  TWO_STAR,
  FREE_POS,
  OPTION_SELECT
} from "../../EveryColorEnums/LOTEC5Validation";

const LOTEC5SettingEnum = {
  LOTEC55S: {
    name: "五星",
    code: "LOTEC55S",
    ruleDescription: LOTEC5RuleEnum.LOTEC55S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      FIVE_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (FiveStarsFunctions[currentGameWayDetail]) {
        newSelect = FiveStarsFunctions[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }

      return newSelect;
    },
  },
  LOTEC54S: {
    name: "四星",
    code: "LOTEC54S",
    ruleDescription: LOTEC5RuleEnum.LOTEC54S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      FOUR_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (FourStarsFunction[currentGameWayDetail]) {
        newSelect = FourStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTEC53S: {
    name: "三星",
    code: "LOTEC53S",
    ruleDescription: LOTEC5RuleEnum.LOTEC53S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      THREE_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (ThreeStarsFunction[currentGameWayDetail]) {
        newSelect = ThreeStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }

      return newSelect;
    },
  },
  LOTEC52S: {
    name: "二星",
    code: "LOTEC52S",
    ruleDescription: LOTEC5RuleEnum.LOTEC52S,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      TWO_STAR,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: {
      LOTEC52S2SH2ZXHZWS: true,
      LOTEC52S2SQ2ZXHZWS: true,
    },
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (TwoStarsFunction[currentGameWayDetail]) {
        if (TwoStarsFunction[currentGameWayDetail].defaultProcess) {
          Object.keys(selected).forEach((key) => {
            newSelect = newSelect?.concat(
              selected[key].map((s) => {
                return {
                  methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                    }${key ? `.${key}` : ""}`,
                  method: currentGameItems?.game_method[0]?.code,
                  content: s,
                  simpleMoney: currentBetNumber,
                  money: currentBetNumber,
                };
              })
            );
          });
        } else {
          newSelect = TwoStarsFunction[currentGameWayDetail].selectProcess({
            selected,
            currentBetNumber,
            currentGameWayDetail,
            method: currentGameItems?.game_method[0]?.code,
          });
        }
      }
      return newSelect;
    },
  },
  LOTEC5DWD: {
    name: "定位胆",
    code: "LOTEC5DWD",
    ruleDescription: LOTEC5RuleEnum.LOTEC5DWD,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.LOTEC5DWD`,
        method: currentGameItems?.game_method[0]?.code,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  LOTEC5BDW: {
    name: "不定位",
    code: "LOTEC54S",
    haveTags: true,
    ruleDescription: LOTEC5RuleEnum.LOTEC5BDW,
    confirmValidation: [
      MONEY_REQUIRED,
      FREE_POS,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (FreePositionFunction[currentGameWayDetail]) {
        newSelect = FreePositionFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTEC5QW: {
    name: "趣味",
    code: "LOTEC5QW",
    haveTags: true,
    ruleDescription: LOTEC5RuleEnum.LOTEC5QW,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_COUNT_LIMIT(1),
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: currentGameItems?.game_method[0]?.code,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  LOTEC5LH: {
    name: "龙虎",
    code: "LOTEC5LH",
    ruleDescription: LOTEC5RuleEnum.LOTEC5LH,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    hideOdds: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${key ? `.${key}` : ""}`,
              method: currentGameItems?.game_method[0]?.code,
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC5DXDS: {
    name: "大小单双",
    code: "LOTEC5DXDS",
    ruleDescription: LOTEC5RuleEnum.LOTEC5DXDS,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${key ? `.${key}` : ""}`,
              method: "LOTECDXDS",
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC5DN: {
    name: "斗牛",
    code: "LOTEC5DN",
    ruleDescription: LOTEC5RuleEnum.LOTEC5DN,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameMethod,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${currentGameMethod ? `.${currentGameMethod}` : ""}.${key}`,
              method: key,
              content: s,
              option: s,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC5TSH: {
    name: "特殊号",
    code: "LOTEC5TSH",
    ruleDescription: LOTEC5RuleEnum.LOTEC5TSH,
    haveTags: true,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameMethod,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${currentGameMethod ? `.${currentGameMethod}` : ""}`,
              method: key,
              content: s,
              option: s,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  LOTEC5RX: {
    name: "任选",
    code: "LOTEC5RX",
    haveTags: true,
    ruleDescription: LOTEC5RuleEnum.LOTEC5RX,
    confirmValidation: [
      MONEY_REQUIRED,
      OPTION_SELECT,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelect = [];
      if (OptionalFunction[currentGameWayDetail]) {
        newSelect = OptionalFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
};

export default LOTEC5SettingEnum;
