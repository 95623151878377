import Selector from "../../../components/Selector";

const LOTEC3SelectorEnum = {
  //=============================五星========================
  "LOTEC35S5SZXFS.5SZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC35S5SZXZH.5SZXZH": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  "LOTEC35S5SDX120.5SDX120": {
    component: Selector.numberLOTEC3, //todo
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC35S5SDX60.5SDX60": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC35S5SDX30.5SDX30": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC35S5SDX20.5SDX20": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC35S5SDX10.5SDX10": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC35S5SDX5.5SDX5": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================五星========================
  //=============================四星========================
  "LOTEC34S4SH4ZXFS.4SH4ZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SH4ZXZH.4SH4ZXZH": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SH4DX24.4SH4DX24": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SH4DX12.4SH4DX12": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SH4DX6.4SH4DX6": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SH4DX4.4SH4DX4": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SQ4ZXFS.4SQ4ZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SQ4ZXZH.4SQ4ZXZH": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SQ4DX24.4SQ4DX24": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SQ4DX12.4SQ4DX12": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SQ4DX6.4SQ4DX6": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC34S4SQ4DX4.4SQ4DX4": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },

  //=============================四星========================
  //=============================三星========================
  "LOTEC33S3SH3ZXFS.3SH3ZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SH3ZXHZ.3SH3ZXHZ": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC33S3SH3ZXKD.3SH3ZXKD": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SH3ZXHZWS.3SH3ZXHZWS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SH3DX3.3SH3DX3": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SH3DX6.3SH3DX6": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SC3ZXFS.3SC3ZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SC3ZXHZ.3SC3ZXHZ": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC33S3SC3ZXKD.3SC3ZXKD": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SC3ZXHZWS.3SC3ZXHZWS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SC3DX3.3SC3DX3": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SC3DX6.3SC3DX6": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SQ3ZXFS.3SQ3ZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SQ3ZXHZ.3SQ3ZXHZ": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC33S3SQ3ZXKD.3SQ3ZXKD": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SQ3ZXHZWS.3SQ3ZXHZWS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SQ3DX3.3SQ3DX3": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC33S3SQ3DX6.3SQ3DX6": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================三星========================
  //=============================二星========================
  "LOTEC32S2SH2ZXFS.2SH2ZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC32S2SH2ZXHZ.2SH2ZXHZ": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC32S2SH2ZXKD.2SH2ZXKD": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC32S2SH2ZXHZWS.2SH2ZXHZWS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTEC32S2SH2DXFS.2SH2DXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.2SH2DXFS`,
    },
  },
  "LOTEC32S2SQ2ZXFS.2SQ2ZXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC32S2SQ2ZXHZ.2SQ2ZXHZ": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC32S2SQ2ZXKD.2SQ2ZXKD": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC32S2SQ2ZXHZWS.2SQ2ZXHZWS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
    },
  },
  "LOTEC32S2SQ2DXFS.2SQ2DXFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.2SQ2DXFS`,
    },
  },
  //=============================二星========================
  //=============================定位胆========================
  "LOTEC3DWD.DWD": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================定位胆========================
  //=============================不定位========================
  "LOTEC3BDW3SBDWH31M.3SBDWH31M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWH31M`,
    },
  },
  "LOTEC3BDW3SBDWC31M.3SBDWC31M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWC31M`,
    },
  },
  "LOTEC3BDW3SBDWQ31M.3SBDWQ31M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWQ31M`,
    },
  },
  "LOTEC3BDW3SBDWH32M.3SBDWH32M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWH32M`,
    },
  },
  "LOTEC3BDW3SBDWC32M.3SBDWC32M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWC32M`,
    },
  },
  "LOTEC3BDW3SBDWQ32M.3SBDWQ32M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.3SBDWQ32M`,
    },
  },
  "LOTEC3BDW4SBDWH41M.4SBDWH41M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWH41M`,
    },
  },
  "LOTEC3BDW4SBDWQ41M.4SBDWQ41M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWQ41M`,
    },
  },
  "LOTEC3BDW4SBDWH42M.4SBDWH42M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWH42M`,
    },
  },
  "LOTEC3BDW4SBDWQ42M.4SBDWQ42M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWQ42M`,
    },
  },
  "LOTEC3BDW4SBDWH43M.4SBDWH43M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWH43M`,
    },
  },
  "LOTEC3BDW4SBDWQ43M.4SBDWQ43M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.4SBDWQ43M`,
    },
  },
  "LOTEC3BDW5SBDW5S1M.5SBDW5S1M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S1M`,
    },
  },
  "LOTEC3BDW5SBDW5S2M.5SBDW5S2M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S2M`,
    },
  },
  "LOTEC3BDW5SBDW5S3M.5SBDW5S3M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S3M`,
    },
  },
  "LOTEC3BDW5SBDW5S4M.5SBDW5S4M": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
      customTitle: `lottery.bet.title.ssc.5SBDW5S4M`,
    },
  },
  //=============================不定位========================
  //=============================趣味========================
  "LOTEC3QWTS1FFS.TS1FFS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3QWTSHSCS.TSHSCS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3QWTS3SBC.TS3SBC": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3QWTS4GFC.TS4GFC": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3QWTS5KDF.TS5KDF": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================趣味========================
  //=============================龙虎========================
  "LOTEC3LH.LOTECLH": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
    },
  },
  //=============================龙虎========================
  //=============================大小单双========================
  "LOTEC3DXDS.LOTECDXDS": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: false,
      mutiple: true,
      shape: "rectangle",
      hideQuickSelect: true,
      hideOdds: true,
    },
  },
  //=============================大小单双========================
  //=============================斗牛========================
  "LOTEC3DN.DN": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC3DN.DN_GROUP": {
    component: Selector.rectangle,
    params: {
      hideQuickSelect: true,
      mutiple: true,
    },
  },
  //=============================斗牛========================
  //=============================特殊号========================
  "LOTEC3TSHTSHH3.TSHH3": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC3TSHTSHC3.TSHC3": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  "LOTEC3TSHTSHQ3.TSHQ3": {
    component: Selector.rectangle,
    params: {
      mutiple: true,
      hideQuickSelect: true,
    },
  },
  //=============================特殊号========================
  //=============================任选========================
  "LOTEC3RXR2FX.RXR2FX": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3RXR2DX.RXR2DX": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3RXR3FX.RXR3FX": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3RXR3Z3.RXR3Z3": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3RXR3Z6.RXR3Z6": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  "LOTEC3RXR4FX.RXR4FX": {
    component: Selector.numberLOTEC3,
    params: {
      textNoI18n: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //=============================任选========================
};

export default LOTEC3SelectorEnum;
