import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";

function MarkSix({ data, isAllGameResult = false }) {
  const { t } = useTranslation();
  ////old
  // const result = data.lottery_number?.split(",");
  // const [nowZodiacs, setNowZodiacs] = useState();
  // const [markSixColorDict, setMarkSixColorDict] = useState();
  // const nowYear = new Date().getFullYear();
  // const zodiacsProccessor = () => {
  //   //2008 rat year
  //   const nowZodiacIndex = (nowYear - 2008) % 12;
  //   const firstGroup = zodiacs.slice(0, nowZodiacIndex + 1);
  //   const secondGroup = zodiacs.slice(nowZodiacIndex + 1);
  //   const finalZodiac = firstGroup.reverse().concat(secondGroup.reverse());
  //   // console.log("finalZodiac", finalZodiac);
  //   setNowZodiacs(finalZodiac);
  // };
  // const numberProccessor = () => {
  //   if (!nowZodiacs) return;
  //   Object.keys(numberColors).map((number) => {
  //     const zodiacIndex = (number - 1) % 12;
  //     numberColors[number] = {
  //       ...numberColors[number],
  //       zodiac: nowZodiacs[zodiacIndex],
  //     };
  //   });
  //   // console.log("numberColors", numberColors);
  //   setMarkSixColorDict(numberColors);
  // };

  // useEffect(() => {
  //   zodiacsProccessor();
  // }, []);

  // useEffect(() => {
  //   numberProccessor();
  // }, [nowZodiacs]);

  //new
  const [finalResult, setFinalResult] = useState([]);
  const resultProccessor = (data) => {
    if (!data.lottery_number) {
      setFinalResult([]);
      return;
    }
    const nums = data.lottery_number.split("|");
    const allNums = nums[0].split(",").concat(nums[1]);
    const colors = data.data.color.split("|");
    const allColors = colors[0].split(",").concat(colors[1]);
    const zodiac = data.data.zodiac_name.split("|");
    const allZodiacs = zodiac[0].split(",").concat(zodiac[1]);
    let formatResult = [];
    allNums.map((item, index) => {
      formatResult.push({
        number: item,
        color: allColors[index],
        zodiac: allZodiacs[index],
      });
    });
    setFinalResult(formatResult);
  };
  useEffect(() => {
    if (!!data) resultProccessor(data);
  }, [data]);

  return (
    <div className={`${styles.markSixResult}`}>
      <div className={`${styles.row}`}>
        {!!finalResult &&
          finalResult?.map((item, index) => {
            return (
              <div className={`${styles.rowItemContainer}`} key={"marksix" + index}>
                <div className={`${styles.rowItem}`}>
                  <div className={`${styles.ball} ${styles[item.color]}`}>
                    <span>{item.number}</span>
                  </div>
                  <div className={`${styles.zodiac} ${isAllGameResult ? styles.ellipsis : ""}`}>
                    {t(`zodiac.${item.zodiac}`)}
                    {/* {markSixColorDict[resultNum]?.zodiac} */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MarkSix;
