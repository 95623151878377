import React from "react";

function RuleIcon({ size = 32, color = "#ffffff" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.998 29c-.038 0-.076-.005-.108-.005l-8.955-.994A3.002 3.002 0 0 1 2 25V6c0-1.656 1.349-3 3-3 .037 0 .074.006.107.006l8.955.994a3.002 3.002 0 0 1 2.935 3v18.997a3.005 3.005 0 0 1-3 3.005zM4.95 5a1 1 0 0 0-.952.999v18.997c0 .553.452 1 1 1 .038 0 .075.005.108.005l8.939.994a1 1 0 0 0 .951-1V7c0-.548-.451-1-1-1-.037 0-.075-.005-.107-.005l-8.94-.995zm14.083 23.882a1 1 0 0 1-.994-.892.994.994 0 0 1 .887-1.102l7.961-.887c.038-.005.07-.005.107-.005.554 0 1-.447 1-1V5.999c0-.538-.424-.973-.951-1l-7.907.876a1 1 0 0 1-1.102-.887.994.994 0 0 1 .887-1.102L26.893 3c.033-.005.07-.005.108-.005 1.655 0 3 1.35 3 3v19.002a3.002 3.002 0 0 1-2.936 3l-7.912.88c-.043.006-.08.006-.119.006zm-6.283-17.696H6.246c-.553 0-1-.446-1-1 0-.553.447-1 1-1h6.505c.553 0 1 .447 1 1 0 .549-.447 1-1 1zm-.006 5.768h-6.51c-.553 0-1-.446-1-1 0-.553.447-1 1-1h6.505c.553 0 1 .447 1 1a.99.99 0 0 1-.995 1zm.006 5.746H6.246c-.553 0-1-.446-1-1 0-.553.447-1 1-1h6.505c.553 0 1 .447 1 1 0 .554-.447 1-1 1zm12.997-11.487h-6.504c-.554 0-1-.446-1-1 0-.553.446-1 1-1h6.504c.554 0 1 .447 1 1 0 .554-.446 1-1 1zm0 5.773h-6.504c-.554 0-1-.446-1-1 0-.553.446-1 1-1h6.504c.554 0 1 .447 1 1 0 .549-.451 1-1 1zm0 5.747h-6.504c-.554 0-1-.446-1-1s.446-1 1-1h6.504c.554 0 1 .446 1 1s-.446 1-1 1z"
        fill={color}
        fill-rule="nonzero"
      />
    </svg>
  );
}

export default RuleIcon;
