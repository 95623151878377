import { request } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";
import { getCurrency } from "../../plugins/utils";

const CURRENCY = getCurrency();

export const apiGetGameWay = (params) =>
  request("get", `/${CURRENCY}/game/way/${params.lottery}`, params, true);

export const apiGetGameWayMethod = (params) =>
  request(
    "get",
    `/${CURRENCY}/game/way/method/${params.game_way_type}`,
    params,
    true
  );

export const apiGetGameWayDetailMethod = (params) =>
  request(
    "get",
    `/${CURRENCY}/game/way/detail/method/${params.game_way_detail_type}`,
    params,
    true
  );
export const apiPostGameBet = (params) =>
  request("post", `/${CURRENCY}/game/bet`, params, true);

//lottery navbar drawer
export const apiGetGame = (params) =>
  request("get", `/${CURRENCY}/game/category/LOT`, params, true);
//
