const LOTVNNEC1RuleEnum = {
  LOTVNNEC1BX: {
    hint: {
      LOTVNNEC1BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.hint",
      LOTVNNEC1BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.hint",
      LOTVNNEC1BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.hint",
      LOTVNNEC1BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.hint",
      LOTVNNEC1BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.hint",
      LOTVNNEC1BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.hint",
      LOTVNNEC1BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.hint",
      LOTVNNEC1BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.hint",
      LOTVNNEC1BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.hint",
      LOTVNNEC1BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.hint",
    },
    description: {
      LOTVNNEC1BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.description",
      LOTVNNEC1BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.description",
      LOTVNNEC1BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.description",
      LOTVNNEC1BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.description",
      LOTVNNEC1BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.description",
      LOTVNNEC1BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.description",
      LOTVNNEC1BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.description",
      LOTVNNEC1BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.description",
      LOTVNNEC1BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.description",
      LOTVNNEC1BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.description",
    },
    example: {
      LOTVNNEC1BXB2BQ2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZX.example",
      LOTVNNEC1BXB2BH2ZX: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZX.example",
      LOTVNNEC1BXB2BQ2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BQ2ZXBDW.example",
      LOTVNNEC1BXB2BH2ZXBDW: "lottery.rules.modal.LOTVNNEC5BXB2BH2ZXBDW.example",
      LOTVNNEC1BXB3BQ3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BQ3ZX.example",
      LOTVNNEC1BXB3BH3ZX: "lottery.rules.modal.LOTVNNEC5BXB3BH3ZX.example",
      LOTVNNEC1BXB4BQ4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BQ4ZX.example",
      LOTVNNEC1BXB4BH4ZX: "lottery.rules.modal.LOTVNNEC5BXB4BH4ZX.example",
      LOTVNNEC1BXQBBQ2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBQ2ZX.example",
      LOTVNNEC1BXQBBH2ZX: "lottery.rules.modal.LOTVNNEC5BXQBBH2ZX.example",
    },
  },
  LOTVNNEC1CG: {
    hint: {
      LOTVNNEC1CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.hint",
      LOTVNNEC1CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.hint",
      LOTVNNEC1CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.hint",
    },
    description: {
      LOTVNNEC1CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.description",
      LOTVNNEC1CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.description",
      LOTVNNEC1CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.description",
    },
    example: {
      LOTVNNEC1CG2CZX: "lottery.rules.modal.LOTVNNEC5CG2CZX.example",
      LOTVNNEC1CG3CZX: "lottery.rules.modal.LOTVNNEC5CG3CZX.example",
      LOTVNNEC1CG4CZX: "lottery.rules.modal.LOTVNNEC5CG4CZX.example",
    },
  },
  LOTVNNEC12S: {
    hint: {
      LOTVNNEC12STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.hint",
      LOTVNNEC12S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.hint",
      LOTVNNEC12ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.hint",
      LOTVNNEC12STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.hint",
      LOTVNNEC12S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.hint",
      LOTVNNEC12ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.hint",
      LOTVNNEC12S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.hint",
    },
    description: {
      LOTVNNEC12STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.description",
      LOTVNNEC12S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.description",
      LOTVNNEC12ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.description",
      LOTVNNEC12STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.description",
      LOTVNNEC12S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.description",
      LOTVNNEC12ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.description",
      LOTVNNEC12S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.description",
    },
    example: {
      LOTVNNEC12STJQ2ZX: "lottery.rules.modal.LOTVNNEC52STJQ2ZX.example",
      LOTVNNEC12S1JQ2ZX: "lottery.rules.modal.LOTVNNEC52S1JQ2ZX.example",
      LOTVNNEC12ST7Q2ZX: "lottery.rules.modal.LOTVNNEC52ST7Q2ZX.example",
      LOTVNNEC12STJH2ZX: "lottery.rules.modal.LOTVNNEC52STJH2ZX.example",
      LOTVNNEC12S1JH2ZX: "lottery.rules.modal.LOTVNNEC52S1JH2ZX.example",
      LOTVNNEC12ST7H2ZX: "lottery.rules.modal.LOTVNNEC52ST7H2ZX.example",
      LOTVNNEC12S7J2SZX: "lottery.rules.modal.LOTVNNEC52S7J2SZX.example",
    },
  },
  LOTVNNEC13S: {
    hint: {
      LOTVNNEC13STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.hint",
      LOTVNNEC13S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.hint",
      LOTVNNEC13ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.hint",
      LOTVNNEC13STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.hint",
      LOTVNNEC13STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.hint",
      LOTVNNEC13STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.hint",
      LOTVNNEC13S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.hint",
      LOTVNNEC13ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.hint",
      LOTVNNEC13STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.hint",
      LOTVNNEC13STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.hint",
      LOTVNNEC13S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.hint",
    },
    description: {
      LOTVNNEC13STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.description",
      LOTVNNEC13S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.description",
      LOTVNNEC13ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.description",
      LOTVNNEC13STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.description",
      LOTVNNEC13STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.description",
      LOTVNNEC13STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.description",
      LOTVNNEC13S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.description",
      LOTVNNEC13ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.description",
      LOTVNNEC13STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.description",
      LOTVNNEC13STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.description",
      LOTVNNEC13S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.description",
    },
    example: {
      LOTVNNEC13STJQ3ZX: "lottery.rules.modal.LOTVNNEC53STJQ3ZX.example",
      LOTVNNEC13S1JQ3ZX: "lottery.rules.modal.LOTVNNEC53S1JQ3ZX.example",
      LOTVNNEC13ST6Q3ZX: "lottery.rules.modal.LOTVNNEC53ST6Q3ZX.example",
      LOTVNNEC13STJQ3DX3: "lottery.rules.modal.LOTVNNEC53STJQ3DX3.example",
      LOTVNNEC13STJQ3DX6: "lottery.rules.modal.LOTVNNEC53STJQ3DX6.example",
      LOTVNNEC13STJH3ZX: "lottery.rules.modal.LOTVNNEC53STJH3ZX.example",
      LOTVNNEC13S1JH3ZX: "lottery.rules.modal.LOTVNNEC53S1JH3ZX.example",
      LOTVNNEC13ST6H3ZX: "lottery.rules.modal.LOTVNNEC53ST6H3ZX.example",
      LOTVNNEC13STJH3DX3: "lottery.rules.modal.LOTVNNEC53STJH3DX3.example",
      LOTVNNEC13STJH3DX6: "lottery.rules.modal.LOTVNNEC53STJH3DX6.example",
      LOTVNNEC13S6J3SZX: "lottery.rules.modal.LOTVNNEC53S6J3SZX.example",
    },
  },
  LOTVNNEC14S: {
    hint: {
      LOTVNNEC14STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.hint",
      LOTVNNEC14S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.hint",
      LOTVNNEC14STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.hint",
      LOTVNNEC14S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.hint",
    },
    description: {
      LOTVNNEC14STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.description",
      LOTVNNEC14S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.description",
      LOTVNNEC14STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.description",
      LOTVNNEC14S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.description",
    },
    example: {
      LOTVNNEC14STJQ4ZX: "lottery.rules.modal.LOTVNNEC54STJQ4ZX.example",
      LOTVNNEC14S1JQ4ZX: "lottery.rules.modal.LOTVNNEC54S1JQ4ZX.example",
      LOTVNNEC14STJH4ZX: "lottery.rules.modal.LOTVNNEC54STJH4ZX.example",
      LOTVNNEC14S1JH4ZX: "lottery.rules.modal.LOTVNNEC54S1JH4ZX.example",
    },
  },
  LOTVNNEC1ZXBZ: {
    hint: {
      LOTVNNEC1ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.hint",
      LOTVNNEC1ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.hint",
      LOTVNNEC1ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.hint",
      LOTVNNEC1ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.hint",
    },
    description: {
      LOTVNNEC1ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.description",
      LOTVNNEC1ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.description",
      LOTVNNEC1ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.description",
      LOTVNNEC1ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.description",
    },
    example: {
      LOTVNNEC1ZXBZH24BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH24BZ.example",
      LOTVNNEC1ZXBZH26BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH26BZ.example",
      LOTVNNEC1ZXBZH28BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH28BZ.example",
      LOTVNNEC1ZXBZH210BZ: "lottery.rules.modal.LOTVNNEC5ZXBZH210BZ.example",
    },
  },
  LOTVNNEC1DXDS: {
    hint: "lottery.rules.modal.LOTVNNEC5DXDS.hint",
    description: "lottery.rules.modal.LOTVNNEC5DXDS.description",
    example: "lottery.rules.modal.LOTVNNEC5DXDS.example",
  },
};

export default LOTVNNEC1RuleEnum;
