import React from "react";

const Question = ({ size = "68", color1 = "#F5F135", color2 = "#F47035" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 68 68"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="10.812%"
          y1="12.454%"
          x2="85.08%"
          y2="86.774%"
          id="mxl1l8qx7a"
        >
          <stop stop-color={color1} offset="0%" />
          <stop stop-color={color2} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <circle fill="url(#mxl1l8qx7a)" cx="34" cy="34" r="34" />
        <path
          d="M32.648 47.158a5.384 5.384 0 0 1 3.493.809 5.458 5.458 0 0 1 2.45 3.733 5.436 5.436 0 0 1-.758 3.745c-.795 1.285-2.144 2.211-3.63 2.467a5.33 5.33 0 0 1-3.548-.586 5.447 5.447 0 0 1-2.045-7.555c.848-1.437 2.384-2.436 4.038-2.613zM32.373 10h3.27c3.84.44 7.436 2.576 9.69 5.728a14.076 14.076 0 0 1 2.66 8.649 14.11 14.11 0 0 1-2.792 8.04 14.043 14.043 0 0 1-7.438 5.124l.001 2.8c-.001.502.014 1.012-.094 1.507a4.467 4.467 0 0 1-2.346 3.096 4.398 4.398 0 0 1-3.49.218c-1.693-.583-2.925-2.282-2.946-4.082-.007-2.533-.007-5.065 0-7.598.017-1.824 1.28-3.54 3-4.11.71-.27 1.478-.24 2.223-.248a5.118 5.118 0 0 0 3.817-1.85 5.155 5.155 0 0 0 1.15-3.929A5.151 5.151 0 0 0 37.242 20a5.095 5.095 0 0 0-7.268.804 5.154 5.154 0 0 0-1.096 3.189c.012.77-.144 1.547-.504 2.23a4.459 4.459 0 0 1-2.861 2.26 4.425 4.425 0 0 1-3.72-.75 4.463 4.463 0 0 1-1.775-3.166c-.21-3.973 1.415-7.981 4.277-10.72A13.987 13.987 0 0 1 32.373 10z"
          fill="#41444B"
        />
      </g>
    </svg>
  );
};

export default Question;
