import req from "./https.js";
import { MAIN_API, PROTOCOL, META_USER_API, META_MAIN_API } from "../config";
import localStorageKey from "../enumerations/localStorageKey.js";
import { isAllowedWithoutCurrency, toLogin } from "./utils.js";
import { getCurrency } from "../plugins/utils.js";

// if(!CURRENCY && isAllowedWithoutCurrency(window.location.pathname) === false) {
//   toLogin();
// }

const CURRENCY = getCurrency();

export const request = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${MAIN_API}${path}`, data, needAuth);

export const requestMetaUser = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${META_USER_API}${path}`, data, needAuth);

export const requestMetaMain = (method, path, data = null, needAuth = true) =>
  req(method, `${PROTOCOL}://${CURRENCY}${META_MAIN_API}${path}`, data, needAuth);

// export const requestData = (method, path, data = null, needAuth = false) =>
//   req(method, `${PROTOCOL}://${DATA_API}${path}`, data, needAuth);

// export const requestMain = (method, path, data = null, needAuth = false) =>
//   req(method, `${PROTOCOL}://${MAIN_API}${path}`, data, needAuth);

// export const requestLogin = (method, path, data = null, needAuth = false) =>
//   req(method, `${PROTOCOL}://${LOGIN_API}${path}`, data, needAuth);

// export const requestPayment = (method, path, data = null, needAuth = false) =>
//   req(method, `${PROTOCOL}://${PAYMENT_API}${path}`, data, needAuth);

// export const requestImage = (method, path, data = null, needAuth = false) =>
//   req(method, `${PROTOCOL}://${IMAGE_API}${path}`, data, needAuth);
