import { request } from "../../apis";
import { getCurrency } from "../../plugins/utils";

const CURRENCY = getCurrency();

export const apiGetBetHistory = (params) =>
  request("get", `/${CURRENCY}/game/bet/history`, params, true);

export const apiGetBetDetail = (params) =>
  request(
    "get",
    `/${CURRENCY}/game/bet/history/${params.game_bet_id}`,
    params,
    true
  );

export const apiGetGameList = (params) =>
  request("get", `/${CURRENCY}/game/category/LOT`, params, true);

export const updateCancelBet = (params) =>
  request(
    "put",
    `/${CURRENCY}/game/bet/${params.trans_id}/cancel`,
    params,
    true
  );
