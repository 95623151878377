
const MAIN_API = "game77lotteryapi.biglotto.game";
const META_USER_API = "globaluser.biglotto.game";
const META_MAIN_API = "api.biglotto.game";
const MOBILE_URL = "https://game77lottery.biglotto.game";
const PROTOCOL = "https";
const SOCKET_URL = "game77lotterydata.biglotto.game/ws";
const WS_PROTOCOL = "wss";
const CDN_URL = "https://dl.biglotto.game";
const DECIMAL_SEPARATOR = '.';
const DEFAULT_CURRENCY = 'INR';
const DEFAULT_LANG = 'en';
const FIXED_NUMBER = 2;
const MAX_DECIMAL_POINT = 2;
const MIN_DECIMAL_POINT = 2;
const PRODUCTION_NAME_DEV = 'bl';
const PRODUCTION_NAME_DISPLAY = 'Big Lotto';
const THOUSANDS_SEPARATOR = ',';
const BANNER = ["/imgs/banner/banner_01.png","/imgs/banner/banner_02.png","/imgs/banner/banner_03.png"]
const DEFAULT_BET_AMOUNT = 1;
const QUICK_MONEY = [10,50,100,500,1000]
const DISABLE_BET_BUTTON_TIMER = 15;

 export {
MAIN_API,
META_USER_API,
META_MAIN_API,
MOBILE_URL,
PROTOCOL,
SOCKET_URL,
WS_PROTOCOL,
CDN_URL,
DECIMAL_SEPARATOR,
DEFAULT_CURRENCY,
DEFAULT_LANG,
FIXED_NUMBER,
MAX_DECIMAL_POINT,
MIN_DECIMAL_POINT,
PRODUCTION_NAME_DEV,
PRODUCTION_NAME_DISPLAY,
THOUSANDS_SEPARATOR,
BANNER,
DEFAULT_BET_AMOUNT,
QUICK_MONEY,
DISABLE_BET_BUTTON_TIMER,
}