export function fraction(n) {
  let result = 1;
  while (n > 0) {
    result *= n;
    n -= 1;
  }
  return result;
}

export function combination(n, m) {
  return fraction(n) / (fraction(n - m) * fraction(m));
}

export function permutation(n, m) {
  return fraction(n) / fraction(n - m);
}

export const combinationResult = (arr, size) => {
  let allResult = [];
  const processor = (arr, size, result) => {

    let arrLen = arr.length;
    if (size > arrLen) {
      return;
    }
    if (size == arrLen) {
      allResult.push([].concat(result, arr))
    } else {
      for (let i = 0; i < arrLen; i++) {
        let newResult = [].concat(result);
        newResult.push(arr[i]);

        if (size == 1) {
          allResult.push(newResult);
        } else {
          let newArr = [].concat(arr);
          newArr.splice(0, i + 1);
          processor(newArr, size - 1, newResult);
        }
      }
    }
  }
  processor(arr, size, []);

  return allResult;
}