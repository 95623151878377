import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomPopup } from "../../../../components";
import RuleIcon from "../../icon/RuleIcon";
import styles from "./style.module.scss";

function OddsAndRuleArea({
  currentGameItems,
  currentCategoryGameSetting,
  gameWaySetting,
  currentSelected,
}) {
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [nowOdds, setNowOdds] = useState(0);
  const { t } = useTranslation();
  const hideRuleButton =
    typeof currentCategoryGameSetting?.hideRuleButton === "object"
      ? currentCategoryGameSetting?.hideRuleButton[gameWaySetting]
      : currentCategoryGameSetting?.hideRuleButton;
  const hint =
    typeof currentCategoryGameSetting?.ruleDescription?.hint === "object"
      ? currentCategoryGameSetting?.ruleDescription?.hint[gameWaySetting]
      : currentCategoryGameSetting?.ruleDescription?.hint;
  const description =
    typeof currentCategoryGameSetting?.ruleDescription?.description === "object"
      ? currentCategoryGameSetting?.ruleDescription?.description[gameWaySetting]
      : currentCategoryGameSetting?.ruleDescription?.description;
  const example =
    typeof currentCategoryGameSetting?.ruleDescription?.example === "object"
      ? currentCategoryGameSetting?.ruleDescription?.example[gameWaySetting]
      : currentCategoryGameSetting?.ruleDescription?.example;
  const gameRuleItems = [
    {
      title: t("lottery.rules.modal.hint.title"),
      content: hint,
    },
    {
      title: t("lottery.rules.modal.description.title"),
      content: description,
    },
    {
      title: t("lottery.rules.modal.example.title"),
      content: example,
    },
  ];

  const getType4Odds = (gameMethod) => {
    if (currentSelected == {} || !currentSelected) {
      return;
    } else {
      // console.log("odds", gameMethod?.option[0]?.odds);
      const selectedLength = Object.keys(currentSelected).reduce(
        (partialSum, key) => partialSum + currentSelected[key]?.length,
        0,
      );
      const oddsSetting = gameMethod?.option[0]?.odds?.find((item) => {
        return item.count == selectedLength;
      });
      if (!!oddsSetting?.odds) {
        setNowOdds(oddsSetting?.odds);
      } else {
        setNowOdds(0);
      }
      if (
        selectedLength > gameMethod?.option[0]?.odds?.length &&
        !currentCategoryGameSetting?.hideOdds4ErrorMsg
      ) {
        setErrorMsg(
          t("validate.zodiac.maxLimit", {
            max: gameMethod?.option[0]?.odds?.length,
          }),
        );
        setShowErrorModal(true);
      }
    }
  };

  const getOdds = () => {
    if (!currentGameItems) return;
    const nowGameMethod = currentGameItems?.game_method?.find(() => {
      return true;
    });
    if (
      nowGameMethod?.odds_type == 4 &&
      currentGameItems.game_way_type !== "LOTEC5LH" &&
      currentGameItems.game_way_type !== "LOTEC3LH" &&
      currentGameItems.game_way_type !== "LOTEC1LH" &&
      currentGameItems.game_way_type !== "LOTEC10LH"
    ) {
      getType4Odds(nowGameMethod);
    } else {
      const odds = nowGameMethod?.odds;
      setNowOdds(odds);
    }
  };

  useEffect(() => {
    getOdds();
  }, [currentGameItems, currentSelected]);

  // useEffect(() => {
  //   console.log("currentSelected", currentSelected);
  // }, [currentSelected]);

  return (
    <div className={`${styles.oddsAndRuleArea}`}>
      {currentGameItems?.game_method?.find(() => {
        return true;
      })?.odds_type == 1 || currentCategoryGameSetting?.hideOdds ? (
        <div />
      ) : (
        <div className={styles.odds}>
          <div className={styles.oddsBox}>
            <span className={styles.title}>{t("normal.odds.title")}</span>
            <span className={styles.content}>
              {t("normal.odds", {
                odds: nowOdds,
              })}
            </span>
          </div>
          <div className={styles.secondBackground} />
        </div>
      )}
      {hideRuleButton ? (
        <div />
      ) : (
        <div
          className={styles.ruleButton}
          onClick={() => {
            setShowRuleModal(true);
          }}
        >
          <div className={styles.ruleText}>
            <RuleIcon size={14} color="var(--color-white)" />
            <span style={{ marginLeft: "5px" }}>{t("lottery.rules.button.text")}</span>
          </div>
          <div className={styles.secondBackground} />
        </div>
      )}
      <CustomPopup
        title={t("lottery.rules.button.text")}
        singleButton={true}
        rightButtonText={t("lottery.rules.modal.button.ok")}
        visible={showRuleModal}
        closeOnMaskClick={true}
        onClose={() => {
          setShowRuleModal(false);
        }}
        onMainButtonPress={() => {
          setShowRuleModal(false);
        }}
      >
        <div className={styles.ruleModal}>
          {gameRuleItems?.map((item, index) => {
            return (
              <div className={styles.blockItem} key={"rule" + index}>
                <div className={styles.itemTitle}>{item.title}</div>
                <div
                  className={styles.itemContent}
                  dangerouslySetInnerHTML={{ __html: t(item.content) }}
                />
              </div>
            );
          })}
        </div>
      </CustomPopup>
      <CustomPopup
        title={t("popup.periodEnd.title")}
        rightButtonText={t("popup.periodEnd.button")}
        visible={showErrorModal}
        singleButton={true}
        onClose={() => {
          setShowErrorModal(false);
        }}
        onSecondaryButtonPress={() => {}}
        onMainButtonPress={() => {}}
      >
        <div>{errorMsg}</div>
      </CustomPopup>
    </div>
  );
}

export default OddsAndRuleArea;
