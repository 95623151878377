import {
  MONEY_REQUIRED,
  SELECTED_NON_NULL,
  SELECTED_COUNT_LIMIT,
  MAX_MIN_BET,
  NOT_ENOUGH_MONEY,
  MAX_PAYOUT,
  BALL_COUNT_EACH_GROUP,
  TWO_SAME_SELECT,
  DUPLICATE_SELECT,
} from "../../valudateFunctionEnum";
import rule from "../../ruleDescription/OFLOTPK10/OFLOTPK10LARuleEnum";

function selectedCount(selected) {
  let newRequest = [];
  Object.keys(selected)?.forEach((key) => {
    newRequest = newRequest.concat(selected[key]);
  });
  return newRequest.length ?? 1;
}

const generalSelectProcess = ({
  selected,
  currentBetNumber,
  currentGameWayDetail,
}) => {
  console.log("currentGameWayDetail", currentGameWayDetail);
  const newSelect = [];
  Object.keys(selected).map((key, index) => {
    selected[key]?.map((option, index) => {
      if (
        key == "GYJHDX" ||
        key == "GYJHDS" ||
        // key == "NNFB" ||
        key == "NNPB"
      ) {
        newSelect.push({
          methodText: `gameWay.items.${key}`,
          method: key,
          content: `lottery.item.${option}`,
          option: option,
          simpleMoney: currentBetNumber,
          count: 1,
        });
      } else if (key == "NNFB") {
        newSelect.push({
          methodText: `gameWay.items.${key}`,
          method: key,
          content: `lottery.item.${option}`,
          option: option,
          simpleMoney: currentBetNumber,
          count: 5,
        });
      } else {
        newSelect.push({
          methodText: `gameWay.items${currentGameWayDetail ? "." + currentGameWayDetail : ""
            }.${key}`,
          method: key,
          content: option,
          option: option,
          simpleMoney: currentBetNumber,
          count: 1,
        });
      }
    });
  });
  return newSelect;
};

const generalRequestProcess = ({
  selected,
  currentBetNumber,
  currentGameWay,
  currentGameWayDetail,
  gameCategoryType,
  gameCategoryDetailType,
  lotteryType,
  period,
  requestItems,
}) => {
  const request = {
    game_category_type: gameCategoryType,
    game_category_detail_type: gameCategoryDetailType,
    game_type: lotteryType,
    game_way_type: currentGameWay,
    game_way_detail_type: currentGameWayDetail,
    period: period,
    choice_number: [],
  };
  request.choice_number = requestItems.map((i) => ({
    game_method_code: i?.method,
    bet_point: i?.simpleMoney,
    bet_content: i?.option,
  }));
  return request;
};
const OFLOTPK10LASettingEnum = {
  OFLOTPK10LAGYJH: {
    name: "冠亚军和",
    code: "OFLOTPK10LAGYJH",
    ruleDescription: rule.OFLOTPK10LAGYJH,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: generalSelectProcess,
    requestProcess: generalRequestProcess,
  },
  OFLOTPK10LAQY: {
    name: "前一",
    code: "OFLOTPK10LAQY",
    ruleDescription: rule.OFLOTPK10LAQY,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({ selected, currentBetNumber, currentGameWay }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWay}`,
        method: "QY",
        content: flatten,
        simpleMoney: currentBetNumber,
        count: selectedCount(selected),
      });
      return newSelect;
    },
  },
  OFLOTPK10LAQE: {
    name: "前二",
    code: "OFLOTPK10LAQE",
    ruleDescription: rule.OFLOTPK10LAQE,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
      BALL_COUNT_EACH_GROUP,
      DUPLICATE_SELECT,
      { game: "OFLOTPK10LAQE", function: TWO_SAME_SELECT },
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWay}`,
        method: "QE",
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  OFLOTPK10LAQS: {
    name: "前三",
    code: "OFLOTPK10LAQS",
    ruleDescription: rule.OFLOTPK10LAQS,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
      BALL_COUNT_EACH_GROUP,
      DUPLICATE_SELECT,
    ],
    selectedProcess: ({ selected, currentBetNumber, currentGameWay }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWay}`,
        method: "QS",
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  OFLOTPK10LADWD: {
    name: "定位胆",
    code: "OFLOTPK10LADWD",
    ruleDescription: rule.OFLOTPK10LADWD,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      const newSelect = [];
      let count = 0;

      const methodDict = {
        OFLOTPK10LADWD0105: "DWD0105",
        OFLOTPK10LADWD0110: "DWD0110",
        OFLOTPK10LADWD0610: "DWD0610",
      };

      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: methodDict[currentGameWayDetail],
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  OFLOTPK10LADWD0105: {
    name: "1-5名",
    code: "OFLOTPK10LADWD0105",
    ruleDescription: rule.OFLOTPK10LADWD,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: "DWD0105",
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  OFLOTPK10LADWD0610: {
    name: "6-10名",
    code: "OFLOTPK10LADWD0610",
    ruleDescription: {},
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail }) => {
      const newSelect = [];
      let count = 0;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count + selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: "DWD0610",
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
  },
  OFLOTPK10LAWX: {
    name: " 五行",
    code: "OFLOTPK10LAWX",
    ruleDescription: rule.OFLOTPK10LAWX,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      const keys = Object.keys(selected);
      const newSelect = [];
      keys?.map((key, index) => {
        const allResults = selected[key];
        let translationKeynames = [];
        allResults?.map((option) => {
          translationKeynames.push(`lottery.item.${option}`);
        });
        newSelect.push({
          methodText: `gameWay.items.${currentGameWayDetail}`,
          method: currentGameItems?.game_method[0].code,
          content: translationKeynames,
          option: allResults.join(","),
          simpleMoney: currentBetNumber,
          count: allResults.length,
        });
      });
      return newSelect;
    },
    requestProcess: generalRequestProcess,
  },
  OFLOTPK10LADXDS: {
    name: "大小单双",
    code: "OFLOTPK10LADXDS",
    ruleDescription: rule.OFLOTPK10LADXDS,
    hideOdds: true,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    hideOdds4ErrorMsg: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameItems,
      currentGameWayDetail,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWayDetail}.${key}.${s}`,
              method: currentGameItems?.game_method[0].code,
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });
      return newSelected;
    },
  },
  OFLOTPK10LALH: {
    name: "龙虎",
    code: "OFLOTPK10LALH",
    hideOdds: true,
    ruleDescription: rule.OFLOTPK10LALH,
    hideOdds4ErrorMsg: true,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({
      selected,
      currentBetNumber,
      currentGameWay,
      currentGameWayDetail,
      currentGameItems,
    }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${currentGameWayDetail ? `.${currentGameWayDetail}` : ""
                }${key ? `.${key}` : ""}`,
              method: currentGameItems?.game_method[0]?.code,
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          })
        );
      });

      return newSelected;
    },
  },
  OFLOTPK10LANN: {
    name: "牛牛",
    code: "OFLOTPK10LANN",
    ruleDescription: rule.OFLOTPK10LANN,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: generalSelectProcess,
    requestProcess: generalRequestProcess,
  },
};

export default OFLOTPK10LASettingEnum;
