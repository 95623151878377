import {
  FourStarsFunction,
  OptionalFunction,
  PackageSelectionFunction,
  ParlayFunction,
  ThreeStarsFunction,
  TwoStarsFunction,
} from "../../VNNorthEveryColorEnums/VNNEC120Function";
import {
  FOUR_STAR,
  OPTIONAL,
  PACKAGE,
  PARLAY,
  THREE_STAR,
  TWO_STAR,
} from "../../VNNorthEveryColorEnums/VNNEC120Validation";
import LOTVNNEC120RuleEnum from "../../ruleDescription/LOTVNNEC/LOTVNNEC120RuleEnum";
import {
  MAX_MIN_BET,
  MAX_PAYOUT,
  MONEY_REQUIRED,
  NOT_ENOUGH_MONEY,
  SELECTED_NON_NULL,
} from "../../valudateFunctionEnum";

const LOTVNNEC120SettingEnum = {
  LOTVNNEC120BX: {
    name: "包选",
    code: "LOTVNNEC120BX",
    ruleDescription: LOTVNNEC120RuleEnum.LOTVNNEC120BX,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, PACKAGE, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (PackageSelectionFunction[currentGameWayDetail]) {
        newSelect = PackageSelectionFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTVNNEC120CG: {
    name: "串关",
    code: "LOTVNNEC120CG",
    ruleDescription: LOTVNNEC120RuleEnum.LOTVNNEC120CG,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, PARLAY, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (ParlayFunction[currentGameWayDetail]) {
        newSelect = ParlayFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
  LOTVNNEC1202S: {
    name: "二星",
    code: "LOTVNNEC1202S",
    ruleDescription: LOTVNNEC120RuleEnum.LOTVNNEC1202S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, TWO_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (TwoStarsFunction[currentGameWayDetail]) {
        newSelect = TwoStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC1203S: {
    name: "三星",
    code: "LOTVNNEC1203S",
    ruleDescription: LOTVNNEC120RuleEnum.LOTVNNEC1203S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, THREE_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (ThreeStarsFunction[currentGameWayDetail]) {
        newSelect = ThreeStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC1204S: {
    name: "四星",
    code: "LOTVNNEC1204S",
    ruleDescription: LOTVNNEC120RuleEnum.LOTVNNEC1204S,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, FOUR_STAR, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (FourStarsFunction[currentGameWayDetail]) {
        newSelect = FourStarsFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },

  LOTVNNEC120DXDS: {
    name: "大小单双",
    code: "LOTVNNEC120DXDS",
    ruleDescription: LOTVNNEC120RuleEnum.LOTVNNEC120DXDS,
    confirmValidation: [
      MONEY_REQUIRED,
      SELECTED_NON_NULL,
      MAX_MIN_BET,
      NOT_ENOUGH_MONEY,
      MAX_PAYOUT,
    ],
    confirmMoneyHaveInput: true,
    selectedProcess: ({ selected, currentBetNumber, currentGameWay, currentGameWayDetail }) => {
      let newSelected = [];
      Object.keys(selected).forEach((key) => {
        newSelected = newSelected?.concat(
          selected[key].map((s) => {
            return {
              methodText: `gameWay.items.${currentGameWay}${
                currentGameWayDetail ? `.${currentGameWayDetail}` : ""
              }${key ? `.${key}` : ""}`,
              method: "LOTVNNECDXDS",
              contentText: s,
              content: `${key}|${s}`,
              simpleMoney: currentBetNumber,
              money: currentBetNumber,
            };
          }),
        );
      });

      return newSelected;
    },
  },
  LOTVNNEC120ZXBZ: {
    name: "自选不中",
    code: "LOTVNNEC120ZXBZ",
    ruleDescription: LOTVNNEC120RuleEnum.LOTVNNEC120ZXBZ,
    haveTags: true,
    confirmValidation: [MONEY_REQUIRED, OPTIONAL, MAX_MIN_BET, NOT_ENOUGH_MONEY, MAX_PAYOUT],
    selectedProcess: ({ selected, currentBetNumber, currentGameWayDetail, currentGameItems }) => {
      let newSelect = [];
      if (OptionalFunction[currentGameWayDetail]) {
        newSelect = OptionalFunction[currentGameWayDetail].selectProcess({
          selected,
          currentBetNumber,
          currentGameWayDetail,
          method: currentGameItems?.game_method[0]?.code,
        });
      }
      return newSelect;
    },
  },
};

export default LOTVNNEC120SettingEnum;
