import { BALL_COUNT_EACH_GROUP } from "../../valudateFunctionEnum";

const FourStarsFunction = {
  // 特奖前四直选
  LOTVNNEC754STJQ4ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 一奖前四直选
  LOTVNNEC754S1JQ4ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 特奖后四直选
  LOTVNNEC754STJH4ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
  // 一奖后四直选
  LOTVNNEC754S1JH4ZX: {
    selectProcess: ({ selected, currentBetNumber, currentGameWayDetail, method }) => {
      const newSelect = [];
      let count = 1;
      const flatten = Object.keys(selected)
        .map((key) => {
          if (selected[key].length > 0) {
            count = count * selected[key].length;
            return selected[key].join(",");
          } else {
            return "_";
          }
        })
        .join("|");
      newSelect.push({
        methodText: `gameWay.items.${currentGameWayDetail}`,
        method: method,
        content: flatten,
        simpleMoney: currentBetNumber,
        count: count,
      });
      return newSelect;
    },
    validate: ({ selected }) => {
      return BALL_COUNT_EACH_GROUP({ selected });
    },
  },
};

export { FourStarsFunction };
