import { Card, List } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

import { moneyFormat } from "../../../../plugins/numberFormat";
import { getCurrency } from "../../../../plugins/utils";

import statusEnum from "../../enumeration/statusEnum";
import renderChoiceNumber from "../../utils/renderChoiceNumber";
import "./styles.scss";

function HistoryItem({ item, onShowDetail = () => {} }) {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  const CURRENCY = getCurrency().toUpperCase();

  return (
    <div className="bet-history-item">
      <Card
        key={"data" + item.id}
        title={<div>{t(`lottery.gameType.${item.game_type}`)}</div>}
        extra={
          <div
            style={{
              fontWeight: "normal",
              color: "#5f2300",
              fontSize: "14px",
            }}
          >
            {t("history.periodNumber", { period: item.period })}
          </div>
        }
        style={{
          borderRadius: "8px",
          backgroundColor: "var(--color-background-secondary)",
          marginBottom: "8px",
        }}
        onClick={() => {
          onShowDetail(item.id);
        }}
      >
        <List.Item
          extra={`【${t(`gameWay.${item.game_way_type}`)} ${
            item.game_way_detail_type ? `-${t(`gameWay.${item.game_way_detail_type}`)}` : ""
          }】${renderChoiceNumber(item.choice_number, t)}`}
        >
          {t("history.betOption")}
        </List.Item>
        <List.Item extra={moneyFormat(item.total_bet_point) + " " + CURRENCY}>
          {t("history.betAmount")}
        </List.Item>
        <List.Item
          // 狀態 0 待开奖 1已中奖 2 未中奖 3結算中 4已撤单
          extra={
            <span style={{ color: statusEnum[item.status]?.color }}>
              {statusEnum[item.status]?.text ? t(statusEnum[item.status]?.text) : "-"}
            </span>
          }
        >
          {t("normal.status")}
        </List.Item>
      </Card>
    </div>
  );
}

export default HistoryItem;
