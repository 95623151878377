import React, { useEffect, useState } from "react";

import { Popup } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GameResultRender from "../../../../components/GameResultRender";
import { useWebsocket } from "../../../../context/WebsocketContext";
import { useGetRecentGameResult } from "../../../../hooks/game/game.hook";
import styles from "./style.module.scss";
import "./styles.scss";
const LotteryResult = ({
  visible,
  setVisible,
  gameWaySetting,
  haveTags,
  gameCategoryDetailType,
  lotteryType,
  latestNumber,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentPeriod } = useWebsocket();
  const [gameResultData, setGameResultData] = useState();
  const { data: resultData, refetch: refetchGameResult } = useGetRecentGameResult(
    {
      enabled: false,
    },
    { game_type: lotteryType },
    currentPeriod?.period || (latestNumber && latestNumber[0].period),
  );
  const gameResultViewer = document.getElementById("gameResultViewer");
  const headerHeight = 42;
  useEffect(() => {
    if (resultData) {
      setGameResultData(resultData);
    }
  }, [resultData]);
  useEffect(() => {
    if (visible === true) {
      refetchGameResult();
    }
  }, [visible]);

  return (
    <Popup
      className="lottery-result-popup"
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      mask={false}
      bodyStyle={{
        height: `calc( var(--vh, 1vh) * 100 - ${gameResultViewer?.offsetHeight + headerHeight}px)`,
        overflowY: "scroll",
      }}
    >
      <div className={styles.container}>
        <div className={styles.table}>
          <div className={styles.header}>
            <div className={`${styles.column} ${styles.period}`}>{t("lottery.results.period")}</div>
            <div className={`${styles.column} ${styles.number}`}>{t("lottery.results.number")}</div>
          </div>
          <div className={styles.tableContent}>
            {gameResultData?.map((gameResult) => {
              return (
                <div className={styles.row}>
                  <div className={`${styles.column} ${styles.period}`}>{gameResult.period}</div>
                  <div className={`${styles.column} ${styles.number}`}>
                    <GameResultRender gameType={gameCategoryDetailType} data={gameResult} />{" "}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={styles.readMore}
          onClick={() => {
            navigate(`/gameResult/detail/${lotteryType}?type=${gameCategoryDetailType}`);
          }}
        >
          {t("normal.readMore")}
        </div>
      </div>
    </Popup>
  );
};

export default LotteryResult;
