import { DISABLE_BET_BUTTON_TIMER } from "../../../config";
export const closeTime = {
  LOTFT1: DISABLE_BET_BUTTON_TIMER,
  LOTFT3: DISABLE_BET_BUTTON_TIMER,
  LOTFT5: DISABLE_BET_BUTTON_TIMER,
  LOTFT10: DISABLE_BET_BUTTON_TIMER,
  LOTEC1: DISABLE_BET_BUTTON_TIMER,
  LOTEC3: DISABLE_BET_BUTTON_TIMER,
  LOTEC5: DISABLE_BET_BUTTON_TIMER,
  LOTEC10: DISABLE_BET_BUTTON_TIMER,
  LOTMS1: DISABLE_BET_BUTTON_TIMER,
  LOTMS3: DISABLE_BET_BUTTON_TIMER,
  LOTMS5: DISABLE_BET_BUTTON_TIMER,
  LOTMS10: DISABLE_BET_BUTTON_TIMER,
  LOTPK101: DISABLE_BET_BUTTON_TIMER,
  LOTPK103: DISABLE_BET_BUTTON_TIMER,
  LOTPK105: DISABLE_BET_BUTTON_TIMER,
  LOTPK1010: DISABLE_BET_BUTTON_TIMER,
  LOTVNNEC1: DISABLE_BET_BUTTON_TIMER,
  LOTVNNEC3: DISABLE_BET_BUTTON_TIMER,
  LOTVNNEC5: DISABLE_BET_BUTTON_TIMER,
  LOTVNNEC10: DISABLE_BET_BUTTON_TIMER,
  LOTVNNEC45: DISABLE_BET_BUTTON_TIMER,
  LOTVNNEC75: DISABLE_BET_BUTTON_TIMER,
  LOTVNNEC120: DISABLE_BET_BUTTON_TIMER,
};
