import React from "react";
import { useGetGameList } from "../../../../hooks/lottery.hooks";
import GameCategoryItem from "../GameCategoryItem";
import styles from "./style.module.scss";

const CategoryPopupContent = ({ changeGame = () => {} }) => {
  const { data: categories } = useGetGameList({});
  return (
    <div className={styles.popupContainer}>
      {categories &&
        categories?.map((category, index) => {
          return (
            <GameCategoryItem
              key={"category" + index}
              gameCategoryType={category.game_category_detail_type}
              gameList={category?.game_list}
              changeGame={changeGame}
            />
          );
        })}
    </div>
  );
};

export default CategoryPopupContent;
