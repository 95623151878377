import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";

function PK10({ data }) {
  const { t } = useTranslation();
  const getTextColor = (option) => {
    const colorDict = {
      big: "var(--color-result-red)",
      small: "var(--color-result-blue)",
      odd: "var(--color-result-red)",
      even: "var(--color-result-blue)",
    };
    return { color: colorDict[option] ?? "" };
  };

  const backgroundDict = {
    1: { start: "var(--color-dandelion)", end: "var(--color-mustard-yellow)" },
    2: { start: "var(--color-bright-sky-blue)", end: "var(--color-blue)" },
    3: { start: "var(--color-warm-grey)", end: "var(--color-greyish-brown)" },
    4: { start: "var(--color-mango)", end: "var(--color-blood-orange)" },
    5: {
      start: "var(--color-seafoam-blue)",
      end: "var(--color-turquoise-blue)",
    },
    6: { start: "var(--color-blue-violet)", end: "var(--color-violet-blue)" },
    7: { start: "var(--color-greyish)", end: "var(--color-warm-grey)" },
    8: { start: "var(--color-salmon)", end: "var(--color-red)" },
    9: { start: "var(--color-dull-red)", end: "var(--color-milk-chocolate)" },
    10: { start: "var(--color-bright-green)", end: "var(--color-deep-green)" },
  };

  const [finalResult, setFinalResult] = useState([]);
  const resultProccessor = (data) => {
    if (!data.lottery_number) {
      setFinalResult([]);
      return;
    }
    const nums = data.lottery_number.split(",");
    let formatResult = [];
    nums.map((item, index) => {
      formatResult.push({
        number: item,
      });
    });
    setFinalResult(formatResult);
  };
  useEffect(() => {
    if (!!data) resultProccessor(data);
  }, [data]);

  return (
    <div className={`${styles.pk10Result}`}>
      <div className={`${styles.row}`}>
        {!!finalResult &&
          finalResult?.map((item, index) => {
            return (
              <div
                className={`${styles.rowItemContainer}`}
                key={"pk10" + index}
              >
                <div
                  style={{
                    background: backgroundDict[item.number]
                      ? `linear-gradient(to bottom, ${
                          backgroundDict[item.number]?.start
                        },  ${backgroundDict[item.number]?.end})`
                      : `linear-gradient(to bottom, ${backgroundDict["1"]?.start},  ${backgroundDict["1"]?.end})`,
                  }}
                  className={`${styles.rowItem}`}
                >
                  <div className={`${styles.ball} ${styles[item.color]}`}>
                    <span>{item.number}</span>
                  </div>
                  {/* <div className={`${styles.zodiac}`}>
                    {t(`zodiac.${item.zodiac}`)}
                  </div> */}
                </div>
              </div>
            );
          })}
      </div>
      <div className={`${styles.row}`}>
        <div className={`${styles.rowDetailItem}`}>
          {data?.data?.sum_number}
        </div>
        <div
          className={`${styles.rowDetailItem} ${styles.bigSmall}`}
          style={getTextColor(data?.data?.big_small)}
        >
          {data?.data?.big_small
            ? t(`gameResult.${data?.data?.big_small}`)
            : ""}
        </div>
        <div
          className={`${styles.rowDetailItem} ${styles.oddEven}`}
          style={getTextColor(data?.data?.odd_even)}
        >
          {data?.data?.odd_even ? t(`gameResult.${data?.data?.odd_even}`) : ""}
        </div>
      </div>
    </div>
  );
}

export default PK10;
