import Selector from "../../../components/Selector";

const LOTMS1SelectorEnum = {
  // 特碼
  "LOTMS1TMTM.TM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS1TMTM.TM_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 半波
  "LOTMS1TMBP.BP_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 特碼生肖
  "LOTMS1TMSX.TMSX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 一肖
  "LOTMS1YX.YX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "LOTMS1YX.TW_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正肖
  "LOTMS1ZX.ZOX": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  "LOTMS1ZX.QSB": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連肖
  //  二肖碰
  "LOTMS1LXEXP.EXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  三肖碰
  "LOTMS1LXSAXP.SAXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  四肖碰
  "LOTMS1LXSIXP.SIXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      itemWidth: "33%",
      mutiple: true,
    },
  },
  //  五肖碰
  "LOTMS1LXWXP.WXP": {
    component: Selector.rectangleWithNums,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 連尾
  //  二尾连
  "LOTMS1LWELW.ELW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  三尾连
  "LOTMS1LWSALW.SALW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  四尾连
  "LOTMS1LWSILW.SILW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  五尾连
  "LOTMS1LWWLW.WLW": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 合肖
  //  中
  "LOTMS1HXZ.Z": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  //  不中
  "LOTMS1HXBZ.BZ": {
    component: Selector.rectangleWithNumsType2,
    params: {
      hideMethodTitle: true,
      mutiple: true,
      hideOdds: true,
    },
  },
  // 連碼
  //  四全中
  "LOTMS1LMSIQZ.SIQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三全中
  "LOTMS1LMSAQZ.SAQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  三中二
  "LOTMS1LMSZE.SZE": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二全中
  "LOTMS1LMEQZ.EQZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  二中特
  "LOTMS1LMEZT.EZT": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  特串
  "LOTMS1LMTC.TC": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選不中
  //  五不中
  "LOTMS1TSBCWBZ.WBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六不中
  "LOTMS1TSBCLBZ.LBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  七不中
  "LOTMS1TSBCQBZ.QBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  八不中
  "LOTMS1TSBCBBZ.BBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  九不中
  "LOTMS1TSBCJBZ.JBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十不中
  "LOTMS1TSBCSBZ.SBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十一不中
  "LOTMS1TSBCSYBZ.SYBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  十二不中
  "LOTMS1TSBCSEBZ.SEBZ": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 自選中一
  //  三中一
  "LOTMS1CYSAZY.SAZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  四中一
  "LOTMS1CYSIZY.SIZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  五中一
  "LOTMS1CYWZY.WZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  //  六中一
  "LOTMS1CYLZY.LZY": {
    component: Selector.circleType2,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
      hideOdds: true
    },
  },
  // 正码
  "LOTMS1CMCM.CM": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  // 正码 1
  "LOTMS1CMCM1.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS1CMCM1.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 2
  "LOTMS1CMCM2.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS1CMCM2.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 3
  "LOTMS1CMCM3.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS1CMCM3.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 4
  "LOTMS1CMCM4.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS1CMCM4.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 5
  "LOTMS1CMCM5.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS1CMCM5.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
  // 正码 6
  "LOTMS1CMCM6.CM16": {
    component: Selector.circle,
    params: {
      textNoI18n: true,
      mutiple: true,
      isColorfulBalls: true,
      itemWidth: "20%",
      hideMethodTitle: true,
    },
  },
  "LOTMS1CMCM6.CM16_GROUP": {
    component: Selector.rectangle,
    params: {
      hideMethodTitle: true,
      mutiple: true,
    },
  },
};

export default LOTMS1SelectorEnum;
