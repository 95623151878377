import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import WebsocketHeartbeatJs from "websocket-heartbeat-js";
import { SOCKET_URL, WS_PROTOCOL } from "../config";
import webSocketActionEnum from "../enumerations/webSocketActionEnum";

//SSC
// {
//   "channel": "LOTEC5",
//   "current_date_time": "2022-09-29 06:23:21 +00:00",
//   "period": "20220929077",
//   "start": "2022-09-29 06:20:00",
//   "end": "2022-09-29 06:25:00",
//   "countdown": "00:01:39",
//   "lottery_number": "",
//   "data": []
// }

// {
//   "channel": "LOTEC5",
//   "current_date_time": "2022-09-29 06:26:29 +00:00",
//   "period": "20220929077",
//   "start": "2022-09-29 06:20:00",
//   "end": "2022-09-29 06:25:00",
//   "countdown": "00:00:00",
//   "lottery_number": "0,8,9,5,9",
//   "data": {
//       "odd_even": "odd",
//       "big_small": "big",
//       "sum_number": 31,
//       "dragon_tiger": "tiger"
//   }
// }

//F3
// {
//   "channel": "LOTFT3",
//   "current_date_time": "2022-09-29 06:23:48 +00:00",
//   "period": "20220929128",
//   "start": "2022-09-29 06:21:00",
//   "end": "2022-09-29 06:24:00",
//   "countdown": "00:00:12",
//   "lottery_number": "",
//   "data": []
// }

// {
//   "channel": "LOTFT3",
//   "current_date_time": "2022-09-29 06:24:27 +00:00",
//   "period": "20220929128",
//   "start": "2022-09-29 06:21:00",
//   "end": "2022-09-29 06:24:00",
//   "countdown": "00:00:00",
//   "lottery_number": "1,2,2",
//   "data": {
//       "odd_even": "odd",
//       "big_small": "small",
//       "sum_number": 5
//   }
// }

//M6
// {
//   "channel": "LOTMS5",
//   "current_date_time": "2022-09-29 06:22:43 +00:00",
//   "period": "20220929077",
//   "start": "2022-09-29 06:20:00",
//   "end": "2022-09-29 06:25:00",
//   "countdown": "00:02:17",
//   "lottery_number": "",
//   "data": []
// }

// {
//   "channel": "LOTMS5",
//   "current_date_time": "2022-09-29 06:26:59 +00:00",
//   "period": "20220929077",
//   "start": "2022-09-29 06:20:00",
//   "end": "2022-09-29 06:25:00",
//   "countdown": "00:00:00",
//   "lottery_number": "32,46,14,38,28,31|07",
//   "data": {
//       "color": "green,red,blue,green,green,blue|red",
//       "zodiac_name": "rooster,pig,rabbit,rabbit,snake,monkey|monkey"
//   }
// }

const WebsocketContext = createContext({
  closeWebsocket: () => {},
  openWebsocket: () => {},
  subscribe: () => {},
  ping: () => {},
  setWebsocketChannel: () => {},
  isWebsocketConnected: false,
  currentPeriod: {},
  prevPeriod: {},
  setWebsocketToken: () => {},
  websocketToken: null,
  isWaitPeriod: false,
  setIsWaitPeriod: () => {},
  disableBet: false,
  setDisableBet: () => {},
  counter: null,
  setCounter: () => {},
});

export const WS_CHANNEL = {
  MARK_SIX_5: "LOTMS5",
  FAST3_3: "LOTFT3",
  EVERY_COLOR_5: "LOTEC5",
};

export const WebsocketContextProvider = ({ children }) => {
  // const WS_URL = `${WS_PROTOCOL}://${SOCKET_URL}?token=${TOKEN}`;
  const webSocket = useRef(null);
  const [isWebsocketConnected, setIsWebsocketConnected] = useState(false);
  const [websocketChannel, setWebsocketChannel] = useState("");
  const [prevPeriod, setPrevPeriod] = useState({});
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [websocketToken, setWebsocketToken] = useState();
  const [wsUrl, setwsUrl] = useState();
  const [isWaitPeriod, setIsWaitPeriod] = useState(false);
  const [disableBet, setDisableBet] = useState(false);
  const [counter, setCounter] = useState();

  const ping = () => {
    webSocket.current.send("ping");
  };

  const subscribe = () => {
    console.debug("SUBS to", {
      method: "SUBSCRIBE",
      channel: websocketChannel,
    });
    webSocket.current.send(
      JSON.stringify({
        method: "SUBSCRIBE",
        channel: websocketChannel,
      }),
    );
  };

  const unsubscribe = () => {
    console.debug("UNSUBS from", {
      method: "UNSUBSCRIBE",
      channel: websocketChannel,
    });
    webSocket.current.send(
      JSON.stringify({
        method: "UNSUBSCRIBE",
        channel: websocketChannel,
      }),
    );
    setWebsocketChannel("");
  };

  const openWebsocket = ({ channel }) => {
    if (!wsUrl) return;

    if (!webSocket.current) {
      const opt = {
        url: wsUrl,
        pingMsg: "ping",
      };
      try {
        console.log("opt", opt);
        setWebsocketChannel(channel);
        webSocket.current = new WebsocketHeartbeatJs(opt);
      } catch (error) {
        console.debug("error creating webscoket", error);
      }
      console.log("test", webSocket);
      setIsWebsocketConnected(true);
    }
  };

  const resetState = () => {
    setPrevPeriod({});
    setCurrentPeriod({});
    setIsWaitPeriod(false);
  };

  const closeWebsocket = () => {
    if (webSocket.current) {
      try {
        unsubscribe();
        webSocket.current.close();
        webSocket.current = null;
        setIsWebsocketConnected(false);
        resetState();
      } catch (error) {
        console.debug(error);
      }
    }
  };

  useEffect(() => {
    if (websocketToken) {
      setwsUrl(`${WS_PROTOCOL}://${SOCKET_URL}?token=${websocketToken}`);
    }
    // else {
    //   const storageToken = window.localStorage.getItem(localStorageKey.TOKEN);
    //   setWebsocketToken(storageToken);
    //   setwsUrl(`${WS_PROTOCOL}://${SOCKET_URL}?token=${storageToken}`);
    // }
    console.debug("token", websocketToken);
    console.debug("ws url", wsUrl);
  }, [websocketToken]);

  useEffect(() => {
    console.debug("+++ws url", wsUrl);
  }, [wsUrl]);

  useEffect(() => {
    console.debug("WS", webSocket.current, "is connected", isWebsocketConnected);
    if (webSocket.current && isWebsocketConnected) {
      webSocket.current.onopen = () => {
        console.log("WebSocket Connection Sucess");
        subscribe();
      };
      webSocket.current.onerror = (error) => {
        console.log("WebSocket error", JSON.stringify(error));
      };
      webSocket.current.onclose = () => {
        // unsubscribe();
        console.log("WebSocket Connection Closed");
      };
      webSocket.current.onreconnect = (error) => {
        console.log("WebSocket Reconnection", JSON.stringify(error));
      };

      webSocket.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          const action = data.action;
          switch (action) {
            //START === CURRENT PERIOD
            case webSocketActionEnum.START:
              if (data.lottery_number === "") {
                if (data.period === null) {
                  setIsWaitPeriod(true);
                } else {
                  setIsWaitPeriod(false);
                }
                setCurrentPeriod(data);
              }
              break;
            case webSocketActionEnum.PENDING:
              if (!prevPeriod.period || prevPeriod.period !== data.period) {
                setPrevPeriod(data);
              }
              break;
            case webSocketActionEnum.END:
              if (!prevPeriod.period || prevPeriod.period === data.period) {
                setPrevPeriod(data);
              }
              break;
            default:
              // PENDING || END === PREV PERIOD
              // PENDING: KILL RATE STILL CALCULATING, lottery_number == empty
              // END    : KILL RATE ALRD FINISH CALCULATE, lottery_number == have data inside
              break;
          }

          // if (data.lottery_number === "") {
          //   if (data.period === null) {
          //     setIsWaitPeriod(true);
          //   } else {
          //     setIsWaitPeriod(false);
          //   }
          //   setCurrentPeriod(data);
          // } else {
          //   setPrevPeriod(data);
          // }
        } catch (error) {
          console.debug(event.data);
        }
      };
    }
  }, [webSocket.current, isWebsocketConnected, isWaitPeriod]);

  return (
    <WebsocketContext.Provider
      value={{
        openWebsocket,
        closeWebsocket,
        subscribe,
        ping,
        setWebsocketToken,
        wsUrl,
        // setWebsocketChannel,
        isWebsocketConnected,
        currentPeriod,
        prevPeriod,
        websocketToken,
        isWaitPeriod,
        setIsWaitPeriod,
        disableBet,
        setDisableBet,
        counter,
        setCounter,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};
export const useWebsocket = () => {
  const WebsocketValue = useContext(WebsocketContext);
  return WebsocketValue;
};
